'use strict';

OccupancyReportController.$inject = ['$state', '$scope', '$document', '$timeout', 'Principal', '$q', '$filter',
    'googleChartApiPromise', 'Statistics', 'Reporting', 'ApplicationContext', 'ApplicationProfile', 'TextUtil'];

function OccupancyReportController($state, $scope, $document, $timeout, Principal, $q, $filter,
                                   googleChartApiPromise, Statistics, Reporting, ApplicationContext,
                                   ApplicationProfile, TextUtil) {
    var vm = this;
    vm.displayChart = displayChart;
    vm.onChartSelect = onChartSelect;
    vm.setSimulationToActuals = setSimulationToActuals;
    vm.calculateSimulation = calculateSimulation;
    vm.calcPct = Reporting.calcPct;
    vm.calculateAvgDuration = calculateAvgDuration;

    vm.isMeal = (ApplicationContext.getProductType() == 'MEAL');
    vm.isTableManaged = ApplicationContext.isTableManaged();
    vm.coverTrans = TextUtil.translate('person','s');

    vm.reportOccupancyRate = ApplicationProfile.isReportOccupancyRateEnabled();
    vm.reportOccupancyRateText = ApplicationProfile.upgradeTierText("ReportOccupancyRate");
    vm.openWindow = ApplicationProfile.openAppCloverPage;

    vm.openPicker = openPicker;

    vm.selectedDate = moment().subtract(1, 'weeks');
    vm.endDate = null;
    vm.selectedDay = 0;

    vm.minDate = moment().subtract(10);
    vm.maxDate = moment();

    vm.pickerDate = moment(); //initial

    function onChartSelect(selectedItem) {
        if (typeof selectedItem != 'undefined') {
            var selDay = selectedItem.row;
            if (selDay != vm.selectedDay) {
                vm.selectedDay = selDay;
                getReportingOccupancyByHour().then(displayHourChart);
            }
        }
    }

    $scope.openingHourNotDefined = function () {
        return ApplicationContext.isBusinessHoursNotDefined();
    }

    if (vm.reportOccupancyRate) {
        load();
    }

    function calculateAvgDuration(occupancyData) {
        if (occupancyData == null) return '';
        if (occupancyData.totalVisits == 0) return '-';
        else {
            return '' + Math.round(occupancyData.totalEventsSeconds / occupancyData.totalVisits / 60) + 'min';
        }
    }

    //datepicker code
    function getPicker(inputName) {
        return $document.find('input[name="' + inputName + '"]');
    }

    function openPicker(inputName) {
        getPicker(inputName).focus();
    }

    function clearPicker(inputName) {
        getPicker(inputName).val('');
    }

    function dPickerInit(inputName, initDate) {
        var pickerObj
        pickerObj = getPicker(inputName);
        pickerObj.daterangepicker(
            {
                //configuration parameters
                autoUpdateInput: false,
                singleDatePicker: true,
                showDropdowns: true,
                locale: {format: 'MMM D, YYYY'}
            },
            function (start, end, label) {
                //callback function - called on picker value change - dates sent in callback
                if (start != vm.selectedDate) {
                    vm.selectedDate = start;
                    reload();
                } else {
                    console.log("Selected date same as existing - no need for reload")
                }
            }
        );

        pickerObj.on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('MMM D, YYYY'));
        });

        pickerObj.on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('');
        });

        //initial datepicker values
        //console.log('dPickerInit   2:' + inputName + '   :' + initDate);
        if (initDate != null) {
            pickerObj.data('daterangepicker').setStartDate(moment(initDate));
            pickerObj.data('daterangepicker').setEndDate(moment(initDate));
            pickerObj.val(moment(initDate).format('MMM D, YYYY'));
        }
    }

    $document.ready(
        function () {
            dPickerInit('pickerDate', vm.selectedDate);
        }
    );


    function load() {
        $q.all([
            googleChartApiPromise,
            getReportingOccupancy(),
            getReportingOccupancyByHour()
        ]).then(displayCharts);
    }

    function displayCharts() {
        displayChart();
        displayHourChart();
        setSimulationToActuals('week');
        setSimulationToActuals('day');
    }

    function reload() {
        load();
        //getReportingOccupancy().then(displayChart);
    }

    function getReportingOccupancy() {
        vm.endDate = moment(vm.selectedDate).clone().add(6, 'days');
        return Statistics.getReportingOccupancy({
            //selectedDate: vm.selectedDate == null ? null : vm.selectedDate.clone().startOf('day').toISOString()
            selectedDate: vm.selectedDate == null ? null : vm.selectedDate.clone().format('YYYY-MM-DD')
        }, function (data) {
            vm.data = data;
        }).$promise;
    }

    function getReportingOccupancyByHour() {
        //return Statistics.getReportingExpectedOccupancyByHour({ //uncomment for test/comparison of values
        return Statistics.getReportingOccupancyByHour({
            selectedDate: vm.selectedDate == null ? null : vm.selectedDate.clone().add(vm.selectedDay, 'days').format('YYYY-MM-DD'),
            reportType: 'excludeExpected'
        }, function (data) {
            vm.hourData = data;
        }).$promise;
    }

    Principal.identity().then(function (account) {
        $scope.account = account;
        $scope.isAuthenticated = Principal.isAuthenticated;
    });

    function myToolTip(day, occupancy, revenue, loss) {
        return '&nbsp;<b>' + day + '</b><br>' +
            '&nbsp;&nbsp;&nbsp;Occupancy: <span class="text-danger">' + occupancy + '</span><br/>' +
            '&nbsp;&nbsp;&nbsp;Revenue: <span class="text-danger">' + revenue + '</span><br/>' +
            '&nbsp;&nbsp;&nbsp;No show loss: <span class="text-danger">' + loss + '</span>'
    }

    function displayChart() {
        var deferred = $q.defer();
        setSimulationToActuals('week');
        // Occupance report
        //WEEK
        var dataOcuppancyWeek = new google.visualization.DataTable();
        dataOcuppancyWeek.addColumn('string', 'Day in Week');
        dataOcuppancyWeek.addColumn('number', 'Occupancy rate', 'color: #1ABC9C');
        dataOcuppancyWeek.addColumn({type: 'string', role: 'annotation'});
        dataOcuppancyWeek.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});
        dataOcuppancyWeek.addColumn('number', 'Avg revenue per '+vm.coverTrans, 'color: #336699');


        _.each(vm.data.occupancyByWeekday, function (item) {
            dataOcuppancyWeek.addRow(
                [
                    item.weekdayName,
                    item.occupancyPct,
                    $filter('percentage')(item.occupancyPct, 2),
                    myToolTip(item.weekdayName, $filter('percentage')(item.occupancyPct, 2), $filter('currency')(item.revenue), $filter('currency')(item.noShowLoss)),
                    {
                        v: item.avgRevenuePerCover,
                        f: $filter('currency')(item.avgRevenuePerCover)
                    }
                ]
            );
        });
        /*
         dataOcuppancyWeek.addRows([
         ['Sunday', 0.6543, '65.43%', myToolTip('Sunday', '65.43%', '$13,456.34', '$2.435,98'), 35.32],
         ['Monday', 0.4345, '43.45%', myToolTip('Monday', '43.45%', '$13,456.34', '$2.435,98'), 41.54],
         ['Tuesday', 0.5532, '55.32%', myToolTip('Tuesday', '55.32%', '$13,456.34', '$2.435,98'), 35.55],
         ['Wednesday', 0.5745, '57.45%', myToolTip('Wednesday', '57.45%', '$13,456.34', '$2.435,98'), 36.65],
         ['Thursday', 0.6121, '61.21%', myToolTip('Thursday', '61.21%', '$13,456.34', '$2.435,98'), 43.55],
         ['Friday', 0.6744, '67.44%', myToolTip('Friday', '67.44%', '$13,456.34', '$2.435,98'), 49.34],
         ['Saturday', 0.7756, '77.56%', myToolTip('Saturday', '77.56%', '$13,456.34', '$2.435,98'), 55.32]
         ]);
         */

        var optionsOccupancyWeek = {
            //width: 900,
            height: 400,

            tooltip: {
                isHtml: true
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 10,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },

            hAxis: {
                title: 'Day in week',
                titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
            },

            vAxes: {
                0: {
                    viewWindowMode: 'explicit',
                    //gridlines: {color: 'transparent'},
                    gridlines: {count: 9}, viewWindow: {min: 0},
                    title: 'Occupancy rate',
                    format: 'percent',
                    titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
                },
                1: {
                    gridlines: {color: 'transparent'},
                    title: 'Avg per '+vm.coverTrans,
                    format: 'currency',
                    color: '#CC9933',
                    viewWindow: {
                        min: 0
                    },
                    textStyle: {color: '#CC3333'},
                    titleTextStyle: {color: '#CC3333', fontSize: 14, bold: true}
                }
            },
            chartArea: {width: '80%', height: '65%'},
            series: {
                0: {
                    type: 'bars',
                    targetAxisIndex: 0
                },
                1: {
                    type: 'line',
                    targetAxisIndex: 1
                }
            },
            formatters: {},
            colors: ['#1ABC9C', '#CC3333'],
            legend: {position: 'top', alignment: 'end'}
        };

        $scope.chartComboObjectOccupancyWeek = {
            type: "ComboChart",
            options: optionsOccupancyWeek,
            data: dataOcuppancyWeek
        };
        return deferred.promise;

    }

    function displayHourChart() {
        var deferred = $q.defer();
        setSimulationToActuals('day');
        // DAY
        var dataOcuppancyDay = new google.visualization.DataTable();
        dataOcuppancyDay.addColumn('string', 'Hour');
        dataOcuppancyDay.addColumn('number', 'Seat occupancy rate', 'color: #1ABC9C');
        dataOcuppancyDay.addColumn({type: 'string', role: 'annotation'});
        dataOcuppancyDay.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});
        dataOcuppancyDay.addColumn('number', 'Table occupancy rate', 'color: #336699');

        _.each(vm.hourData.occupancyByHour, function (item) {
            dataOcuppancyDay.addRow(
                [
                    item.hourOfDayFmt,
                    item.occupancyPct,
                    $filter('percentage')(item.occupancyPct, 2),
                    myToolTip(item.hourOfDayFmt, $filter('percentage')(item.occupancyPct, 2), $filter('currency')(item.revenue), $filter('currency')(item.noShowLoss)),
                    {
                        v: item.occupancyTablePct,
                        f: $filter('percentage')(item.occupancyTablePct, 2)
                    }
                ]);
        });
        /*
         dataOcuppancyDay.addRows([
         ['7am', 0.3450, '34.50%', myToolTip('7am', '65.43%', '$13,456.34', '$2.435,98'), 27.50],
         ['8am', 0.4253, '42.53%', myToolTip('8am', '65.43%', '$13,456.34', '$2.435,98'), 33.34],
         ]);
         */

        var optionsOccupancyDay = {
            //width: 900,
            height: 400,

            tooltip: {
                isHtml: true
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 10,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },

            hAxis: {
                title: 'Hour of day',
                titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
            },

            vAxes: {
                0: {
                    viewWindowMode: 'explicit',
                    //gridlines: {color: 'transparent'},
                    gridlines: {count: 9},
                    title: 'Seat occupancy rate',
                    titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
                    format: 'percent',
                    viewWindow: {
                        min: 0
                    }
                },
                1: {
                    //gridlines: {color: 'transparent'},
                    title: 'Table occupancy rate',
                    //titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
                    format: 'percent',
                    color: '#CC9933',
                    viewWindow: {
                        min: 0
                    },
                    textStyle: {color: '#CC3333'},
                    titleTextStyle: {color: '#CC3333'}
                }
            },
            chartArea: {width: '80%', height: '65%'},
            series: {
                0: {
                    type: 'bars',
                    targetAxisIndex: 0
                },
                1: {
                    type: 'line',
                    targetAxisIndex: 0
                }
            },
            formatters: {},
            colors: ['#1ABC9C', '#CC3333'],
            legend: {position: 'top', alignment: 'end'}
        };

        $scope.chartComboObjectOccupancyDay = {
            type: "ComboChart",
            options: optionsOccupancyDay,
            data: dataOcuppancyDay
        };

        return deferred.promise;
    }

////////////////////
//simulation sliders
////////////////////

    function setSimulationToActuals(slider) {
        if ('week' == slider) {
            vm.slider_occupancy_week.update({from: vm.data.totalWeekOccupancy.occupancyPct * 100});
            vm.slider_avgrevenuepercover_week.update({
                from: vm.data.totalWeekOccupancy.avgRevenuePerCover,
                max: Number(3 * vm.data.totalWeekOccupancy.avgRevenuePerCover).toFixed(0)
            });
            vm.slider_noshow_week.update({from: vm.data.totalWeekOccupancy.noShowPct * 100});
            calculateSimulation('week');
        }
        if ('day' == slider) {
            vm.slider_occupancy_day.update({from: vm.hourData.totalDayOccupancy.occupancyPct * 100});
            vm.slider_avgrevenuepercover_day.update({
                from: vm.hourData.totalDayOccupancy.avgRevenuePerCover,
                max: Number(3 * vm.hourData.totalDayOccupancy.avgRevenuePerCover).toFixed(0)
            });
            vm.slider_noshow_day.update({from: vm.hourData.totalDayOccupancy.noShowPct * 100});

            calculateSimulation('day');
        }
    }

    function calculateSimulation(slider) {
        $timeout(function () {
            if ('week' == slider) {
                var estimatedCovers = vm.slider_occupancy_week.result.from / 100 * vm.data.totalWeekOccupancy.totalCovers / vm.data.totalWeekOccupancy.occupancyPct;
                var estimatedVisits = vm.slider_occupancy_week.result.from / 100 * vm.data.totalWeekOccupancy.totalVisits / vm.data.totalWeekOccupancy.occupancyPct;
                vm.week_calc.noShowLoss = vm.slider_noshow_week.result.from / 100 * vm.data.totalWeekOccupancy.noShowLoss / vm.data.totalWeekOccupancy.noShowPct;
                vm.week_calc.revenue = vm.slider_avgrevenuepercover_week.result.from * estimatedCovers - (vm.week_calc.noShowLoss - vm.data.totalWeekOccupancy.noShowLoss);
                vm.week_calc.tips = vm.week_calc.revenue * vm.data.totalWeekOccupancy.noShowPct;
                //$scope.$apply();
            }
            if ('day' == slider) {
                var estimatedCovers = vm.slider_occupancy_day.result.from / 100 * vm.hourData.totalDayOccupancy.totalCovers / vm.hourData.totalDayOccupancy.occupancyPct;
                var estimatedVisits = vm.slider_occupancy_day.result.from / 100 * vm.hourData.totalDayOccupancy.totalVisits / vm.hourData.totalDayOccupancy.occupancyPct;
                vm.day_calc.noShowLoss = vm.slider_noshow_day.result.from / 100 * vm.hourData.totalDayOccupancy.noShowLoss / vm.hourData.totalDayOccupancy.noShowPct;
                vm.day_calc.revenue = vm.slider_avgrevenuepercover_day.result.from * estimatedCovers - (vm.day_calc.noShowLoss - vm.hourData.totalDayOccupancy.noShowLoss);
                vm.day_calc.tips = vm.day_calc.revenue * vm.hourData.totalDayOccupancy.noShowPct;
                //$scope.$apply();
            }
        });
    }

    vm.week_calc = {
        revenue: 0,
        tips: 0,
        noShowLoss: 0
    };

    vm.day_calc = {
        revenue: 0,
        tips: 0,
        noShowLoss: 0
    };

//WEEK
    vm.div_slider_occupancy_week = $document.find('#slider_occupancy_week');
    vm.div_slider_occupancy_week.ionRangeSlider({
        min: 0,
        max: 100,
        step: 0.01,
        from: 0,
        postfix: "%",
        prettify: true,
        grid: true,
        decorate_both: true,
        onChange: function (data) {
            calculateSimulation('week');
        }
    });
    vm.slider_occupancy_week = vm.div_slider_occupancy_week.data("ionRangeSlider");

    vm.div_slider_avgrevenuepercover_week = $document.find('#slider_avgrevenuepercover_week');
    vm.div_slider_avgrevenuepercover_week.ionRangeSlider({
        min: 0,
        max: 250, // 3x avg revenue per cover
        step: 0.01,
        from: 0,
        prefix: "$",
        max_postfix: '+',
        prettify: true,
        grid: true,
        decorate_both: true,
        onChange: function (data) {
            vm.calculateSimulation('week');
        }
    });
    vm.slider_avgrevenuepercover_week = vm.div_slider_avgrevenuepercover_week.data("ionRangeSlider");

    vm.div_slider_noshow_week = $document.find('#slider_noshow_week');
    vm.div_slider_noshow_week.ionRangeSlider({
        min: 0,
        max: 100, // 3x avg revenue per cover
        step: 0.01,
        from: 0,
        postfix: "%",
        prettify: true,
        grid: true,
        decorate_both: true,
        onChange: function (data) {
            vm.calculateSimulation('week');
        }
    });
    vm.slider_noshow_week = vm.div_slider_noshow_week.data("ionRangeSlider");

//DAY
    vm.div_slider_occupancy_day = $document.find('#slider_occupancy_day');
    $("#slider_occupancy_day").ionRangeSlider({
        min: 0,
        max: 100,
        step: 0.01,
        from: 0,
        postfix: "%",
        prettify: true,
        grid: true,
        decorate_both: true,
        onChange: function (data) {
            vm.calculateSimulation('day');
        }
    });
    vm.slider_occupancy_day = vm.div_slider_occupancy_day.data("ionRangeSlider");

    vm.div_slider_avgrevenuepercover_day = $document.find('#slider_avgrevenuepercover_day');
    $("#slider_avgrevenuepercover_day").ionRangeSlider({
        min: 0,
        max: 250, // 3x avg revenue per cover
        step: 0.01,
        from: 0,
        prefix: "$",
        max_postfix: '+',
        prettify: true,
        grid: true,
        decorate_both: true,
        onChange: function (data) {
            vm.calculateSimulation('day');
        }
    });
    vm.slider_avgrevenuepercover_day = vm.div_slider_avgrevenuepercover_day.data("ionRangeSlider");

    vm.div_slider_noshow_day = $document.find('#slider_noshow_day');
    $("#slider_noshow_day").ionRangeSlider({
        min: 0,
        max: 100, // 3x avg revenue per cover
        step: 0.01,
        from: 0,
        postfix: "%",
        prettify: true,
        grid: true,
        decorate_both: true,
        onChange: function (data) {
            vm.calculateSimulation('day');
        }
    });
    vm.slider_noshow_day = vm.div_slider_noshow_day.data("ionRangeSlider");

}

angular.module('seatonApp')
    .controller('OccupancyReportController', OccupancyReportController);
