(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('WorkTimeController', WorkTimeController);

    WorkTimeController.$inject = ['$scope', '$state', 'SettingsResource', 'Log', '$location', '$uibModal', '$document',
        'Notification', 'SweetAlert'];

    var DATE_FORMAT = 'YYYY-MM-DD';
    var TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
    var TIME_DISPLAY_FORMAT = 'hh:mmA';
    var DATE_PICKER_FORMAT = 'MMM D, YYYY';

    function WorkTimeController($scope, $state, SettingsResource, Log, $location, $uibModal, $document, Notification, SweetAlert) {

        var vm = this;
        vm.synchronizeWithClover = true;
        vm.monday = null;
        vm.tuesday = null;
        vm.wednesday = null;
        vm.thursday = null;
        vm.friday = null;
        vm.saturday = null;
        vm.sunday = null;
        vm.nonworkingDays = null;

        vm.fromTime = -1;
        vm.toTime=-1;
        vm.specialDateFromTime = -1;
        vm.specialDateToTime = -1;

        vm.selectedDay = moment.now();
        vm.bookingTime = -1;


        // initialize date & time picker
        $document.ready(
            function () {
                // date picker
                vm.datePicker = $document.find('input[name="datePicker"]');
                vm.datePicker.daterangepicker(
                    {
                        singleDatePicker: true,
                        showDropdowns: true,
                        locale: {format: DATE_PICKER_FORMAT}
                    },
                    function (start, end, label) {
                        vm.selectedDay = start.format(DATE_FORMAT);
                        vm.selectedDayFormatted = moment(vm.selectedDay, DATE_FORMAT).format(DATE_PICKER_FORMAT);
                    }
                );
                vm.datePicker.val(vm.selectedDayFormatted);
            }
        );

        $scope.openDatePicker = function () {
            vm.datePicker.focus();
        }


        // get data
        SettingsResource.getWorkTimeSettings({}, onSuccessGetData, onErrorGetData);

        function onSuccessGetData(data, headers) {

            $scope.data = data;
            vm.synchronizeWithClover = data.syncWithClover;

            vm.monday = data.monday;
            vm.tuesday = data.tuesday;
            vm.wednesday = data.wednesday;
            vm.thursday = data.thursday;
            vm.friday = data.friday;
            vm.saturday = data.saturday;
            vm.sunday = data.sunday;
            vm.nonworkingDays = data.nonWorkingDays;
            vm.enableTimeSlots = data.enableTimeSlots;
            vm.sameForAllWeekDays = data.sameForAllWeekDays;
            vm.mondaySlots = data.mondaySlots;
            vm.tuesdaySlots = data.tuesdaySlots;
            vm.wednesdaySlots = data.wednesdaySlots;
            vm.thursdaySlots = data.thursdaySlots;
            vm.fridaySlots = data.fridaySlots;
            vm.saturdaySlots = data.saturdaySlots;
            vm.sundaySlots = data.sundaySlots;
            vm.weekSlots = data.weekSlots;
            vm.specialDays = data.specialDays;

            Log.debug("WorkTimeController", "HTTP request succeed.");
        }

        function onErrorGetData(error) {
            Log.debug("WorkTimeController", "HTTP request error." + error);
        }


        // sync Clover changed
        $scope.syncCloverChanged = function() {
            if(vm.synchronizeWithClover) {
                vm.warningText = "Do you want to enable synchronization with Clover and use business hours defined in Clover system?"
            } else {
                vm.warningText = "Do you want to disable business hours synchronization with Clover?"
            }
            SweetAlert.swal({
                    title: "Confirm action",
                    text: vm.warningText,
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#ec4758",
                    confirmButtonColor: "#1ab394",
                    cancelButtonText: "No",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        SettingsResource.syncWithClover({syncClover: vm.synchronizeWithClover}, onSuccessGetData, onErrorGetData);
                    } else {
                        vm.synchronizeWithClover = !vm.synchronizeWithClover;
                    }
                });

        }

        // add working hours
        $scope.addWorkingHours = function(dayOfTheWeek) {
            Log.debug("WorkTimeController", " Adding business hours with parameters:" + "Day="+dayOfTheWeek+" from="+vm.fromTime+" to="+vm.toTime);

            var valid = validateBusinessHourInput(dayOfTheWeek, vm.fromTime, vm.toTime);
            if(valid) {
                var business_hours = {
                    day: dayOfTheWeek,
                    fromHour: vm.fromTime,
                    endHour: vm.toTime
                }

                SettingsResource.addBusinessHours(business_hours, onSuccessGetData, onErrorGetData);

                vm.fromTime = -1;
                vm.toTime = -1;
            }
        }


        // add special day working hours
        $scope.addSpecialDayWorkingHours = function(dayOfTheWeek) {
            Log.debug("WorkTimeController", " Adding special date business hours with parameters:" +
                "Date="+vm.selectedDay+" from="+vm.specialDateFromTime+" to="+vm.specialDateToTime+" description="+vm.specialDateDescription);

            var valid = validateBusinessHourInput(dayOfTheWeek, vm.specialDateFromTime, vm.specialDateToTime);
            if(valid) {
                var business_hours = {
                    day: dayOfTheWeek,
                    specialDate: vm.selectedDay,
                    description: vm.specialDateDescription,
                    fromHour: vm.specialDateFromTime,
                    endHour: vm.specialDateToTime
                }

                SettingsResource.addSpecialDayWorkingHours(business_hours, onSuccessGetData, onErrorGetData);

                vm.specialDateFromTime = -1;
                vm.specialDateToTime = -1;
            }
        }


        // add non working day
        $scope.addNonWorkingDay = function() {
            console.log("Day="+vm.selectedDay+" description="+vm.description);

            var non_working_day = {
                nonWorkingDay: vm.selectedDay,
                description: vm.description
            }

                SettingsResource.addNonWorkingDay(non_working_day, onSuccessGetData, onErrorGetData);

                vm.description = null;
                vm.selectedDay = moment.now();
                vm.selectedDayFormatted = moment(vm.selectedDay).format(DATE_PICKER_FORMAT);
                vm.datePicker.val(vm.selectedDayFormatted);

        }

        $scope.enableTimeSlots = function() {
            console.log("Enable predefined bokking time.");

            SweetAlert.swal({
                    title: "Confirm action",
                    text: vm.warningText,
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#ec4758",
                    confirmButtonColor: "#1ab394",
                    cancelButtonText: "No",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        SettingsResource.enableTimeSlots({enableTimeSlots: vm.enableTimeSlots}, onSuccessGetData, onErrorGetData);
                    } else {
                        vm.enableTimeSlots = !vm.enableTimeSlots;
                    }
                });
        }

        $scope.sameForAllDays = function() {
            console.log("Set if predefined time slot same for all day in the week or not.");
            SettingsResource.sameForAllDeysInWeek({useSame: vm.sameForAllWeekDays}, onSuccessGetData, onErrorGetData);

        }

        // add predefined bookin slot
        $scope.addPredefinedBookingTime = function(dayOfTheWeek) {
            console.log("Day="+dayOfTheWeek+" booking time="+vm.bookingTime);

            if(vm.bookingTime!=-1) {
                var predefined_booking_times = {
                    dayInWeek: dayOfTheWeek,
                    bookingTime: vm.bookingTime
                }

                SettingsResource.addPredefinedBookingTime(predefined_booking_times, onSuccessGetData, onErrorGetData);
            }

            vm.bookingTime = -1;
        }


        function validateBusinessHourInput(dayOfTheWeek, fromTime, toTime) {
            var a_start = moment(fromTime, "HH:mm");
            if(toTime=='00:00') toTime='23:59';
            var a_end = moment(toTime, "HH:mm");
            if (a_start >= a_end) { // from has to smaller than to
                //vm.fromHigher = true;
                Notification.showAlert("error", "End (field To) of business hours is before or same as start (field From)!");
                return false;
            }

            /*var overlap = false;
            if (dayOfTheWeek == 1) {
                overlaps = overlaps(a_start, a_end, vm.monday);
            }

            if(overlap) {
                vm.overlaps = true;
                return false;
            }*/

            return true;
        }

        function overlaps(a_start, a_end, day) {
            // check overlap times
            for(var busHours in day) {
                console.log("BusHours from:"+busHours.fromHour+" to:"+busHours.endHour);
                var b_start = moment(busHours.fromHour12, "hh:mm a");
                var b_end = moment(busHours.endHour12, "hh:mm a")-1;
                console.log("Range Defined b_start:"+b_start+" b_end:"+b_end);

                if ((a_start<=b_start && b_start<= a_end) ||  // b starts in a
                        (a_start<=b_end && b_end<= a_end) || // b ends in a
                            (b_start<a_start && a_end<b_end)) {
                    return true;
                }
            }

            return false;
        }

        // deleteBusinessHours
        $scope.deleteWorkingHours = function(businessHourId) {
            console.log("Delete business hours with id:"+businessHourId);
            SettingsResource.deleteBusinessHours({id: businessHourId}, onSuccessGetData, onErrorGetData);
        }

        $scope.deleteNonWorkingDay = function(nonWorkingDayId) {
            console.log("Delete non working day with id:"+nonWorkingDayId);
            SettingsResource.deleteNonWorkingDay({id: nonWorkingDayId}, onSuccessGetData, onErrorGetData);

        }

        $scope.deleteTimeSlot = function(onlineTimeSlotId) {
            console.log("Delete online predefined time slot with id:"+onlineTimeSlotId);
            SettingsResource.deletePredefinedBookingTime({id: onlineTimeSlotId}, onSuccessGetData, onErrorGetData);
        }

        $scope.isClosed = function(day) {
            if(Array.isArray(day) && day.length) return false;
            else return true;
        }

        $scope.formatDateForDisplay = function(dateToFormat) {
            return moment(dateToFormat, DATE_FORMAT).format(DATE_PICKER_FORMAT);
        }

        $scope.availableTimes = [
            {time: "12:00 AM", timeForDB: "00:00"},
            {time: "12:15 AM", timeForDB: "00:15"},
            {time: "12:30 AM", timeForDB: "00:30"},
            {time: "12:45 AM", timeForDB: "00:45"},
            {time: "1:00 AM", timeForDB: "01:00"},
            {time: "1:15 AM", timeForDB: "01:15"},
            {time: "1:30 AM", timeForDB: "01:30"},
            {time: "1:45 AM", timeForDB: "01:45"},
            {time: "2:00 AM", timeForDB: "02:00"},
            {time: "2:15 AM", timeForDB: "02:15"},
            {time: "2:30 AM", timeForDB: "02:30"},
            {time: "2:45 AM", timeForDB: "02:45"},
            {time: "3:00 AM", timeForDB: "03:00"},
            {time: "3:15 AM", timeForDB: "03:15"},
            {time: "3:30 AM", timeForDB: "03:30"},
            {time: "3:45 AM", timeForDB: "03:45"},
            {time: "4:00 AM", timeForDB: "04:00"},
            {time: "4:15 AM", timeForDB: "04:15"},
            {time: "4:30 AM", timeForDB: "04:30"},
            {time: "4:45 AM", timeForDB: "04:45"},
            {time: "5:00 AM", timeForDB: "05:00"},
            {time: "5:15 AM", timeForDB: "05:15"},
            {time: "5:30 AM", timeForDB: "05:30"},
            {time: "5:45 AM", timeForDB: "05:45"},
            {time: "6:00 AM", timeForDB: "06:00"},
            {time: "6:15 AM", timeForDB: "06:15"},
            {time: "6:30 AM", timeForDB: "06:30"},
            {time: "6:45 AM", timeForDB: "06:45"},
            {time: "7:00 AM", timeForDB: "07:00"},
            {time: "7:15 AM", timeForDB: "07:15"},
            {time: "7:30 AM", timeForDB: "07:30"},
            {time: "7:45 AM", timeForDB: "07:45"},
            {time: "8:00 AM", timeForDB: "08:00"},
            {time: "8:15 AM", timeForDB: "08:15"},
            {time: "8:30 AM", timeForDB: "08:30"},
            {time: "8:45 AM", timeForDB: "08:45"},
            {time: "9:00 AM", timeForDB: "09:00"},
            {time: "9:15 AM", timeForDB: "09:15"},
            {time: "9:30 AM", timeForDB: "09:30"},
            {time: "9:45 AM", timeForDB: "09:45"},
            {time: "10:00 AM", timeForDB: "10:00"},
            {time: "10:15 AM", timeForDB: "10:15"},
            {time: "10:30 AM", timeForDB: "10:30"},
            {time: "10:45 AM", timeForDB: "10:45"},
            {time: "11:00 AM", timeForDB: "11:00"},
            {time: "11:15 AM", timeForDB: "11:15"},
            {time: "11:30 AM", timeForDB: "11:30"},
            {time: "11:45 AM", timeForDB: "11:45"},
            {time: "12:00 PM", timeForDB: "12:00"},
            {time: "12:15 PM", timeForDB: "12:15"},
            {time: "12:30 PM", timeForDB: "12:30"},
            {time: "12:45 PM", timeForDB: "12:45"},
            {time: "1:00 PM", timeForDB: "13:00"},
            {time: "1:15 PM", timeForDB: "13:15"},
            {time: "1:30 PM", timeForDB: "13:30"},
            {time: "1:45 PM", timeForDB: "13:45"},
            {time: "2:00 PM", timeForDB: "14:00"},
            {time: "2:15 PM", timeForDB: "14:15"},
            {time: "2:30 PM", timeForDB: "14:30"},
            {time: "2:45 PM", timeForDB: "14:45"},
            {time: "3:00 PM", timeForDB: "15:00"},
            {time: "3:15 PM", timeForDB: "15:15"},
            {time: "3:30 PM", timeForDB: "15:30"},
            {time: "3:45 PM", timeForDB: "15:45"},
            {time: "4:00 PM", timeForDB: "16:00"},
            {time: "4:15 PM", timeForDB: "16:15"},
            {time: "4:30 PM", timeForDB: "16:30"},
            {time: "4:45 PM", timeForDB: "16:45"},
            {time: "5:00 PM", timeForDB: "17:00"},
            {time: "5:15 PM", timeForDB: "17:15"},
            {time: "5:30 PM", timeForDB: "17:30"},
            {time: "5:45 PM", timeForDB: "17:45"},
            {time: "6:00 PM", timeForDB: "18:00"},
            {time: "6:15 PM", timeForDB: "18:15"},
            {time: "6:30 PM", timeForDB: "18:30"},
            {time: "6:45 PM", timeForDB: "18:45"},
            {time: "7:00 PM", timeForDB: "19:00"},
            {time: "7:15 PM", timeForDB: "19:15"},
            {time: "7:30 PM", timeForDB: "19:30"},
            {time: "7:45 PM", timeForDB: "19:45"},
            {time: "8:00 PM", timeForDB: "20:00"},
            {time: "8:15 PM", timeForDB: "20:15"},
            {time: "8:30 PM", timeForDB: "20:30"},
            {time: "8:45 PM", timeForDB: "20:45"},
            {time: "9:00 PM", timeForDB: "21:00"},
            {time: "9:15 PM", timeForDB: "21:15"},
            {time: "9:30 PM", timeForDB: "21:30"},
            {time: "9:45 PM", timeForDB: "21:45"},
            {time: "10:00 PM", timeForDB: "22:00"},
            {time: "10:15 PM", timeForDB: "22:15"},
            {time: "10:30 PM", timeForDB: "22:30"},
            {time: "10:45 PM", timeForDB: "22:45"},
            {time: "11:00 PM", timeForDB: "23:00"},
            {time: "11:15 PM", timeForDB: "23:15"},
            {time: "11:30 PM", timeForDB: "23:30"},
            {time: "11:45 PM", timeForDB: "23:45"},
            {time: "12:00 AM", timeForDB: "00:00"}
        ];


        /* $scope.availableTimes = [
            {time: "12:00 AM", timeForDB: "00:00"},
            {time: "12:30 AM", timeForDB: "00:30"},
            {time: "1:00 AM", timeForDB: "01:00"},
            {time: "1:30 AM", timeForDB: "01:30"},
            {time: "2:00 AM", timeForDB: "02:00"},
            {time: "2:30 AM", timeForDB: "02:30"},
            {time: "3:00 AM", timeForDB: "03:00"},
            {time: "3:30 AM", timeForDB: "03:30"},
            {time: "4:00 AM", timeForDB: "04:00"},
            {time: "4:30 AM", timeForDB: "04:30"},
            {time: "5:00 AM", timeForDB: "05:00"},
            {time: "5:30 AM", timeForDB: "05:30"},
            {time: "6:00 AM", timeForDB: "06:00"},
            {time: "6:30 AM", timeForDB: "06:30"},
            {time: "7:00 AM", timeForDB: "07:00"},
            {time: "7:30 AM", timeForDB: "07:30"},
            {time: "8:00 AM", timeForDB: "08:00"},
            {time: "8:30 AM", timeForDB: "08:30"},
            {time: "9:00 AM", timeForDB: "09:00"},
            {time: "9:30 AM", timeForDB: "09:30"},
            {time: "10:00 AM", timeForDB: "10:00"},
            {time: "10:30 AM", timeForDB: "10:30"},
            {time: "11:00 AM", timeForDB: "11:00"},
            {time: "11:30 AM", timeForDB: "11:30"},
            {time: "12:00 PM", timeForDB: "12:00"},
            {time: "12:30 PM", timeForDB: "12:30"},
            {time: "1:00 PM", timeForDB: "13:00"},
            {time: "1:30 PM", timeForDB: "13:30"},
            {time: "2:00 PM", timeForDB: "14:00"},
            {time: "2:30 PM", timeForDB: "14:30"},
            {time: "3:00 PM", timeForDB: "15:00"},
            {time: "3:30 PM", timeForDB: "15:30"},
            {time: "4:00 PM", timeForDB: "16:00"},
            {time: "4:30 PM", timeForDB: "16:30"},
            {time: "5:00 PM", timeForDB: "17:00"},
            {time: "5:30 PM", timeForDB: "17:30"},
            {time: "6:00 PM", timeForDB: "18:00"},
            {time: "6:30 PM", timeForDB: "18:30"},
            {time: "7:00 PM", timeForDB: "19:00"},
            {time: "7:30 PM", timeForDB: "19:30"},
            {time: "8:00 PM", timeForDB: "20:00"},
            {time: "8:30 PM", timeForDB: "20:30"},
            {time: "9:00 PM", timeForDB: "21:00"},
            {time: "9:30 PM", timeForDB: "21:30"},
            {time: "10:00 PM", timeForDB: "22:00"},
            {time: "10:30 PM", timeForDB: "22:30"},
            {time: "11:00 PM", timeForDB: "23:00"},
            {time: "11:30 PM", timeForDB: "23:30"},
            {time: "12:00 AM", timeForDB: "00:00"}
        ]; */

    }
})();



