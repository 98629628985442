/**
 * Created by branimir on 18.12.2016..
 */
(function () {
    'use strict';
    angular.module('seatonApp')
        .filter('percentage', ['$filter', function ($filter) {
            return function (input, decimals) {
                return $filter('number')(input * 100, decimals) + '%';
            };
        }]);
})();

