(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('WaitListQueueController', WaitListQueueController);

    WaitListQueueController.$inject = ['$scope', '$state', '$stateParams', 'Log', 'PublicResource'];

    function WaitListQueueController($scope, $state, $stateParams, Log, PublicResource) {

        var vm = this;
		vm.data = null;
        if (window.location.href.indexOf("//eu.getseat.net") != -1)  vm.isEU = true;

        var hashedEventId = $stateParams.eventId;
        Log.debug("queue.controller","Hashed event id is '"+hashedEventId+"'");
        if(hashedEventId==undefined) {
            $state.go("public.error");
        }


        PublicResource.getWaitListQueueStatus({
            hashedEventId: hashedEventId
        }, onSuccess, onError);

        function onSuccess(data, headers) {
            vm.data = data;
            if(vm.data.numberInQueue<=0) {
                Log.info("queue.controllor","Event '"+hashedEventId+"' no longer in wait list queue.");
                vm.noEventinWaitList = true;
                var params = {'code': "WL001",'errorMessage': "Wait list entry not valid anymore"};
                //$state.go("public.error");
            } else {
                vm.expectedIn = vm.data.quotedTime - vm.data.waitingTime;

                if (vm.data.numberInQueue > 5) vm.picNumber = 6;
                else vm.picNumber = vm.data.numberInQueue;

                var list = ['first', 'second', 'third', ' fourth', 'fifth'];
                if (vm.data.numberInQueue <= 5 && vm.data.numberInQueue > 0) vm.queueText = list[vm.data.numberInQueue - 1];
            }
        }

        function onError(error) {
            Log.debug("public.queue", "HTTP request error." + error);
            $state.go("public.error");
        }

    }
})();



