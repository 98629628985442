(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('AdminLocationController', AdminLocationController);

    AdminLocationController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'AppService', 'Notification', 'ParseLinks', '$uibModal'];

    function AdminLocationController($scope, $rootScope, $state, $stateParams, AppService, Notification, ParseLinks, $uibModal) {
        var vm = this;

        vm.returnToList = returnToList;
        vm.showCounterDialog = showCounterDialog;
        vm.closeResetCounterDialog = closeResetCounterDialog;
        vm.resetCounter = resetCounter;
        vm.showSubscriptionDialog = showSubscriptionDialog;
        vm.closeSubscriptionDialog = closeSubscriptionDialog;
        vm.changeSubscription = changeSubscription;

        vm.validMonth=1;
        vm.validYear=2021;

        vm.location = $stateParams.location;
        if(!vm.location.id) {
            vm.returnToList();
        } else {
            showApplication(vm.location);
        }

        function showApplication(location) {
            vm.selectedLocation = location;
            if(vm.selectedLocation.cloverId != "") {
                AppService.applications({
                    cloverId: location.cloverId
                }, onSuccessApplication, onErrorApplication);
            } else {
                vm.applications = null;
            }

            function onSuccessApplication(data, headers) {
                vm.applications = data;
            }

            function onErrorApplication(error) {
                Notification.error(error.data.message);
            }
        }

        function returnToList() {
            $state.go("admin.application")
        }

        function showCounterDialog() {
            vm.resetCounterDialog = $uibModal.open({
                templateUrl: 'app/admin/location/reset.dialog.html',
                scope: $scope
            });
        }

        function closeResetCounterDialog() {
            vm.resetCounterDialog.dismiss('cancel');
        }

        function resetCounter() {

            var params = {
                'locationId': vm.location.id,
                'counterId': vm.counterId
            };

            AppService.resetCounter(params, onSuccess, onError);

            function onSuccess(data, headers) {
                Notification.success("Counter successfully reset!");
                vm.closeResetCounterDialog();
                vm.location = data;
            }

            function onError(error) {
                Notification.error(error.data.message);
            }

        }

        function showSubscriptionDialog() {
            vm.changeSubscriptionDialog = $uibModal.open({
                templateUrl: 'app/admin/location/subscription.dialog.html',
                scope: $scope
            });
        }

        function closeSubscriptionDialog() {
            vm.changeSubscriptionDialog.dismiss('cancel');
        }

        function changeSubscription(){

            var params = {
                'locationId': vm.location.id,
                'subscriptionType': vm.subscriptionType,
                'validMonth': vm.validMonth,
                'validYear': vm.validYear
            };

            console.log("New subscriptionType ="+JSON.stringify(params)+'');

            AppService.changeSubscription(params, onSuccess, onError);

            function onSuccess(data, headers) {
                Notification.success("Subscription successfully changed!");
                vm.closeSubscriptionDialog();
                vm.applications = data;
            }

            function onError(error) {
                Notification.error(error.data.message);
            }

        }


    }
})();
