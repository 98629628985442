(function() {
    'use strict';
    angular
        .module('seatonApp')
        .factory('MeteredService', MeteredService);

    MeteredService.$inject = ['$resource'];

    function MeteredService($resource) {
        var resourceUrl = 'api/admin/metered/';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'metered': { method:'GET', url: 'api/admin/metered/:locationId?meteredType=:meteredType&selectedMonth=:selectedMonth', isArray: false }
        });
    }
})();
