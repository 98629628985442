'use strict';

EmployeesPerformanceController.$inject = ['$state', '$scope', '$document', '$q', '$filter', 'googleChartApiPromise',
    'Statistics', 'Reporting', 'ApplicationContext', 'ApplicationProfile'];

function EmployeesPerformanceController($state, $scope, $document, $q, $filter, googleChartApiPromise, Statistics,
                                        Reporting, ApplicationContext, ApplicationProfile) {
    var vm = this;
    vm.viewEmployeeDetails = viewEmployeeDetails;
    vm.changedSelectedInterval = changedSelectedInterval;
    vm.changedSelectedDates = changedSelectedDates;

    vm.reportEmployeePerformance = ApplicationProfile.isReportEmployeePerformanceEnabled();
    vm.reportEmployeePerformanceText = ApplicationProfile.upgradeTierText("ReportEmployeePerformance");
    vm.openWindow = ApplicationProfile.openAppCloverPage;

    vm.dateRanges = {
        'last12Months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'lastMonth': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'lastWeek': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
        'yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'today': [moment(), moment()],
        'currentWeek': [moment().startOf('week'), moment().endOf('week')],
        'currentMonth': [moment().startOf('month'), moment().endOf('month')]
    };

    vm.selectedInterval = 'currentWeek';
    vm.filterStartDate = vm.dateRanges[vm.selectedInterval][0];
    vm.filterEndDate = vm.dateRanges[vm.selectedInterval][1];
    vm.selectedDates = {
        startDate: null,
        endDate: null
    };
    vm.currentFilter = '';

    vm.order = {
        field: 'amountSum',
        reverse: true
    };
    vm.dynamicOrder = function (item) {
        return item[vm.order.field];
    }

    //picker
    $document.ready(
        function () {
            vm.pickerDates = $document.find('input[name="pickerDates"]');
            vm.pickerDates.daterangepicker(
                {
                    showDropdowns: true,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        'Last 12 Months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    },
                    locale: {format: 'MMM D, YYYY'}
                },
                function (start, end, label) {
                    vm.selectedInterval = '';
                    vm.filterStartDate = start;
                    vm.filterEndDate = end;
                    reload();
                }
            );
            vm.pickerDates.val('');
        }
    );
    vm.openPicker = openPicker;

    function openPicker() {
        vm.pickerDates.focus();
    }

    //end - picker


    if(vm.reportEmployeePerformance) {
        load();
    }

    function changedSelectedInterval() {
        vm.filterStartDate = vm.dateRanges[vm.selectedInterval][0].startOf('day');
        vm.filterEndDate = vm.dateRanges[vm.selectedInterval][1].endOf('day');

        vm.pickerDates.data('daterangepicker').setStartDate(vm.filterStartDate);
        vm.pickerDates.data('daterangepicker').setEndDate(vm.filterEndDate);

        reload();
    }

    function changedSelectedDates() {
        vm.selectedInterval = '';
        vm.filterStartDate = vm.selectedDates.startDate.clone();
        vm.filterEndDate = vm.selectedDates.endDate.clone();
        reload();
    }

    function load() {
        //clearDatesFilter();
        $q.all([
            googleChartApiPromise,
            loadLocationStats()
        ]).then(getReportingEmployeesPerformance).then(displayEmployeesChart);
    }

    function reload() {
        var selectedFilter = vm.filterStartDate.toISOString() + vm.filterEndDate.toISOString();
        console.log('selectedFilter' + selectedFilter + '  vm.currentFilter' + vm.currentFilter + '  vm.filterStartDate.format():' + vm.filterStartDate.format());
        if (vm.currentFilter != selectedFilter) {
            vm.currentFilter = selectedFilter;
            getReportingEmployeesPerformance().then(displayEmployeesChart);
        }
    }

    function clearDatesFilter() {
        vm.selectedDates = {
            startDate: null,
            endDate: null
        };
    }

    function loadLocationStats() {
        return Statistics.getCustomerLocationAllVisitsStats({}, function (data) {
            vm.customerLocationStats = data;
            vm.avgSpendPerCover = vm.customerLocationStats.avgSpendByCover;
        }).$promise;
    }

    function getReportingEmployeesPerformance() {
        return Statistics.getReportingEmployeesPerformance({
            selectedInterval: vm.selectedInterval,
            filterStartDate: vm.filterStartDate == null ? null : vm.filterStartDate.toISOString(),
            filterEndDate: vm.filterEndDate == null ? null : vm.filterEndDate.toISOString()
        }, function (data) {
            vm.chartData = data.chartData;
            vm.employeesDetails = data.details;
            for (var i = 0; i < vm.employeesDetails.detailData.length; i++)
                Reporting.calcAggrByWeekdayDataItem(vm.employeesDetails.detailData[i], vm.customerLocationStats);
        }).$promise;
    }

    function viewEmployeeDetails(id) {
        $state.go('settings.employees.view', {id: id});
    }

    function displayEmployeesChart() {
        var deferred = $q.defer();

        // employees performance
        var dataEmployees = new google.visualization.DataTable();
        dataEmployees.addColumn('string', 'Employee');
        dataEmployees.addColumn('number', 'Revenue');
        dataEmployees.addColumn('number', 'Tips');
        dataEmployees.addColumn({type: 'string', role: 'annotation'});

        _.each(vm.chartData, function (item) {
            dataEmployees.addRow(
                [
                    (item.firstName != null ? item.firstName : '') + ' ' + (item.lastName != null ? item.lastName : ''),
                    {
                        v: item.revenue,
                        f: $filter('currency')(item.revenue)
                    },
                    {
                        v: item.tips,
                        f: $filter('currency')(item.tips)
                    },
                    $filter('currency')(item.revenue + item.tips)]);
        });

        var optionsEmployees = {
            //title: 'Employee performance',
            isStacked: 'true',
            chartArea: {width: '90%', height: '70%'},
            legend: {position: 'top', alignment: 'end'},
            //width: 1000,
            height: 450,
            vAxis: {
                title: 'Revenue and Tips in ' + ApplicationContext.getCurrencySym(),
                textPosition: 'none',
                gridlines: {count: 13}, viewWindow: {min: 0},
                titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
            },
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            colors: ['#1ABC9C', '#3399CC'],
            formatters: {}
        };

        vm.chartObjectEmployees = {
            type: 'ColumnChart',
            options: optionsEmployees,
            data: dataEmployees
        };

        deferred.resolve(true);
        return deferred.promise;
    }
}

angular.module('seatonApp').controller('EmployeesPerformanceController', EmployeesPerformanceController);
