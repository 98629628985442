(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('GeneralController', GeneralController);

    GeneralController.$inject = ['$scope', '$state', 'SettingsResource', 'Log', '$location', '$uibModal', '$document',
                                'ApplicationContext', 'ListUtils', 'SweetAlert', 'Utils'];

    var DATE_FORMAT = 'YYYY-MM-DD';
    var TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
    var TIME_DISPLAY_FORMAT = 'HH:mm';
    var DATE_PICKER_FORMAT = 'MMM D, YYYY';

    function GeneralController($scope, $state, SettingsResource, Log, $location, $uibModal, $document,
                               ApplicationContext, ListUtils, SweetAlert, Utils) {

        var vm = this;
        vm.formatMinutes = Utils.formatMinutes;
        vm.formatAmount = Utils.formatAmount;

        vm.currencySymbol = ApplicationContext.getCurrencySym();
        vm.ruleList = ListUtils.getPaymentRuleList();
        vm.availableTimes = ListUtils.getAvailableTimesForDay30MinStep();
        vm.availableTimesForDuration = ListUtils.getAvailableTimesForEventDuration();
        vm.maxDurationList = ListUtils.getMaxSimpleDurationList();
        vm.minDurationList = ListUtils.getMinSimpleDurationList();

        vm.meals = {};
        vm.productType="DURATION";
        vm.productTypeOriginal = vm.productType;

        vm.isTableManaged = ApplicationContext.isTableManaged();

        // get data
        //SettingsResource.getGeneralSettings({}, onSuccessGetData, onErrorGetData);

        SettingsResource.getProductSettings({}, onSuccessGetProductData);

        function onSuccessGetProductData(data, headers) {
            vm.productType=data.type;
            vm.productTypeOriginal = vm.productType;
            vm.meals = data.meal;
            vm.duration = data.duration;
            vm.productList = data.productList;
        }

        // change product type
        vm.changeProductType = function() {
            SweetAlert.swal({
                    title: "Confirm action",
                    text: "Do you want to change product definition to " + vm.productTypeDesc(vm.productType) +".",
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#ec4758",
                    confirmButtonColor: "#1ab394",
                    cancelButtonText: "No",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        SettingsResource.changeProductType({productType: vm.productType}, onSuccessChange, onErrorChange);
                    } else {
                        vm.productType = vm.productTypeOriginal;
                    }
                });

            function onSuccessChange() {
                vm.productTypeOriginal = vm.productType;
                ApplicationContext.setProductType(vm.productType);
            }

            function onErrorChange() {
                vm.productType = vm.productTypeOriginal;
            }
        }

        // save meals settings
        vm.saveMealSettings = function() {
            SettingsResource.saveMealSettings(vm.meals);
        }

        // save meals settings
        vm.saveProduct = function() {
            SettingsResource.saveProduct(vm.product, onProductSaveSuccess);

            function onProductSaveSuccess(data) {
                vm.productList = data;
                vm.product = {};
            }

        }

        vm.editProduct = function(product) {
            Log.debug("Product controller","Product id "+product.id+", production location_id "+product.locationId);
            vm.product = product;
        }

        vm.deleteProduct = function(id) {
            SweetAlert.swal({
                    title: "Confirm product deletion",
                    text: "Do you want to delete product from settings?",
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#ec4758",
                    confirmButtonColor: "#1ab394",
                    cancelButtonText: "No",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        SettingsResource.deleteProduct({'id': id}, productListUpdate);
                    }
                });

        }

        function productListUpdate(data) {
            vm.productList = data;
        }


        vm.saveDurationSettings = function() {
            SettingsResource.saveDurationSettings(vm.duration);
        }

        vm.reOrderProduct = function(sourceId, destinationId) {
            Log.debug("ProductController","Source id="+sourceId+", destination id="+destinationId+".");
            SettingsResource.reOrderProduct(
                {
                    sourceId: sourceId,
                    destinationId: destinationId
                }
                , productListUpdate
            )
        }

        // save meal duration data
        $scope.saveData = function () {

            if (angular.isDefined($scope.data.breakfastStart)) {

                if ($scope.data.breakfastStart.substring($scope.data.breakfastStart.length - 2, $scope.data.breakfastStart.length) == "am") {
                    if ($scope.data.breakfastStart == "12am") {
                        $scope.data.breakfastStart = "00:00";
                    } else if ($scope.data.breakfastStart == "12:30am") {
                        $scope.data.breakfastStart = "00:30";
                    } else {
                        $scope.data.breakfastStart = $scope.data.breakfastStart.substring(0, $scope.data.breakfastStart.length - 2);
                    }
                } else {
                    if ($scope.data.breakfastStart == "12pm") {
                        $scope.data.breakfastStart = "12:00";
                    } else if ($scope.data.breakfastStart == "12:30pm") {
                        $scope.data.breakfastStart = "12:30";
                    } else {
                        if ($scope.data.breakfastStart.substring(1, 3) == "pm") {
                            // 1pm
                            $scope.breakfastStart_temp = Number($scope.data.breakfastStart.substring(0, 1));
                            $scope.breakfastStart_temp = $scope.breakfastStart_temp + 12;
                            $scope.data.breakfastStart = $scope.breakfastStart_temp.toString() + ":00";
                        } else if ($scope.data.breakfastStart.substring(4, 6) == "pm") {
                            // 1:30pm
                            $scope.breakfastStart_temp = Number($scope.data.breakfastStart.substring(0, 1));
                            $scope.breakfastStart_temp = $scope.breakfastStart_temp + 12;
                            $scope.data.breakfastStart = $scope.breakfastStart_temp.toString() + ":30";
                        } else if ($scope.data.breakfastStart.substring(2, 4) == "pm") {
                            // 10pm
                            $scope.breakfastStart_temp = Number($scope.data.breakfastStart.substring(0, 2));
                            $scope.breakfastStart_temp = $scope.breakfastStart_temp + 12;
                            $scope.data.breakfastStart = $scope.breakfastStart_temp.toString() + ":00";
                        } else if ($scope.data.breakfastStart.substring(5, 7) == "pm") {
                            // 10:30pm
                            $scope.breakfastStart_temp = Number($scope.data.breakfastStart.substring(0, 2));
                            $scope.breakfastStart_temp = $scope.breakfastStart_temp + 12;
                            $scope.data.breakfastStart = $scope.breakfastStart_temp.toString() + ":30";
                        }
                    }
                }

            }
            if (angular.isDefined($scope.data.lunchStart)) {

                if ($scope.data.lunchStart.substring($scope.data.lunchStart.length - 2, $scope.data.lunchStart.length) == "am") {
                    if ($scope.data.lunchStart == "12am") {
                        $scope.data.lunchStart = "00:00";
                    } else if ($scope.data.lunchStart == "12:30am") {
                        $scope.data.lunchStart = "00:30";
                    } else {
                        $scope.data.lunchStart = $scope.data.lunchStart.substring(0, $scope.data.lunchStart.length - 2);
                    }
                } else {
                    if ($scope.data.lunchStart == "12pm") {
                        $scope.data.lunchStart = "12:00";
                    } else if ($scope.data.lunchStart == "12:30pm") {
                        $scope.data.lunchStart = "12:30";
                    } else {
                        if ($scope.data.lunchStart.substring(1, 3) == "pm") {
                            // 1pm
                            $scope.lunchStart_temp = Number($scope.data.lunchStart.substring(0, 1));
                            $scope.lunchStart_temp = $scope.lunchStart_temp + 12;
                            $scope.data.lunchStart = $scope.lunchStart_temp.toString() + ":00";
                        } else if ($scope.data.lunchStart.substring(4, 6) == "pm") {
                            // 1:30pm
                            $scope.lunchStart_temp = Number($scope.data.lunchStart.substring(0, 1));
                            $scope.lunchStart_temp = $scope.lunchStart_temp + 12;
                            $scope.data.lunchStart = $scope.lunchStart_temp.toString() + ":30";
                        } else if ($scope.data.lunchStart.substring(2, 4) == "pm") {
                            // 10pm
                            $scope.lunchStart_temp = Number($scope.data.lunchStart.substring(0, 2));
                            $scope.lunchStart_temp = $scope.lunchStart_temp + 12;
                            $scope.data.lunchStart = $scope.lunchStart_temp.toString() + ":00";
                        } else if ($scope.data.lunchStart.substring(5, 7) == "pm") {
                            // 10:30pm
                            $scope.lunchStart_temp = Number($scope.data.lunchStart.substring(0, 2));
                            $scope.lunchStart_temp = $scope.lunchStart_temp + 12;
                            $scope.data.lunchStart = $scope.lunchStart_temp.toString() + ":30";
                        }
                    }
                }

            }
            if (angular.isDefined($scope.data.dinnerStart)) {

                if ($scope.data.dinnerStart.substring($scope.data.dinnerStart.length - 2, $scope.data.dinnerStart.length) == "am") {
                    if ($scope.data.dinnerStart == "12am") {
                        $scope.data.dinnerStart = "00:00";
                    } else if ($scope.data.dinnerStart == "12:30am") {
                        $scope.data.dinnerStart = "00:30";
                    } else {
                        $scope.data.dinnerStart = $scope.data.dinnerStart.substring(0, $scope.data.dinnerStart.length - 2);
                    }
                } else {
                    if ($scope.data.dinnerStart == "12pm") {
                        $scope.data.dinnerStart = "12:00";
                    } else if ($scope.data.dinnerStart == "12:30pm") {
                        $scope.data.dinnerStart = "12:30";
                    } else {
                        if ($scope.data.dinnerStart.substring(1, 3) == "pm") {
                            // 1pm
                            $scope.dinnerStart_temp = Number($scope.data.dinnerStart.substring(0, 1));
                            $scope.dinnerStart_temp = $scope.dinnerStart_temp + 12;
                            $scope.data.dinnerStart = $scope.dinnerStart_temp.toString() + ":00";
                        } else if ($scope.data.dinnerStart.substring(4, 6) == "pm") {
                            // 1:30pm
                            $scope.dinnerStart_temp = Number($scope.data.dinnerStart.substring(0, 1));
                            $scope.dinnerStart_temp = $scope.dinnerStart_temp + 12;
                            $scope.data.dinnerStart = $scope.dinnerStart_temp.toString() + ":30";
                        } else if ($scope.data.dinnerStart.substring(2, 4) == "pm") {
                            // 10pm
                            $scope.dinnerStart_temp = Number($scope.data.dinnerStart.substring(0, 2));
                            $scope.dinnerStart_temp = $scope.dinnerStart_temp + 12;
                            $scope.data.dinnerStart = $scope.dinnerStart_temp.toString() + ":00";
                        } else if ($scope.data.dinnerStart.substring(5, 7) == "pm") {
                            // 10:30pm
                            $scope.dinnerStart_temp = Number($scope.data.dinnerStart.substring(0, 2));
                            $scope.dinnerStart_temp = $scope.dinnerStart_temp + 12;
                            $scope.data.dinnerStart = $scope.dinnerStart_temp.toString() + ":30";
                        }
                    }
                }

            }
            if (angular.isDefined($scope.data.dinnerEnd)) {

                if ($scope.data.dinnerEnd.substring($scope.data.dinnerEnd.length - 2, $scope.data.dinnerEnd.length) == "am") {
                    if ($scope.data.dinnerEnd == "12am") {
                        $scope.data.dinnerEnd = "00:00";
                    } else if ($scope.data.dinnerEnd == "12:30am") {
                        $scope.data.dinnerEnd = "00:30";
                    } else {
                        $scope.data.dinnerEnd = $scope.data.dinnerEnd.substring(0, $scope.data.dinnerEnd.length - 2);
                    }
                } else {
                    if ($scope.data.dinnerEnd == "12pm") {
                        $scope.data.dinnerEnd = "12:00";
                    } else if ($scope.data.dinnerEnd == "12:30pm") {
                        $scope.data.dinnerEnd = "12:30";
                    } else {
                        if ($scope.data.dinnerEnd.substring(1, 3) == "pm") {
                            // 1pm
                            $scope.dinnerEnd_temp = Number($scope.data.dinnerEnd.substring(0, 1));
                            $scope.dinnerEnd_temp = $scope.dinnerEnd_temp + 12;
                            $scope.data.dinnerEnd = $scope.dinnerEnd_temp.toString() + ":00";
                        } else if ($scope.data.dinnerEnd.substring(4, 6) == "pm") {
                            // 1:30pm
                            $scope.dinnerEnd_temp = Number($scope.data.dinnerEnd.substring(0, 1));
                            $scope.dinnerEnd_temp = $scope.dinnerEnd_temp + 12;
                            $scope.data.dinnerEnd = $scope.dinnerEnd_temp.toString() + ":30";
                        } else if ($scope.data.dinnerEnd.substring(2, 4) == "pm") {
                            // 10pm
                            $scope.dinnerEnd_temp = Number($scope.data.dinnerEnd.substring(0, 2));
                            $scope.dinnerEnd_temp = $scope.dinnerEnd_temp + 12;
                            $scope.data.dinnerEnd = $scope.dinnerEnd_temp.toString() + ":00";
                        } else if ($scope.data.dinnerEnd.substring(5, 7) == "pm") {
                            // 10:30pm
                            $scope.dinnerEnd_temp = Number($scope.data.dinnerEnd.substring(0, 2));
                            $scope.dinnerEnd_temp = $scope.dinnerEnd_temp + 12;
                            $scope.data.dinnerEnd = $scope.dinnerEnd_temp.toString() + ":30";
                        }
                    }
                }

            }

            console.log('save data', $scope.data);

            SettingsResource.saveGeneralSettings($scope.data, onSuccessGetData, onErrorGetData);
        }

        $scope.endTimeChanged = function (type) {
            if(moment(vm.meals.lunchEnd,TIME_DISPLAY_FORMAT).isBefore(moment(vm.meals.breakfastEnd, TIME_DISPLAY_FORMAT))) {
                Log.debug("ProductController","Lunch end "+vm.meals.lunchEnd+" is before lunch start "+vm.meals.breakfastEnd+".");
                vm.meals.lunchEnd = vm.meals.breakfastEnd;
            }
        }

        vm.productTypeDesc = function(type) {
            if(type=="MEAL") return "Standard products depending on the time of day (e.g. Breakfast, Lunch, Dinner)";
            else if(type=="DURATION") return "Simple duration list";
            else if(type=="PRODUCT") return "Product list";
            else return "Undefined";
        }

        $scope.availablePeriods = [
            {period: "1 day", periodForDB: 1},
            {period: "2 days", periodForDB: 2},
            {period: "3 days", periodForDB: 3},
            {period: "5 days", periodForDB: 5},
            {period: "1 week", periodForDB: 7},
            {period: "2 weeks", periodForDB: 14},
            {period: "3 weeks", periodForDB: 21},
            {period: "1 month", periodForDB: 30},
            {period: "2 months", periodForDB: 60},
            {period: "3 months", periodForDB: 90},
            {period: "6 months", periodForDB: 180}
        ];

    }
})();



