(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('account.clover.logout', {
                parent: 'account.clover',
                url: '/logout',
                data: {
                    authorities: [],
                    specialClass: " gray-bg"
                },
                views: {
                    'layout@': {
                        templateUrl: 'app/layouts/clover_layout.html'
                    },
                    'content@account.clover.logout': {
                        templateUrl: 'app/account/clover/logout/logout.html',
                        controller: 'CloverLogoutController',
                        controllerAs: 'vm'
                    },
                    'navbar@app': {},
                    'topnavbar@app': {}
                    //'footer@app': {
                    //    templateUrl: 'app/layouts/footer/footer.html'
                    //}

                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('account.clover.error');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
