'use strict';

angular.module('seatonApp')
    .factory('LocationResource', locationResource);

locationResource.$inject = ['$resource', 'ApplicationContext'];

function locationResource($resource, ApplicationContext) {
    return $resource('api/v1/merchants/_MERCHANTID_', {},
        {
            'getLocation': {
                method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/getLocation', isArray: false
            },
            'getLocationSettings': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/getLocationSettings'},
            'requestIVR': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/requestIVR'},
            'activateIvrPhone': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/activateIvrPhone'},
            'changeHome': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/changeHome'}
        });
}

