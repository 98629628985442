(function () {
    'use strict';

    function Time() {
        return {
            fromNow: fromNow,
            isNearDate: isNearDate

        };

        function fromNow(time) {
            return moment(time).diff(moment());            
        }

        function isNearDate(customerDate) {
            var today = moment();
            var diffDates = (moment(customerDate).year(today.year())).diff(today, 'days');
            return 0 <= diffDates && diffDates <= 10;
        }
    }

    angular.module('seatonApp').factory('Time', Time);
})();
