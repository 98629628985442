 (function() {
    'use strict';

    angular
        .module('seatonApp')
        .factory('authExpiredInterceptor', authExpiredInterceptor);

    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', '$localStorage', '$sessionStorage', '$location'];

    function authExpiredInterceptor($rootScope, $q, $injector, $localStorage, $sessionStorage, $location) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError(response) {
            var page = $location.path().trim();
            console.log("Page is "+page+" status="+response.status);
            if (response.status === 401) {
                var Principal = $injector.get('Principal');
                var Auth = $injector.get('Auth');
                if (!isAdminEntryPage() && Principal.isAuthenticated()) {
                    console.log("Authenticate again with known credentials. ADMIN access.");
                    Auth.authorize(true);

                } else {
                    console.log("Auth interceptor: Not Authenticated.");
                    Auth.logout(); // to delete stored data in browser, if any

                    var params = { 'errorCode': response.status, 'errorMessage': response.data, 'contExec': true};
                    console.log("Params:"+params);

                    var $state = $injector.get('$state');
                    $state.go("account.error",params);

                }
            }
            return $q.reject(response);
        }

        function isAdminEntryPage(page) {
            return (page == "/account/login");
        }
    }
})();
