'use strict';

angular.module('seatonApp').controller('CustomerCreateController', CustomerCreateController);
// services to use: Customer (customer), CodeList (predefined allergies), Resource (tables)

CustomerCreateController.$inject = ['$scope', '$state', '$stateParams', 'Notification', 'CustomerResource', 'ApplicationContext', 'LocationResource', 'PreviousState', '$timeout', '$document'];
function CustomerCreateController($scope, $state, $stateParams, Notification, CustomerResource, ApplicationContext, LocationResource, PreviousState, $timeout, $document) {
    $scope.customer = {
        reservationAllowed: true,
        waitlistAllowed: true,
        importantDates: [],
        emailAddresses: [],
        phoneNumbers: []
    };

    $scope.dataIsloaded = false;

    $scope.defaults = {};

    $scope.tables = [];

    $scope.areas = [];

    $scope.load = function (customerId) {
        if (customerId) {
            $scope.customer = CustomerResource.get({id: customerId}, function (data) {
                    var old = data.allergies;
                    data.allergies = {};
                    _.each(old, function (al) {
                        data.allergies[al.otherName] = al;
                    });
                    $scope.customer.favoriteTable = data.favoriteTable;
                    if (_.isUndefined($scope.customer.importantDates)) {
                        $scope.customer.importantDates = [];
                    }
                    $scope.dataIsloaded = true;
                }
            );
        } else {
            loadLocation();
            $scope.dataIsloaded = true;
        }

        if (_.isUndefined($scope.customer.importantDates)) {
            $scope.customer.importantDates = [];
        }

        //TODO 4 branimir check - add load of settings FIRST
        loadSettings();

        return $scope.customer;
    };

    function loadLocation() {
        return LocationResource.getLocation({}, function (location) {
            $scope.defaults.country = location.country;
            $scope.defaults.countryState = location.countryState;
        }).$promise;
    }

    function loadSettings() {
        return LocationResource.getLocationSettings({}, function (settings) {
            $scope.areas = settings.areas;
        }).$promise;
    }

    $scope.load($stateParams.customerId);
    //$timeout(function () {
    //    $scope.load($stateParams.customerId);
    //});
    //$document.ready($scope.load($stateParams.customerId));

    $scope.save = function () {
        $scope.customer.allergies = _.map($scope.customer.allergies, function (allergy) {
            return allergy;
        });
        console.log("$scope.customer:");
        console.log($scope.customer);

        CustomerResource.updateCustomer($scope.customer,
            function (savedCustomer) {
                var customerResource = $scope.load(savedCustomer.id);
                customerResource.$promise.then(function (customer) {
                    //var msgHtml = "<a target=\"_blank\" ui-sref=\"customers.profile({customerId:" + savedCustomer.id + " })\">Customer " + customer.firstName + ' ' + customer.lastName + ' saved</a>';
                    var msgHtml = 'Customer ' + customer.firstName + ' ' + customer.lastName + ' saved';
                    Notification.info('Customer saved', msgHtml);
                });
                var newState;
                var params;
                if (angular.isDefined(PreviousState) && PreviousState.name.match(/^customer\.profile/)) {
                    newState = PreviousState.name;
                    params = PreviousState.params;
                } else {
                    newState = $state.current;
                    params = angular.copy($stateParams);
                    params.customerId = undefined;
                }
                $state.go('customers.profile', {customerId: savedCustomer.id});
                //return $state.transitionTo(newState, params, {reload: true, inherit: true, notify: true});
            },
            function (response) {
                // TODO more appropriate error handling
                console.log(response);
            }
        );
    };

    $scope.nonEmpty = function (value) {
        if (_.isArray(value) && !_.isEmpty(value)) {
            return true;
        } else {
            return false;
        }
    };
}
