(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('ApplicationContext', ApplicationContext);

    ApplicationContext.$inject = ['$rootScope', '$localStorage', '$translate', 'tmhDynamicLocale', '$locale'];

    function ApplicationContext($rootScope, $localStorage, $translate, tmhDynamicLocale, $locale) {

        var service = {
            storeLocation: storeLocation,
            storeAppContext: storeAppContext,
            getLocation: getLocation,
            hasLocation: hasLocation,
            getLocationID: getLocationID,
            hasLocationID: hasLocationID,
            getLocale: getLocale,
            hasLocale: hasLocale,
            getTimeZone: getTimeZone,
            hasTimeZone: hasTimeZone,
            getAuthType: getAuthType,
            getMerchantID: getMerchantID,
            getEmployeeID: getEmployeeID,
            getClientID: getClientID,
            clear: clear,
            logout: logout,
            setLanguageAndLocale: setLanguageAndLocale,
            getCurrencySym: getCurrencySym,
            storeWarnings: storeWarnings,
            isConfigNotCompleted: isConfigNotCompleted,
            isBusinessHoursNotDefined: isBusinessHoursNotDefined,
            getPhoneRegion: getPhoneRegion,
            getPhoneCountryPrefix: getPhoneCountryPrefix,
            setResourceType: setResourceType,
            getResourceType: getResourceType,
            setProductType: setProductType,
            getProductType: getProductType,
            isTableManaged: isTableManaged

        };

        return service;

        function storeLocation(location) {
            $localStorage.LocationID = location.id;
            $localStorage.Locale = location.locale;
            $localStorage.TimeZone = location.timezone;
            $localStorage.Location = location;
            $localStorage.ResourceType = location.managedResourceType;
            $localStorage.ProductType = location.productType;
            $rootScope.$broadcast("_LS_location_stored_");
        }

        function storeAppContext(authType, merchant_id, employee_id, client_id) {
            $localStorage.AuthType = authType;
            $localStorage.MerchantID = merchant_id;
            $localStorage.EmployeeID = employee_id;
            $localStorage.ClientID = client_id;
        }

        function setLanguageAndLocale() {

            if ($localStorage.TimeZone) {
                //if timezone is defined in localStorage then set it
                moment.tz.setDefault($localStorage.TimeZone);

                //REMOVE - for testing only!
                //$rootScope.isEU = true;

                var localeApp = $localStorage.Locale.toLowerCase();
                if (localeApp == 'en-us') {
                    localeApp = 'en';
                    $rootScope.currencyIcon = 'fa fa-dollar';
                } else if (localeApp == 'en-gb') {
                    $rootScope.currencyIcon = 'fa fa-gbp';
                    $rootScope.isEU = true;
                } else if (localeApp == 'en-ie') {
                    $rootScope.currencyIcon = 'fa fa-euro';
                    $rootScope.isEU = true;
                } else if (localeApp == 'en-ca') {
                    $rootScope.currencyIcon = 'fa fa-money';
                }

                moment.locale(localeApp);
                //TODO check $translate config
                //$translate.use(localeApp);
                tmhDynamicLocale.set(localeApp);
            } else {
                console.log("Unable to set Language And Locale - not defined!");
            }
        }

        function getCurrencySym() {
            return $locale.NUMBER_FORMATS.CURRENCY_SYM;
        }

        function getPhoneRegion() {
            var localePhone = 'US'; //default
            var localeApp = $localStorage.Locale.toLowerCase();
            if (localeApp == 'en-us') {
                localePhone = 'US';
            } else if (localeApp == 'en-gb') {
                localePhone = 'GB';
            } else if (localeApp == 'en-ie') {
                localePhone = 'IE';
            } else if (localeApp == 'en-ca') {
                localePhone = 'CA';
            }
            return localePhone;
        }

        function getPhoneCountryPrefix() {
            var localePhonePrefix = '+1'; //default
            var localeApp = $localStorage.Locale.toLowerCase();
            if (localeApp == 'en-us') {
                localePhonePrefix = '+1';
            } else if (localeApp == 'en-gb') {
                localePhonePrefix = '+44';
            } else if (localeApp == 'en-ie') {
                localePhonePrefix = '+353';
            } else if (localeApp == 'en-ca') {
                localePhonePrefix = '+1';
            }
            return localePhonePrefix;
        }

        function storeWarnings(configNotCompleted, businessHoursNotDefined) {
            $localStorage.configNotCompleted = configNotCompleted;
            $localStorage.businessHoursNotDefined = businessHoursNotDefined;
        }

        function getLocation() {
            return $localStorage.Location;
        }

        function hasLocation() {
            return angular.isDefined(getLocation());
        }

        //LocationID
        function getLocationID() {
            return $localStorage.LocationID;
        }

        function hasLocationID() {
            return angular.isDefined(getLocationID());
        }

        //Locale
        function getLocale() {
            return $localStorage.Locale;
        }

        function hasLocale() {
            return angular.isDefined(getLocale());
        }

        //ZimeZone
        function getTimeZone() {
            return $localStorage.TimeZone;
        }

        function hasTimeZone() {
            return angular.isDefined(getTimeZone());
        }

        // AuthType
        function getAuthType() {
            if ($localStorage.AuthType == undefined) {
                return "CLOVER";
            }
            return $localStorage.AuthType;
        }

        // MerchantID
        function getMerchantID() {
            return $localStorage.MerchantID;
        }

        // EmployeeID
        function getEmployeeID() {
            return $localStorage.EmployeeID;
        }

        // ClientID
        function getClientID() {
            return $localStorage.ClientID;
        }

        // configNotCompleted
        function isConfigNotCompleted() {
            return $localStorage.configNotCompleted;
        }

        // businessHoursNotDefined
        function isBusinessHoursNotDefined() {
            return $localStorage.businessHoursNotDefined;
        }

        function getResourceType() {
            return $localStorage.ResourceType;
        }

        function setResourceType(resourceType) {
            if (resourceType == 1 || resourceType == "TABLE") {
                $localStorage.ResourceType = "TABLE";
            } else {
                $localStorage.ResourceType = "OTHER";
            }
        }

        function getProductType() {
            return $localStorage.ProductType;
        }

        function setProductType(productType) {
            $localStorage.ProductType = productType;
        }

        function isTableManaged() {
            return (getResourceType() == 'TABLE');
        }


        function clear() {
            delete $localStorage.LocationID;
            delete $localStorage.Locale;
            delete $localStorage.TimeZone;
            delete $localStorage.Location;
            delete $localStorage.AuthType;
            delete $localStorage.MerchantID;
            delete $localStorage.EmployeeID;
            delete $localStorage.ClientID;
            delete $localStorage.configNotCompleted;
            delete $localStorage.businessHoursNotDefined;
            delete $localStorage.ResourceType;
            delete $localStorage.ProductType;

            $rootScope.$broadcast("_LS_location_deleted_");

        }

        function logout() {
            clear();
        }
    }
})();
