(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('EventUpdateController', EventUpdateController);

    EventUpdateController.$inject = ['$scope', '$state', '$stateParams', '$document', 'Tables', 'Notification', 'Utils',
        'Time', 'CustomerResource', 'SweetAlert', 'ApplicationContext', 'Log', 'SettingsResource'];

    var DATE_FORMAT = 'YYYY-MM-DD';
    var TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
    var TIME_DISPLAY_FORMAT = 'hh:mmA';
    var DATE_PICKER_FORMAT = 'MMM D, YYYY';

    function EventUpdateController($scope, $state, $stateParams, $document, Tables, Notification, Utils,
                                   Time, CustomerResource, SweetAlert, ApplicationContext, Log, SettingsResource) {

        var vm = this;
        vm.eventId = $stateParams.eventId;
        vm.utils = Utils;
        vm.event = $stateParams.event;
        vm.productList = {};
        vm.selectedProduct = null;
        vm.settings = null;

        // function definition
        vm.findTables = findTables;
        vm.daySelected = daySelected;
        vm.clearTables = clearTables;
        vm.getSelectedResources = getSelectedResources;
        vm.getSelectedResourcesCapacity = getSelectedResourcesCapacity;
        vm.getSelectedResourcesIds = getSelectedResourcesIds;
        vm.altTimeSelected = altTimeSelected;
        vm.resetCustomer = resetCustomer;
        vm.isUpdateDisabled = isUpdateDisabled;
        vm.isUpdateWithWarning = isUpdateWithWarning;
        vm.isTimeSet = isTimeSet;
        vm.updateEvent = updateEvent;
        vm.reformatPhone = reformatPhone;
        vm.openDatePicker = openDatePicker;
        vm.findProduct = findProduct;
        vm.findDuration = findDuration;
        vm.selectProductText = selectProductText;
        vm.getProductInfo = getProductInfo;
        vm.changeDuration = changeDuration;

            function getGeneralSettings() {
            SettingsResource.getGeneralSettings(
                {},
                function (data) {
                    vm.settings = data;
                    if(vm.settings.shortProductList) {
                        vm.productList = vm.settings.shortProductList.concat(vm.settings.selectProductList);
                    } else {
                        vm.productList = vm.settings.selectProductList;
                    }

                    if(!vm.event || !vm.event.id) {
                        Log.debug("Fetching event details with id " + vm.eventId + "from database.")
                        Tables.getEventDetails(
                            {
                                id: vm.eventId
                            },
                            function (data) {
                                vm.event = data;
                                init();
                            }
                        );
                    } else {
                        init();
                    }
                }
            );
        }
        getGeneralSettings();

        function init() {
            vm.oldExpectedStartTime = vm.event.expectedStartTime;
            vm.oldDuration = vm.event.expectedDuration;
            vm.oldNumberOfPersons = vm.event.numberOfPersons;
            vm.oldResources = vm.event.resources;
            vm.oldCustomer = vm.event.customer;
            vm.isExpectedArrival = vm.event.state === 'OPEN' && (vm.event.status === 'RESERVED' || vm.event.status == 'NOTIFIED' || vm.event.status === 'CONFIRMED');

            vm.note = vm.event.note;
            vm.customer = vm.event.customer;
            vm.customerOriginal = vm.event.customer != null ? Utils.getBasicCustomerData(vm.event.customer) : null;
            vm.numberOfPersons = vm.event.numberOfPersons;
            vm.selectedDay = moment(vm.event.expectedStartTime).format(DATE_FORMAT);
            vm.selectedTime = moment(vm.event.expectedStartTime).format(TIME_DISPLAY_FORMAT);
            vm.duration = vm.event.expectedDuration;
            vm.productId = vm.event.product.id;
            vm.findTables();
        }

        function daySelected() {

            vm.clearTables();
            vm.selectedDayFormatted = moment(vm.selectedDay, DATE_FORMAT).format(DATE_PICKER_FORMAT);
            vm.findTables();
        }

        function clearTables() {
            vm.tables = null;
            vm.alternativeTimes = null;
        }

        function findTables() {

            if (vm.selectedDay && vm.isTimeSet() && vm.numberOfPersons && vm.duration) {
                var currentlySelectedResources = (vm.getSelectedResources().length == 0) ? vm.oldResources : vm.getSelectedResources();

                vm.clearTables();
                Tables.findAvailableTables(
                    {
                        date: vm.selectedDay,
                        time: timeToServerFormat(vm.selectedTime),
                        numberOfPersons: vm.numberOfPersons,
                        duration: vm.duration,
                        eventToIgnoreId : vm.event.id
                    },
                    function (data) {

                        vm.tables = selectPreviouslySelected(data.availableResources, currentlySelectedResources);
                        vm.noResource = data.noResource;

                        vm.alternativeTimes = [];
                        for (var j = 0; j < data.alternativeTimes.length; j++) {
                            vm.alternativeTimes.push({
                                time: moment(data.alternativeTimes[j].time, TIME_SERVER_FORMAT).format(TIME_DISPLAY_FORMAT),
                                capacity: data.alternativeTimes[j].capacity
                            });
                        }
                    }
                );
            }
        }

        // Returns the list passed as tables, but if marks tables that were previously selected (if any) as selected.
        function selectPreviouslySelected(tables, previouslySelected) {

            var resultList = [];

            for (var i = 0; i < tables.length; i++) {
                var table = tables[i];
                var wasSelected = false;
                for (var j = 0; j < previouslySelected.length; j++) {
                    if (table.id == previouslySelected[j].id) {
                        wasSelected = true;
                        break;
                    }
                }
                table.selected = wasSelected;
                resultList.push(table);
            }
            return resultList;
        }

        /** Returns a list of all currently selected resources. */
        function getSelectedResources() {
            var selectedResources = [];

            if (vm.tables) {
                for (var i = 0; i < vm.tables.length; i++) {
                    if (vm.tables[i].selected === true) {
                        selectedResources.push(vm.tables[i]);
                    }
                }
            }
            return selectedResources;
        }

        function getSelectedResourcesIds() {
            var selectedIds = [];
            var selectedResources = getSelectedResources();

            for (var i = 0; i < selectedResources.length; i++) {
                selectedIds.push(selectedResources[i].id);
            }
            return selectedIds;
        }

        function getSelectedResourcesCapacity() {
            var capacity = 0;
            var selectedResources = getSelectedResources();

            for (var i = 0; i < selectedResources.length; i++) {
                capacity += selectedResources.capacity;
            }
            return capacity;
        }

        function altTimeSelected() {
            vm.selectedTime = vm.selectedAltTime;

            if (vm.selectedDay && vm.selectedTime && vm.numberOfPersons && vm.duration) {
                vm.findTables();
            }
        }

        /** Called by the customer search directive when user selects a customer from a list */
        $scope.customerSelected = function (selCust) {

            CustomerResource.get(
                {id: selCust.id},
                function (data) {
                    vm.customer = data;
                    vm.customer.primaryPhone = intlTelInputUtils.formatNumber(vm.customer.primaryPhone, ApplicationContext.getPhoneRegion(), intlTelInputUtils.numberFormat.INTERNATIONAL);
                    vm.customerTotal = vm.customer.totalVisits + vm.customer.totalNoShow + vm.customer.totalCanceled;
                    vm.customerOriginal = Utils.getBasicCustomerData(data);
                }
            );
        }

        function resetCustomer() {
            // workaround for https://github.com/angular/angular.js/issues/2743
            jQuery("input[name='primaryEmail']").val("");
            jQuery("input[name='primaryPhone']").val("");

            vm.customer = null;
            vm.customerTotal = null;
            vm.customerOriginal = null;
            $scope.customerSearchKeyword = '';
        }

        function isUpdateDisabled() {
            var isDisabled =
                !vm.numberOfPersons ||
                !vm.duration ||
                !vm.isTimeSet() ||
                !vm.selectedDayFormatted ||
                vm.getSelectedResources().length === 0 ||
                (vm.event.type !== 'WALK_IN_SEATED' &&
                    (!vm.customer ||
                        !vm.customer.firstName ||
                        !(vm.customer.primaryPhone || vm.customer.primaryEmail)));
            return isDisabled;
        }

        function isUpdateWithWarning() {
            var warning =
                (vm.getSelectedResources().length > 0 && vm.getSelectedResourcesCapacity() < vm.numberOfPersons);

            return warning;
        }

        function isTimeSet() {
            return vm.selectedTime && moment(vm.selectedTime, TIME_DISPLAY_FORMAT).isValid();
        }


        function doCreate() {

            var currentCustomer = vm.customer != null ? Utils.getBasicCustomerData(vm.customer) : null;
            var customerChangedFlag = currentCustomer && currentCustomer.id && !angular.equals(currentCustomer, vm.customerOriginal);


            var params = {
                id: vm.eventId,
                date: vm.selectedDay,
                time: timeToServerFormat(vm.selectedTime),
                channel: 'WEB',
                duration: vm.duration,
                numberOfPersons: vm.numberOfPersons,
                resourceIds: vm.getSelectedResourcesIds(),
                note: vm.note,
                existingCustomerUpdated: customerChangedFlag,
                customer: currentCustomer,
                productType: vm.settings.productType,
                productId: vm.productId
            }

            Tables.updateEvent(
                params,
                function (data) {
                    $state.go('tables.eventDetails', {eventId: data.id});
                });
        }

        function updateEvent() {

            if (vm.isUpdateWithWarning()) {

                var text = ' ';
                if (vm.getSelectedResourcesCapacity() < vm.numberOfPersons) {
                    text += ' Total table capacity (' + vm.getSelectedResourcesCapacity() + ') is not enough to seat ' + vm.numberOfPersons + ' person(s)!';
                }

                SweetAlert.swal({
                        title: "Are you sure?",
                        text: text,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Update event",
                        cancelButtonText: "Cancel",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            doCreate();
                        }
                    });
            } else {
                doCreate();
            }
        }

        /** Returns the selected time in the format expected by the REST service. */
        function timeToServerFormat(time) {
            return moment(time, TIME_DISPLAY_FORMAT).format(TIME_SERVER_FORMAT);
        }

        // initialize date & time picker
        $document.ready(
            function () {
                // date picker
                vm.datePicker = $document.find('input[name="datePicker"]');
                vm.datePicker.daterangepicker(
                    {
                        singleDatePicker: true,
                        showDropdowns: true,
                        locale: {format: DATE_PICKER_FORMAT}
                    },
                    function (start, end, label) {
                        vm.selectedDay = start.format(DATE_FORMAT)
                        vm.daySelected();
                    }
                );
                vm.datePicker.val(vm.selectedDayFormatted);

                // time picker
                vm.timePicker = $('.clockpicker').clockpicker({
                    donetext: 'Done',
                    twelvehour: true
                });
            }
        );

        function openDatePicker() {
            vm.datePicker.focus();
        }

        vm.phoneRegion = ApplicationContext.getPhoneRegion();
        function reformatPhone() {
            $scope.numInput = $document.find('#primaryPhone');
            if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
                var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.E164);
                if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                    $scope.numInput.intlTelInput('setNumber', currentText); // will autoformat because of formatOnDisplay=true
                }
            }
        }

        function findProduct(productId) {
            if(vm.productList) {
                for (var i = 0; i < vm.productList.length; i++) {
                    if(vm.productList[i].id == productId)
                        return vm.productList[i];
                }
            }
            // product not found for product id
            return null;
        }

        function findDuration(duration) {
            if(vm.productList) {
                for (var i = 0; i < vm.productList.length; i++) {
                    console.log("Product duration:"+vm.productList[i].duration+": duration:"+duration);
                    if(vm.productList[i].duration == duration)
                        return vm.productList[i];
                }
            }
            // product not found for product id
            return null;
        }

        function selectProductText() {
            if(vm.event.productType=='MEAL') {
                return " meal or custom duration";
            } else if(vm.event.productType=='PRODUCT') {
                return " product";
            } else {
                return " duration";
            }
        }

        function getProductInfo() {
            if(vm.event.product) {
                return vm.event.product.name;
            } else {
                return "unknown";
            }
        }

        function changeDuration(productId) {
            if (productId) {
                vm.selectedProduct = findProduct(productId);
                vm.duration = vm.selectedProduct.duration;
                vm.productId = vm.selectedProduct.id;
                findTables();
            } else {
                vm.selectedProduct = null;
                vm.duration = null;
                vm.productId = null;
            }
        }
    }
})();



