(function() {
    'use strict';

    angular
        .module('seatonApp')
        .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$rootScope', 'Notification'];

    function notificationInterceptor ($rootScope, Notification) {
        var service = {
            response: response
        };

        return service;

        function response (response) {

            var headers = Object.keys(response.headers()).filter(function (header) {
                return header.endsWith('pp-alert') || header.endsWith('pp-alert-type')
            }).sort();

            var alertMessage = response.headers(headers[0]);
            if (angular.isString(alertMessage)) {
                var alertType = response.headers(headers[1]);
                if (angular.isString(alertType)) {
                    Notification.showAlert(alertType, alertMessage);
                }
            }

            return response;
        }
    }
})();
