(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('EventDetailsController', EventDetailsController);

    EventDetailsController.$inject = ['$scope', '$state', '$stateParams', 'Tables', 'Notification', 'Utils',
        'Time', 'ApplicationProfile', 'SweetAlert', '$uibModal','Log'];

    function EventDetailsController($scope, $state, $stateParams, Tables, Notification, Utils, Time, ApplicationProfile,
                                    SweetAlert, $uibModal, Log) {

        var vm = this;
        vm.utils = Utils;
        vm.event = {};
        vm.eventId = $stateParams.eventId;

        vm.showChangeHistory = ApplicationProfile.isEventChangeHistoryEnabled();
        vm.showChangeHistoryText = ApplicationProfile.upgradeTierText("EventChangeHistory");
        vm.openWindow = ApplicationProfile.openAppCloverPage;
        vm.noteEditMode = false;

        // defines possible actions (state transitions) for each event type/state/status combination
        // KEY: type - state - status
        var actions = {

            "RESERVATION-OPEN-RESERVED" : ["Show", "No show", "Cancel"],
            "RESERVATION-OPEN-PRERESERVED" : ["Cancel"],
            "RESERVATION-OPEN-CONFIRMED" : ["Cancel"],
            "RESERVATION-OPEN-SHOW" : ["Cancel", "Move back to arrivals"],
            "RESERVATION-CLOSED-CANCELLED" : ["Re-open"],
            "RESERVATION-CLOSED-NO_SHOW" : ["Re-open"],

            "WALK_IN_SEATED-OPEN-SHOW" : ["Close"],

            "WALK_IN_WAITLISTED-OPEN-WAITLISTED" : ["Cancel"],
            "WALK_IN_WAITLISTED-OPEN-NOTIFIED" : ["Show", "No show", "Cancel"],
            "WALK_IN_WAITLISTED-OPEN-CONFIRMED" : ["Show", "No show", "Cancel"],
            "WALK_IN_WAITLISTED-OPEN-SHOW" : ["Cancel"],
            "WALK_IN_WAITLISTED-CLOSED-CANCELLED" : ["Re-open"],
            "WALK_IN_WAITLISTED-CLOSED-NO_SHOW" : ["Re-open"]
        }

        var actionMap = {
            "Confirm" : "CONFIRMATION",
            "Show" : "ARRIVAL",
            "No show" : "NO_ARRIVAL",
            "Cancel" : "CANCELLATION",
            "Close" : "CLOSE_WITHOUT_PAYMENT",
            "Move back to arrivals" : "BACK_TO_ARRIVALS",
            "Re-open" : "REOPEN",
            "Notify" : "NOTIFICATION"
        }

        var messageMap = {
            "No show": "Are you sure you want to mark event as No-SHOW (customer did not show up)?",
            "Cancel": "Are you sure you want to cancel event?",
            "Close": "Dow you want to close this event without payment details?"
        }

        vm.refresh = function() {
            Tables.getEventDetails(
                {
                    id: vm.eventId
                },
                function (data) {
                    vm.event = data;
                    updateTimeDiffs(data);
                    vm.specialDate = getSpecialDateToday(data);
                }
            );
        }
        vm.refresh();

        vm.capitalize = function (str) {
            if (str) {
                str = str.replace('_', ' ');
                return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            } else {
                return "";
            }
        }

        vm.enableEditNote = function() {
            vm.editNoteMode = true;
            vm.newNote = vm.event.note;
        }

        vm.saveNote = function() {
            vm.editNoteMode = false;
            vm.event.note = vm.newNote;

            var putParams = {};
            putParams.note = vm.newNote;
            putParams.id = vm.eventId;

            Tables.updateEventNote(
                putParams,
                function (data) {
                    vm.refresh();
                },
                function (error) {
                    Log.debug("EventDetailsController", "Tables.updateEventNote() returned na error: " + error);
                    vm.refresh();
                }
            );
        }

        vm.cancelEditNote = function() {
            vm.editNoteMode = false;
            vm.newNote = vm.event.note;
        }

        vm.getActions = function() {
            var key = vm.event.type + "-" + vm.event.state + "-" + vm.event.status;
            var hasTables = vm.event.resources && vm.event.resources.length > 0;

            return actions[key] || [];
        }

        vm.changeStatus = function(action, params) {

            var confirmMsg = messageMap[action];
            if (confirmMsg) {
                SweetAlert.swal({
                        title: "Please confirm action",
                        text: confirmMsg,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            if(vm.event.type == 'RESERVATION' && action=='Cancel') {
                                vm.cancelCustomerName = vm.event.customer.firstName + ' ' + vm.event.customer.lastName;
                                vm.cancelCustomerEmail = vm.event.customer.primaryEmail;
                                vm.cancelEventId = vm.event.id;

                                Tables.personalizedCancel(
                                    {},
                                    function (data) {

                                        vm.cancel = data;
                                        if (vm.cancel.personalisationAllowed) {
                                            vm.cancelMessageText = vm.cancel.cancelMessage;

                                            vm.cancelDialog = $uibModal.open({
                                                templateUrl: 'app/tables/management/cancel.dialog.html',
                                                scope: $scope
                                            });

                                        } else{
                                            vm.triggerStateChange(vm.event.id, actionMap[action]);
                                        }
                                    }
                                );
                            } else {
                                vm.triggerStateChange(vm.event.id, actionMap[action]);
                            }
                        }
                    });
            } else {
                vm.triggerStateChange(vm.event.id, actionMap[action]);
            }
        }

        vm.triggerStateChange = function(eventId, action, params) {
            var postParams = params ? params : {};
            postParams.id = eventId;
            postParams.trigger = action;

            Tables.triggerStateChange(
                postParams,
                function (data) {
                    vm.refresh();
                },
                function (error) {
                    // this will usually happen if someone else updated the event state (server will return HTTP code 409 Conflict)
                    // we'll just refresh the screen
                    Log.debug("EventDetailsController", "Tables.triggerStateChange() returned na error: " + error);
                    vm.refresh();
                }
            );
        }

        vm.closeCancelDialog = function () {
            vm.cancelDialog.dismiss('cancel');
        }

        vm.cancelWithPersonalizedMessage = function () {

            vm.triggerStateChange(
                vm.cancelEventId,
                'CANCELLATION',
                {message: vm.cancelMessageText});

            vm.closeCancelDialog();
        }

        vm.findTables = function (event) {
            $state.go('tables.newreservation', {prereservedEventId: event.id});
        }

        vm.formatChannel = function (channel) {
            if (channel == 'DEVICE') {
                return 'Clover device';
            } else if (channel == 'WEB') {
                return 'SeatOn web app';
            } else if (channel == 'MERCHANT_WEB') {
                return 'Restaurant\'s web page';
            } else if (channel == 'FACEBOOK') {
                return 'Restaurant\'s Facebook page';
             } else if (channel == 'GOOGLE') {
                return 'Restaurant\'s Google page';
            } else if (channel == 'IVR') {
                    return 'SeatOn IVR system';
            } else {
                return channel;
            }
        }

        /** If the feedback is older than two days, this method returns false,
            otherwise it returns true. */
        vm.isNewFeedback = function (time) {
            var timeDiffMillis = Time.fromNow(time);
            var timeDiffDays   = timeDiffMillis / 1000.0 / 60.0 / 60.0 / 24.0;

            return (timeDiffDays > -2);
        }

        function updateTimeDiffs(event) {
            if (event.expectedStartTime && event.arrivalTime) {
                // negative : came in DIFF minutes early
                // zero     : came on time
                // positive : came in DIFF minute late
                vm.arrivalDiff = (moment(event.arrivalTime).diff(event.expectedStartTime) / 60000.0).toFixed(0);
            }

            if (event.expectedEndTime && (event.paidTime || event.checkOutTime)) {
                var closingTime = event.paidTime ? event.paidTime : event.checkOutTime;
                // negative : left DIFF minutes early
                // zero     : left on time
                // positive : left in DIFF minute late
                vm.closingDiff = (moment(closingTime).diff(event.expectedEndTime) / 60000.0).toFixed(0);
            }

            if (event.notifiedTime && event.quotedWaitTime && event.createdDate) {
                var expectedTime = moment(event.createdDate).add(event.quotedWaitTime, 'minutes');
                // negative : notified DIFF minutes before quoted time
                // zero     : notified on time
                // positive : notified DIFF minutes later then quoted time
                vm.notifiedDiff = (moment(event.notifiedTime).diff(expectedTime) / 60000.0).toFixed(0);
            }
        }

        function getSpecialDateToday(event) {

            if (event.customer) {
                for (var i = 0; i < event.customer.importantDates.length; i++) {
                    var imDate = event.customer.importantDates[0];
                    if (moment().isSame(moment(imDate.value), 'day')) {
                        return imDate.dateType; // return only the first one
                    }
                }
            }
            return undefined;
        }

        vm.calculateActualDuration = function() {
            var closingTime = vm.event.paidTime ? vm.event.paidTime : vm.event.checkOutTime;
            Log.debug("EventDetailsController","Closing time:"+actualDuration+". start time:"+vm.event.arrivalTime+".");
            if(closingTime && vm.event.arrivalTime) {
                 var actualDuration = (moment(closingTime).diff(vm.event.arrivalTime) / 60000.0).toFixed(0);
                 Log.debug("EventDetailsController","Actual duration "+actualDuration+".");
                return actualDuration;
            }
            return 0;
        }

        // Reservation fee
        vm.captureUntil = function(createdDate) {
            var start = moment(createdDate);
            return start.add(7, 'days').format("MMM Do YYYY");
        }

        vm.paymentEnabled = function() {
            return vm.event.paymentStatus && vm.event.paymentStatus!='NO_PAYMENT';
        }

        vm.refundApprovedAmount = function() {
            var confirmMsg = "Please confirm you want to refund reservation fee.";
            SweetAlert.swal({
                    title: "Reservation fee refund",
                    text: confirmMsg,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if(isConfirm) {

                        var postParams = {
                            eventId: vm.event.id,
                            chargeId: vm.event.paymentChargeId,
                            amount: vm.event.paymentApprovedAmount
                        };

                        Tables.refundReservationFee (
                            postParams,
                            function (data) {
                                var msgTitle = "Refund successfully completed!";
                                var alertType = "success";
                                var msgText = "";
                                if(data.refundSucceed) {
                                    vm.event.paymentStatus = data.paymentStatus;
                                    vm.event.eventPaymentHistories = data.eventPaymentHistories;
                                } else {
                                    msgTitle = "Refund failed!";
                                    msgText = "Refund declined by Clover. Please try later again. If the error persists please see refund decline details under transaction list in Clover dashboard.";
                                    alertType = "error"
                                }

                                SweetAlert.swal({
                                        title: msgTitle,
                                        text: msgText,
                                        type: alertType,
                                        showCancelButton: false,
                                        confirmButtonColor: "#DD6B55",
                                        confirmButtonText: "OK",
                                        closeOnConfirm: true
                                    },
                                    function (isConfirm) {
                                    });
                            }
                        )
                    }
                });

        }

        vm.captureApprovedAmount = function() {
            var confirmMsg = "Please confirm you want to capture reservation fee.";
            SweetAlert.swal({
                    title: "Reservation fee capture.",
                    text: confirmMsg,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if(isConfirm) {

                        var postParams = {
                            eventId: vm.event.id,
                            chargeId: vm.event.paymentChargeId,
                            amount: vm.event.paymentApprovedAmount,
                            receiptEmail: vm.event.customer.primaryEmail
                        };

                        Tables.captureReservationFee (
                            postParams,
                            function (data) {
                                var msgTitle = "Reservation fee successfully captured!";
                                var alertType = "success";
                                var msgText = "";
                                if(data.refundSucceed) {
                                    vm.event.paymentStatus = data.paymentStatus;
                                    vm.event.eventPaymentHistories = data.eventPaymentHistories;
                                } else {
                                    msgTitle = "Reservation fee capture failed!";
                                    msgText = "Reservation fee capture declined by Clover. Please try later again. If the error persists please see capture decline details under transaction list in Clover dashboard.";
                                    alertType = "error"
                                }

                                SweetAlert.swal({
                                        title: msgTitle,
                                        text: msgText,
                                        type: alertType,
                                        showCancelButton: false,
                                        confirmButtonColor: "#DD6B55",
                                        confirmButtonText: "OK",
                                        closeOnConfirm: true
                                    },
                                    function (isConfirm) {
                                    });
                            }
                        )
                    }
                });
        }
    }
})();



