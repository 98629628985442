'use strict';

angular.module('seatonApp')
    .controller('RevenueTrendController', RevenueTrendController);

RevenueTrendController.$inject = ['$scope', 'Principal', '$q', '$filter', 'googleChartApiPromise', 'Statistics', 'Reporting', '$timeout', 'ApplicationContext'];

function RevenueTrendController($scope, Principal, $q, $filter, googleChartApiPromise, Statistics, Reporting, $timeout, ApplicationContext) {
    var vm = this;
    vm.customerFilter = 'all';
    vm.reload = reload;
    vm.onChartSelect = onChartSelect;
    vm.selectedMonth = -1;
    vm.calcPct = Reporting.calcPct;

    Principal.identity().then(function (account) {
        $scope.account = account;
        $scope.isAuthenticated = Principal.isAuthenticated;
    });

    function onChartSelect(selectedItem) {
        //console.log(selectedItem);
        if (typeof selectedItem != 'undefined') {
            var selMonth = selectedItem.row - 12;
            if (selMonth != vm.selectedMonth) {
                vm.selectedMonth = selMonth;
                reloadMonthDetails().then(displayMonthDetailsCharts);
            }
        }
    }

    load();

    function reload() {
        getReportingRevenueTrend().then(displaySpendTrendChart);
        reloadMonthDetails().then(displayMonthDetailsCharts);
    }

    function reloadMonthDetails() {
        return getReportingRevenueTrendMonthDetails();
    }

    function displayMonthDetailsCharts() {
        displayVisitsChart();
        displayMealChart();
    }

    function displayAllCharts() {
        displaySpendTrendChart();
        displayVisitsChart();
        displayMealChart();
    }

    function load() {
        $q.all([
            googleChartApiPromise,
            getReportingRevenueTrend(),
            getReportingRevenueTrendMonthDetails()
        ]).then(displayAllCharts);
    }

    function getReportingRevenueTrend() {
        return Statistics.getReportingRevenueTrend({
            customerFilter: vm.customerFilter
        }, function (data) {
            vm.revenueData = data;
            vm.total = vm.revenueData.total;
            vm.avgSpendPerCover = data.total.amountAvgPerCover;

            vm.templateParam1 = data.total.amountAvgPerCover;
            vm.templateParam2 = data.total.tipAvg;
            vm.templateParam3 = data.total.coversPerVisit;
            vm.templateParam4 = data.total.noShowPct;
        }).$promise;
    }

    function getReportingRevenueTrendMonthDetails() {
        return Statistics.getReportingRevenueTrendMonthDetails({
            customerFilter: vm.customerFilter,
            months: vm.selectedMonth
        }, function (data) {
            vm.revenueMonthDetailsData = data;
            vm.mealData = data.mealData;
            vm.visitTypeData = data.visitTypeData;
        }).$promise;
    }


    function displaySpendTrendChart() {
        var deferred = $q.defer();

        // Spend trend
        var dataTrend = new google.visualization.DataTable();
        dataTrend.addColumn('string', 'Month');
        dataTrend.addColumn('number', 'Reservation', 'color: #007E3B');
        dataTrend.addColumn('number', 'Walk-In Seated');
        dataTrend.addColumn('number', 'Walk-In Waitlisted');
        dataTrend.addColumn({type: 'string', role: 'annotation'});

        Reporting.setSpendTrendChartData(dataTrend, vm.revenueData.chartData);

        var optionsTrend = {
            legend: {position: 'top', alignment: 'end'},
            isStacked: 'true',
            //width: 1050,
            height: 425,
            vAxis: {
                title: 'Revenue in ' + ApplicationContext.getCurrencySym(),
                textPosition: 'none',
                gridlines: {count: 13}, viewWindow: {min: 0},
                titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
            },
            hAxis: {
                title: "Month",
                //slantedText: true,
                //slantedTextAngle: 45,
                titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
            },
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            chartArea: {width: '90%', height: '70%'},
            formatters: {},
            colors: ['#1ABC9C', '#3399CC', '#996699']
        };

        $scope.chartObjectTrend = {
            type: "ColumnChart",
            options: optionsTrend,
            data: dataTrend
        };

        return deferred.promise;

    }

    function displayVisitsChart() {
        var deferred = $q.defer();
        //visits - general pie chart
        $scope.chartObjectVisitGeneral = null;

        var chartObject = new google.visualization.DataTable();
        chartObject.type = "PieChart";

        chartObject.options = {
            title: 'Revenue spread by visit type',
            legend: {position: 'right'},
            //width: 450,
            height: 425,
            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#3399CC'},
                2: {color: '#996699'},
            }

        };

        var chartData = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Reservation"},
                        {
                            v: vm.visitTypeData.reservation.amountSum,
                            f: $filter('currency')(vm.visitTypeData.reservation.amountSum)
                        },
                    ]
                },
                {
                    c: [
                        {v: "Walk-In Seated"},
                        {
                            v: vm.visitTypeData.seated.amountSum,
                            f: $filter('currency')(vm.visitTypeData.seated.amountSum)
                        }
                    ]
                },
                {
                    c: [
                        {v: "Walk-In Waitlisted"},
                        {
                            v: vm.visitTypeData.waitlist.amountSum,
                            f: $filter('currency')(vm.visitTypeData.waitlist.amountSum)
                        }
                    ]
                }
            ]
        };

        $timeout(function () {
            chartObject.data = chartData;
            $scope.chartObjectVisitGeneral = chartObject;
            $scope.$apply();
        });
        return deferred.promise;
    }

    function displayMealChart() {
        var deferred = $q.defer();

        $scope.chartObjectMealGeneral = null;

        $timeout(function () {
            //Meal -  pie chart
            $scope.chartObjectMealGeneral = {};
            $scope.chartObjectMealGeneral.type = "PieChart";

            $scope.chartObjectMealGeneral.options = {
                title: 'Revenue spread by period of the day',
                legend: {position: 'right'},
                //width: 450,
                height: 425,
                slices: {
                    0: {color: '#1ABC9C'},
                    1: {color: '#3399CC'},
                    2: {color: '#996699'},
                }

            };

            $scope.chartObjectMealGeneral.data = {
                "cols": [
                    {id: "t", label: "Type", type: "string"},
                    {id: "s", label: "Number", type: "number"}
                ], "rows": [
                    {
                        c: [
                            {v: "Breakfast (" + vm.mealData.breakfestStart + " - " + vm.mealData.breakfestEnd + ")"},
                            {
                                v: vm.mealData.breakfest.amountSum,
                                f: $filter('currency')(vm.mealData.breakfest.amountSum)
                            },
                        ]
                    },
                    {
                        c: [
                            {v: "Lunch (" + vm.mealData.lunchStart + " - " + vm.mealData.lunchEnd + ")"},
                            {
                                v: vm.mealData.lunch.amountSum,
                                f: $filter('currency')(vm.mealData.lunch.amountSum)
                            }
                        ]
                    },
                    {
                        c: [
                            {v: "Dinner (" + vm.mealData.dinnerStart + " - " + vm.mealData.dinnerEnd + ")"},
                            {
                                v: vm.mealData.dinner.amountSum,
                                f: $filter('currency')(vm.mealData.dinner.amountSum)
                            }
                        ]
                    }
                ]
            };
        });
        return deferred.promise;
    }
}
