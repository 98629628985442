(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('FloorController', FloorController);

    FloorController.$inject = ['$rootScope', '$scope', '$state', '$timeout', '$document', 'SettingsResource', 'Log',
                                'ApplicationContext', 'TextUtil', 'SweetAlert'];

    function FloorController($rootScope, $scope, $state, $timeout, $document, SettingsResource, Log,
                             ApplicationContext, TextUtil, SweetAlert) {

        var vm = this;
        vm.isTableManaged = ApplicationContext.isTableManaged();
        var capacityText =  TextUtil.translate('capacity');

            SettingsResource.getAreasAndTables({
        }, onSuccess, onError);

        if ($rootScope.configNotCompleted == true) {
            $scope.showIntroFloor = true;
            $rootScope.configNotCompleted = false;
            Log.debug("Flor settings", "Show floor settings intro.");
        }


        function onSuccess(data, headers) {
            data.sort(function(a, b){return a.id - b.id});
            $scope.data = data;

            angular.forEach($scope.data, function (area){
                area.statusData = [];
                angular.forEach(area.resources, function (table){
                    var count = 0;
                    var nadeno = 0;

                    $scope.capacity = "";
                    $scope.capacity = table.capacity + " " + capacityText;

                    if(table.vip == true){
                        $scope.vip = 1;
                    }
                    else {
                       $scope.vip = 0;
                    }

                    if (table.name.length > 16) {
                        if(vm.isTableManaged) {
                            table.nameToShow = table.name.substring(0, 16) + "...";
                        } else {
                            table.nameToShow = table.name;
                        }
                    }
                    else {
                        table.nameToShow = table.name;
                    }

                    if (area.statusData.length == 0) {
                        area.statusData.push({seats: $scope.capacity, tables: 1, vipTables: $scope.vip, capacity: table.capacity});
                    } else {
                        angular.forEach(area.statusData, function (searchTables) {
                            count++;
                            if (searchTables.seats == $scope.capacity) {
                                nadeno++
                                searchTables.tables++;
                                searchTables.vipTables += $scope.vip;
                            } else if (count == area.statusData.length && $scope.capacity && nadeno == 0) {
                                area.statusData.push({seats: $scope.capacity, tables: 1, vipTables: $scope.vip, capacity: table.capacity});
                            }
                        });
                    }

                });
            });

            console.log('$scope.data', $scope.data);
            Log.debug("TablesController", "HTTP request succeed. Data=" + data.id);
        }

        function onError(error) {
            Log.debug("settings.tables", "HTTP request error." + error);
        }

        function onSuccessRefreshArea() {
            //console.log('tu sam');
            SettingsResource.getAreasAndTables({
            }, onSuccess, onError);
        }

        $scope.anyPopupVisible = false;

        $scope.availableSigns = [
            {signDB: "A", sign: "A"},
            {signDB: "B", sign: "B"},
            {signDB: "C", sign: "C"},
            {signDB: "D", sign: "D"},
            {signDB: "E", sign: "E"},
            {signDB: "F", sign: "F"},
            {signDB: "G", sign: "G"},
            {signDB: "H", sign: "H"},
            {signDB: "I", sign: "I"},
            {signDB: "J", sign: "J"},
            {signDB: "K", sign: "K"},
            {signDB: "L", sign: "L"},
            {signDB: "M", sign: "M"},
            {signDB: "N", sign: "N"},
            {signDB: "O", sign: "O"},
            {signDB: "P", sign: "P"},
            {signDB: "Q", sign: "Q"},
            {signDB: "R", sign: "R"},
            {signDB: "S", sign: "S"},
            {signDB: "T", sign: "T"},
            {signDB: "U", sign: "U"},
            {signDB: "V", sign: "V"},
            {signDB: "W", sign: "W"},
            {signDB: "X", sign: "X"},
            {signDB: "Y", sign: "Y"},
            {signDB: "Z", sign: "Z"}
        ];

        $scope.capacityButtonColor = function(capacity) {
            switch(capacity) {
                case 1: return 'btn-info';
                case 2: return 'btn-primary';
                case 4: return 'btn-success';
                case 6: return 'btn-warning';
                default: return 'btn-danger';
            }
        }


        // ADD NEW AREA

        $scope.showPopupAddNewArea = function(){
            $scope.anyPopupVisible = true;
            $scope.popupNewAreaVisible = true;
            $scope.newArea={name: "", sign: "", color: "#1c84c6", vip: false}
        }

        $scope.newAreaVipChanged = function(){
            if ($scope.newArea.vip == false) {
                $scope.newArea.vip = true
            } else {
                $scope.newArea.vip = false
            }
        }

        $scope.saveNewArea = function () {
            $scope.nameExist = false;
            $scope.signExist = false;
            var broji = 0;
            var nadeno = 0;
            var nadenaOznaka = 0;

            angular.forEach($scope.data, function (item) {
                broji++
                if (item.name == $scope.newArea.name) {
                    nadeno++;
                }
                if(item.sign == $scope.newArea.sign){
                    nadenaOznaka ++;
                }
            });

            if (broji == $scope.data.length && nadeno == 0 && nadenaOznaka == 0) {
                SettingsResource.createArea($scope.newArea, onSuccessRefreshArea, onError);
                $scope.popupNewAreaVisible = false;
                $scope.anyPopupVisible = false;
            }
            else if(broji == $scope.data.length && nadeno > 0){
                $scope.nameExist = true;
            }
            else if(broji == $scope.data.length && nadenaOznaka > 0){
                $scope.signExist = true;
            }

        }



        // EDIT AREA

        $scope.showPopupEditArea = function(index){
//            console.log($scope.data[index]);
            $scope.editArea = {
                id: $scope.data[index].id,
                locationId: $scope.data[index].locationId,
                name: $scope.data[index].name,
                vip: $scope.data[index].vip,
                color: $scope.data[index].color,
                sign: $scope.data[index].sign
            };
            $scope.anyPopupVisible = true;
            $scope.popupEditAreaVisible = true;
        }

        $scope.editAreaVipChanged = function(){
            if ($scope.editArea.vip == false) {
                $scope.editArea.vip = true
            } else {
                $scope.editArea.vip = false
            }
        }

        $scope.saveEditedArea = function () {
            $scope.editedAreaNameExist = false;
            $scope.editedAreaSignExist = false;
            var broji = 0;
            var nadeno = 0;
            var nadenaOznaka = 0;

            angular.forEach($scope.data, function (item) {
                broji++;
                if (item.name == $scope.editArea.name && item.id !=$scope.editArea.id) {
                    nadeno++;
                }
                if (item.sign == $scope.editArea.sign && item.id !=$scope.editArea.id) {
                    nadenaOznaka++;
                }
            });

            if (broji == $scope.data.length && nadeno == 0 && nadenaOznaka == 0) {
                SettingsResource.updateArea($scope.editArea, onSuccessRefreshArea, onError);
                $scope.popupEditAreaVisible = false;
                $scope.anyPopupVisible = false;
            }
            else if (broji == $scope.data.length && nadeno > 0) {
                $scope.editedAreaNameExist = true;
            } else if (broji == $scope.data.length && nadenaOznaka > 0) {
                $scope.editedAreaSignExist = true;
            }

        }


        // DELETE AREA

        $scope.showPopupDeleteArea = function(index){
            $scope.areaIdForDelete = 0;
//            console.log($scope.data[index]);

            $scope.areaIdForDelete = {id: $scope.data[index].id};

            $scope.anyPopupVisible = true;
            $scope.popupDeleteAreaVisible = true;

            $scope.deleteAreaShowOkDeleteAreaButton = false;

            if ($scope.data[index].resources.length > 0) {
                $scope.deleteAreaMessage = "You can not delete " + TextUtil.translate('category', 's') +
                    " with " + TextUtil.translate('resource','p') + " in it. " +
                    "Please delete all "+ TextUtil.translate('resource','p') +" from this " +
                    TextUtil.translate('category','s') +" to proceed.";
                $scope.deleteAreaShowOkDeleteAreaButton = false;
            } else {
                $scope.deleteAreaMessage = "Are you sure you want to delete " + $scope.data[index].name + " area?";
                $scope.deleteAreaShowOkDeleteAreaButton = true;
            }
        }

        $scope.deleteArea = function () {
            SettingsResource.deleteArea($scope.areaIdForDelete, onSuccessRefreshArea, onError);
            $scope.popupDeleteAreaVisible = false;
            $scope.anyPopupVisible = false;
            $scope.areaIdForDelete = 0;
        }


        // ADD TABLE

        $scope.generateTableName = function (index, actionType) {
            $scope.broj++;

            if ($scope.broj < 10) {
                $scope.newTableName = $scope.data[index].sign + "0" + $scope.broj;
            } else {
                $scope.newTableName = $scope.data[index].sign + $scope.broj;
            }

            $scope.checkTableName($scope.newTableName, actionType);
        }

        $scope.checkTableName = function (nameToCheck, actionType) {
            var nadeno = 0;
            var countArea = 0;

            angular.forEach($scope.data, function (area) {
                var brojiStolove = 0;
                countArea++;

                if (area.resources.length > 0) {
                    angular.forEach(area.resources, function (item) {
                        brojiStolove++;
                        if (item.name == nameToCheck) {
                            nadeno++;
                        }

                        if (actionType == "addNewCustTable") {
                            if (nadeno > 0 && brojiStolove == area.resources.length && countArea == $scope.data.length) {
                                $scope.generateTableName($scope.areaIndex, "addNewCustTable");
                            } else if (brojiStolove == area.resources.length && countArea == $scope.data.length && nadeno == 0) {
                                $scope.newCustomTable.name = $scope.newTableName;
                            }
                        }

                        if (actionType == "addNewDefaultTable") {
                            if (nadeno > 0 && brojiStolove == area.resources.length && countArea == $scope.data.length) {
                                $scope.generateTableName($scope.areaIndex, "addNewDefaultTable");
                            } else if (brojiStolove == area.resources.length && countArea == $scope.data.length && nadeno == 0) {
                                $scope.newTable.name = $scope.newTableName;
                                SettingsResource.createTable($scope.newTable, onSuccessRefreshArea, onError);
                                $scope.areaIndex = -1;
                            }
                        }

                        if (actionType == "editedTable") {
                            if (nadeno > 0) {
                                $scope.editedTableNameExist = true;
                            } else if (brojiStolove == area.resources.length && countArea == $scope.data.length && nadeno == 0) {

                                SettingsResource.updateTable($scope.editTable, onSuccessRefreshArea, onError);
                                $scope.anyPopupVisible = false;
                                $scope.popupEditTableVisible = false;
                                $scope.editedTableNameExist = false;
                                $scope.areaIndex = -1;
                            }
                        }

                    });
                }
                else {

                    if (actionType == "addNewCustTable") {
                        if (nadeno > 0 && countArea == $scope.data.length) {
                            $scope.generateTableName($scope.areaIndex, "addNewCustTable");
                        } else if (countArea == $scope.data.length && nadeno == 0) {
                            $scope.newCustomTable.name = $scope.newTableName;
                            $scope.areaIndex = -1;
                        }
                    }

                    if (actionType == "addNewDefaultTable") {
                        if (nadeno > 0 && countArea == $scope.data.length) {
                            $scope.generateTableName($scope.areaIndex, "addNewDefaultTable");
                        } else if (countArea == $scope.data.length && nadeno == 0) {
                            $scope.newTable.name = $scope.newTableName;
                            SettingsResource.createTable($scope.newTable, onSuccessRefreshArea, onError);
                        }
                    }

                    if (actionType == "editedTable") {
                        if (nadeno > 0) {
                            $scope.editedTableNameExist = true;
                        } else if (countArea == $scope.data.length && nadeno == 0) {

                            SettingsResource.updateTable($scope.editTable, onSuccessRefreshArea, onError);
                            $scope.anyPopupVisible = false;
                            $scope.popupEditTableVisible = false;
                            $scope.editedTableNameExist = false;
                            $scope.areaIndex = -1;
                        }
                    }
                }

            });
        }

        $scope.addNewTable = function (index, type){

            $scope.newTable = {
                vip: false,
                areaId: $scope.data[index].id,
                type: "TABLE",
                locationId: $scope.data[index].locationId
            };

            if (type == "twoSeats") {
                $scope.newTable.capacity = 2;
            }
            if (type == "fourSeats") {
                $scope.newTable.capacity = 4;
            }
            if (type == "sixSeats") {
                $scope.newTable.capacity = 6;
            }
            if(type == "oneSeat") {
                $scope.newTable.capacity = 1;
            }

            $scope.newTableName = "";
            $scope.broj = $scope.data[index].resources.length;
            $scope.areaIndex = index;

            $scope.generateTableName($scope.areaIndex, "addNewDefaultTable");

        }

        $scope.addNewCustomTable = function(index){
            $scope.anyPopupVisible = true;
            $scope.popupAddCustomTableVisible = true;

            $scope.newCustomTable = {
                locationId: $scope.data[index].locationId,
                capacity: 0,
                vip: false,
                type: "TABLE",
                areaId: $scope.data[index].id
            };

            $scope.broj = $scope.data[index].resources.length;
            $scope.areaIndex = index;

            $scope.generateTableName($scope.areaIndex, "addNewCustTable");
        }

        $scope.newCustomTableVipChanged = function () {
            if ($scope.newCustomTable.vip == false) {
                $scope.newCustomTable.vip = true;
            } else {
                $scope.newCustomTable.vip = false;
            }
        }

        $scope.saveNewCustomTable = function () {
            SettingsResource.createTable($scope.newCustomTable, onSuccessRefreshArea, onError);
            $scope.anyPopupVisible = false;
            $scope.popupAddCustomTableVisible = false;
            $scope.areaIndex = -1;
        }

        // EDIT TABLE
        $scope.showPopupEditTable = function (areaIndex, tableIndex){

            $scope.editTableAreaIndex = areaIndex;

            $scope.tableNameBeforeEditing = $scope.data[areaIndex].resources[tableIndex].name;
            $scope.tableCapacityBeforeEditing = $scope.data[areaIndex].resources[tableIndex].capacity;

            $scope.editTable = {
                id: $scope.data[areaIndex].resources[tableIndex].id,
                locationId: $scope.data[areaIndex].resources[tableIndex].locationId,
                name: $scope.data[areaIndex].resources[tableIndex].name,
                vip: $scope.data[areaIndex].resources[tableIndex].vip,
                disabled: $scope.data[areaIndex].resources[tableIndex].disabled,
                disabledForOnline: $scope.data[areaIndex].resources[tableIndex].disabledForOnline,
                capacity: $scope.data[areaIndex].resources[tableIndex].capacity,
                cloverResourceId: $scope.data[areaIndex].resources[tableIndex].cloverResourceId,
                type: $scope.data[areaIndex].resources[tableIndex].type,
                areaId: $scope.data[areaIndex].resources[tableIndex].areaId,
            }

            console.log($scope.editTable);
            $scope.anyPopupVisible = true;
            $scope.popupEditTableVisible = true;
            $scope.editedTableNameExist = false;

        }

        $scope.editTableVipChanged = function () {
            if ($scope.editTable.vip == false) {
                $scope.editTable.vip = true
            } else {
                $scope.editTable.vip = false
            }
        }

        $scope.editTableDisabledChanged = function () {
            if ($scope.editTable.disabled == false) {
                $scope.editTable.disabled = true;
                $scope.editTable.disabledForOnline = true;
            } else {
                $scope.editTable.disabled = false
            }
        }

        $scope.editTableDisabledForOnlineChanged = function () {
            if ($scope.editTable.disabledForOnline == false) {
                $scope.editTable.disabledForOnline = true
            } else {
                $scope.editTable.disabledForOnline = false
            }
        }

        $scope.saveEditedTable = function (dirty) {
            $scope.editedTableNameExist = false;
            //TODO check if there are open events!!
            console.log("New capacity: " + $scope.editTable.capacity + "    Old capacity:"+$scope.tableCapacityBeforeEditing);
            var dialogMsg= "<br><br>  Please note that changing capacity may result with inadequate capacity for existing events.";
            if($scope.tableCapacityBeforeEditing > $scope.editTable.capacity)
                dialogMsg=  "<br><br>  Please note that decreasing capacity may cause insufficient capacity for existing events!";


            if($scope.tableCapacityBeforeEditing != $scope.editTable.capacity) {
                SweetAlert.swal({
                        html: true,
                        title: "Confirm action",
                        text: "Do you want to change capacity from "+$scope.tableCapacityBeforeEditing+" to " + $scope.editTable.capacity + " ?"+ dialogMsg,
                        type: "warning",
                        showCancelButton: true,
                        cancelButtonColor: "#ec4758",
                        confirmButtonColor: "#1ab394",
                        cancelButtonText: "No",
                        confirmButtonText: "Yes",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            if (dirty == true && $scope.editTable.name != $scope.tableNameBeforeEditing) {
                                $scope.checkTableName($scope.editTable.name, "editedTable");
                            } else {
                                SettingsResource.updateTable($scope.editTable, onSuccessRefreshArea, onError);
                                $scope.anyPopupVisible = false;
                                $scope.popupEditTableVisible = false;
                            }

                            $scope.tableNameBeforeEditing = "";
                        } else {
                            $scope.editTable.capacity = $scope.tableCapacityBeforeEditing;
                        }
                    });
            } else {
                if (dirty == true && $scope.editTable.name != $scope.tableNameBeforeEditing) {
                    $scope.checkTableName($scope.editTable.name, "editedTable");
                } else {
                    SettingsResource.updateTable($scope.editTable, onSuccessRefreshArea, onError);
                    $scope.anyPopupVisible = false;
                    $scope.popupEditTableVisible = false;
                }

                $scope.tableNameBeforeEditing = "";
            }
        }

        // DELETE TABLE

        $scope.showPopupDeleteTable = function(areaIndex, tableIndex){
            $scope.tableIdForDelete = 0;
            console.log($scope.data[areaIndex].resources[tableIndex]);

            $scope.tableIdForDelete = {id: $scope.data[areaIndex].resources[tableIndex].id};

            $scope.anyPopupVisible = true;
            $scope.popupDeleteTableVisible = true;

            $scope.deleteTableMessage = "Are you sure you want to delete " + $scope.data[areaIndex].resources[tableIndex].name +
                " " + TextUtil.translate('resource', 's') + "?";
        };

        $scope.deleteTable = function () {
            console.log($scope.tableIdForDelete);
            SettingsResource.deleteTable($scope.tableIdForDelete, onSuccessRefreshArea, onError);
            $scope.popupDeleteTableVisible = false;
            $scope.anyPopupVisible = false;
            $scope.tableIdForDelete = 0;
        };


        // close popup

        $scope.closePopup = function (type) {

            $scope.popupNewAreaVisible = false;
            $scope.popupEditAreaVisible = false;
            $scope.popupDeleteAreaVisible = false;

            $scope.popupAddCustomTableVisible = false;
            $scope.popupEditTableVisible = false;
            $scope.popupDeleteTableVisible = false;

            $scope.anyPopupVisible = false;

            $scope.nameExist = false;
            $scope.editedAreaNameExist = false;
            $scope.signExist = false;
            $scope.editedAreaSignExist = false;
        };


        $scope.IntroOptionsFloor = {
            steps:[
                {
                    intro: '<h3 align="center">Welcome to SeatOn Reservations</h3>' +
                    '<p>' +
                    '<strong>Dear Customer</strong>,<br/><br/>' + getCategoryFloorText("FIRST") +
                    '<p>' +
                    "<code>SeatOn Reservations, All rights reserved &copy; since 2018</code>" +
                    '</p>'
                },
                {
                    element: '#floor',
                    intro: getCategoryFloorText("SECOND") +
                    '<p>' +
                    '<span class="font-bold">We strongly recommend to visit <a href="http://support.seaton.site" taget="_blank">SeatOn support center</a> and ' +
                    'find out more about how to customize SeatOn to match your needs.</span><br/>' +
                    '</p>' +
                    '<p class="text-navy font-bold">' +
                    'Enjoy using SeatOn!<br/>' +
                    '<small>Please share your feedback, lets us to know what you like and dont like, and new functionality you would like to see in SeatOn.</small>' +
                    '<p>' +
                    '<code>SeatOn Next Generation Table Management System, All rights reserved &copy; since 2018</code>' +
                    '</p>',
                    position: 'left'
                }
            ],
            showStepNumbers: true,
            showBullets: true,
            exitOnOverlayClick: false,
            exitOnEsc:true,
            nextLabel: '<strong>Next</strong>',
            prevLabel: '<strong>Previous</strong>',
            skipLabel: 'Exit',
            doneLabel: "Let's start"
        };

        function getCategoryFloorText(page) {
            if(page=='FIRST') {
                if(vm.isTableManaged) {
                    return  'Before you start to explore possibilities of the SeatOn system, first you need to define your restaurant areas and tables.<br/>' +
                            'SeatOn allows you to define up to 26 <strong>areas</strong> and an unlimited number of <strong>tables</strong> for each area.<br/>' +
                            '</p>' +
                            '<p>' +
                            '<strong>Area</strong> - represents part of your restaurant floor. You can define the area for specific part ' +
                            'of your restaurant like main room, VIP room, balcony, etc. <br/>' +
                            '</p>' +
                            '<p>' +
                            '<strong>Table</strong> - represents a table in the defined restaurant area.<br/><br/> ' +
                            'To make the table definition process easier, we have created predefined tables with the capacity of 1, 2, 4 and 6 chairs. ' +
                            'Additionally, you can create a table with the custom definition.' +
                            '</p>';

                } else {
                    return  'Before you start to explore possibilities of the SeatOn system, first you need to define your business resources.<br/>' +
                            'SeatOn allows you to define up to 26 <strong>categories</strong> and an unlimited number of <strong>resources</strong> for each category.<br/>' +
                            '</p>' +
                            '<p>' +
                            '<strong>Category</strong> - you can split resource in the categories which represent different type of services you provide or to reflect your internal organisation. <br/>' +
                            '</p>' +
                            '<p>' +
                            '<strong>Resource</strong> - represents a resource of your business which SeatOn will manage.<br/><br/> ' +
                            'To make the table definition process easier, we have created predefined resources with the capacity of 1, 2, 4 and 6. ' +
                            'Additionally, you can create a resource with the custom definition.' +
                            '</p>';
                }
            } else if ("SECOND"){
                if(vm.isTableManaged) {
                    return '<h3 align="center">Define your first area</h3>' +
                            '<p>' +
                            'To start with area and table definition click on the "Add area" button on the right side.<br/>' +
                            'Once you define at least one area and one table, you are ready to use SeatOn.' +
                            '</p>';
                } else {
                    return '<h3 align="center">Define your first category</h3>' +
                            '<p>' +
                            'To start with category and resource definition click on the "Add category" button on the right side.<br/>' +
                            'Once you define at least one category and one resource, you are ready to use SeatOn.' +
                            '</p>';
                }
            }
        }

    }
})();



