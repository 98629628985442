(function() {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('settings.online.reservation.google', {
            parent: 'settings.online.reservation',
            url: '/google',
            data: {
                roles: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/settings/online/reservation/google/google.html',
                    controller: 'OnlineReservationSettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    //$translatePartialLoader.addPart('settings.tables');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
