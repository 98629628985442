(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('OnlineSuccessController', OnlineSuccessController);

    OnlineSuccessController.$inject = ['$scope', '$state', '$stateParams', 'Log', 'PublicResource',
                                    'OnlineReservationContext'];

    function OnlineSuccessController($scope, $state, $stateParams, Log, PublicResource,
                                     OnlineReservationContext) {

        var vm = this;
        vm.type = getType(OnlineReservationContext.getReference());
        vm.hashedLocationId = OnlineReservationContext.getLocationId();
        vm.channel = OnlineReservationContext.getChannel();
        vm.settings = OnlineReservationContext.getSettings();

        if(!vm.channel) {
            if(vm.type=="r")  {
                $state.go("public.online.reservation");
            } else if (vm.type=="w") {
                $state.go("public.online.waitlist");
            }
        }

        if(vm.type=="r") {
            vm.userEmail = OnlineReservationContext.getCustomer().primaryEmail;
            vm.verificationSent = OnlineReservationContext.getVerificationSent();
            vm.enablePayment = vm.settings.enablePayment;
        }

        vm.newReservation = newReservation;
        vm.newWaitList = newWaitList;

        Log.debug("OnlineSuccessController", "Type value is " + vm.type);

        PublicResource.getCompletedSettings(
            {
                hashedLocationId: vm.hashedLocationId
            },
            {},
            function (data) {
                vm.generalMessage = data.generalMessage;
                vm.confirmationSentMessage = data.confirmationSentMessage;
                vm.verificationSentMessage = data.verificationSentMessage;

            },
            onError
        );

        function onError(error) {
            vm.generalMessage = "Thank you for your reservation.";
            vm.confirmationSentMessage = "Your reservation request is being processed and soon you will receive an email with reservation acknowledgment from us on your email.";
            vm.verificationSentMessage = "A verification email is sent to your email. Please open the email and verify your reservation request.";
        }

        function newReservation() {

            var params = {
                'locationId': vm.hashedLocationId
            }

            $state.go("public.online.reservation", params);

        }

        function newWaitList() {

            var params = {
                'locationId': vm.hashedLocationId
            }

            $state.go("public.online.waitlist", params);

        }


        function getType(reference) {
            if("CONTACT" == reference || "PAYMENT"==reference) {
                return "r";
            } else if("WAITLIST" == reference) {
                return "w";
            } else {
                return "";
            }
        }

    }
})();



