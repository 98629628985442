(function() {
    'use strict';

    angular
        .module('seatonApp')
        .provider('Notification', Notification);

    function Notification () {

        this.$get = getService;

        getService.$inject = ['toastr','$translate'];

        function getService (toastr, $translate) {

            return {
                success: success,
                error: error,
                info: info,
                warning : warning,
                infoLang: infoLang,
                showAlert: showAlert
            };

            function infoLang(messageKey, titleKey) {
                titleKey = titleKey || "global.messages.info.title";
                $translate([messageKey, titleKey]).then(function (translations) {
                    var messageText = translations[messageKey];
                    var titleText = translations[titleKey];
                    info(messageText, titleText);
                });

            }

            function info(body, title, config) {
                title = title || "Information";
                toastr.info(body, title, config);
            }

            function success(body, title, config) {
                title = title || "Action completed";
                toastr.success(body, title, config);
            }

            function warning(body, title, config) {
                title = title || "Warning";
                toastr.warning(body, title, config);
            }

            function error(body, title, config) {
                title = title || "Error occured.";
                toastr.error(body, title, config);
            }

            function showAlert(alertType, alertMessage) {
                switch (alertType) {
                    case 'success':
                        success(alertMessage);
                        break;
                    case 'error':
                        error(alertMessage);
                        break;
                    case 'warn':
                        warning(alertMessage);
                        break;
                    default:
                        info(alertMessage);
                        break;
                }
            }

        }

    }
})();
