(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customers.feedback', {
                parent: 'customers',
                url: '/feedback',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/customer/feedback/feedback.html',
                        controller: 'FeedbackSearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    employees: null,
                    employeeLogin: null,
                    employeeName: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
