'use strict';

angular.module('seatonApp')
    .factory('CloverResource',CloverResource );

CloverResource.$inject = ['$resource'];
function CloverResource($resource) {

        return $resource('api/clover/', {
        }, {
            'cloverTest': {method: 'GET', url: 'api/admin/test'},
            'healthCheck': {method: 'GET', url: 'api/v1/public/health-check'},
            'cloverAuthorization': {method: 'GET', url: 'api/auth/clover/web'},
            'cloverSync': {method: 'GET', url: '/api/auth/clover/web/sync'},
            'setResourceType': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/resource_type/:resourceType'}
        });

}

