(function() {
    'use strict';

    angular
        .module('seatonApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$rootScope', '$q', '$injector', '$location', 'Notification'];

    function errorHandlerInterceptor ($rootScope, $q, $injector, $location, Notification) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {

            var Log = $injector.get('Log');
            switch(response.status) {
                case 0:
                    Log.error("errorhandler.interceptor","Server not reachable (err-code: 0). " + response.data);
                    showError("Server not reachable.", response.status, false);
                    break;
                case 400:
                    Log.error("errorhandler.interceptor","Bad request from server (err-code 400). " + response.data);
                    var headers = Object.keys(response.headers()).filter(function (header) {
                        return header.endsWith('app-alert') || header.endsWith('app-alert-type')
                    }).sort();
                    Log.debug("errorhandler.interceptor","Object.keys=" + Object.keys(response.headers()));

                    var alertMessage = response.headers(headers[0]);
                    if (angular.isString(alertMessage)) {
                        var alertType = response.headers(headers[1]);
                        if (angular.isString(alertType)) {
                            Notification.showAlert(alertType, alertMessage);
                        } else {
                            showError(response.data.description, response.status, true)
                        }
                    } else {
                        showError(response.data.description, response.status, true)
                    }
                    break;
                case 401:
                    // handled by auth-expired.interceptor.js
                    break;
                case 404:
                    Log.error("errorhandler.interceptor", "Page not found (err-code 404). " + response.data);
                    showError("Page not found. Msg:"+response.data.description, response.status, true);
                    break;
                case 408:
                    Log.error("errorhandler.interceptor", "Action timeout (err-code 408). Please try again later. " + response.data);
                    Notification.error("Timeout occur, please try later again.");
                    break;
                case 409:
                    // should be handled by individual controllers
                    Log.error("errorhandler.interceptor", "Conflict exception (err-code 409). ", response.data);
                    break;
                case 500:
                    Log.error("errorhandler.interceptor","Internal server error (err-code 500)." + response.data.description);
                    showError("Internal server error.", response.status, true);
                    break;
                default:
                    Log.error("errorhandler.interceptor", "Application error (err-code "+response.status+"). " + response.data);
                    showError("Application error. Msg:"+response.data, response.status, true);
                    break;
            }

            return $q.reject(response);

        }

        function showError(msg, code, contExec) {

            var page = $location.path().trim();
            if(page=="/account/clover" || page=="/account/login" || page=="/account/locations" || page=="/") {
                // cannot recover from exception if I'm on clover access screen or admin access screen or home page
                contExec=false;
                var Auth = $injector.get('Auth');
                Auth.logout();
            }

            var $state = $injector.get('$state');
            if(page.startsWith("/public")) {
                if(!page.startsWith("/public/online")) {
                    //show error page for public access
                    var params = {'errorMessage': msg};
                    $state.go("public.error", params);
                } else {
                    // leave to each public online page to handle exceptions
                }
            } else {
                // otherwise show general error page
                var params = { 'errorCode': code, 'errorMessage': msg, 'contExec': contExec};
                $state.go("account.error",params);
            }

        }

    }

})();
