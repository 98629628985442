'use strict';

ReservationsDashboardController.$inject = ['$state', '$scope', '$q', '$document', '$filter', 'googleChartApiPromise',
    'EventResource', 'Reporting', 'ApplicationContext', 'ApplicationProfile'];

function ReservationsDashboardController($state, $scope, $q, $document, $filter, googleChartApiPromise, EventResource,
                                         Reporting, ApplicationContext, ApplicationProfile) {
    var vm = this;
    //vm.showMoreFeedback = showMoreFeedback;
    vm.tabChange = tabChange;
    vm.changedSelectedDates = changedSelectedDates;
    vm.changedCompareDaysBeforeButton = changedCompareDaysBeforeButton;
    vm.calcPct = Reporting.calcPct;
    vm.pickerDate;

    vm.compareDaysBeforeButton = -1;
    vm.lastWeekDay = moment().subtract(1, 'weeks');
    vm.lastWeekDayData = null;
    vm.comparedDay = vm.lastWeekDay.clone();

    vm.todayVsEnabled = ApplicationProfile.isTablesTodayVsEnabled();
    vm.todayVsText = ApplicationProfile.upgradeTierText("TablesTodayVs");
    vm.openWindow = ApplicationProfile.openAppCloverPage;

    vm.isMeal = (ApplicationContext.getProductType() == 'MEAL');


    $document.ready(
        function () {
            vm.comparedate = $document.find('input[name="comparedate"]');
            vm.comparedate.daterangepicker(
                {
                    //configuration parameters
                    singleDatePicker: true,
                    showDropdowns: true,
                    locale: {format: 'MMM D, YYYY'}
                },
                function (start, end, label) {
                    //callback function - called on picker value change - dates sent in callback parameters
                    vm.comparedDay = start;
                    vm.compareDaysBeforeButton = -1;
                    loadComparedDayData().then(loadTodayVsChart).then(windowResize);
                }
            );

            //initial datepicker values
            vm.comparedate.data('daterangepicker').setStartDate(vm.comparedDay);
            vm.comparedate.data('daterangepicker').setEndDate(vm.comparedDay);
        }
    );

	$scope.openingHourNotDefined = function() {
            return ApplicationContext.isBusinessHoursNotDefined();
    }

    vm.openPicker = openPicker;
    function openPicker() {
        vm.comparedate.focus();
    }

    load();

    function load() {
        $q.all([
            loadTodayData(),
            loadComparedDayData(),
            googleChartApiPromise
        ]).then(loadInitialCharts).then(copyLastWeekDayData);
    }

    function copyLastWeekDayData() {
        var deferred = $q.defer();
        vm.lastWeekDayData = angular.copy(vm.comparedDayData);
        deferred.resolve(true);
        return deferred.promise;
    }

    function tabChange(tab) {
        switch (tab) {
            case 'todayTab':
                $(window).resize();
                break;
            case 'visitTab':
                loadVisitsCharts().then(windowResize);
                break;
            case 'todayVsTab':
                if(vm.todayVsEnabled) { loadTodayVsChart().then(windowResize); }
                break;
        }
    }

    //not used!
    function changedSelectedDates() {
        if ((typeof vm.pickerDate) != 'string') {
            vm.comparedDay = vm.pickerDate.clone();
            vm.compareDaysBeforeButton = -1;
            loadComparedDayData().then(loadTodayVsChart).then(windowResize);
        }
    }

    function changedCompareDaysBeforeButton() {
        vm.comparedDay = moment().subtract(vm.compareDaysBeforeButton, 'days');
        vm.comparedate.data('daterangepicker').setStartDate(vm.comparedDay);
        vm.comparedate.data('daterangepicker').setEndDate(vm.comparedDay);
        loadComparedDayData().then(loadTodayVsChart).then(windowResize);
    }

    function windowResize() {
        var deferred = $q.defer();
        $(window).resize();
        deferred.resolve(true);
        return deferred.promise;
    }

    /*
     function showMoreFeedback() {
     ViewState.goToState('customer.feedback', {
     dates: {
     startDate: moment().subtract(1, 'year'),
     endDate: moment()
     }
     });
     }
     */

    function loadTodayData() {
        return EventResource.getEventsDashboardTodayData({}, function (data) {
            vm.todayData = data;
        }).$promise;
    }

    function loadComparedDayData() {
        //console.log(vm.comparedDay);
        return EventResource.getEventsDashboardTodayVsData({
            selectedDate: vm.comparedDay.format('YYYY-MM-DD')
        }, function (data) {
            vm.comparedDayData = data;
        }).$promise;
    }

    function loadInitialCharts() {
        var deferred = $q.defer();
        // revenue by hours
        var dataHours = new google.visualization.DataTable();
        dataHours.addColumn('string', 'Time of Day');
        dataHours.addColumn('number', 'Revenue', 'color: #1ABC9C');
        dataHours.addColumn({'type': 'string', 'role': 'tooltip'});
        dataHours.addColumn({type: 'string', role: 'annotation'});

        _.each(vm.todayData.revenueByHour, function (item) {
            dataHours.addRow([item.hourOfDayFmt, item.revenue,
                'Revenue ' + $filter('currency')(item.revenue)
                + '\n Tips ' + $filter('currency')(item.tips)
                + '\n Visits ' + $filter('number')(item.totalVisits)
                + '\n Covers ' + $filter('number')(item.totalCovers)
                + '', $filter('currency')(item.revenue)]);
        });

        var optionsHours = {
            title: 'Today\'s performance on hourly basis',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            //width: 950,
            height: 400,
            vAxis: {
                title: 'Revenue',
                gridlines: {count: 12}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: 'Time of Day',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            chartArea: {width: '85%', height: '65%'},
            formatters: {},
            colors: ['#1ABC9C'],
            legend: 'none'
        };

        vm.chartObjectHours = {
            type: "ColumnChart",
            options: optionsHours,
            data: dataHours
        };

        deferred.resolve(true);
        return deferred.promise;
    }

    function loadVisitsCharts() {
        var deferred = $q.defer();
        //vistis - general pie chart
        vm.chartObjectVisitGeneral = {};
        vm.chartObjectVisitGeneral.type = "PieChart";

        vm.chartObjectVisitGeneral.options = {
            title: 'Visit statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            width: 380,
            height: 320,
            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#3399CC'},
                2: {color: '#996699'}
            }

        };

        vm.chartObjectVisitGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Reservation"},
                        {v: vm.todayData.todayVisitStats.totalReservationShow},
                    ]
                },
                {
                    c: [
                        {v: "Walk-In Seated"},
                        {v: vm.todayData.todayVisitStats.totalWalkinSeated}
                    ]
                },
                {
                    c: [
                        {v: "Walk-In Waitlist"},
                        {v: vm.todayData.todayVisitStats.totalWaitlistShow},
                    ]
                }
            ]
        };

        //reservation - general pie chart
        vm.chartObjectReservationGeneral = {};
        vm.chartObjectReservationGeneral.type = "PieChart";

        vm.chartObjectReservationGeneral.options = {
            title: 'Reservation statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            width: 450,
            height: 300,
            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#FF3333'},
                2: {color: '#FFCC33'}
            }
        };

        vm.chartObjectReservationGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Show"},
                        {v: vm.todayData.todayVisitStats.totalReservationShow},
                    ]
                },
                {
                    c: [
                        {v: "No Show"},
                        {v: vm.todayData.todayVisitStats.totalReservationNoShow}
                    ]
                },
                {
                    c: [
                        {v: "Canceled"},
                        {v: vm.todayData.todayVisitStats.totalReservationCanceled},
                    ]
                }
            ]
        };

        //wait list - general pie chart
        vm.chartObjectWaitlistGeneral = {};
        vm.chartObjectWaitlistGeneral.type = "PieChart";

        vm.chartObjectWaitlistGeneral.options = {
            title: 'Wait list statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            width: 450,
            height: 300,
            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#FF3333'},
                2: {color: '#FFCC33'}
            }
        };

        vm.chartObjectWaitlistGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Show"},
                        {v: vm.todayData.todayVisitStats.totalWaitlistShow},
                    ]
                },
                {
                    c: [
                        {v: "No Show"},
                        {v: vm.todayData.todayVisitStats.totalWaitlistNoShow}
                    ]
                },
                {
                    c: [
                        {v: "Canceled"},
                        {v: vm.todayData.todayVisitStats.totalWaitlistCanceled},
                    ]
                }
            ]
        };

        deferred.resolve(true);
        return deferred.promise;
    }

    function loadTodayVsChart() {
        var deferred = $q.defer();
        // performance today vs ...
        var dataCompare = new google.visualization.DataTable();
        dataCompare.addColumn('string', 'Time of Day');
        dataCompare.addColumn('number', 'Today');
        dataCompare.addColumn('number', vm.comparedDay.format('dddd (Do MMM YY)'));

        var todayItem = null;
        var comparedDayItem = null;
        for (var i = 0; i < vm.todayData.revenueByHour.length; i++) {
            todayItem = vm.todayData.revenueByHour[i];
            comparedDayItem = vm.comparedDayData.revenueByHour[i];

            dataCompare.addRow(
                [
                    todayItem.hourOfDayFmt,
                    {
                        v: todayItem.revenue,
                        f: $filter('currency')(todayItem.revenue)
                    },
                    {
                        v: comparedDayItem.revenue,
                        f: $filter('currency')(comparedDayItem.revenue)
                    }
                ]
            );
        }

        var optionsCompare = {
            title: 'Today vs ' + vm.comparedDay.format('dddd (Do MMM YY)'),
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            //width: 950,
            height: 450,
            bar: {groupWidth: "95%"},
            legend: {position: 'top', alignment: 'end'},
            vAxis: {
                title: 'Revenue',
                gridlines: {count: 12}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: 'Time of Day',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            chartArea: {width: '75%', height: '65%'},
            formatters: {},
            colors: ['#1ABC9C', '#FF3333']
            //legend: {position: 'top', alignment: 'end'}
        };

        vm.chartObjectCompare = {
            type: "ColumnChart",
            options: optionsCompare,
            data: dataCompare
        };

        deferred.resolve(true);
        return deferred.promise;
    }
}

angular.module('seatonApp').controller('ReservationsDashboardController', ReservationsDashboardController);
