'use strict';

angular.module('seatonApp')
    .factory('EmployeeResource', employeeResource)
    .service('EmployeeService', employeeService);

employeeResource.$inject = ['$resource', 'ApplicationContext'];

function employeeResource($resource, ApplicationContext) {
    return $resource('api/v1/merchants/_MERCHANTID_/employees/:id', {
        merchantId: ApplicationContext.getLocationID()
    }, {
        'getEmployeeWithStats': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/employees/:id/statistics/employeeWithStats'
        },
        'weeklyPerformance': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/employees/:id/statistics/weeklyPerformance'
        },
        'monthlyPerformance': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/employees/:id/statistics/monthlyPerformance'
        },
        'sinceLastLogin': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/employees/:id/statistics/sinceLastLogin'
        },
        'getAllEmployees': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/employees/getAllEmployees',
            isArray: true
        },
        'updateAttribute': {
            method: 'POST',
            url: 'api/v1/merchants/_MERCHANTID_/employees/:id/updateAttribute?attribute=:attribute&value=:value',
            params: {attribute: '@attribute', id: '@id', value: '@value'}
        }

    });
}

function employeeService() {

    function _toNumberOrZero(number) {
        if (_.isNumber(number)) {
            return number;
        } else {
            return 0;
        }
    }

    function _avgRevenuePerCover(employee) {
        var avg = 0.00;
        var totalCustomerRevenue = _toNumberOrZero(employee.totalCustomerRevenue);
        var totalUnknownCustomerCover = _toNumberOrZero(employee.totalUnknownCustomerCover);
        var totalReservationCover = _toNumberOrZero(employee.totalReservationCover);
        var totalWaitlistCover = _toNumberOrZero(employee.totalWaitlistCover);
        var totalWalkInSeatedCover = _toNumberOrZero(employee.totalWalkinSeatedCover);
        var totalCovers = (totalReservationCover + totalWaitlistCover + totalWalkInSeatedCover);
        if (totalCovers > 0 && totalCustomerRevenue >= 0) {
            avg = employee.totalCustomerRevenue / totalCovers;
        }
        return avg;
    }

    function _avgTipPerCover(employee) {
        var avg = 0.0;
        var totalTips = _toNumberOrZero(employee.totalTips);
        var totalVisitsWithTip = _toNumberOrZero(employee.totalVisitsWithTip);
        var totalReservationCover = _toNumberOrZero(employee.totalReservationCover);
        var totalWaitlistCover = _toNumberOrZero(employee.totalWaitlistCover);
        var totalWalkInSeatedCover = _toNumberOrZero(employee.totalWalkinSeatedCover);
        var totalCovers = (totalReservationCover + totalWaitlistCover + totalWalkInSeatedCover);
        if (totalCovers > 0) {
            avg = totalTips / totalCovers;
        }
        return avg;
    }

    function _rating(employee) {
        if (_.isUndefined(employee.totalRatedVisits) || employee.totalRatedVisits <= 0) {
            return 0;
        } else {
            return employee.totalRatedPoints / employee.totalRatedVisits;
        }
    }

    function _avgTipPercentage(employee) {
        var avg = 0;
        var avgTipPerCover = _avgTipPerCover(employee);
        var avgRevenuePerCover = _avgRevenuePerCover(employee);
        if (avgRevenuePerCover > 0) {
            avg = 100 * (avgTipPerCover / avgRevenuePerCover);
        }
        return avg;
    }

    function _totalNoShows(employee) {
        var total = 0;
        total += _toNumberOrZero(employee.totalReservationNoShow);
        total += _toNumberOrZero(employee.totalWaitlistNoShow);
        return total;
    }

    function _noShowRate(employee) {
        var rate = 0;
        var totalNoShows = _totalNoShows(employee);

        var totalReservationAndWaitlist = 0;

        //CALCULATING noShowPct same as in reports!!
        //totalReservationAndWaitlist += employee.totalReservationShow + employee.totalReservationNoShow + employee.totalReservationCanceled;
        //totalReservationAndWaitlist += employee.totalWaitlistShow + employee.totalWaitlistNoShow + employee.totalWaitlistCanceled;
        totalReservationAndWaitlist += employee.totalReservationShow + employee.totalReservationNoShow ;
        totalReservationAndWaitlist += employee.totalWaitlistShow + employee.totalWaitlistNoShow ;

        if (totalReservationAndWaitlist > 0) {
            rate =  (totalNoShows / totalReservationAndWaitlist);
        }
        return rate;
    }

    function getLocationById(locations, id) {

        if (typeof locations.id !== 'undefined') {
            return locations;
        }

        return _.find(locations, function (location) {
            return location.id === id;
        });
    }

    function enrichEmployee(employee, locations) {
        if (locations) {
            employee.location = getLocationById(locations, employee.locationId);
        }
        //employee.role = _.chain(employee.authorities).pluck('name').find(function (name) {
        //    return !_.isUndefined(name);
        //}).value();
        //employee.name = employee.firstName + ' ' + employee.lastName;
        employee.rating = _rating(employee);
        employee.avgRevenuePerCover = _avgRevenuePerCover(employee);
        employee.avgTipPerCover = _avgTipPerCover(employee);

        employee.avgTipPercentage = _avgTipPercentage(employee);
        employee.noShowRate = _noShowRate(employee);
        employee.totalNoShows = _totalNoShows(employee);
        return employee;
    }

    return {
        enrichEmployee: enrichEmployee
    };
}

