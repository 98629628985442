/**
 * Created by branimir on 26.3.2017..
 */
(function () {

    'use strict';
    angular.module('seatonApp')
        .directive('feedbacksWidget', function () {
            return {
                restrict: 'EA',
                scope: {
                    listScope: '@',
                    size: '@',
                    scopeId: '=scopeId',
                    title: '@'
                },
                templateUrl: 'app/customer/feedback/feedbacks.directive.html',
                controller: FeedbacksDirectiveController,
                controllerAs: 'vm',
                bindToController: true
            };
        });

    FeedbacksDirectiveController.$inject = ['FeedbackResource'];
    function FeedbacksDirectiveController(FeedbackResource) {
        var vm = this
        vm.feedbacks = [];
        vm.feedbackLimitInitial = 5;
        vm.feedbackLimit = vm.feedbackLimitInitial;
        console.log(vm);
        console.log(vm.scopeId);

        loadFeedbacks();

        function loadFeedbacks() {
            return FeedbackResource.getLastFeedbacks(
                {
                    listScope: vm.listScope,
                    scopeId: vm.scopeId,
                    size: vm.size
                }, function (data) {
                    vm.feedbacks = data;
                    var sumRating = 0;
                    _.each(vm.feedbacks, function (feedback) {
                        sumRating += feedback.rating;
                    })
                    vm.feedbackAverage = sumRating / vm.feedbacks.length;
                    console.log(vm.feedbacks);
                    console.log(vm.feedbackAverage);
                }).$promise;
        }


    }
})();
