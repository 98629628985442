(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('OnlineWaitlistController', OnlineWaitlistController);

    OnlineWaitlistController.$inject = ['$scope', '$state', '$document', 'SettingsResource', 'Log', '$uibModal', 'ApplicationContext', '$timeout'];

    function OnlineWaitlistController($scope, $state, $document, SettingsResource, Log, $uibModal, ApplicationContext, $timeout) {

        var vm = this;
        vm.position_options = [
            {id: 1, name: 'left page edge'},
            {id: 2, name: 'right page edge'},
            {id: 3, name: 'bottom page edge'},
            {id: 4, name: 'top page edge'}
        ];

        vm.padding_options = [
            {id: 0, name: 'start of window'},
            {id: 25, name: '1/4 of window'},
            {id: 33, name: '1/3 of window'},
            {id: 50, name: '1/2 of window'},
            {id: 66, name: '2/3 of window'},
            {id: 75, name: '3/4 of window'},
            {id: 100, name: 'end of window'}
        ];

        vm.font_size = [
            {id: 8, name: '8px'},
            {id: 9, name: '9px'},
            {id: 10, name: '10px'},
            {id: 11, name: '11px'},
            {id: 12, name: '12px'},
            {id: 14, name: '14px'},
            {id: 16, name: '16px'},
            {id: 18, name: '18px'},
            {id: 20, name: '20px'},
            {id: 22, name: '22px'},
            {id: 24, name: '24px'}
        ];

        vm.threshold_options = [
            {id: 10, name: '10%'},
            {id: 20, name: '20%'},
            {id: 30, name: '30%'},
            {id: 40, name: '40%'},
            {id: 50, name: '50%'},
            {id: 60, name: '60%'},
            {id: 70, name: '70%'},
            {id: 80, name: '80%'},
            {id: 90, name: '90%'}
        ];


        $document.ready(
            function () {

                vm.div_slider_transparency = $document.find('#slider_transparency');
                vm.div_slider_transparency.ionRangeSlider({
                    min: 0,
                    max: 90,
                    step: 1.00,
                    from: 0,
                    postfix: "%",
                    prettify: true,
                    grid: true,
                    decorate_both: true,
                    onChange: function (data) {
                        //vm.transparency = vm.slider_transparency.result.from;
                        vm.custo.transparency = data.from;
                        vm.updatePreview();
                    }
                });
                vm.slider_transparency = vm.div_slider_transparency.data("ionRangeSlider");
                load();
            }
        );

        function load() {
            SettingsResource.getOnlineWaitlistCustomization({}, onSuccessCustomisationDto, onErrorGetData);
            SettingsResource.getAvailabilityIntegrationScript({}, onIntegrationScriptFetched, onErrorGetData);
        }

        function onSuccessCustomisationDto(data) {
            vm.custo = data;
            vm.custo.transparency = vm.custo.transparency != null ? vm.custo.transparency : 0;
            vm.slider_transparency.update({from: vm.custo.transparency});
            $timeout(function(){
                // ugly workaround since ng-change does not work with the chosen color picker
                $scope.$watch('[vm.custo.backgroundColor, vm.custo.textColor, vm.custo.backgroundColorHover, vm.custo.textColorHover, vm.custo.buttonLineColor]',
                    function () {
                        vm.updatePreview();
                    }, true);
            }, 500);
        }

        function onIntegrationScriptFetched(data) {
            vm.integrationScript = data.content
            // insertin teh script tag to the preview iframe
            var doc = document.getElementById('previewFrame').contentWindow.document;
            doc.open();
            doc.write(vm.integrationScript);
            doc.close();
        }

        function onErrorGetData(error) {
            console.log("HTTP request error." + error);
        }

        vm.save = function () {
            console.log('vm.custo', vm.custo);
            SettingsResource.saveOnlineWaitlistCustomization(
                vm.custo,
                function (data) {
                    console.log("Updates success");
                    console.log(data);
                    //SweetAlert.swal("Updated", "", "success");
                }
            )
        }

        vm.updatePreview = function() {
            document.getElementById("previewFrame").contentWindow["SeatOn" + ApplicationContext.getLocationID()].updatePreview('availability', vm.custo);
        }

        //var iframe = $document.find('preview');
        //console.log(iframe);
        //var innerDoc = (iframe.contentDocument) ? iframe.contentDocument : iframe.contentWindow.document;

        //var ulObj = innerDoc.getElementById("buton");


        //$scope.closeAvailabilityStatusDialog = function() {
        //    vm.availbilityDialog.dismiss('cancel');
        //}

        $scope.showAvailabilityStatusDialog = function (type) {
            vm.type = type;
            vm.availbilityDialog = $uibModal.open({
                templateUrl: 'app/settings/online/availability/preview.status.message.html',
                scope: this
            });
        }

        //$scope.showAvailabilityStatusDialog = function on() {
        //    document.getElementById("overlay").style.display = "block";
        //}

        //function off() {
        //    document.getElementById("overlay").style.display = "none";
        //}

        vm.copyToClipboard = function() {
            var copyText = document.getElementById("onlineWaitlistCode");
            copyText.disabled = false;
            copyText.select();
            document.execCommand("Copy");
            copyText.disabled = true;
        }

    }
})();



