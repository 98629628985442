(function() {
    'use strict';
    angular
        .module('seatonApp')
        .factory('Locations', Locations);

    Locations.$inject = ['$resource'];

    function Locations($resource) {
        var resourceUrl = 'api/auth/admin/locations';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'employees': { method:'GET', url: 'api/auth/admin/employees/:id', isArray: true }
        });
    }
})();
