(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('TableManagementController', TableManagementController);

    TableManagementController.$inject = ['$scope', '$state', '$stateParams', 'Tables', 'Time', '$uibModal', 'Utils',
        'SweetAlert', 'SettingsResource', 'ApplicationContext', '$document', '$timeout', 'ChannelService', 'TextUtil'];

    function TableManagementController($scope, $state, $stateParams, Tables, Time, $uibModal, Utils,
                                       SweetAlert, SettingsResource, ApplicationContext, $document, $timeout,
                                       ChannelService, TextUtil) {
        var vm = this;

        vm.refreshText = 'Refresh list';
        vm.activeTab = -1;
        vm.initialLoad = true;
        // if Show is clicked 15 minutes before expected start time, we will display a Warning
        var EARLY_SHOW_WARNING_MINUTES = 15;
        var tabUrls = ['arrivals', 'walkin', 'waitlist', 'active', 'online-res'];
        vm.tabRefreshTimes = [];
        var TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
        vm.selectedProduct = {};
        vm.eventDuration = 0;

        vm.isTableManaged = ApplicationContext.isTableManaged();

        vm.timeNow = moment();
        var updateTimeNow = function () {
            vm.timeNow = moment();
            //console.log('vm.timeNow:' + vm.timeNow)
            //console.log($state.current.name);
            if ('tables.management' == $state.current.name) {
                $timeout(updateTimeNow, 10000);
            }
        }
        $timeout(updateTimeNow, 10000);
        vm.diffInSeconds = function (time, timeNow) {
            var diff = -Math.round(moment(time).diff(timeNow) / 1000);
            return diff;
        }

        function updateTabTime(tabInd) {
            vm.timeNow = moment();
            vm.tabRefreshTimes[tabInd] = moment();
        }

        vm.selectTab = function (tabIndex) {
            tabIndex = (tabIndex != -1) ? tabIndex : 0; // in case of wrong URL, we will load /arrivals by default
            vm.activeTab = tabIndex;
            $state.go('tables.management', {tab: tabUrls[tabIndex]}, {notify: false}); // change the URL
            if (vm.initialLoad) {
                loadDataForAllTabs();
                vm.initialLoad = false;
            } else {
                loadDataForSelectedTab();
            }
        }

        vm.utils = Utils;
        vm.arrivals = [];
        vm.tables = [];
        vm.activeEvents = [];

        // init Walkins filters
        vm.walkinsFilterSeats = 0;
        vm.walkinsFilterAvailableTime = 0;

        $scope.customerSearchKeyword = '';

        // walkin form
        vm.walkinForm = {
            noOfPersons: 0,
            quotedWaitTime: 0,
            note: null,
            customer: {}
        }

        // fetch general settings from server
        SettingsResource.getGeneralSettings({}, function (data) {
            vm.settings = data;
        });

        /** Called by the customer search directive when user selects a customer from a list */
        $scope.customerSelected = function (customer) {

            vm.walkinForm.customer = customer;
            vm.walkinForm.customer.primaryPhone = intlTelInputUtils.formatNumber(customer.primaryPhone, ApplicationContext.getPhoneRegion(), intlTelInputUtils.numberFormat.INTERNATIONAL);
            vm.walkinForm.customerOriginal = Utils.getBasicCustomerData(customer);

            vm.showNewWalkinCustomerForm(true);
        }

        vm.loadExpArrivals = function () {
            vm.refreshStart();
            Tables.getExpArrivalsToday({}, function (data) {
                vm.arrivals = data;
                updateTabTime(0);
                updateExpArrivalsData(vm.arrivals);

                vm.refreshStop();
            });
        }

        vm.loadWalkouts = function () {
            vm.refreshStart();
            Tables.getActiveEvents({}, function (data) {
                vm.activeEvents = data;
                updateTabTime(3);
                updateWalkoutData(vm.activeEvents);
                vm.refreshStop();
            });
        }

        vm.loadWalkins = function () {
            vm.refreshStart();
            Tables.getResourceAvailability({}, function (data) {
                vm.tables = data;
                updateTabTime(1);
                updateWalkinData(vm.tables);
                vm.refreshStop();
            });
        }

        // just used to animate the 'Refresh list' button
        vm.refreshStart = function() {
            vm.isRefreshing = true; // causes the icon to rotate
            vm.refreshText = 'In progress';
            vm.refreshStartTimeMillis = (new Date()).getMilliseconds();
        }
        // the minimum time spinner is being shown: 500 millis
        vm.refreshStop = function() {
        var serverFetchTimeMillis = (new Date()).getMilliseconds() - vm.refreshStartTimeMillis;
            var delay = Math.max(500 - serverFetchTimeMillis, 0);
            $timeout(function() {
                vm.isRefreshing = false;
                vm.refreshText = 'Refresh list';
            }, delay);
        }

        vm.cancelEvent = function (eventId, customerName, customerEmail) {
            SweetAlert.swal({
                    title: "Please confirm action",
                    text: "Are you sure you want to cancel this reservation?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {

                        vm.cancelCustomerName = customerName;
                        vm.cancelCustomerEmail = customerEmail;
                        vm.cancelEventId = eventId;

                        Tables.personalizedCancel(
                            {},
                            function (data) {
                                vm.cancel = data;
                                if(vm.cancel.personalisationAllowed) {
                                    vm.cancelMessageText = vm.cancel.cancelMessage;

                                    vm.cancelDialog = $uibModal.open({
                                        templateUrl: 'app/tables/management/cancel.dialog.html',
                                        scope: $scope
                                    });

                                } else {
                                    vm.triggerStateChange(eventId, 'CANCELLATION');
                                }
                            }
                        );
                    }
                });
        }

        vm.closeCancelDialog = function () {
            vm.cancelDialog.dismiss('cancel');
        }

        vm.cancelWithPersonalizedMessage = function () {

            vm.triggerStateChange(
                vm.cancelEventId,
                'CANCELLATION',
                null,
                {message: vm.cancelMessageText});

            vm.closeCancelDialog();
        }



        vm.triggerShow = function (id, expectedStartTime) {
            var confirmMsg = null;
            if (moment(expectedStartTime).diff(moment(), 'minutes') > EARLY_SHOW_WARNING_MINUTES) {
                var timeFmt = moment(expectedStartTime).format('hh:mmA');
                confirmMsg = 'The expected start time for this reservation is at ' + timeFmt + '. Are you sure you want to seat the customer this early?';
            }
            vm.triggerStateChange(id, 'ARRIVAL', confirmMsg)
        }

        vm.triggerStateChange = function (id, trigger, confirmMsg, params) {

            if (confirmMsg) {
                SweetAlert.swal({
                        title: "Please confirm action",
                        text: confirmMsg,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            triggerStateChangeOnServer(id, trigger, params);
                        }
                    });
            } else {
                triggerStateChangeOnServer(id, trigger, params);
            }
        }

        function triggerStateChangeOnServer(id, trigger, params) {
            var postParams = params ? params : {};
            postParams.id = id;
            postParams.trigger = trigger;

            Tables.triggerStateChange(
                postParams,
                function (data) {
                    loadDataForSelectedTab();
                },
                function (error) {
                   // this will usually happen if someone else updated the event state (server will return HTTP code 409 Conflict)
                   // we'll just refresh the screen
                   console.log("Tables.triggerStateChange() returned na error: ", error)

                   loadDataForSelectedTab();
                });
        }

        vm.releaseTable = function (eventId) {
            vm.walkoutPaymentInfo = null;
            vm.walkoutSelectedEventId = eventId;
            vm.walkoutDialog = $uibModal.open({
                templateUrl: 'app/tables/management/walkout.option.select.html',
                scope: $scope
            });
        }

        vm.walkoutNoPaymentInfo = function () {
            vm.triggerStateChange(vm.walkoutSelectedEventId, 'CLOSE_WITHOUT_PAYMENT');
            vm.closeWalkoutDialog();
        }

        vm.walkoutWithPaymentInfo = function () {

            if (vm.walkoutPaymentInfo && vm.walkoutPaymentInfo.amount >= 0 && (!vm.walkoutPaymentInfo.tip || vm.walkoutPaymentInfo.tip >= 0)) {

                if (!vm.walkoutPaymentInfo.tip) {
                    vm.walkoutPaymentInfo.tip = 0;
                }

                vm.triggerStateChange(
                    vm.walkoutSelectedEventId,
                    'PAYMENT',
                    null,
                    {
                        amount: parseInt(vm.walkoutPaymentInfo.amount * 100),
                        tip: parseInt(vm.walkoutPaymentInfo.tip * 100)
                    });
                vm.closeWalkoutDialog();
            }
        }

        vm.refreshWaitlistQueue = function () {
        vm.refreshStart();
            Tables.getWaitlistQueues(
                {},
                function (data) {
                    vm.waitlistQueues = data;
                    updateTabTime(2);

                    vm.refreshStop();
                }
            );
        }

        function updateExpArrivalsData(arrivals) {
            vm.arrivalsTotal = 0;
            vm.arrivalsTotalCover = 0;
            vm.arrivalsTotalOverdue = 0;
            vm.arrivalsTotalOverdueCover = 0;
            for (var i = 0; i < arrivals.length; i++) {
                var arrival = arrivals[i];
                vm.arrivalsTotal++;
                vm.arrivalsTotalCover += arrival.numberOfPersons;

                if (Time.fromNow(arrival.expectedStartTime) < 0) {
                    arrival.overdue = true;
                    vm.arrivalsTotalOverdue++;
                    vm.arrivalsTotalOverdueCover += arrival.numberOfPersons;
                }
            }
        }

        function updateWalkoutData(activeEvents) {
            vm.walkoutTotal = 0;
            vm.walkoutTotalCovers = 0;
            vm.walkoutTotalOverdue = 0;
            vm.walkoutTotalOverdueCovers = 0;

            for (var i = 0; i < activeEvents.length; i++) {
                var event = activeEvents[i];
                vm.walkoutTotal += event.resources.length;
                vm.walkoutTotalCovers += event.numberOfPersons;
                if (Time.fromNow(event.expectedEndTime) < 0) {
                    event.overdue = true;
                    vm.walkoutTotalOverdue += event.resources.length;
                    vm.walkoutTotalOverdueCovers += event.numberOfPersons;
                }
            }
        }

        function updateWalkinData(tables) {
            vm.walkinsAvailableTables = 0;
            vm.walkinsAvailableSeats = 0;
            vm.walkinsOccupiedTables = 0;
            vm.walkinsOccupiesSeats = 0;

            for (var i = 0; i < tables.length; i++) {
                var table = tables[i];
                if (table.availableIn === 0) {
                    vm.walkinsAvailableTables++;
                    vm.walkinsAvailableSeats += table.resource.capacity;
                    table.status = "Available now"
                } else {
                    vm.walkinsOccupiedTables++;
                    vm.walkinsOccupiesSeats += table.resource.capacity;
                    if (table.availableIn > 0) {
                        table.status = "Available in " + Utils.formatMinutes(table.availableIn);
                    } else {
                        table.status = Utils.formatMinutes(Math.abs(table.availableIn)) + " overdue";
                    }
                }
            }
        }

        vm.getWalkinsMaxCapacity = function () {
            var capacity = 0;
            for (var i = 0; i < vm.tables.length; i++) {
                if (vm.tables[i].selected) {
                    capacity += vm.tables[i].resource.capacity;
                }
            }
            return capacity;
        }

        /* Returns the available time of selected tables. If more that one table is selected, the minimal available time is returned. */
        vm.getSelectedTablesAvailTime = function () {
            var availableTime = 1440; // available the whole day
            for (var i = 0; i < vm.tables.length; i++) {
                if (vm.tables[i].selected && vm.tables[i].availableIn === 0 && vm.tables[i].availableTime < availableTime) {
                    availableTime = vm.tables[i].availableTime;
                }
            }
            return availableTime;
        }

        /** Returns true is the available time of tables selected for walkin less that the current meal duration */
        vm.isAvailableTimeTooShort = function () {
            return vm.getSelectedTablesAvailTime() < vm.walkinForm.duration;
        }

        /* Checks if all mandatory customer fields are filled on walkin form */
        vm.isWalkinFormCustomerDataFilled = function () {
            var filled = vm.walkinForm.customer && vm.walkinForm.customer.firstName &&
                (vm.walkinForm.customer.primaryPhone || vm.walkinForm.customer.primaryEmail);
            return filled;
        }

        function getSelectedWalkinTables() {
            var selectedIds = [];
            for (var i = 0; i < vm.tables.length; i++) {
                if (vm.tables[i].selected) {
                    selectedIds.push(vm.tables[i].resource.id);
                }
            }
            return selectedIds;
        }

        /* Returns the duration (from global settings), of the current meal (which is determined by the current time). */
        vm.getCurrentMealDuration = function () {
            var now = moment();
            var breakStart = moment(vm.settings.breakfastStart, TIME_SERVER_FORMAT);
            var breakEnd = moment(vm.settings.breakfastEnd, TIME_SERVER_FORMAT);
            if (now.isBetween(breakStart, breakEnd)) {
                return vm.settings.breakfastDuration;
            }
            var lunchStart = moment(vm.settings.lunchStart, TIME_SERVER_FORMAT);
            var lunchEnd = moment(vm.settings.lunchEnd, TIME_SERVER_FORMAT);
            if (now.isBetween(lunchStart, lunchEnd)) {
                return vm.settings.lunchDuration;
            }
            var dinnerStart = moment(vm.settings.dinnerStart, TIME_SERVER_FORMAT);
            var dinnerEnd = moment(vm.settings.dinnerEnd, TIME_SERVER_FORMAT);
            if (now.isBetween(dinnerStart, dinnerEnd)) {
                return vm.settings.dinnerDuration;
            }

            // we are outside of working hours, just return the maximum duration
            return Math.max(vm.settings.breakfastDuration, vm.settings.lunchDuration, vm.settings.dinnerDuration);
        }

        vm.showWalkinDialog = function () {
            vm.showNewWalkinCustomerForm(false);
            vm.walkinForm = {};
            vm.walkinForm.duration = Math.min(vm.getCurrentMealDuration(), vm.getSelectedTablesAvailTime());

            vm.walkinDialog = $uibModal.open({
                templateUrl: 'app/tables/management/walkins.dialog.html',
                scope: $scope,
                size: 'lg'
            });
        }

        vm.changeDuration = function(productId) {
            vm.selectedProduct = findProduct(productId);
            vm.eventDuration = vm.selectedProduct.duration;
        }

        vm.closeWalkoutDialog = function () {
            vm.walkoutDialog.dismiss('cancel');
        }

        vm.closeWalkinDialog = function () {
            vm.walkinDialog.dismiss('cancel');
        }

        vm.showNewWalkinCustomerForm = function (show) {
            $scope.showWalkinCustomerForm = show;
        }

        function doCreateWalkin() {

            var currentCustomer = null;
            if (vm.walkinForm.customer && !angular.equals(vm.walkinForm.customer, {})) {
                currentCustomer = Utils.getBasicCustomerData(vm.walkinForm.customer);
            }

            var customerChangedFlag = false;
            if (vm.walkinForm.customer && vm.walkinForm.customer.id != undefined && !angular.equals(currentCustomer, vm.walkinForm.customerOriginal)) {
                customerChangedFlag = true;
            }

            var params = {
                numberOfPersons: vm.walkinForm.noOfPersons,
                channel: 'WEB',
                note: vm.walkinForm.note,
                duration: vm.eventDuration,
                resourceIds: getSelectedWalkinTables(),
                existingCustomerUpdated: customerChangedFlag,
                customer: currentCustomer,
                productId: vm.walkinForm.productId,
                productType: vm.settings.productType
            }

            Tables.createWalkin(
                params,
                function (data) {
                    vm.closeWalkinDialog();
                    loadDataForSelectedTab();
                });
        }

        vm.createWalkin = function () {


            if (vm.walkinForm.noOfPersons > vm.getWalkinsMaxCapacity()) {
                // ask for confirmation
                SweetAlert.swal({
                        title: "Are you sure?",
                        text: 'Total ' + TextUtil.translate('resource','sc') + ' capacity (' + vm.getWalkinsMaxCapacity() + ') is not enough to accommodate ' + vm.walkinForm.noOfPersons + ' person(s)!',
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Create",
                        cancelButtonText: "Cancel",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            doCreateWalkin();
                        }
                    });
            } else {
                doCreateWalkin();
            }
        }

        vm.waitlistNotify = function (eventId, numberOfPersons) {

            vm.loadWalkins(); // fetch fresh information about table availability

            vm.waitlistSelectedEventId = eventId;
            vm.waitlistSelectedNumberOfPersons = numberOfPersons;
            vm.waitlistNotifyDialog = $uibModal.open({
                templateUrl: 'app/tables/management/waitlist.notify.html',
                scope: $scope,
                size: 'lg'
            });
        }

        vm.closeWaitlistNotifyDialog = function () {
            vm.waitlistNotifyDialog.dismiss('cancel');
        }

        vm.waitlistNotifyCustomer = function () {
            var selectedTables = [];
            for (var i = 0; i < vm.tables.length; i++) {
                var table = vm.tables[i];
                if (table.selected) {
                    selectedTables.push(table.resource.id);
                }
            }
            vm.triggerStateChange(
                vm.waitlistSelectedEventId,
                'NOTIFICATION',
                null,
                {
                    resources: selectedTables,
                    duration: vm.eventDuration,
                    productId: vm.selectedProduct.id,
                    productType: vm.settings.productType
                }
            );

            vm.closeWaitlistNotifyDialog();
        }

        /** Fetches data from the server for the currently selected tab */
        function loadDataForSelectedTab() {
            if (vm.activeTab == 0) {
                vm.loadExpArrivals();
            } else if (vm.activeTab == 1) {
                vm.loadWalkins();
            } else if (vm.activeTab == 2) {
                vm.refreshWaitlistQueue();
            } else if (vm.activeTab == 3) {
                vm.loadWalkouts();
            } else if (vm.activeTab == 4) {
                vm.getPreresevedReservations();
            }
        }

        /** Fetches data from the server for all tabs */
        function loadDataForAllTabs() {
            vm.loadExpArrivals();
            vm.loadWalkins();
            vm.refreshWaitlistQueue();
            vm.loadWalkouts();
            vm.getPreresevedReservations();
        }

        vm.tabTimeout = function (tabInd, timeNow) {
            var diffInSec = vm.diffInSeconds(vm.tabRefreshTimes[tabInd], timeNow);
            //console.log('tabInd: '+tabInd+' diffInSec' + diffInSec);
            if (diffInSec >= 59) {
                return true;
            }
            return false;
        }

        // initial tab selection
        var tabIndex = tabUrls.indexOf($stateParams.tab);
        vm.activeTab = (tabIndex != -1) ? tabIndex : 0; // in case of wrong URL, we will load /arrivals by default

        vm.phoneRegion = ApplicationContext.getPhoneRegion();
        vm.reformatPhone = function () {
            $scope.numInput = $document.find('#primaryPhone');
            if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
                var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.E164);
                if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                    $scope.numInput.intlTelInput('setNumber', currentText); // will autoformat because of formatOnDisplay=true
                }
            }
        }

        //online reservations part

        vm.getPreresevedReservations = function () {
            vm.refreshStart();
            Tables.getPreresevedReservations(
                {
                    //from: moment().subtract(14, 'days').startOf('day').toISOString(),
                    from: moment("2000-01-01", "YYYY-MM-DD").startOf('day').toISOString(),
                    to: moment().add(1, 'year').toISOString(),
                    page: vm.currentPage - 1,
                    size: vm.itemsPerPage
                },
                function (data) {
                    vm.reservations = data.content;
                    vm.totalReservations = data.totalElements;
                    vm.tabRefreshTimes[4] = moment();
                    vm.refreshStop();
                }
            );
        }

        // paging data
        vm.currentPage = 1;
        vm.itemsPerPage = 25;
        vm.pageChanged = function () {
            vm.getPreresevedReservations();
        };

        vm.findTables = function (event) {
            $state.go('tables.newreservation', {prereservedEventId: event.id});
        }

        vm.diffInMinutes = function (time) {
            var diff = -Math.round(moment(time).diff(moment()) / 1000 / 60);
            return diff;
        }

        $scope.channelIcon = function(channel) {
            return ChannelService.getChannelIcon(channel);
        }

        $scope.channelToolTipText = function(channel) {
            return ChannelService.getChannelToolTipText(channel);
        }

        function findProduct(productId) {
            if(vm.settings.shortProductList) {
                for (var i = 0; i < vm.settings.shortProductList.length; i++) {
                    if(vm.settings.shortProductList[i].id == productId)
                        return vm.settings.shortProductList[i];
                }
            }

            if(vm.settings.selectProductList) {
                for (var i = 0; i < vm.settings.selectProductList.length; i++) {
                    if(vm.settings.selectProductList[i].id == productId)
                        return vm.settings.selectProductList[i];
                }
            }
            // product not found for product id
            return null;
        }

        vm.selectText = function() {
            if(vm.settings.productType=='MEAL') {
                return " meal or custom duration";
            } else if(vm.settings.productType=='PRODUCT') {
                return " product";
            } else if(vm.settings.productType=='DURATION') {
                return " duration";
            } else {
                return "";
            }
        }

    }
})();
