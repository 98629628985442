'use strict';

angular.module('seatonApp')
    .factory('Tables', tblMngmntResource);

tblMngmntResource.$inject = ['$resource', 'ApplicationContext'];
function tblMngmntResource($resource, ApplicationContext) {

        return $resource('api/v1/merchants/_MERCHANTID_/', {
            //merchantId: ApplicationContext.getLocationID()
            id: "@id", trigger: "@trigger"
        }, {
            'getExpArrivalsToday': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/events/exp_arrivals_today', isArray: true},
            'getActiveEvents': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/events/active', isArray: true},
            'getEventDetails': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/events/:id', isArray: false},
            'getResourceAvailability': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/resources/availability', isArray: true},
            'getOpenReservations': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/open_reservations?from=:from&to=:to&page=:page&size=:size',
                isArray: false
            },
            'getPreresevedReservations': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/prereserved_reservations?from=:from&to=:to&page=:page&size=:size',
                isArray: false
            },
            'getReservationOverview': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events/reservation/overview?from=:from&to=:to',
                isArray: false
            },
            'searchEvents': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/events?page=:page&size=:size',
                isArray: false
            },
            'triggerStateChange' : {method: 'POST', url: "api/v1/merchants/_MERCHANTID_/events/:id?trigger=:trigger", isArray: false},
            'getWaitlistQueues' : {method: 'GET', url: "api/v1/merchants/_MERCHANTID_/waitlist/data", isArray: false},
            'addToWaitlist' : {method: 'POST', url: "/api/v1/merchants/_MERCHANTID_/waitlist/add", isArray: false},
            'getSuggestedTimes' : {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reservation/suggestedTimes?date=:date', isArray: false},
            'findAvailableTables' : {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/reservation/availableResources?date=:date&time=:time&numberOfPersons=:numberOfPersons&duration=:duration&eventToIgnoreId=:eventToIgnoreId',
                isArray: false
            },
            'createReservation' : {method: 'POST', url: "api/v1/merchants/_MERCHANTID_/reservation/create", isArray: false},
            'createWalkin' : {method: 'POST', url: "api/v1/merchants/_MERCHANTID_/walkins", isArray: false},
            'updateEvent' : {method: 'PUT', url: "api/v1/merchants/_MERCHANTID_/events/:id", isArray: false},
            'updateEventNote' : {method: 'PUT', url: "api/v1/merchants/_MERCHANTID_/events/:id/note", isArray: false},
            'rearrangeFetch' : {method: 'GET', url: "api/v1/merchants/_MERCHANTID_/reservation/rearrangeTables", isArray: false},
            'rearrangeSave' : {method: 'POST', url: "api/v1/merchants/_MERCHANTID_/reservation/rearrangeTables", isArray: false},
            'personalizedCancel' : {method: 'GET', url: "api/v1/merchants/_MERCHANTID_/reservation/personalizedCancel", isArray: false},
            'sendPersonalizedCancel' : {method: 'POST', url: "api/v1/merchants/_MERCHANTID_/reservation/sendPersonalizedCancel", isArray: false},
            'refundReservationFee' : {method: 'POST', url: "api/v1/merchants/_MERCHANTID_/reservation/refund", isArray: false},
            'captureReservationFee' : {method: 'POST', url: "api/v1/merchants/_MERCHANTID_/reservation/capture", isArray: false}

        });

}

