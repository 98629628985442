'use strict';

angular.module('seatonApp')
    .factory('PublicResource', publicResource);

publicResource.$inject = ['$resource', 'ApplicationContext'];
function publicResource($resource, ApplicationContext) {

        return $resource('api/v1/public', {}, {

            // public interfaces
            'getWaitListQueueStatus': {method: 'GET', url: 'api/v1/public/queue/:hashedEventId'},
            //'getWaitListLocationQueueStatus': {method: 'GET', url: 'api/v1/public/waitlist/:hashedLocationId'},
            'getFeedbackDetails': {method: 'GET', url: 'api/v1/public/feedback/:hashedEventId'},
            'saveFeedback': {method: 'POST', url: 'api/v1/public/feedback/:hashedEventId'},
            'cancelEventDetails': {method: 'GET', url: 'api/v1/public/cancelDetails/:hashedEventId'},
            'cancelEvent': {method: 'GET', url: 'api/v1/public/cancel/:hashedEventId'},
            'confirmReservationByCustomer': {method: 'GET', url: 'api/v1/public/confirm/:hashedEventId'},

            // public online interfaces
            'calculateWaitTimes': {method: 'GET', url: 'api/v1/public/online/waitlist/wait-times/:hashedLocationId'},
            'addToWaitlistFromWeb': {method: 'POST', url: 'api/v1/public/online/waitlist/add/:hashedLocationId'},
            'fetchReservationSettings': {method: 'GET', url: 'api/v1/public/online/reservation/settings/:hashedLocationId'},
            'fetchReservationHourList': {method: 'GET', url: 'api/v1/public/online/reservation/hours/:hashedLocationId?requestedDate=:requestedDate'},
            'fetchProductList': {method: 'GET', url: 'api/v1/public/online/reservation/productList/:hashedLocationId?requestedDate=:requestedDate&requestedTime=:requestedTime'},
            'findTable': {method: 'POST', url: 'api/v1/public/online/reservation/findTable/:hashedLocationId'},
            'prereserve': {method: 'POST', url: 'api/v1/public/online/reservation/prereserve/:hashedLocationId'},
            'getPaymentSettings': {method: 'PUT', url: 'api/v1/public/online/reservation/payment_settings/:hashedLocationId'},
            'getCompletedSettings': {method: 'GET', url: 'api/v1/public/online/reservation/completed/:hashedLocationId'},


            'getReservationPage': {method: 'GET', url: 'api/v1/public/online/reservation/page/:hashedLocationId'},

            // new reservation implementation
            'getReserveSettings': {method: 'GET', url: 'api/v1/public/online/reserve/settings/:hashedLocationId'},
            'getReserveAvailability': {method: 'POST', url: 'api/v1/public/online/reserve/availability/:hashedLocationId'}





        });

}

