(function () {
    'use strict';
    angular.module('seatonApp').controller('OnlinePaymentReservationController', OnlinePaymentReservationController);

    OnlinePaymentReservationController.$inject = ['$rootScope', '$scope', '$state', '$document', '$stateParams', 'Log',
        'PublicResource', 'ApplicationContext', 'Utils', 'Notification', 'OnlineReservationContext', 'TextUtil'];

    function OnlinePaymentReservationController($rootScope, $scope, $state, $document, $stateParams, Log, PublicResource,
                       ApplicationContext, Utils, Notification, OnlineReservationContext, TextUtil) {

        var vm = this;

        // date and time
        vm.DATE_FORMAT = 'YYYY-MM-DD';
        vm.DATE_PICKER_FORMAT = 'MMM D, YYYY';
        vm.TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
        vm.TIME_DISPLAY_FORMAT = 'hh:mmA';
        vm.moment = moment;

        // flags
        vm.errorOccured = false;
        vm.errorStatus = 0;
        vm.actionSubmitted = false;
        vm.resourceNotAvailableAnymore = false;
        vm.resourceText = TextUtil.translate("resource","s");

        loadAndValidateParams();

        const clover = new Clover(vm.payment.pakms);
        const elements = clover.elements();

        const form = document.getElementById('payment-form');

        const styles = {
            body: {
                fontFamily: 'Roboto, Open Sans, sans-serif',
                fontSize: '14px'
            },
            input: {
                fontSize: '14px',
                // Fixes for https://community.clover.com/questions/24714/issue-in-clover-hosted-iframe-application-running.html
                padding: '0px',
                margin: '0px'
            }
        };

        const cardNumber = elements.create('CARD_NUMBER', styles);
        const cardDate = elements.create('CARD_DATE', styles);
        const cardCvv = elements.create('CARD_CVV', styles);
        const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);

        cardNumber.mount('#card-number');
        cardDate.mount('#card-date');
        cardCvv.mount('#card-cvv');
        cardPostalCode.mount('#card-postal-code');

        const cardResponse = document.getElementById('card-response');
        const displayCardError = document.getElementById('card-errors');

        const displayCardNumberError = document.getElementById('card-number-errors');
        const displayCardDateError = document.getElementById('card-date-errors');
        const displayCardCvvError = document.getElementById('card-cvv-errors');
        const displayCardPostalCodeError = document.getElementById('card-postal-code-errors');


        cardNumber.addEventListener('change', function (event) {
            displayCardNumberError.innerHTML = event.CARD_NUMBER.error || '';
            displayCardError.innerHTML = '';
        });

        cardNumber.addEventListener('blur', function (event) {
            displayCardNumberError.innerHTML = event.CARD_NUMBER.error || '';
            displayCardError.innerHTML = '';
        });

        cardDate.addEventListener('change', function (event) {
            displayCardDateError.innerHTML = event.CARD_DATE.error || '';
            displayCardError.innerHTML = '';
        });

        cardDate.addEventListener('blur', function (event) {
            displayCardDateError.innerHTML = event.CARD_DATE.error || '';
            displayCardError.innerHTML = '';
        });

        cardCvv.addEventListener('change', function (event) {
            displayCardCvvError.innerHTML = event.CARD_CVV.error || '';
            displayCardError.innerHTML = '';
        });

        cardCvv.addEventListener('blur', function (event) {
            displayCardCvvError.innerHTML = event.CARD_CVV.error || '';
            displayCardError.innerHTML = '';
        });

        cardPostalCode.addEventListener('change', function (event) {
            displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || '';
            displayCardError.innerHTML = '';
        });

        cardPostalCode.addEventListener('blur', function (event) {
            displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || '';
            displayCardError.innerHTML = '';
        });

        /*form.addEventListener('submit', function(event) {
            event.preventDefault();
            Log.debug("OnlinePaymentReservationController","Payment form submitted.");
            vm.completeReservationWithPayment(event);
        });*/

        // ONLOAD PARAMS
        function loadAndValidateParams() {
            // location id
            vm.hashedLocationId = OnlineReservationContext.getLocationId();

            // payment page parameters
            vm.settings= OnlineReservationContext.getSettings();
            vm.request = OnlineReservationContext.getRequest();
            vm.customer = OnlineReservationContext.getCustomer();
            vm.payment = OnlineReservationContext.getPaymentSettings();
            vm.channel = OnlineReservationContext.getChannel();
            vm.reference = OnlineReservationContext.getReference();
            vm.reservationPage = OnlineReservationContext.getReservationPage();

            Log.debug("Payment page",
                "Input params. location id:" + vm.hashedLocationId
                + ": settings " + JSON.stringify(vm.settings)
                + ": request " + JSON.stringify(vm.request)
                + ": customer " + JSON.stringify(vm.customer)
                + ": payment " + JSON.stringify(vm.payment)
                + ": channel " + vm.channel
                + ": reference " + vm.reference
                + ": reservationPage " + vm.reservationPage
            );

            vm.amountValue - vm.payment.amount;
            vm.amountDisplay = Utils.formatAmount(vm.payment.totalAmount);
            vm.amountPerCoverDisplay = Utils.formatAmount(vm.payment.amount);

            // check if access link contain hash coded locationId
            if (!vm.hashedLocationId ) {
                // if location id not return to reservation proxy
                goToFindResourceState();
            }

            if(!vm.settings || !vm.request || !vm.customer || !vm.payment) {
                goToFindResourceState();
            }
        }

        // COMPLETE RESERVATION
        vm.completeReservationWithPayment = function () {

            // disable action button, so that the user does not accidentally send two requests
            $("#paymentSubmit").attr("disabled", true);
            vm.errorOccured=false;
            vm.errorStatus = 0;

            clover.createToken()
                .then(function (result) {
                    if (result.errors) {
                        $("#paymentSubmit").attr("disabled", false);
                        Log.debug("OnlinePaymentReservationController", "Error occurred while fetching token :"+JSON.stringify(result.errors)+":");
                        if(result.errors.CARD_NUMBER) {
                            displayCardNumberError.innerHTML = result.errors.CARD_NUMBER;
                        } else {
                            displayCardNumberError.innerHTML = '';
                        }
                        if(result.errors.CARD_DATE) {
                            displayCardDateError.innerHTML = result.errors.CARD_DATE;
                        } else {
                            displayCardDateError.innerHTML = '';
                        }
                        if(result.errors.CARD_CVV) {
                            displayCardCvvError.innerHTML = result.errors.CARD_CVV;
                        } else {
                            displayCardCvvError.innerHTML = '';
                        }
                        if(result.errors.CARD_POSTAL_CODE) {
                            displayCardPostalCodeError.innerHTML = result.errors.CARD_POSTAL_CODE;
                        } else {
                            displayCardPostalCodeError.innerHTML = '';
                        }
                    } else {
                        if(vm.actionSubmitted) {
                            Log.debug("OnlinePaymentReservationController", "Already submitted, return.");
                            return;
                        }
                        vm.actionSubmitted = true;
                        onTokenFetchSuccess(result.token);
                    }
                }).catch(function (data) {
                    Log.debug("OnlinePaymentReservationController", "Exception while fetching token from Clover. Exception data:"+data+":");
                    $("#paymentSubmit").attr("disabled", false);
                    goToErrorState("Exception while fetching token from Clover");
                });
        }

        function onTokenFetchSuccess(token) {

            var params = {
                // reservation requested params
                requestedDate: vm.request.date,
                requestedTime: vm.request.time,
                containerId: vm.request.containerId,
                containerName: vm.request.containerName,
                numberOfPersons: vm.request.party_size,
                product: vm.request.product,
                // other
                productChosenByGuest: vm.settings.productChosenByGuest,
                productType: vm.settings.productType,
                // customer details
                firstName: vm.customer.firstName,
                lastName: vm.customer.lastName,
                email: vm.customer.primaryEmail,
                phone: vm.customer.primaryPhone,
                note: vm.customer.note,
                // other
                channel: vm.channel,
                occasion: 1,
                // payment details
                source: token,
                amount: vm.payment.totalAmount

            };

            PublicResource.prereserve(
                {
                    hashedLocationId: vm.hashedLocationId
                },
                params,
                function (data) {

                    vm.resourceNotAvailableAnymore = data.resourceNotAvailable;
                    vm.actionSubmitted = false;
                    if (vm.resourceNotAvailableAnymore == false) {

                        OnlineReservationContext.storeCustomer(vm.customer);
                        OnlineReservationContext.storeVerificationSent(data.verificationSent);

                        // store new reference
                        OnlineReservationContext.storeReference("PAYMENT");

                        $state.go("public.online.success");
                    }
                    vm.actionSubmitted = false;
                    $("#paymentSubmit").attr("disabled", false);
                },
                onError
            );
        }

        function onError(error) {
            Log.debug("OnlinePaymentReservationController", "HTTP request error." + error.status);
            vm.actionSubmitted = false;
            $("#paymentSubmit").attr("disabled", false);
            if(error.status == 402) {
                vm.errorOccured = true;
                vm.errorStatus = error.data.paymentStatus;
                Log.debug("Error status is " + vm.errorStatus);
            } else {
                goToErrorState("Error while performing payment transaction.");
            }
        }

        function goToErrorState(error) {
            Log.debug("OnlinePaymentReservationController", "Unexpected error:" + error + ".");
            // store new reference
            OnlineReservationContext.storeReference("PAYMENT");

            $state.go("public.online.error");
        }

        function goToFindResourceState() {
            Notification.showAlert("error", "Reservation parameters missing. Returning to first step in process.");
            var params = {
                'locationId': vm.hashedLocationId
            };
            $state.go("public.online.reservation", params);

        }

        // RESCHEDULE RESERVATION
        vm.reschedule = function() {

            // store new reference
            OnlineReservationContext.storeReference("PAYMENT");

            if("LEGACY" == vm.reservationPage) {
                $state.go("public.online.legacy");
            } else if("RESERVE" == vm.reservationPage){
                $state.go("public.online.reserve");
            } else {
                onError("Unknown reference value, expected LEGACY OR RESERVE, found:" + vm.reference + ".")
            }
        }

        // not needed
        /*
        const form = document.getElementById('payment-form');
        form.addEventListener('submit', function(event) {
            event.preventDefault();

            clover.createToken()
                .then(function(result) {
                    debugger;
                    if (result.errors) {
                        Object.values(result.errors).forEach(function (value) {
                            cardDataDisplayError.textContent = value;
                        });
                    } else {
                        alert(result.token);
                    }
                }).catch(function(data){
                console.log(data);
            });
        });


        // Submit the form with the token ID.
        function cloverTokenHandler(token) {
            // Insert the token ID into the form so it gets submitted to the server
            var form = document.getElementById('payment-form');
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'cloverToken');
            hiddenInput.setAttribute('value', token);
            form.appendChild(hiddenInput);
            alert(token);
            // Submit the form
            // form.submit();
        }

        */

   }

})();



