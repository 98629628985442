(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('HelpPagesMapping', HelpPagesMapping);

    HelpPagesMapping.$inject = ['$state', '$window', 'SweetAlert'];

    function HelpPagesMapping($state, $window, SweetAlert) {

        function getSupportLinks() {
            var currentState = $state.current.name;
            var currentUrl = $state.href($state.current.name, $state.params, {absolute: false});
            //console.log($state.current.url);
            //console.log('currentUrl: ' + currentUrl);

            var linksUrl = helpLinksMapping[currentUrl];
            var linksStates = helpLinksMapping[currentState];

            //matching by URL has higher priority than angular states!
            if (linksUrl != undefined) {
                return linksUrl;
            } else if (linksStates != undefined) {
                return linksStates;
            } else {
                return [];
            }
        }

        function getSupportLink() {
            var link = '';
            var links = getSupportLinks();
            if (links.length > 0) {
                link = getSupportLinks()[0].link;
            }
            return link;
        }

        function openInNewTab(url) {
            var win = $window.open(url, '_blank');
            win.focus();
        }

        function showHelp() {
            var links = getSupportLinks();
            if (links.length == 0) {
                // go to seaton.support.site home page
                openInNewTab("http://support.seaton.site");
                return;
            } else if (links.length == 1) {
                // go directly to link
                openInNewTab("http://support.seaton.site/" + links[0].link);
                return;
            }

            //' <b>Please click on following link with description of current page:</b> <br>'

            var titleText = ' <b>Help page is on following link: </b>  <br><br>';
            var url = "http://support.seaton.site/" + links[0].link;
            //var htmlText = titleText + "  <a target=\"_blank\" href=\"" + url + "\"><br><b>" + links[0].desc + "</b>  <br> " + url + " <br>(please click)  </a> <br>";
            //var htmlText = titleText + "  <a target=\"_blank\" href=\"" + url + "\">    <br><b>" + url + "</b>  <br>(please click to display)  </a> <br>";
            //style="background:#F9EECF"
            //style="background:#ccffcc"
            //style="background:#ffffcc"
            //var htmlText = titleText + "  <div class=\"widget \" style=\"background:#ccffcc\"><a target=\"_blank\" href=\"" + url + "\"> "+ links[0].desc +"<br>" + url + "  <br>(please click to display)  </a></div> <br>";
            var htmlText = titleText + "  <div class=\"widget \" style=\"background:#ccffcc\"><a target=\"_blank\" href=\"" + url + "\"> " + url + "  <br>(please click to display)  </a></div> <br>";

            if (links.length > 1) htmlText += "Suggested other articles for reading: <br><br>";

            for (var i = 1; i < links.length; i++) {
                var desc = links[i].desc;
                if (desc == '') desc = 'page description ';
                //htmlText += desc;
                htmlText += "  <a target=\"_blank\" href=\"http://support.seaton.site/" + links[i].link + "\">" + desc + "</a> <br>";
            }
            //htmlText += "<br> SeatOn support site: <a target=\"_blank\" href=\"http://support.seaton.site/\">http://support.seaton.site</a> <br>";


            SweetAlert.swal({
                html: true,
                title: '',
                text: htmlText,
                //type: "warning",
                showCancelButton: false,
                confirmButtonColor: "#DD6B55", confirmButtonText: "Close",
                cancelButtonText: "Cancel",
                closeOnConfirm: false,
                closeOnCancel: false
            });
        }

        var helpLinksMapping = {
            'home': [{desc: 'SeatOn Web - Home Dashboard', link: '682495-Home-Dashboard'},
                {desc: 'Getting started guide', link: '669145-Getting-started-guide'},
                {desc: 'Events Workflow description', link: '343584-Events-Workflow description'},
                {
                    desc: 'How to create new reservation? (screencast)',
                    link: '604248-How-to-create-new-reservation#WebReservation'
                },
                {
                    desc: 'How to add customer to the waiting list? (screencast)',
                    link: '135871-How-to-add-customer-to-the-waiting-list'
                },
                {
                    desc: 'How to manage customer walk-ins? (screencast)',
                    link: '596934-How-to-manage-customer-walk-ins#SeatonWeb'
                }
            ],

            'customers.dashboard': [{desc: 'SeatOn Web - Customer dashboard', link: '298278-Customer-Dashboard'}],
            'customers.create': [{desc: 'SeatOn Web - Add new customer', link: '058884-Add-new-customer'}],
            '#/customers/list/basicSearch': [{desc: 'SeatOn Web - Customer search', link: '027126-Customer-search'}],
            'customers.list': [{desc: 'SeatOn Web - Customer list', link: '019831-Customer-list'}],
            'customers.feedback': [{desc: 'SeatOn Web - Customer feedback', link: '858564-Customer-feedback'}],
            'customers.profile': [{desc: 'SeatOn Web - Customer details', link: '781216-Customer-details'}],

            'tables.dashboard': [{desc: 'Tables Dashboard', link: '551222-Tables-Dashboard'}],


            '#/tables/management/arrivals': [{
                desc: 'Table management - Arrivals',
                link: '183821-Table-management#Arrivals'
            },
                {desc: 'Events Workflow description', link: '343584-Events-Workflow'},
                {
                    desc: 'How to create new reservation? (screencast)',
                    link: '604248-How-to-create-new-reservation#WebReservation'
                }
            ],
            '#/tables/management/walkin': [{desc: 'Table management- Walkin', link: '183821-Table-management#Walkin'},
                {desc: 'Events Workflow description', link: '343584-Events-Workflow'},
                {
                    desc: 'How to manage customer walk-ins? (screencast)',
                    link: '596934-How-to-manage-customer-walk-ins#SeatonWeb'
                }
            ],
            '#/tables/management/waitlist': [
                {desc: 'Table management - Waitlist', link: '183821-Table-management#Waitlist'},
                {desc: 'Events Workflow description', link: '343584-Events-Workflow'},
                {
                    desc: 'How to add customer to the waiting list? (screencast)',
                    link: '135871-How-to-add-customer-to-the-waiting-list'
                },
                {
                    desc: 'How to remove customer from the waiting list? (screencast)',
                    link: '785895-How-to-remove-customer-from-the-waiting-list'
                }
            ],
            '#/tables/management/walkout': [{
                desc: 'Table management - Walkout',
                link: '183821-Table-management#Walkout'
            },
                {desc: 'Events Workflow description', link: '343584-Events-Workflow'},
                {
                    desc: 'How to release tables and manage customer payments? (screencast)',
                    link: '442644-How-to-release-tables-and-manage-customer-payments#PaymentWeb'
                },
                {
                    desc: 'How can customers leave feedback?',
                    link: '266465-How-can-customers-leave-feedback'
                }
            ],
            'tables.overview': [{desc: 'SeatOn Web - Reservation overview', link: '094781-Reservation-overview'}],
            'tables.search': [{desc: 'SeatOn Web - Event Search', link: '781869-Search'}],
            'tables.newreservation':
                [{
                    desc: 'How to create new reservation?',
                    link: '604248-How-to-create-new-reservation#WebReservation'
                }],
            'tables.addtowaitlist': [
                {
                    desc: 'Add to Waitlist',
                    link: '755244-Add-to-Waitlist'
                },
                {
                    desc: 'How to add customer to the waiting list? (screencast)',
                    link: '135871-How-to-add-customer-to-the-waiting-list#SeatonWeb'
                },
                {
                    desc: 'How to remove customer from the waiting list? (screencast)',
                    link: '785895-How-to-remove-customer-from-the-waiting-list#SeatonWeb'
                }
            ],
            'tables.eventDetails': [{desc: 'SeatOn Web - Event Details', link: '253835-Event-Details'}],
            'tables.eventUpdate': [{desc: 'SeatOn Web - Edit reservation', link: '452617-Edit-reservation'}],

            'reporting.performance': [{desc: 'SeatOn Web - Daily performance', link: '730299-Daily-performance'}],
            'reporting.revenuetrend': [{desc: 'SeatOn Web - Revenue trend', link: '512011-Revenue-trend'}],
            'reporting.employees': [{desc: 'SeatOn Web - Employees performance', link: '182749-Employees-performance'}],
            'reporting.aggregated': [{desc: 'SeatOn Web - Aggregated revenue', link: '940183-Aggregated-revenue'}],
            'reporting.occupancy': [{desc: 'SeatOn Web - Occupancy report', link: '540061-Occupancy-report'}],

            'settings.general': [{desc: '445302-Settings-General', link: '445302-Settings-General'},
                {
                    desc: 'Customize SeatOn application for your needs (screencast)',
                    link: '627656-Customize-SeatOn-application-for-your-needs'
                }
            ],
            'settings.floor': [{desc: '047082-Floor', link: '047082-Floor'},
                {
                    desc: 'Customize SeatOn application for your needs (screencast)',
                    link: '627656-Customize-SeatOn-application-for-your-needs'
                }
            ],
            'settings.notification': [{desc: 'Notifications', link: '583140-Notification'}],
            'settings.membership': [{desc: 'Settings Membership', link: '712729-Membership'}],
            'settings.employees': [{desc: 'SeatOn Web - Employees', link: '347583-Employees'}],
            'settings.employees.view': [{desc: 'SeatOn Web - Employee details', link: '275736-Employee-details'}],

            //not used - URLs are used insted angular state
            'tables.management': [{desc: 'SeatOn Web - Table management', link: '183821-Table-management'},
                {desc: 'Table management - Arrivals', link: '183821-Table-management#Arrivals'},
                {desc: 'Table management- Walkin', link: '183821-Table-management#Walkin'},
                {desc: 'Table management - Waitlist', link: '183821-Table-management#Waitlist'},
                {desc: 'Table management - Walkout', link: '183821-Table-management#Walkout'}
            ]
        }

        return {
            getSupportLinks: getSupportLinks,
            getSupportLink: getSupportLink,
            showHelp: showHelp
        };
    }
})();




