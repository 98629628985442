'use strict';

angular.module('seatonApp')
    .factory('Statistics',statisticsResource );

statisticsResource.$inject = ['$resource', 'ApplicationContext'];
function statisticsResource($resource, ApplicationContext) {

        return $resource('api/v1/merchants/_MERCHANTID_/reports', {
            //merchantId: ApplicationContext.getLocationID()
        }, {
            'getLocationRegularsStats': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/customerLocationStats', isArray: false},
            'getCustomerLocationAllVisitsStats': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/customerLocationAllVisitsStats'},
            'getReportingRevenueTrend': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/revenueTrend'},
            'getReportingRevenueTrendLastMonth': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/revenueTrendLastMonth'},
            'getReportingRevenueTrendMonthDetails': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/revenueTrendMonthDetails'},
            'getReportingRevenueTrendDayDetails': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/revenueTrendDayDetails'},
            'getReportingEmployeesPerformance': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/employeePerformance'},
            'getReportingOccupancy': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/occupancy'},
            'getReportingOccupancyByHour': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/occupancyByHour'},
            'getReportingExpectedOccupancyByHour': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/reports/expectedOccupancyByHour'}
        });

}

