'use strict';

CloverLogoutController.$inject = ['$rootScope', '$scope', '$state'];

function CloverLogoutController($rootScope, $scope, $state) {
    var vm = this;

}

angular.module('seatonApp').controller('CloverLogoutController', CloverLogoutController);
