(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('ChannelService', ChannelService);

    ChannelService.$inject = ['$state'];

    function ChannelService($state) {

        function getChannelIcon(channel) {
            var icon = "fa-laptop text-info";
            switch(channel) {
                case "MERCHANT_WEB":
                    icon="fa-home text-danger";
                    break;
                case "DEVICE":
                    icon="fa-laptop text-info";
                    break;
                case "WEB":
                    icon="fa-desktop text-info";
                    break;
                case "FACEBOOK":
                    icon="fa-facebook-square text-success";
                    break;
                case "GOOGLE":
                    icon="fa-google-plus-square text-success";
                    break;
                case "IVR":
                    icon="fa-phone-square text-success";
                    break;

            }
            return icon;
        }

        function getChannelToolTipText (channel) {
            var tooltip = "Clover Device";
            switch(channel) {
                case "MERCHANT_WEB":
                    tooltip="Your Web Page";
                    break;
                case "DEVICE":
                    tooltip="Clover Device";
                    break;
                case "WEB":
                    tooltip="SeatOn Web Application";
                    break;
                case "FACEBOOK":
                    tooltip="Your Facebook Page";
                    break;
                case "GOOGLE":
                    tooltip="Google Pages";
                    break;
                case "IVR":
                    tooltip="Interactive Voice Response (IVR)";
                    break;

            }
            return tooltip;
        }

        return {
            getChannelIcon: getChannelIcon,
            getChannelToolTipText: getChannelToolTipText
        };
    }
})();




