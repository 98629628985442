(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('OnlineWaitListController', OnlineWaitListController);

    OnlineWaitListController.$inject = ['$rootScope', '$scope', '$state', '$document', '$stateParams', 'Log', 'PublicResource', 'Utils',
                                    'OnlineReservationContext'];

    function OnlineWaitListController($rootScope, $scope, $state, $document, $stateParams, Log, PublicResource, Utils,
                                      OnlineReservationContext) {

        var vm = this;
        vm.isInternationalSmsEnabled = false;
        if (window.location.href.indexOf("//eu.getseat.net") != -1)  vm.isEU = true;
        vm.systemErrorOccured = false;
        vm.inputErrorOccured = false;
        vm.phoneRegion = null;
        vm.phonePattern = null;
        vm.addSubmitted = false;
        vm.locationName = "Restaurant waiting list";
        vm.locationAddress = "";
        vm.contactEmail = null;
        vm.contactPhone = null;

        vm.persons = [
            {"id": 1, "label": "1 person"},
            {"id": 2, "label": "2 persons"},
            {"id": 3, "label": "3 persons"},
            {"id": 4, "label": "4 persons"},
            {"id": 5, "label": "5 persons"},
            {"id": 6, "label": "6 persons"}
        ];

        vm.reset = function () {
            vm.inputErrorOccured = false;
            vm.numberOfPersons = 2;
            vm.addSubmitted = false;
            vm.resetCustomer();
        };

        vm.resetCustomer = function () {
            // workaround for https://github.com/angular/angular.js/issues/2743
            jQuery("input[name='primaryEmail']").val("");
            jQuery("input[name='primaryPhone']").val("");
            vm.customer = null;
        };

        vm.reset();

        var hashedLocationId = $stateParams.locationId;
        Log.debug("OnlineWaitListController", "Hashed locationId id is '" + hashedLocationId + "'");
        if (hashedLocationId == undefined) {
            vm.systemErrorOccured - true;
        }


        // GET wait times from host
        $(function () {
            if (vm.systemErrorOccured) return; // do not call host

            PublicResource.calculateWaitTimes({
                hashedLocationId: hashedLocationId
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.systemErrorOccured = false;
                vm.data = data;
                vm.isInternationalSmsEnabled = data.internationalSmsEnabled;
                //vm.isInternationalSmsEnabled = false;
                vm.setQuotedWaitTime();

                //vm.phoneRegion = 'US';
                vm.phoneRegion = data.phoneRegion;

                //vm.phonePrefix = '+1'
                vm.phonePrefix = data.phonePrefix;

                vm.locationName = data.locationName;
                vm.locationAddress = data.locationAddress;
                vm.contactEmail = data.contactEmail;
                vm.contactPhone = data.contactPhone;

                if (vm.isInternationalSmsEnabled || vm.isEU) {
                    vm.phonePattern = '';
                } else {
                    vm.phonePattern = new RegExp('^\\' + vm.phonePrefix);
                }
            }

            function onError(error) {
                Log.debug("OnlineWaitListController", "HTTP request error." + error);
                vm.systemErrorOccured = true;
            }
        });

        // get wait time text
        vm.getWaitTimeText = function(){
            if(vm.quotedWaitTime>0) {
                return 'Average wait time for ' + vm.numberOfPersons + ' person(s) is ' + vm.quotedWaitTime + ' minute(s).';
            } else {
                return 'The waiting time is short, you will soon be notified that the table is ready.';
            }
        };

        // ADD visitor to wait list
        vm.addToWaitlist = function (phoneValid) {

            if (vm.inputNOK(phoneValid)) {
                vm.inputErrorOccured = true;
                return;
            } else {
                vm.inputErrorOccured = false;
            }
            var currentCustomer = Utils.getBasicCustomerData(vm.customer);

            var event = {
                note: vm.note,
                quotedWaitTime: vm.quotedWaitTime,
                numberOfPersons: vm.numberOfPersons,
                channel: 'MERCHANT_WEB',
                existingCustomerUpdated: false,
                customer: currentCustomer
            };
            //console.log(event);

            // disable create button, so that the user does not accidentally send two requests
            vm.addSubmitted = true;
            PublicResource.addToWaitlistFromWeb(
                {
                    hashedLocationId: hashedLocationId
                },
                event, onSuccessSave, onErrorSave);

            function onSuccessSave(data, headers) {
                vm.systemErrorOccured = false;
                var settings = {
                    'locationName': vm.locationName,
                    'locationAddress': vm.locationAddress,
                    'contactEmail': vm.contactEmail,
                    'contactPhone': vm.contactPhone
                };

                OnlineReservationContext.storeReference("WAITLIST");
                OnlineReservationContext.storeLocationChannelPage(hashedLocationId, "MERCHANT_WEB");
                OnlineReservationContext.storeSettings(settings);

                $state.go("public.online.success", settings);
                vm.addSubmitted = false;
            }

            function onErrorSave(error) {
                vm.systemErrorOccured = true;
                Log.debug("OnlineWaitListController", "Error while adding visitors to wait list. Err=" + error);
                vm.addSubmitted = false;
            }

        };

        vm.systemErrorOccurs = function () {
            return vm.systemErrorOccured;
        };

        vm.setQuotedWaitTime = function () {
            if (vm.numberOfPersons < 2) {
                if (vm.data.waitlistData.approxWaitTimeOneToTwo != undefined) {
                    vm.quotedWaitTime = vm.data.waitlistData.approxWaitTimeOneToTwo;
                }
            } else if (vm.numberOfPersons < 4) {
                if (vm.data.waitlistData.approxWaitTimeThreeToFour != undefined) {
                    vm.quotedWaitTime = vm.data.waitlistData.approxWaitTimeThreeToFour;
                }
            } else {
                if (vm.data.waitlistData.approxWaitTimeFivePlus != undefined) {
                    vm.quotedWaitTime = vm.data.waitlistData.approxWaitTimeFivePlus;
                }
            }
        };

        vm.inputNOK = function (phoneValid) {
            var input =
                !(vm.numberOfPersons > 0) ||
                !vm.customer ||
                !vm.customer.firstName ||
                !vm.customer.lastName ||
                !vm.customer.primaryEmail ||
                !(vm.customer.primaryPhone && phoneValid);

            return input;
        };

        vm.inputErrorOccurs = function () {
            return vm.inputErrorOccured;
        };

        vm.reformatPhone = function () {
            $scope.numInput = $document.find('#primaryPhone');
            if (typeof intlTelInputUtils !== 'undefined') {
                var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
                if (typeof currentText === 'string') {
                    $scope.numInput.intlTelInput('setNumber', currentText);
                }
            }
        }
    }


})();



