(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('PublicConfirmController', PublicConfirmController);

    PublicConfirmController.$inject = ['$scope', '$state', '$stateParams', 'Log', 'PublicResource'];

    function PublicConfirmController($scope, $state, $stateParams, Log, PublicResource) {

        var vm = this;
        var hashedEventId = $stateParams.eventId;

        Log.debug("confirm.controller","Hashed event id is '"+hashedEventId+"'");
        if(hashedEventId==undefined || hashedEventId=='') {
            $state.go("public.error");
        }

        PublicResource.confirmReservationByCustomer({
            hashedEventId: hashedEventId
        }, onSuccess, onError);


        function onSuccess(data, headers) {
            vm.data = data;
        }

        function onError(error) {
            Log.debug("confirm.controller", "HTTP request error due event details request." + error);
        }

    }
})();



