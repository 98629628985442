/**
 * Created by branimir on 26.3.2017..
 */
(function () {

    'use strict';
    angular.module('seatonApp')
        .directive('timeDiff', function () {
            return {
                restrict: 'EA',
                scope: {
                    startTime: '=startTime'
                },
                template: '<span ng-class="vm.timeDiffCss">{{vm.timeDiffPrefix}}{{vm.timeDiffFormatted }} @ {{vm.startTime | amDateFormat: "LT" }}</span>',
                controller: TimeDiffController,
                controllerAs: 'vm',
                bindToController: true
            };
        });

    function TimeDiffController() {
        var vm = this;
        init();
        function init() {
            vm.timeDiff_millis = moment(vm.startTime).diff(moment());
            if (Math.abs(vm.timeDiff_millis) > 3590000) {
                vm.timeDiffFormatted = moment.duration(Math.abs(vm.timeDiff_millis), 'ms').format("h[h]m[min]");
            } else {
                vm.timeDiffFormatted = moment.duration(Math.abs(vm.timeDiff_millis), 'ms').format("m[min]");
            }
            vm.timeDiffCss = vm.timeDiff_millis < 0 ? 'text-danger' : 'text-navy';
            vm.timeDiffPrefix = vm.timeDiff_millis > 0 ? '' : '-';
        }
    }
})();
