(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('ListUtils', ListUtils);

    ListUtils.$inject = ['ApplicationContext'];

    /** Contains various utility methods used in multiple controllers. */
    function ListUtils(ApplicationContext) {


        var ruleList = [
            {id: 'RESERVATION', name: 'Fee per reservation'},
            {id: 'COVER', name: 'Fee per person'}
        ];

        var availableTimesForDay30MinStep = [
            {time: "12:00 AM", timeForDB: "00:00"},
            {time: "12:30 AM", timeForDB: "00:30"},
            {time: "1:00 AM", timeForDB: "01:00"},
            {time: "1:30 AM", timeForDB: "01:30"},
            {time: "2:00 AM", timeForDB: "02:00"},
            {time: "2:30 AM", timeForDB: "02:30"},
            {time: "3:00 AM", timeForDB: "03:00"},
            {time: "3:30 AM", timeForDB: "03:30"},
            {time: "4:00 AM", timeForDB: "04:00"},
            {time: "4:30 AM", timeForDB: "04:30"},
            {time: "5:00 AM", timeForDB: "05:00"},
            {time: "5:30 AM", timeForDB: "05:30"},
            {time: "6:00 AM", timeForDB: "06:00"},
            {time: "6:30 AM", timeForDB: "06:30"},
            {time: "7:00 AM", timeForDB: "07:00"},
            {time: "7:30 AM", timeForDB: "07:30"},
            {time: "8:00 AM", timeForDB: "08:00"},
            {time: "8:30 AM", timeForDB: "08:30"},
            {time: "9:00 AM", timeForDB: "09:00"},
            {time: "9:30 AM", timeForDB: "09:30"},
            {time: "10:00 AM", timeForDB: "10:00"},
            {time: "10:30 AM", timeForDB: "10:30"},
            {time: "11:00 AM", timeForDB: "11:00"},
            {time: "11:30 AM", timeForDB: "11:30"},
            {time: "12:00 PM", timeForDB: "12:00"},
            {time: "12:30 PM", timeForDB: "12:30"},
            {time: "1:00 PM", timeForDB: "13:00"},
            {time: "1:30 PM", timeForDB: "13:30"},
            {time: "2:00 PM", timeForDB: "14:00"},
            {time: "2:30 PM", timeForDB: "14:30"},
            {time: "3:00 PM", timeForDB: "15:00"},
            {time: "3:30 PM", timeForDB: "15:30"},
            {time: "4:00 PM", timeForDB: "16:00"},
            {time: "4:30 PM", timeForDB: "16:30"},
            {time: "5:00 PM", timeForDB: "17:00"},
            {time: "5:30 PM", timeForDB: "17:30"},
            {time: "6:00 PM", timeForDB: "18:00"},
            {time: "6:30 PM", timeForDB: "18:30"},
            {time: "7:00 PM", timeForDB: "19:00"},
            {time: "7:30 PM", timeForDB: "19:30"},
            {time: "8:00 PM", timeForDB: "20:00"},
            {time: "8:30 PM", timeForDB: "20:30"},
            {time: "9:00 PM", timeForDB: "21:00"},
            {time: "9:30 PM", timeForDB: "21:30"},
            {time: "10:00 PM", timeForDB: "22:00"},
            {time: "10:30 PM", timeForDB: "22:30"},
            {time: "11:00 PM", timeForDB: "23:00"},
            {time: "11:30 PM", timeForDB: "23:30"},
            {time: "12:00 AM", timeForDB: "00:00"}
        ];

        var availableTimesForEventDuration = [
            {time: "0:05h", timeForDB: 5},
            {time: "0:10h", timeForDB: 10},
            {time: "0:15h", timeForDB: 15},
            {time: "0:20h", timeForDB: 20},
            {time: "0:25h", timeForDB: 25},
            {time: "0:30h", timeForDB: 30},
            {time: "0:35h", timeForDB: 35},
            {time: "0:40h", timeForDB: 40},
            {time: "0:45h", timeForDB: 45},
            {time: "0:50h", timeForDB: 50},
            {time: "0:55h", timeForDB: 55},
            {time: "1:00h", timeForDB: 60},
            {time: "1:05h", timeForDB: 65},
            {time: "1:10h", timeForDB: 70},
            {time: "1:15h", timeForDB: 75},
            {time: "1:20h", timeForDB: 80},
            {time: "1:25h", timeForDB: 85},
            {time: "1:30h", timeForDB: 90},
            {time: "1:35h", timeForDB: 95},
            {time: "1:40h", timeForDB: 100},
            {time: "1:45h", timeForDB: 105},
            {time: "1:50h", timeForDB: 110},
            {time: "1:55h", timeForDB: 115},
            {time: "2:00h", timeForDB: 120},
            {time: "2:15h", timeForDB: 135},
            {time: "2:30h", timeForDB: 150},
            {time: "2:45h", timeForDB: 165},
            {time: "3:00h", timeForDB: 180},
            {time: "3:15h", timeForDB: 195},
            {time: "3:30h", timeForDB: 210},
            {time: "3:45h", timeForDB: 225},
            {time: "4:00h", timeForDB: 240},
            {time: "4:15h", timeForDB: 255},
            {time: "4:30h", timeForDB: 270},
            {time: "4:45h", timeForDB: 285},
            {time: "5:00h", timeForDB: 300},
            {time: "6:00h", timeForDB: 360},
            {time: "8:00h", timeForDB: 480},
            {time: "12:00h", timeForDB: 720},
            {time: "24:00h", timeForDB: 1440}
        ];


        var maxSimpleDurationList = [
            {id: 60, name: '1 hour'},
            {id: 120, name: '2 hours'},
            {id: 180, name: '3 hours'},
            {id: 240, name: '4 hours'},
            {id: 360, name: '6 hours'},
            {id: 720, name: '12 hours'},
            {id: 1440, name: '24 hours'}
        ];

        var minSimpleDurationList = [
            {id: 5, name: '5 min'},
            {id: 10, name: '10 min'},
            {id: 15, name: '15 min'},
            {id: 30, name: '30 min'},
            {id: 60, name: '1 hour'}
        ];


        function getPaymentRuleList() {
            return ruleList;
        }

        function getAvailableTimesForDay30MinStep() {
            return availableTimesForDay30MinStep;
        }

        function getAvailableTimesForEventDuration() {
            return availableTimesForEventDuration;
        }

        function getMaxSimpleDurationList() {
            return maxSimpleDurationList;
        }

        function getMinSimpleDurationList() {
            return minSimpleDurationList;
        }

        return {
            getPaymentRuleList: getPaymentRuleList,
            getAvailableTimesForDay30MinStep: getAvailableTimesForDay30MinStep,
            getAvailableTimesForEventDuration: getAvailableTimesForEventDuration,
            getMaxSimpleDurationList: getMaxSimpleDurationList,
            getMinSimpleDurationList: getMinSimpleDurationList
        };
    }
})();




