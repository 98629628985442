'use strict';

angular.module('seatonApp')
    .factory('FeedbackResource', feedbackResource);

feedbackResource.$inject = ['$resource', 'ApplicationContext'];
function feedbackResource($resource, ApplicationContext) {
    return $resource('api/v1/merchants/_MERCHANTID_/feedbacks', {},
        {
            'getLastFeedbacks': {
                method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/feedbacks/getLastFeedbacks', isArray: true
            },
            'searchFeedbacks': {
                method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/feedbacks/searchFeedbacks', isArray: false
            }
        });
}

