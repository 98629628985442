(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('CreateReservationController', CreateReservationController);

    CreateReservationController.$inject = ['$scope', '$state', '$stateParams', '$document', 'Tables', 'CustomerResource', 'Utils',
        'SettingsResource', 'SweetAlert', 'ApplicationProfile', 'ApplicationContext', 'Notification'];


    var DATE_FORMAT = 'YYYY-MM-DD';
    var TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
    var TIME_DISPLAY_FORMAT = 'hh:mmA';
    var DATE_PICKER_FORMAT = 'MMM D, YYYY';

    function CreateReservationController($scope, $state, $stateParams, $document, Tables, CustomerResource, Utils,
                                         SettingsResource, SweetAlert, ApplicationProfile, ApplicationContext, Notification) {

        var vm = this;
        vm.productId=null;

        vm.showCustomerStatistic = ApplicationProfile.isEventCreateCustomerStatsEnabled();
        vm.showCustomerStatisticText = ApplicationProfile.upgradeTierText("CreateReservation");
        vm.openWindow = ApplicationProfile.openAppCloverPage;

        vm.days = [
            {
                date: moment().format(DATE_FORMAT),
                label: 'Today'
            },
            {
                date: moment().add(1, 'days').format(DATE_FORMAT),
                label: 'Tomorrow'
            },
            {
                date: moment().add(2, 'days').format(DATE_FORMAT),
                label: moment().add(2, 'days').format('dddd')
            },
            {
                date: moment().add(3, 'days').format(DATE_FORMAT),
                label: moment().add(3, 'days').format('dddd')
            },
            {
                date: moment().add(4, 'days').format(DATE_FORMAT),
                label: moment().add(4, 'days').format('dddd')
            }];

        function getGeneralSettings() {
            SettingsResource.getGeneralSettings(
                {},
                function (data) {
                    vm.settings = data;
                }
            );
        }

        getGeneralSettings();

        vm.prereservedEventId = $stateParams.prereservedEventId;
        if (vm.prereservedEventId) {
            Tables.getEventDetails(
                {
                    id: vm.prereservedEventId,
                },
                function (data) {
                    vm.prereservedEvent = data;
                    vm.fillDataFromPreReservedEvent();
                });
        } else {

            // check for pending reservations (online | IVR) and notify user if necessary
            Tables.getPreresevedReservations(
                {
                    from: moment().startOf('day').toISOString(),
                    to: moment().add(1, 'year').toISOString(),
                    page: 0,
                    size: 1
                },
                function (data) {
                    if (data.totalElements > 0) {
                        Notification.warning('There are pending reservations in the queue. Please go to <b><a href="/#/tables/management/online-res">Pending Reservations</a></b> screen and assign tables.');
                    }
                }
            );
        }

        vm.clearTables = function () {
            vm.tables = null;
            vm.alternativeTimes = null;
            vm.nonWorkingDay = false;
        }

        vm.formatDateForDisplay = function(dateToFormat) {
            return moment(dateToFormat, DATE_FORMAT).format(DATE_PICKER_FORMAT);
        }

        vm.daySelected = function () {

            vm.slots = null;
            vm.clearTables();
            vm.selectedDayFormatted = moment(vm.selectedDay, DATE_FORMAT).format(DATE_PICKER_FORMAT);
            Tables.getSuggestedTimes(
                {
                    date: vm.selectedDay
                },
                function (data) {
                    vm.openHours = data.openHours;
                    vm.slots = [];
                    for (var i = 0; i < data.times.length; i++) {
                        vm.slots.push(moment(data.times[i], TIME_SERVER_FORMAT).format(TIME_DISPLAY_FORMAT));
                    }
                }
            );
        }

        vm.timeChanged = function() {
            if(vm.isReservationTimeInPast()) {
                SweetAlert.swal({
                        title: "Selected time is the past.",
                        text: "Please choose another time.?",
                        type: "error",
                        showCancelButton: false,
                        cancelButtonColor: "#1a94b3",
                        confirmButtonColor: "#1ab394",
                        confirmButtonText: "OK",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        vm.selectedTime = null;
                    });

            } else if(!vm.isWithinOpenHours(vm.selectedTime)) {
                SweetAlert.swal({
                        title: "Selected time is outside business hours.",
                        text: "Do you want to create reservation with time outside business hours?",
                        type: "info",
                        showCancelButton: true,
                        cancelButtonColor: "#1a94b3",
                        confirmButtonColor: "#1ab394",
                        cancelButtonText: "No",
                        confirmButtonText: "Yes",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (!isConfirm) {
                            vm.selectedTime = null;
                        }
                    });
            }
            vm.clearTables();
        }

        vm.isTimeSet = function() {
            return vm.selectedTime && moment(vm.selectedTime, TIME_DISPLAY_FORMAT).isValid();
        }

        vm.findTables = function () {
            vm.clearTables();

            Tables.findAvailableTables(
                {
                    date: vm.selectedDay,
                    time: timeToServerFormat(vm.selectedTime),
                    numberOfPersons: vm.numberOfPersons,
                    duration: findDuration(vm.productId)
                },
                function (data) {

                    vm.tables = data.availableResources;
                    vm.noResource = data.noResource;
                    vm.nonWorkingDay = data.nonWorkingDay;

                    vm.alternativeTimes = [];
                    for (var i = 0; i < data.alternativeTimes.length; i++) {
                        vm.alternativeTimes.push({
                            time: moment(data.alternativeTimes[i].time, TIME_SERVER_FORMAT).format(TIME_DISPLAY_FORMAT),
                            capacity: data.alternativeTimes[i].capacity
                        });
                    }
                }
            );
        }

        vm.selectedDay = vm.days[0].date;
        vm.daySelected();

        vm.isWithinOpenHours = function (timeStr) {
            var time = moment(timeStr, TIME_DISPLAY_FORMAT);

            for (var i = 0; i < vm.openHours.length; i++) {

                var from = moment(vm.openHours[i].fromHour, TIME_SERVER_FORMAT);
                var to = moment(vm.openHours[i].endHour, TIME_SERVER_FORMAT);

                if (time.isSameOrAfter(from) && time.isBefore(to)) {
                    return true;
                }
            }
            return false;
        }

        /** Returns a list of IDs of all selected tables. */
        vm.getSelectedTables = function () {
            var selectedIds = [];

            if (vm.tables) {
                for (var i = 0; i < vm.tables.length; i++) {
                    if (vm.tables[i].selected === true) {
                        selectedIds.push(vm.tables[i].id);
                    }
                }
            }
            return selectedIds;
        }

        vm.getSelectedTablesCapacity = function () {
            var capacity = 0;
            if (vm.tables) {
                for (var i = 0; i < vm.tables.length; i++) {
                    if (vm.tables[i].selected === true) {
                        capacity += vm.tables[i].capacity;
                    }
                }
            }
            return capacity;
        }

        vm.altTimeSelected = function () {
            vm.selectedTime = vm.selectedAltTime;

            if (vm.selectedDay && vm.selectedTime && vm.numberOfPersons && vm.productId) {
                vm.findTables();
            }
        }

        /** Called by the customer search directive when user selects a customer from a list */
        $scope.customerSelected = function (selCust) {

            CustomerResource.get(
                {id: selCust.id},
                function (data) {
                    vm.customer = data;
                    //var primPhone = vm.customer.primaryPhone;

                    //isValidNumber

                    vm.customer.primaryPhone = intlTelInputUtils.formatNumber(vm.customer.primaryPhone, vm.phoneRegion, intlTelInputUtils.numberFormat.INTERNATIONAL);
                    vm.reformatPhone();
                    //vm.customer.primaryPhone = null;
                    //console.log('currentText:' + currentText);
                    //$scope.numInput = $document.find('#primaryPhone');
                    //$scope.numInput.intlTelInput('setNumber', currentText);
                    //$scope.numInput.val(currentText);

                    //vm.customer.primaryPhone = undefined;
                    //$scope.customerForm.primaryPhone.$modelValue = undefined;
                    //$scope.customerForm.primaryPhone.$viewValue = currentText;

                    //vm.customer.primaryPhone.validate();
                    vm.customerTotal = vm.customer.totalVisits + vm.customer.totalNoShow + vm.customer.totalCanceled;
                    vm.customerOriginal = Utils.getBasicCustomerData(data);
                }
            );
        }

        vm.resetCustomer = function () {
            // workaround for https://github.com/angular/angular.js/issues/2743
            jQuery("input[name='primaryEmail']").val("");
            jQuery("input[name='primaryPhone']").val("");

            vm.customer = null;
            vm.customerTotal = null;
            vm.customerOriginal = null;
            $scope.customerSearchKeyword = '';
        }

        vm.reset = function () {
            vm.selectedDay = null;
            vm.selectedTime = null;
            vm.selectedDayFormatted = null;
            vm.numberOfPersons = null;
            vm.duration = null;
            vm.productId=null;
            vm.clearTables();
            vm.note = null;
            vm.selectedAltTime = null;
            vm.prereservedEventId = null;
            vm.resetCustomer();
        }


        vm.isCreateDisabled = function () {
            var isDisabled =
                vm.getSelectedTables().length === 0 ||
                !vm.customer ||
                !vm.customer.firstName ||
                !((vm.customer.primaryPhone && $scope.customerForm.primaryPhone.$valid) || vm.customer.primaryEmail) ||
                (vm.customer.id && !vm.customer.reservationAllowed);
            return isDisabled;
        }

        vm.isReservationTimeInPast = function () {
            if (vm.selectedDay && vm.isTimeSet()) {
                var selectedDateTime = vm.selectedDay + ' ' + vm.selectedTime;
                var FORMAT = DATE_FORMAT + ' ' + TIME_DISPLAY_FORMAT;

                var reservationTime = moment(selectedDateTime, FORMAT);
                var now = moment();

                return reservationTime.isBefore(now);
            }
            return false;
        }

        vm.isCreateWithWarning = function () {
            return (vm.getSelectedTables().length > 0 && vm.getSelectedTablesCapacity() < vm.numberOfPersons);
        }


        function doCreate() {

            var currentCustomer = Utils.getBasicCustomerData(vm.customer);
            var customerChangedFlag = currentCustomer.id && !angular.equals(currentCustomer, vm.customerOriginal);

            var params = {
                reservationDate: vm.selectedDay,
                reservationTime: timeToServerFormat(vm.selectedTime),
                numberOfPersons: vm.numberOfPersons,
                channel: 'WEB',
                note: vm.note,
                duration: findDuration(vm.productId),
                productId: vm.productId,
                resourceIds: vm.getSelectedTables(),
                existingCustomerUpdated: customerChangedFlag,
                customer: currentCustomer,
                prereservedEventId: vm.prereservedEventId
            }

            Tables.createReservation(
                params,
                function (data) {
                    if(vm.prereservedEventId) {
                        vm.confirmButtonText= "Go to open pre-reservations list";
                    } else {
                        vm.confirmButtonText= "Create new reservation";
                    }

                    SweetAlert.swal({
                            title: "Successfully created a reservation",
                            text: "What do you want to do next?",
                            type: "success",
                            showCancelButton: true,
                            cancelButtonColor: "#1a94b3",
                            confirmButtonColor: "#1ab394",
                            cancelButtonText: "Go to reservation center",
                            confirmButtonText: vm.confirmButtonText,
                            closeOnConfirm: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                if(vm.prereservedEventId) {
                                    $state.go('tables.management', {tab: 'online-res'});
                                } else {
                                    vm.reset();
                                }
                            } else {
                                $state.go('tables.overview');
                            }
                        });

                });
        }

        vm.createReservation = function () {

            if (vm.getSelectedTablesCapacity() < vm.numberOfPersons) {

                var text = ' Total table(s) capacity (' + vm.getSelectedTablesCapacity() + ') is not enough for ' + vm.numberOfPersons + ' person(s)!';

                SweetAlert.swal({
                        title: "Are you sure?",
                        text: text,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Create reservation",
                        cancelButtonText: "Cancel",
                        closeOnConfirm: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            doCreate();
                        }
                    });
            } else {
                doCreate();
            }
        }

        /** Returns the selected time in the format expected by the REST service. */
        function timeToServerFormat(time) {
            return moment(time, TIME_DISPLAY_FORMAT).format(TIME_SERVER_FORMAT);
        }

        // initialize date & time picker
        $document.ready(
            function () {
                // date picker
                vm.datePicker = $document.find('input[name="datePicker"]');
                vm.datePicker.daterangepicker(
                    {
                        singleDatePicker: true,
                        showDropdowns: true,
                        locale: {format: DATE_PICKER_FORMAT}
                    },
                    function (start, end, label) {
                        vm.selectedDay = start.format(DATE_FORMAT)
                        vm.daySelected();
                    }
                );
                vm.datePicker.val(vm.selectedDayFormatted);

                // time picker
                vm.timePicker = $('.clockpicker').clockpicker({
                    donetext: 'Done',
                    twelvehour: true
                });
            }
        );

        vm.openDatePicker = function () {
            vm.datePicker.focus();
        }

        vm.phoneRegion = ApplicationContext.getPhoneRegion();
        vm.phonePrefix = ApplicationContext.getPhoneCountryPrefix();
        vm.isInternationalSmsEnabled = ApplicationProfile.isInternationalSmsEnabled();
        if (vm.isInternationalSmsEnabled) {
            vm.phonePattern = '';
        } else {
            //vm.phonePattern='^\+44';  // /^\+44/
            vm.phonePattern = new RegExp('^\\+44');
            //vm.phonePattern = '/^\+' + ApplicationContext.getPhoneCountryPrefix() + '/';
        }

        vm.reformatPhone = function () {
            $scope.numInput = $document.find('#primaryPhone');
            if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
                var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
                if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                    $scope.numInput.intlTelInput('setNumber', currentText); // will autoformat because of formatOnDisplay=true
                }
            }
        }


        vm.fillDataFromPreReservedEvent = function () {
            if (vm.prereservedEvent) {
                vm.selectedDay = moment(vm.prereservedEvent.expectedStartTime).format(DATE_FORMAT);
                vm.selectedTime = moment(vm.prereservedEvent.expectedStartTime).format(TIME_DISPLAY_FORMAT);
                vm.duration = vm.prereservedEvent.expectedDuration;
                vm.productId = findProductId(vm.duration); // TODO productId is baze
                vm.numberOfPersons = vm.prereservedEvent.numberOfPersons;
                vm.note = vm.prereservedEvent.note;

                vm.seatingOptionId = vm.prereservedEvent.seatingOptionId;
                vm.seatingOptionLabel = vm.prereservedEvent.seatingOptionLabel;
                vm.seatingAreaIds = vm.prereservedEvent.seatingAreaIds;

                //vm.customer = vm.prereservedEvent.customer;

                $scope.customerSelected(vm.prereservedEvent.customer);

                vm.daySelected();
                //vm.duration = vm.prereservedEvent.expectedDuration;
                vm.findTables();
            }
        }

        function findProductId(duration) {
            if(vm.settings.shortProductList) {
                for (var i = 0; i < vm.settings.shortProductList.length; i++) {
                    if(vm.settings.shortProductList[i].duration == duration)
                        return vm.settings.shortProductList[i].id;
                }
            }

            if(vm.settings.selectProductList) {
                for (var i = 0; i < vm.settings.selectProductList.length; i++) {
                    if(vm.settings.selectProductList[i].duration == duration)
                        return vm.settings.selectProductList[i].id;
                }
            }
            // product id not found for duration
            return null;
        }

        function findDuration(productId) {
            if(vm.settings.shortProductList) {
                for (var i = 0; i < vm.settings.shortProductList.length; i++) {
                    if(vm.settings.shortProductList[i].id == productId)
                        return vm.settings.shortProductList[i].duration;
                }
            }

            if(vm.settings.selectProductList) {
                for (var i = 0; i < vm.settings.selectProductList.length; i++) {
                    if(vm.settings.selectProductList[i].id == productId)
                        return vm.settings.selectProductList[i].duration;
                }
            }
            // duration not found for product id
            return null;
        }

        vm.tableButtonStyle = function(table) {
            if(vm.seatingOptionId) {
                if(vm.seatingAreaIds) {
                    if(vm.seatingAreaIds.includes(table.areaId)) {
                        return "btn btn-primary btn-outline"
                    }
                }
            }
            return "btn btn-default btn-outline"
        }


    }
})();


