(function() {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('public.online.reservation', {
            parent: 'public.online',
            url: '/reservation/:locationId',
            data: {
                authorities: [],
                bodyClass: " transparent-bg"
            },
            views: {
                'layout@': {
                    templateUrl: 'app/layouts/public_layout.html'
                },
                'content@public.online.reservation': {
                    templateUrl: 'app/public/online/reservation/proxy.html',
                    controller: 'OnlineProxyReservationController',
                    controllerAs: 'vm'
                },
                'navbar@public.online.reservation': {},
                'topnavbar@public.online.reservation': {}
            },
            params: {
            },

            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    //$translatePartialLoader.addPart('settings.tables');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
