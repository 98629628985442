(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('NotificationController', NotificationController);

    NotificationController.$inject = ['$scope', '$state', 'SettingsResource', 'Log', '$uibModal', 'ApplicationContext', 'ApplicationProfile'];

    function NotificationController($scope, $state, SettingsResource, Log, $uibModal, ApplicationContext, ApplicationProfile) {

        var vm = this;
        vm.subscription = ApplicationContext.getLocation().subscription;
        vm.openWindow = ApplicationProfile.openAppCloverPage;

        SettingsResource.getNotificationSettings({
        }, onSuccessGetData, onErrorGetData);

        function onSuccessGetData(data, headers) {

            $scope.data = data;
            console.log('$scope.data', $scope.data);

            Log.debug("NotificationController", "HTTP request succeed. Data=" + data.id);
        }

        function onErrorGetData(error) {
            Log.debug("NotificationController", "HTTP request error." + error);
        }

           // save data
        $scope.saveData = function () {
            console.log('save data', $scope.data);
            SettingsResource.saveNotificationSettings($scope.data, onSuccessGetData, onErrorGetData);
        }

         $scope.availableTimesForReservationReminder = [
            {time: "1 hour", timeForDB: 1},
            {time: "2 hours", timeForDB: 2},
            {time: "3 hours", timeForDB: 3},
            {time: "4 hours", timeForDB: 4},
            {time: "5 hours", timeForDB: 5},
            {time: "6 hours", timeForDB: 6},
            {time: "7 hours", timeForDB: 7},
            {time: "8 hours", timeForDB: 8},
            {time: "12 hours", timeForDB: 12},
            {time: "1 day", timeForDB: 24},
            {time: "2 days", timeForDB: 48}
        ];

        $scope.verificationCondition = [
            {text: "Always send verification email.", value: 1},
            {text: "Send verification email only if customer email is not verified before.", value: 2},
            {text: "Never send verification email.", value: 3}
        ]

        vm.isFree = vm.subscription=='FREE';
        vm.isStandard = vm.subscription=='STANDARD';
        vm.isProfessional = vm.subscription=='PROFESSIONAL';
        $scope.reminderChannels = [
            {text: "E-mail only.", value: "EMAIL"}
        ]
        if(!vm.isFree) {
            $scope.reminderChannels.push({text: "Text message if phone defined, otherwise via e-mail.", value: "SMS"});
        }
        //if(vm.isProfessional) {
        //    $scope.reminderChannels.push({text: "Voice call if phone defined, otherwise via e-mail.", value: "VOICE"});
        //}
        $scope.confirmationChannels = [
            {text: "E-mail only.", value: "EMAIL"}
        ]
        if(!vm.isFree) {
            $scope.confirmationChannels.push({text: "Text message if phone defined, otherwise via e-mail.", value: "SMS"});
        }
        $scope.closeReservationVerificationDialog = function() {
            vm.reservationVerificationPreview.dismiss('cancel');
        }

        vm.reminderSubscribeText = function() {
            if(vm.isFree) {
                return "Upgrade to PAY_AS_YOU_GO, STANDARD to enable text or to PROFESSIONAL subscription to enable voice reminders as well.";
            }
            //else if(!vm.isProfessional) {
            //    return "Upgrade to PROFESSIONAL subscription to enable voice reminders";
            //}
        }

        vm.confirmationSubscribeText = function() {
            if(vm.isFree) {
                return "Upgrade to PAY_AS_YOU_GO, STANDARD to enable text or to PROFESSIONAL subscription to enable voice confirmation messages as well.";
            }
        }

        $scope.showReservationVerificationDialog = function() {
            vm.reservationVerificationPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.verification.preview.html',
                scope: $scope
            });
        }

        $scope.closeReservationConfirmationDialog = function() {
            vm.reservationConfirmationPreview.dismiss('cancel');
        }

        $scope.showReservationConfirmDialog = function() {
            vm.reservationConfirmationPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.confirm.preview.html',
                scope: $scope
            });
        }

        $scope.closeReservationReminderDialog = function() {
            vm.reservationReminderPreview.dismiss('cancel');
        }

        $scope.showReservationReminderDialog = function() {
            vm.reservationReminderPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.reminder.preview.html',
                scope: $scope
            });
        }

        $scope.closeReservationCancelDialog = function() {
            vm.reservationCancelPreview.dismiss('cancel');
        }

        $scope.showReservationCancelDialog = function() {
            vm.reservationCancelPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.cancel.preview.html',
                scope: $scope
            });
        }


        $scope.closeReservationThankYouDialog = function() {
            vm.reservationThankYouPreview.dismiss('cancel');
        }

        $scope.showReservationThankYouDialog = function() {
            vm.reservationThankYouPreview = $uibModal.open({
                templateUrl: 'app/settings/notification/reservation.thankyou.preview.html',
                scope: $scope
            });
        }

        $scope.closeWaitlistAddToDialog = function() {
            vm.waitlistAddToDialog.dismiss('cancel');
        }

        $scope.showWaitlistAddToDialog = function() {
            vm.waitlistAddToDialog = $uibModal.open({
                templateUrl: 'app/settings/notification/waitlist.addto.preview.html',
                scope: $scope
            });
        }

        $scope.closeWaitlistNotifyDialog = function() {
            vm.waitlistNotifyDialog.dismiss('cancel');
        }

        $scope.showWaitlistNotifyDialog = function() {
            vm.waitlistNotifyDialog = $uibModal.open({
                templateUrl: 'app/settings/notification/waitlist.notify.preview.html',
                scope: $scope
            });
        }

        $scope.closeWaitlistThankYouDialog = function() {
            vm.waitlistThankYouDialog.dismiss('cancel');
        }

        $scope.showWaitlistThankYouDialog = function() {
            vm.waitlistThankYouDialog = $uibModal.open({
                templateUrl: 'app/settings/notification/waitlist.thankyou.preview.html',
                scope: $scope
            });
        }

        $scope.getDate = function(offset) {
            var monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            var date = new Date(Date.now() + (offset * 24 * 60 * 60 * 1000));
            return monthNames[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
        }

        $scope.getLocationName = function() {
            Log.debug("Notification", "Location="+ApplicationContext.getLocation());
            return ApplicationContext.getLocation().name;
        }

        $scope.getLocationAddress = function() {

            var fullAddress =
                ((ApplicationContext.getLocation().address1==null)?"":ApplicationContext.getLocation().address1)
                + ((ApplicationContext.getLocation().address2==null)?"":" " + ApplicationContext.getLocation().address2)
                + ((ApplicationContext.getLocation().address3==null)?"":" " + ApplicationContext.getLocation().address3) + ", "
                + ((ApplicationContext.getLocation().zipcode==null)?"":ApplicationContext.getLocation().zipcode) + " "
                + ((ApplicationContext.getLocation().city==null)?"":ApplicationContext.getLocation().city) + ", "
                + ((ApplicationContext.getLocation().countryState==null)?"":ApplicationContext.getLocation().countryState) + ", "
                + ((ApplicationContext.getLocation().country==null)?"":ApplicationContext.getLocation().country);

            return fullAddress;
        }

        $scope.getContactPhoneNumber = function() {
            return (ApplicationContext.getLocation().phone==null?"":ApplicationContext.getLocation().phone);
        }

        $scope.getContactEmail = function() {
            return (ApplicationContext.getLocation().email==null?"":ApplicationContext.getLocation().email);
        }

    }
})();



