(function() {
    'use strict';

    angular
        .module('seatonApp')
        .factory('merchantidInterceptor', merchantidInterceptor);

    merchantidInterceptor.$inject = ['$rootScope', '$q', 'ApplicationContext', 'Notification'];

    function merchantidInterceptor ($rootScope, $q, ApplicationContext, Notification) {
        var service = {
            request: request
        };

        return service;

        function request (config) {

            /* check if "_MERCHANTID_" code is part of url replace  "_MERCHANTID_" with merchant id value frm localStorage*/
            if(config.url.indexOf("_MERCHANTID_")>-1) {
                // console.log("_MERCHANTID_ string found");
                // url require merchantid, check if merchantid is defined in ApplicationContext
                if(ApplicationContext.getLocationID()==undefined) {
                    console.log("Merchantid not defined in ApplicationContext.");
                    //merchantid is not defined in ApplicationContext, stop request and show notification to merchant
                    var canceller = $q.defer();
                    config.timeout = canceller.promise;
                    Notification.error("Error 0x001. Please contact support with error description!","Internal Error");
                } else {
                    // console.log("Replacing _MERCHANTID_ string with merchantid value from ApplicationContext.");
                    // console.log("URL before replacement:"+config.url+":");
                    //merchantid is defined in ApplicationContext, replace "_MERCHANTID" string with value from ApplicationContext
                    config.url = config.url.replace("_MERCHANTID_",ApplicationContext.getLocationID());
                    // console.log("URL after replacement:"+config.url+":");
                }
            }

            return config;
        }
    }
})();
