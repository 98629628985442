(function() {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('settings.application', {
            parent: 'settings',
            url: '/application',
            data: {
                roles: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/settings/application/application.html',
                    controller: 'ApplicationController',
                    controllerAs: 'vm'
                },
                'company@settings.application': {
                    templateUrl: 'app/settings/application/partials/company-view.html'
                },
                'general@settings.application': {
                    templateUrl: 'app/settings/application/partials/general.html'
                },
                'tables@settings.application': {
                    templateUrl: 'app/settings/application/partials/tables.html'
                },
                'notifications@settings.application': {
                    templateUrl: 'app/settings/application/partials/notifications.html'
                },
                'members@settings.application': {
                    templateUrl: 'app/settings/application/partials/members.html'
                },
                'meals@settings.application': {
                    templateUrl: 'app/settings/application/partials/meals.html'
                },
                'widget@settings.application': {
                    templateUrl: 'app/settings/application/partials/widget.html'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    //$translatePartialLoader.addPart('settings.application');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
