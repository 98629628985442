'use strict';

angular.module('seatonApp').directive('includePieChart', ['Reporting', function (Reporting) {
    return {
        restrict: 'EA',
        scope: {
            'piechartdata': '=',

        },
        transclude: true,
        template: '<div ng-show="showChart" google-chart chart="piechartdata" style="width: 95%"></div>' +
        '<div ng-show="!showChart">' +
        '<div class="row">' +
        '<div class="col-lg-10 col-lg-offset-2"><br><br>' +
        '<span class="text-info font-bold">{{piechartdata.options.title}}</span>' +
        '</div>' +
        '</div>' +
        '<div class="row">' +
        '<div class="col-lg-11 text-center">' +
        '<img  src="content\\images\\NoDataPieChart.png">' +
        '</div>' +
        '</div>' +
        '</div>',
        controller: includePieChartCtrl
    };

}]);

includePieChartCtrl.$inject = ['$scope', 'Reporting'];
function includePieChartCtrl($scope, Reporting) {
    //console.log($scope.piechartdata);
    //console.log('piechartdata:' + $scope.piechartdata);
    if ($scope.piechartdata != null && typeof $scope.piechartdata != 'undefined') {
        $scope.showChart = !Reporting.allZeroesInPieChartData($scope.piechartdata.data.rows);
    } else {
        $scope.showChart = false;
    }
};
