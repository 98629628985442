(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('PublicErrorController', PublicErrorController);

    PublicErrorController.$inject = ['$scope', '$state', '$stateParams', 'Log', 'PublicResource'];

    function PublicErrorController($scope, $state, $stateParams, Log, PublicResource) {

        var vm = this;
        vm.code = $stateParams.code;

        if(angular.isString(vm.code)) {
            if(vm.code=="WL001") vm.eventClosed=true;
        } else vm.eventClosed=false;

    }
})();



