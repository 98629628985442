(function() {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('account.login', {
            parent: 'account',
            url: '/login',
            data: {
                authorities: [],
                specialClass: " gray-bg"
            },
            views: {
                'layout@': {
                    templateUrl: 'app/layouts/login_layout.html'
                },
                'content@account.login': {
                    templateUrl: 'app/account/login/login.html',
                    controller: 'LoginController',
                    controllerAs: 'vm'
                },
                'navbar@app': {},
                'topnavbar@account.login': {
                    templateUrl: 'app/layouts/topnavbar/login_topnavbar.html'
                },
                'footer@app': {}
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('account.login');
                    return $translate.refresh();
                }]
            },
            onEnter: ['Auth', function (Auth) {
                Auth.logout(true);
            }]

        });
    }
})();
