(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('tables', {
            abstract: true,
            parent: 'app',
            url: '/tables',
            data: {
                roles: ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_MANAGER']
            }
        });
    }
})();

