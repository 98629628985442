'use strict';

ErrorController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', 'ApplicationContext', 'Auth'];

function ErrorController($rootScope, $scope, $state, $stateParams, ApplicationContext, Auth) {
    var vm = this;

    vm.errorCode = $stateParams.errorCode;
    vm.errorMessage = $stateParams.errorMessage;
    vm.contExec = $stateParams.contExec;
    if(vm.errorMessage!=undefined && (vm.errorMessage.length>350)) {
        vm.errorMessage = vm.errorMessage.substr(0,350);
    }
    vm.merchant_id = ApplicationContext.getMerchantID();
    vm.employee_id = ApplicationContext.getEmployeeID();
    vm.client_id = ApplicationContext.getClientID();
    vm.errorTime = moment.now();

    vm.authType = ApplicationContext.getAuthType();

    vm.cloverEnvUrl= "https://www.clover.com/oauth/authorize?client_id=VW368JQ4FJ6SY";
    if (window.location.href.indexOf("//eu.getseat.net") != -1) vm.cloverEnvUrl= "https://eu.clover.com/oauth/authorize?client_id=N4XXKPTCDBQN6";
    else if (window.location.href.indexOf("//test.getseat.net") != -1) vm.cloverEnvUrl= "https://sandbox.dev.clover.com/oauth/authorize?client_id=7V7HJJKFXWZ1A";

    console.log("AuthType is "+vm.authType+" Is Recoverable error "+vm.contExec+" ErrorCode is "+vm.errorCode
        +" ErrorMessage is "+vm.errorMessage+" ErrorTime is "+vm.errorTime);
    console.log("MerchantId is "+vm.merchantId+" EmployeeId is "+vm.employeeId+" ClientId is "+vm.client_id);

    $scope.isGeneralError = function() {
        return (vm.errorCode != 401);
    };

    $scope.is401Error = function() {
        return (vm.errorCode == 401);
    };

    $scope.canRecover = function() {
        if(vm.contExec) {
            if(vm.errorCode==401 && vm.authType=="CLOVER") {
                return false;
            } else {
                return true;
            }
        }
        return false;
    };

    $scope.recoverButtonText = function() {
        if (vm.errorCode==401 && vm.authType=="ADMIN") {
            return "Login again";
        } else {
            return "Continue using SeatOn application";
        }
    };

    $scope.recoverState = function() {
        if(vm.authType=="CLOVER") { // authType="CLOVER"
            $state.go("home");
        } else { // authType="ADMIN";
            if (vm.errorCode==401) {
                $state.go("account.login");
            } else {
                $state.go("home");
            }
        }
    }

}

angular.module('seatonApp').controller('ErrorController', ErrorController);
