(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('public.online', {
            abstract: true,
            parent: 'public',
            url: '/online',
            data: {
            }
        });
    }
})();

