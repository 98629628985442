(function () {
    'use strict';

    angular
        .module('seatonApp')
        .directive("customerSearchDialog", customerSearchDirective)
        .config(stateConfig);

    customerSearchDirective.$inject = ['$uibModal']
    stateConfig.$inject = ['$stateProvider'];

    function customerSearchDirective($uibModal) {
        return {
            controller: 'CustomerSearchCtrl',
            controllerAs: 'vm',
            scope : {
                customerSearchKeyword: '=', // here you will set two-way data bind with a property from the parent scope
                customerSelectedCallback: '='
            },

            link: function (scope, element, attrs) {

                element.on("click", function (event) { // when button is clicked we show the dialog
                    scope.modalInstance = $uibModal.open({
                        templateUrl: 'app/customer/search/customer.search.html',
                        scope: scope, // this will pass the isolated scope of search-dialog to the angular-ui modal,
                        windowClass: 'high-zindex',
                        size: 'lg',
                        controller: 'CustomerSearchCtrl',
                        controllerAs: 'vm'
                    });
                });
            }
        }
    }

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customer.search', {
                abstract: true,
                parent: 'customers',
                url: '/customer/search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Search customers'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/customer/search/customer.search.html',
                        controller: 'CustomerSearchCtrl',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        // $translatePartialLoader.addPart('tables.management');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
