(function() {
    'use strict';

    angular
        .module('seatonApp')
        .factory('TextUtil', TextUtil);

    TextUtil.$inject = ['ApplicationContext'];

    function TextUtil (ApplicationContext) {

        var service = {
            translate : translate
        };

        return service;


        function translate(text, type) {
            var resourceType = ApplicationContext.getResourceType();
            switch(text) {
                case 'resource': return translateResourceText(resourceType, type);
                case 'capacity': return translateCapacityText(resourceType, type);
                case 'person': return translatePersonText(resourceType, type);
                case 'category': return translateCategoryText(resourceType, type);
                case undefined: return '';
            }
        }

        function translateResourceText(resourceType, type) {
            if (resourceType == 'TABLE') {
                //console.log("type="+type);
                switch (type) {
                    case 's':
                        return "table";
                    case 'sc':
                        return "Table";
                    case 'p':
                        return "tables";
                    case 'pc':
                        return "Tables";
                    case undefined:
                        return "table(s)";
                    case "":
                        return "table(s)";
                    case 'c':
                        return "Table(s)";
                    case 'SC':
                        return "TABLE";
                }
            } else {
                switch (type) {
                    case 's':
                        return "resource";
                    case 'sc':
                        return "Resource";
                    case 'p':
                        return "resources";
                    case 'pc':
                        return "Resources";
                    case '':
                        return "resource(s)";
                    case undefined:
                        return "resource(s)";
                    case 'c':
                        return "Resource(s)";
                    case 'SC':
                        return "RESOURCE";
                }
            }
        }

        function translatePersonText(resourceType, type) {
            if (resourceType == 'TABLE') {
                switch (type) {
                    case 's':
                        return "cover";
                    case 'sc':
                        return "Cover";
                    case 'p':
                        return "covers";
                    case 'pc':
                        return "Covers";
                    case undefined:
                        return "cover(s)";
                    case "":
                        return "cover(s)";
                    case 'c':
                        return "Cover(s)";
                    case 'SC':
                        return "COVER";

                }
            } else {
                switch (type) {
                    case 's':
                        return "person";
                    case 'sc':
                        return "Person";
                    case 'p':
                        return "persons";
                    case 'pc':
                        return "Persons";
                    case '':
                        return "person(s)";
                    case 'c':
                        return "Person(s)";
                    case undefined:
                        return "person(s)";
                    case 'SC':
                        return "PERSON";

                }
            }
        }

        function translateCategoryText(resourceType, type) {
            if (resourceType == 'TABLE') {
                switch (type) {
                    case 's':
                        return "area";
                    case 'sc':
                        return "Area";
                    case 'p':
                        return "areas";
                    case 'pc':
                        return "Areas";
                    case undefined:
                        return "area(s)";
                    case "":
                        return "area(s)";
                    case 'c':
                        return "Area(s)";
                }
            } else {
                switch (type) {
                    case 's':
                        return "category";
                    case 'sc':
                        return "Category";
                    case 'p':
                        return "categories";
                    case 'pc':
                        return "Categories";
                    case '':
                        return "category(ies)";
                    case undefined:
                        return "category(ies)";
                    case 'c':
                        return "Category(ies)";
                }
            }
        }


        function translateCapacityText(resourceType, type) {
            if (resourceType == 'TABLE') {
                switch (type) {
                    case 's':
                        return "seat";
                    case 'sc':
                        return "Seat";
                    case 'p':
                        return "seats";
                    case 'pc':
                        return "Seats";
                    case undefined:
                        return "seat(s)";
                    case "":
                        return "seat(s)";
                    case 'c':
                        return "Seat(s)";
                    case 'n':
                        return "seated";
                    case 'SC':
                        return "SEAT";
                }
            } else {
                if (type == 'c' || type == 'sc' || type == 'pc') {
                    return "Capacity";
                } else if( type == 'SC') {
                    return "CAPACITY";
                } else if(type == 'n') {
                    return "accommodated";
                } else {
                    return "capacity";
                }
            }
        }

    }

})();
