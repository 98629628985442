(function() {
    'use strict';
    angular
        .module('seatonApp')
        .factory('PaymentService', PaymentService);

    PaymentService.$inject = ['$resource'];

    function PaymentService($resource) {
        var resourceUrl = 'api/admin/payment/';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getPublicKey': { method:'GET', url: 'api/admin/payment/:locationId?meteredType=:meteredType&selectedMonth=:selectedMonth', isArray: false }
        });
    }
})();
