(function () {
    'use strict';

    angular
        .module('seatonApp')
        .directive('stran', stran);

    function stran() {
        var directive = {
            restrict: 'EA',
            scope: {
                'stext': '@',
                'type': '@'
            },
            transclude: true,
            controller: TranslateNameController,
            template: ' {{translatedText}}'
        };

        return directive;
    }

    TranslateNameController.$inject = ['$scope', 'TextUtil'];

    function TranslateNameController($scope, TextUtil) {

        $scope.translatedText = TextUtil.translate($scope.stext, $scope.type);

    }


})();
