(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('HomeDashboardController', HomeDashboardController);

    HomeDashboardController.$inject = ['$rootScope', '$scope', 'Principal', '$state', '$q', 'googleChartApiPromise',
        '$filter', 'EventResource', 'CustomerResource', 'Reporting', 'LocationResource', 'ApplicationContext',
        'EmployeeResource', '$document', 'Log'];

    function HomeDashboardController($rootScope, $scope, Principal, $state, $q, googleChartApiPromise, $filter, EventResource,
                                     CustomerResource, Reporting, LocationResource, ApplicationContext, EmployeeResource,
                                     $document, Log) {

        if (ApplicationContext.getLocation().webHome == 0) {
            $state.go("home");
            return;
        }

        var vm = this;
        vm.changeHome = changeHome;
        vm.counters = {};
        vm.numberOfEmployees = -1;
        vm.calcPct = Reporting.calcPct;
        vm.isTableManaged = ApplicationContext.isTableManaged();

        //$scope.showIntro = true;

        if ($rootScope.initialStart == true) {
            $scope.showIntro = true;
            $rootScope.initialStart = false;
            $rootScope.configNotCompleted = true; // always show intro for floor setup when showing intro for home screen
            Log.debug("HomeDashboard", "Show application intro.");
        }

        vm.viewEvent = viewEvent;

        function viewEvent(id) {
            $state.go('tables.eventDetails', {eventId: id});
        }

        //vm.account = Principal.getAccountData();
        $scope.managerAccess = true;
        Principal.identity().then(function (account) {
            vm.account = account;
            if (vm.account.authorities.includes('ROLE_EMPLOYEE') || vm.account.authorities.includes('ROLE_USER')) {
                $scope.managerAccess = false;
            }
        });

        //if (Principal.isAuthenticated()) {
        //    load();
        //}

        $document.ready(load());

        function changeHome() {
            LocationResource.changeHome(
                {},
                function onSuccess(data) {
                    var location = ApplicationContext.getLocation();
                    location.webHome = data.webHome;
                    ApplicationContext.storeLocation(location);
                    $state.go("home");
                    return;
                }
            );
        }

        function load() {

            vm.timeNow = moment();
            vm.lastWeekDay = moment().subtract(1, 'weeks');
            vm.timeDebugNotification = '';

            Log.debug("HomeDashboard", "timeNow=" + vm.timeNow + ", timeNow.format=" + vm.timeNow.format());

            $q.all([
                loadTodayData(),
                loadLastWeekData(),
                loadSettings(),
                loadLocation(),
                getNumberOfCustomers(),
                loadCounters(),
                loadEmployees(),
                googleChartApiPromise
            ]).then(calucalteCustomersStats).then(loadInitialCharts);

        }

        $scope.openingHourNotDefined = function () {
            return ApplicationContext.isBusinessHoursNotDefined();
        }

        function calucalteCustomersStats() {
            var deferred = $q.defer();
            var totalVisits = vm.location.totalReservationShow + vm.location.totalWaitlistShow + vm.location.totalWalkinSeated;
            vm.visitsPct = Reporting.divide(totalVisits - vm.location.totalUnknownCustomerVisit, totalVisits);
            vm.revenuePct = Reporting.divide(vm.location.totalCustomerRevenue - vm.location.totalUnknownCustomerRevenue, vm.location.totalCustomerRevenue);
            //vm.avgSpend = Reporting.divide(vm.location.totalCustomerRevenue, vm.numberOfCustomers);
            //vm.avgSpendVsToday = Reporting.calcPct(vm.todayData.totalDayOccupancy.avgRevenuePerVisit, vm.avgSpend);

            vm.avgSpendPerCover = Reporting.divide(vm.location.totalCustomerRevenue - vm.location.totalUnknownCustomerRevenue, vm.location.totalReservationCover + vm.location.totalWaitlistCover + vm.location.totalWalkinSeatedCover - vm.location.totalUnknownCustomerCover);
            vm.avgSpendPerCoverNonRegulars = Reporting.divide(vm.location.totalUnknownCustomerRevenue, vm.location.totalUnknownCustomerCover);

            deferred.resolve(true);
            return deferred.promise;
        }

        function loadTodayData() {
            return EventResource.getHomeDashboardTodayData({}, function (data) {
                vm.todayData = data;

                if (Math.abs(vm.timeNow - moment(data.dateTime)) > 3590000) {
                    vm.timeDebugNotification = ' POSSIBLE TIME ZONE DIFFERENCE BETWEEN CLIENT AND SERVER!';
                    Log.debug("HomeDashboard", "Time diff(ms): js_time - server_time : " + Math.abs(vm.timeNow - moment(data.dateTime)));
                } else if (Math.abs(vm.timeNow - moment(data.dateTime)) > 10000) {
                    vm.timeDebugNotification = ' TIME DIFFERENCE BETWEEN CLIENT AND SERVER!';
                    Log.debug("HomeDashboard", "Time diff(ms): js_time - server_time : " + Math.abs(vm.timeNow - moment(data.dateTime)));
                }

            }).$promise;
        }

        function loadCounters() {
            //TODO 5 check ApplicationContext.getEmployeeID() has value "ADMIN%20ACCESS"
            Log.debug("HomeDashboard", "Value of ApplicationContext.getEmployeeID():" + ApplicationContext.getEmployeeID())
            return EmployeeResource.sinceLastLogin({id: -1}, function (data) {
                vm.counters = data;
            }).$promise;
        }

        function getNumberOfCustomers() {
            return CustomerResource.numberOfCustomers({}, function (data) {
                vm.numberOfCustomers = data.value;
            }).$promise;
        }

        function loadLastWeekData() {
            return EventResource.getHomeDashboardTodayVsData({
                selectedDate: vm.lastWeekDay.format('YYYY-MM-DD')
            }, function (data) {
                vm.lastWeekDayData = data;
            }).$promise;
        }

        function loadSettings() {
            return LocationResource.getLocationSettings({}, function (settings) {
                vm.settings = settings;
                vm.numberOfAreas = vm.settings.areas.length;
                /*vm.numberOfTables = 0;
                 _.each(vm.settings.areas, function (area) {
                 _.each(area.resources, function (item) { if (item.type == "TABLE") vm.numberOfTables++; });
                 }); */
            }).$promise;
        }

        function loadLocation() {
            return LocationResource.getLocation({}, function (location) {
                vm.location = location;
            }).$promise;
        }

        function loadEmployees() {
            //filter for location
            return EmployeeResource.getAllEmployees({}, function (data) {
                vm.employees = data;
                vm.numberOfEmployees = 0;
                _.each(vm.employees, function (item) {
                    if (item.active) vm.numberOfEmployees++;
                });
            }).$promise;
        }

        function loadInitialCharts() {
            var deferred = $q.defer();

            //revenue by hours
            var dataHours = new google.visualization.DataTable();
            dataHours.addColumn('string', 'Time of Day');
            dataHours.addColumn('number', 'Today', 'color: #1ABC9C');
            dataHours.addColumn({type: 'string', role: 'annotation'});
            dataHours.addColumn('number', 'Last ' + vm.timeNow.format('dddd'), 'color: #336699');
            //dataHours.addColumn('number', 'Last ' + vm.timeNow.format('dddd') + '--' + vm.timeNow.format('dddd h:mm:ss a'), 'color: #336699');

            var todayItem = null;
            var lastWeekDayItem = null;
            for (var i = 0; i < vm.todayData.revenueByHour.length; i++) {
                todayItem = vm.todayData.revenueByHour[i];
                lastWeekDayItem = vm.lastWeekDayData.revenueByHour[i];
                dataHours.addRow([
                    todayItem.hourOfDayFmt,
                    {
                        v: todayItem.revenue,
                        f: $filter('currency')(todayItem.revenue)
                    },
                    $filter('currency')(todayItem.revenue),
                    lastWeekDayItem.revenue]);
            }

            var optionsHours = {
                //width: 900,
                height: 350,
                vAxis: {
                    title: 'Revenue in ' + ApplicationContext.getCurrencySym(),
                    textPosition: 'none',
                    gridlines: {count: 13},
                    viewWindow: {min: 0},
                    titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true},
                    minValue: 0
                },
                hAxis: {title: 'Time of Day', titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}},

                annotations: {
                    alwaysOutside: true,
                    textStyle: {
                        fontSize: 10,
                        bold: 'true',
                        color: '#000',
                        auraColor: 'none'
                    }
                },
                chartArea: {width: '90%', height: '70%'},
                seriesType: 'bars',
                series: {1: {type: 'line'}},
                formatters: {},
                colors: ['#1ABC9C', '#CC3333'],
                legend: {position: 'top', alignment: 'end'}
            };

            vm.chartComboObjectHours = {
                type: "ComboChart",
                options: optionsHours,
                data: dataHours
            };

            deferred.resolve(true);
            return deferred.promise;
        }

        $scope.IntroOptions = {
            steps: [
                {
                    intro: "<h3 align=\"center\">Welcome to SeatOn Reservations</h3>" +
                        "<p>" +
                        "<strong>Dear Customer</strong>,<br/><br/>" +
                        "Thank you for choosing our solution to assist you in running your business. <br/><br/>" +
                        "Any time when you need support or have any questions related to SeatOn, please do not hesitate to contact us via email " +
                        "<a href=\"mailto:support@seaton.site\"/>support@seaton.site</a> or visit SeatOn customer support portal at " +
                        "<a href=\"http://support.seaton.site\" target=\"_blank\"/>http://support.seaton.site</a>" +
                        "</p>" +
                        "<p>" +
                        "As a SeatOn user you will use one of the following applications:<br/>" +
                        "<ul>" +
                        "<li><strong>SeatOn Clover</strong> - intended for use by employees on Clover device</li>" +
                        "<li><strong>SeatOn Web</strong> - intended for use by management or employees using any modern internet browser</li>" +
                        "</ul>" +
                        "</p>" +
                        "<p>" +
                        "SeatOn Web consists of three main modules:" +
                        "<ul>" +
                        "<li> Resource management" +
                        "<li> Customer relationship management" +
                        "<li> Reports and analytics" +
                        "</ul>" +
                        "</p>" +
                        "<p><small><font color=\"red\">Press button \"Next\" or right arrow key on your keyboard to continue with the introduction guide to SeatOn.</font></small></p>" +
                        "<p>" +
                        "<code>SeatOn Reservations, All rights reserved &copy; 2016-2021</code>" +
                        "</p>"
                },
                {
                    element: '#menu',
                    intro: "<h3 align=\"center\">SeatOn Web application menu</h3>" +
                        "<p>" +
                        "Menu is organized to match main SeatOn Web components including resource management, customer relationship management, reports and settings.<br/><br/>" +
                        "Top level menu includes following items:" +
                        "<ul>" +
                        "<li><i>Home</i> - application entry point with current performance dashboard</li>" +
                        "<li><i>Today reservation</i> - upcoming reservation scheduled for today</li>" +
                        "<li><i>Open pre-reservation</i> - pending reservation which requires your approval / resource assignment.</li>" +
                        "<li><i>Wait list</i> - waiting list management.</li>" +
                        "<li><i>Walk-in</i> - walk-in management.</li>" +
                        "<li><i>Active visits</i> - started visits list.</li>" +
                        "<li><i>Reservation center</i> - management of open reservations.</li>" +
                        "<li><i>Search</i> - search all event on system.</li>" +
                        "<li><i>Customers</i> - all details about your customers.</li>" +
                        "<li><i>Reports</i> - various reports to give you insight in your business.</li>" +
                        "<li><i>Settings</i> - customize SeatOn to fit your needs.</li>" +
                        "</ul>" +
                        "</p>" +
                        "<p>" +
                        "<code>SeatOn Reservations, All rights reserved &copy; 2016-2021</code>" +
                        "</p>",
                    position: 'auto'
                },
                {
                    element: document.querySelectorAll('#performance')[0],
                    intro: "<h3 align=\"center\">Today's performance</h3>" +
                        "<p>" +
                        "In this section of Home dashboard page, you can see <strong>current performance on an hourly basis</strong>. " +
                        "Each time you refresh the page, performance graph will be updated with the latest data. " +
                        "This is the most suitable way to track current performance." +
                        "</p>" +
                        "<p>" +
                        "Performance graph displays total revenue on an hourly basis and compares it to the same day from the previous week.<br/>" +
                        "</p>" +
                        "<p>" +
                        "<code>SeatOn Reservations,, All rights reserved &copy; 2016-2021</code>" +
                        "</p>"
                },
                {
                    element: '#lastlogin',
                    intro: "<h3 align=\"center\">Since your last login</h3>" +
                        "<p>In this section of Home dashboard page, you can see <strong>changes in the system since the last time you used SeatOn Web application</strong>." +
                        " Following changes are displayed:" +
                        "<ul>" +
                        "<li>Number of new customers registered in the system</li>" +
                        "<li>Number of new customer feedbacks received</li>" +
                        "<li>Number of new reservations created in the system</li>" +
                        "</ul>" +
                        "</p>" +
                        "<p>" +
                        "<code>SeatOn Next Generation Table Management System, All rights reserved &copy; 2016-2021</code>" +
                        "</p>",
                    position: 'left'
                },
                {
                    element: '#arrivals',
                    intro: "<h3 align=\"center\">Today's expected arrivals</h3>" +
                        "<p>In this section of Home dashboard page, you can see the list of today's expected arrivals, including <strong>today's reservations</strong> " +
                        "and <strong>wait-listed arrivals</strong>. Clicking on the button \"All expected arrivals\" will take you to the Table management " +
                        "page where you can see all the details regarding expected arrivals." +
                        "</p>" +
                        "<p>" +
                        "<code>SeatOn Reservations,, All rights reserved &copy; 2016-2021</code>" +
                        "</p>",
                    position: 'left'
                },
                {
                    element: '#modules',
                    intro: "<h3 align=\"center\">SeatOn modules</h3>" +
                    "<p>SeatOn consists of following moduels:" +
                    "<ul>" +
                    "<li>Resource managegment - module which take care of your resources</li>" +
                    "<li>Customer management - manage your customer data</li>" +
                    "<li>Reporting - various reports which helps you to analyse your business performance</li>" +
                    "<li>Settings - rich customization options to set up SeatOn to match your business needs</li>" +
                    "</ul>" +
                    "</p>" +
                    "<p>" +
                    "Do not forget to:" +
                    "<ul>" +
                    "<li>Visit SeatOn support pages <a href='http://support.seaton.site' target='_blank'>support.seaton.site</a> to find out more details</li>" +
                    "<li>Send us your feedback on SeatOn application or  email <a href='mailto:support@seaton.site'>support@seaton.site</a></li>" +
                    "</ul>" +
                    "<p class='text-danger font-bold'>" +
                    "Start with the customization to set up SeatOn to better match your business needs.<br/>" +
                    "Follow this link to continue - <a href='/#/settings/floor'>start customization.</a></strong></font>" +
                    "</p>" +
                    "<code>SeatOn Reservations, All rights reserved &copy; since 2018</code>" +
                    "</p>",
                    position: 'auto'
                }

                /*
                {
                    element: '#table',
                    intro: "<h3 align=\"center\">Table management</h3>" +
                        "<p>Table management module of the application manages whole procedure from <strong>customer arrival</strong> to <strong>customer walkout</strong>. " +
                        "It handles all type of your guests' arrivals, including walk-in, reservation, waitlist and walkout management. " +
                        "</p>" +
                        "<p>" +
                        "In the table management module, you can <strong>create reservations</strong>, <strong>handle walk-in guests</strong>," +
                        " as well as <strong>add customers to the waiting list</strong>." +
                        " and finally, <strong>manage customer walkouts</strong>. " +
                        "</p>" +
                        "<p> In Table management section on Home dashboard page you have a quick insight into the status of restaurant tables: " +
                        "<ul>" +
                        "<li>Number of currently available tables</li>" +
                        "<li>Number of currently occupied tables and related covers</li>" +
                        "<li>Number of expected arrivals and related covers</li>" +
                        "<li>Number of wait list events and related covers</li>" +
                        "</ul>" +
                        "</p>" +
                        "<p>" +
                        "<code>SeatOn Reservations,, All rights reserved &copy; 2016-2021</code>" +
                        "</p>",
                    position: 'right'
                },
                {
                    element: '#customer',
                    intro: "<h3 align=\"center\">Customer relationship management</h3>" +
                        "<p>Customer relationship management module <strong>records customer information</strong>, <strong>feedback</strong>, <strong>visiting habits</strong>, " +
                        "and <strong>spending behavior</strong> to the customer database. By analyzing customer data, it is possible to trace " +
                        "top customers and loyal guests, as well as check for frequent no-show customers, and eventually restrict " +
                        "reservations for them. " +
                        "</p>" +
                        "<p> In Customer management section on Home dashboard page you have a quick insight into some key indicators " +
                        "related to customers:" +
                        "<ul>" +
                        "<li>Number of all registered customers</li>" +
                        "<li>Percentage of visits made by registered customers</li>" +
                        "<li>Percentage of revenue generated by registered customers</li>" +
                        "<li>Average spend per person by registered customers</li>" +
                        "</ul>" +
                        "</p>" +
                        "<p>" +
                        "<code>SeatOn Next Generation Table Management System, All rights reserved &copy; 2016-2021</code>" +
                        "</p>",

                    position: 'right'
                },
                {
                    element: '#report',
                    intro: "<h3 align=\"center\">Reports and analytics</h3>" +
                        "<p>Reporting module contains a number of different reports with extensive analytics, such as <strong>visit statistics</strong>," +
                        " <strong>aggregated spending trend</strong>, <strong>occupancy rate</strong> and <strong>employee performance</strong>. It is intended for users who want to " +
                        "improve and grow their business, recognize new opportunities and maximize profit." +
                        "</p>" +
                        "<p>" +
                        "<code>SeatOn Reservations,, All rights reserved &copy; 2016-2021</code>" +
                        "</p>",
                    position: 'left'
                },
                {
                    element: '#settings',
                    intro: "<h3 align=\"center\">SeatOn settings</h3>" +
                        "<p>Through Settings pages, you can <strong>customize SeatOn application to match your restaurant's needs</strong>. " +
                        "Here you can set up your floor, including areas and tables definitions, notification messages, customer membership," +
                        " check your employees' data, set up employees' restrictions and track their performance." +
                        "</p>" +
                        "<p>" +
                        "Do not forget to:" +
                        "<ul>" +
                        "<li>Visit SeatOn support pages <a href='http://support.seaton.site' target='_blank'>support.seaton.site</a> to find out more details</li>" +
                        "<li>Send us your feedback on SeatOn application or  email <a href='mailto:support@seaton.site'>support@seaton.site</a></li>" +
                        "</ul>" +
                        "<p>" +
                        "<br/><font color=\"red\"><strong>Now you are ready to use SeatOn application. <br/>" +
                        "Let's start with the set up of your restaurant floor first by following this link -> <a href='/#/settings/floor'>Floor setup.</a></strong></font>" +
                        "</p>" +
                        "<p>" +
                        "<small>Note: You will not be able to use SeatOn on Clover devices before you set up your restaurant floor.</small>" +
                        "</p>" +
                        "<p>" +
                        "<code>SeatOn Reservations,, All rights reserved &copy; 2016-2021</code>" +
                        "</p>",
                    position: 'left'
                }
                */

            ],
            showStepNumbers: true,
            showBullets: true,
            exitOnOverlayClick: false,
            exitOnEsc: false,
            nextLabel: '<strong>Next</strong>',
            prevLabel: '<strong>Previous</strong>',
            skipLabel: 'Exit',
            doneLabel: 'Done'
        };

    }
})();



