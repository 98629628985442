(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('WidgetController', WidgetController);

    WidgetController.$inject = ['$scope', '$state', 'SettingsResource', 'Log'];

    function WidgetController($scope, $state, SettingsResource, Log) {

        var vm = this;

        SettingsResource.getAreasAndTables({
        }, onSuccess, onError);

        /*SettingsResource.createArea({
            locationId: 1,
            name: 'Test',
            vip: false

        }, onSuccess, onError);*/

        /*SettingsResource.updateArea({
            id: 1013,
            locationId: 1,
            name: 'Test123',
            vip: true

         }, onSuccess, onError);*/

        /*SettingsResource.deleteArea({
            id: 2
        }, onSuccess, onError);*/


        function onSuccess(data, headers) {
            Log.debug("WidgetController", "HTTP request succeed. Data=" + data.id);
        }

        function onError(error) {
            Log.debug("WidgetController", "HTTP request error." + error);
        }

    }
})();



