angular.module('seatonApp')
    .directive('phoneList', phoneListCtrl);

function phoneListCtrl() {
    'use strict';
    return {
        scope: {
            items: '=ngModel'
        },
        restrict: 'E',
        transclude: true,
        require: ['ngModel', '^form'],
        templateUrl: 'app/customer/create/partials/phone_list.html',
        link: function (scope, elem, attrs, ctrls) {
            scope.ngModelCtrl = ctrls[0];
            scope.ngFormCtrl = ctrls[1];
        },
        controller: phone_Ctrl
    };
}

phone_Ctrl.$inject = ['$scope', '$document', 'ApplicationContext'];

function phone_Ctrl($scope, $document, ApplicationContext) {

    $scope.newItem = {};

    $scope.phoneRegion = ApplicationContext.getPhoneRegion();
    $scope.phonePrefix = ApplicationContext.getPhoneCountryPrefix();

    $scope.reformatPhone = function () {
        $scope.numInput = $document.find('#phoneNumber');
        if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
            var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
            if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                $scope.numInput.intlTelInput('setNumber', currentText); // will autoformat because of formatOnDisplay=true
            }
        }
    }

    /*

    $document.ready(
        function () {
            $scope.numInput = $document.find('#phoneNumber');

            $scope.numInput.intlTelInput({
                utilsScript: "bower_components/intl-tel-input/build/js/utils.js",
                initialCountry: ApplicationContext.getPhoneRegion(),
                preferredCountries: [ApplicationContext.getPhoneRegion()],
                nationalMode: false,
                formatOnDisplay: true // SET THIS!!!
            });

            $scope.numInput.on("keyup change", resetIntlTelInput);

            function resetIntlTelInput() {
                if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
                    var currentText = $scope.numInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.E164);
                    if (typeof currentText === 'string') { // sometimes the currentText is an object :)
                        $scope.numInput.intlTelInput('setNumber', currentText); // will autoformat because of formatOnDisplay=true
                    }
                }
            }
        }
    );

    $scope.myCustomValidationFunction = function (phoneNumber) {

        console.log(phoneNumber);

        return false;
    }
    */


    $scope.addItem = function () {
        var propertyName = 'phoneNumber';
        if (!$scope.newItem[propertyName] || $scope.newItem[propertyName].trim() == '') {
            $scope.newItem = {};
            return;
        }

        var index = $scope.newItem.index;
        if (!_.isUndefined(index)) {
            delete $scope.newItem.index;
            $scope.items[index] = $scope.newItem;
        } else {
            if ($scope.newItem[propertyName]) {
                $scope.items.push($scope.newItem);
            }
        }
        $scope.initItem();
        validate();
    };

    $scope.initItem = function () {
        $scope.newItem = {};
        $scope.phoneType = 1;
    };

    $scope.editItem = function (index) {
        $scope.newItem = _.clone($scope.items[index]);
        $scope.newItem.index = index;
    };

    $scope.deleteItem = function (index) {
        $scope.items.splice(index, 1);
        validate();
    };

    $scope.makePrimary = function (index) {
        _.each($scope.items, function (phone) {
            phone.primaryPhone = false;
        });
        $scope.items[index].primaryPhone = true;
        validate();
    };

    function validate() {
        $scope.ngModelCtrl.$setDirty();
        $scope.ngModelCtrl.$validate();
    }

}
