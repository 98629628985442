'use strict';

angular.module('seatonApp')
    .factory('CustomerSearch', customerSearch)
    .factory('CustomerResource', customerResource)
    .factory('CalcCustomerStats', calcCustomerStats)
    .factory('CustomerStats', ['CalcCustomerStats', customerStats]);

customerResource.$inject = ['$resource', 'ApplicationContext'];
function customerResource($resource, ApplicationContext) {
    return $resource('api/v1/merchants/_MERCHANTID_/customers/:id', {
        //merchantId: ApplicationContext.getLocationID()
    }, {
        'lists': {
            method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/customers/lists',
            isArray: true
        },
        'get': {
            method: 'GET',
            transformResponse: function (data) {
                data = angular.fromJson(data);
                return data;
            }
        },
        'updateCustomer': { method:'PUT', url: 'api/v1/merchants/_MERCHANTID_/customers/update' },
        'updateAttribute': { method:'GET', url: 'api/v1/merchants/_MERCHANTID_/customers/updateAttribute/:id' },
        'getProfile': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/customers/:id/statistics/profileVisitStats'},
        'getSpendTrend': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/customers/:id/statistics/spendTrend'},
        'getAggregateByHourData': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/customers/:id/statistics/aggregateSpendByHour'
        },
        'getAggregateByWeekdayData': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/customers/:id/statistics/aggregateSpendByWeekday'
        },
        'getDashboardGeneralData': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/customers/statistics/dashboard/general'
        },
        'getDashboardGrowthTrendData': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/customers/statistics/dashboard/growthTrend'
        },
        'getDashboardMemberTypeData': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/customers/statistics/dashboard/memberType'
        },
        'numberOfCustomers': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/customers/statistics/numberOfCustomers'
        }
    });
}

customerSearch.$inject = ['$resource'];
function customerSearch($resource) {

    return $resource('api/v1/merchants/_MERCHANTID_/customers/', {}, {
        'searchCustomers': {
            method: 'GET',
            url: 'api/v1/merchants/_MERCHANTID_/customers/search?keyword=:keyword&page=:page&size=:size',
            isArray: false
        }
    });
}

function calcCustomerStats() {
    function calculateCustomerPcts(customer) {
        var totalEvents = customer.totalReservation + customer.totalWaitlist + customer.totalWalkinSeated;
        if (totalEvents > 0) {
            customer.reservationsPct = customer.totalReservation / totalEvents;
            customer.noShowPct = customer.totalNoShow / totalEvents;
            customer.canceledPct = customer.totalCanceled / totalEvents;
            customer.walkinSeatedPct = customer.totalWalkinSeated / totalEvents;
            customer.waitlistPct = customer.totalWaitlist / totalEvents;
        } else {
            customer.reservationsPct = customer.noShowPct = customer.canceledPct = customer.walkinSeatedPct = customer.waitlistPct = 0;
        }

        return customer;
    }

    return {
        calculateCustomerPcts: calculateCustomerPcts
    };
}

function customerStats(CalcCustomerStats) {
    function calculatePcts(customers) {
        return _.map(customers, CalcCustomerStats.calculateCustomerPcts);
    }

    return {
        calculatePcts: calculatePcts
    };
}
