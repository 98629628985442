(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$translate', 'JhiLanguageService', 'translationHandler', '$window',
        'Auth', 'Principal', 'VERSION', 'ApplicationContext', 'AuthServerProvider', 'Log'];

    function stateHandler($rootScope, $state, $sessionStorage, $translate, JhiLanguageService, translationHandler, $window,
                          Auth, Principal, VERSION, ApplicationContext, AuthServerProvider, Log) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState, fromParams) {

                Log.debug("StateHandler", "State transition from state:" + fromState.name + ": to state:" + toState.name + ":");
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;
                $rootScope.fromStateParams = fromParams;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                // check if already on login screen and define backgound
                //var isGrayBg = toState.name === "account.login" || toState.name === "account.locations" ||
                //    toState.name === "account.clover" || toState.name === "account.error" || toState.name === "account.clover.logout";

                var isGrayBg = toState.name.startsWith("account") || toState.name.startsWith("public.");
                if (isGrayBg) {
                    if (toState.name.startsWith("public.online")) {
                        $rootScope.bodyClass = 'transparent-bg';
                    } else {
                        $rootScope.bodyClass = 'white-bg';
                    }
                    if (toState.name === "account.clover") {
                        // each time on clover login clear stored data in local storage if client didn't logout
                        // new token will be generated from server side on each access
                        Log.debug("StateHandler", "Clover access. Clear local storage.");
                        AuthServerProvider.logout();
                        ApplicationContext.clear();
                    }
                    return; // no need to redirect
                } else {
                    $rootScope.bodyClass = '';
                }

                if (toState.name === "account.error" || toState.name === "account.clover.logout") {
                    Log.debug("StateHandler", "Proceed to error/logout page");
                } else {

                    Principal.identity().then(function (account) {
                        if (!Principal.isAuthenticated() || !ApplicationContext.getLocation()) {
                            //if (!Principal.isAuthenticated()) {
                            event.preventDefault(); // stop current execution
                            Log.debug("StateHandler", "NotAuthenticated: Preventing transition from:" + fromState.name + ": to:" + toState.name
                                + ":. Preventing transition.");

                            $rootScope.bodyClass = 'white-bg';

                            var params = {'errorCode': 401, 'errorMessage': "Not authenticated.", 'contExec': true};
                            Log.debug("StateHandler", "Params:" + params);
                            $state.go("account.error", params);

                        } else {
                            if (fromState.name == '') {
                                //moment.tz.setDefault(ApplicationContext.getLocation().timezone);
                                ApplicationContext.setLanguageAndLocale();
                                Log.debug("StateHanlder", "Timezone set  on page reload to: " + ApplicationContext.getLocation().timezone);
                            }
                        }
                    });
                }

                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.use(language);
                });
            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                var titleKey = 'global.title';

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                translationHandler.updateTitle(titleKey);
            });

            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
                    stateChangeStart();
                }
                if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
                    stateChangeSuccess();
                }
            });
        }
    }
})();
