(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('FeedbackSearchController', FeedbackSearchController);

    FeedbackSearchController.$inject = ['$scope', '$state', '$stateParams', '$document', 'FeedbackResource'];

    function FeedbackSearchController($scope, $state, $stateParams, $document, FeedbackResource) {
        var vm = this;
        vm.moment = moment;
        vm.employeeLogin = $stateParams.employeeLogin;
        vm.employees = $stateParams.employees;
        vm.employeeName = $stateParams.employeeName;

        // paging data
        vm.currentPage = 1;
        vm.itemsPerPage = 10;
        vm.totalItems = 0;
        vm.searchFeedbacks = searchFeedbacks;
        vm.reset = reset;

        vm.filterFrom = null;
        vm.filterTo = null;

        vm.slider_stars = $document.find('#slider_stars');
        vm.slider_stars.ionRangeSlider({
            type: 'double',
            grid: true,
            values: [
                "zero", "one",
                "two", "three",
                "four", "five"
            ], onChange: function (data) {
                console.log(data);
                vm.minRating = data.from;
                vm.maxRating = data.to;
            }
        });

        // initialize date picker
        $document.ready(
            function () {
                vm.pickerDates = $document.find('input[name="pickerDates"]');
                vm.pickerDates.daterangepicker(
                    {
                        showDropdowns: true,
                        alwaysShowCalendars: true,
                        ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'Last 12 Months': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                        },
                        locale: {format: 'MMM D, YYYY'}
                    },
                    function (start, end, label) {
                        vm.filterFrom = moment(start).toISOString();
                        vm.filterTo = moment(end).toISOString();
                    }
                );
                vm.pickerDates.val('');
            }
        );

        function reset() {
            vm.slider_stars.data("ionRangeSlider").update({
                from: 0,
                to: 5
            });
            vm.minRating = null;
            vm.maxRating = null;
            vm.pickerDates.val('');
            vm.filterFrom = null;
            vm.filterTo = null;
        }

        vm.openPicker = function () {
            vm.pickerDates.focus();
        }

        vm.pageChanged = function () {
            vm.searchFeedbacks();
        };


        load();

        function load() {
            vm.searchFeedbacks();
        }

        function searchFeedbacks() {
            console.log('searchFeedbacks() vm:');
            console.log(vm);

            // mandatory params
            var params = {
                page: vm.currentPage - 1,
                size: vm.itemsPerPage
            };

            // extend with date range if it's selected
            if (vm.filterFrom != null && vm.filterTO != null) {
                params.startDate = vm.filterFrom;
                params.endDate = vm.filterTo;
            }

            FeedbackResource.searchFeedbacks(
                {
                    startDate: vm.filterFrom,
                    endDate: vm.filterTo,
                    minRating: vm.minRating,
                    maxRating: vm.maxRating,
                    employeeLogin: vm.employeeLogin,
                    page: vm.currentPage - 1,
                    size: vm.itemsPerPage
                },
                function (data) {
                    vm.feedbacks = data.feedbacks.content;
                    vm.totalItems = data.feedbacks.totalElements;
                    vm.dto = data;
                }
            );
        }
    }
})();
