(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('Utils', Utils);

    Utils.$inject = ['ApplicationContext'];

    /** Contains various utility methods used in multiple controllers. */
    function Utils(ApplicationContext) {

        // 'event type'  -->  ['text displayed to the user', 'CSS background for this type']
        var eventTypes = {
            'RESERVATION': ['Reservation', 'text-navy', 'navy-bg'],
            'WALK_IN_SEATED': ['Walk in (seated)', 'text-info', 'lazur-bg'],
            'WALK_IN_WAITLISTED': ['Walk in (waitlisted)', 'text-warning', 'yellow-bg']
        }

        /** Merges STATE and STATUS into a single, user-friendly status */
        function prettyStatus(state, status) {
            if (status == 'WAITLISTED') {
                return 'Waiting to be accomodated';
            } else if (status == 'NOTIFIED' || status == 'RESERVED' || status == 'CONFIRMED') {
                return 'Expected arrival';
            } else if (status == 'SHOW' && state == 'OPEN') {
                return 'Active';
            } else if (status == 'SHOW' && state == 'CLOSED') {
                return 'Closed (show)';
            } else if (status == 'NO_SHOW') {
                return 'Closed (no show)';
            } else if (status == 'CANCELLED') {
                return 'Closed (cancelled)';
            } else if (status == 'PRERESERVED') {
                return 'Pre-reserved.';
            } else {
                // this should never happen
                return state + "-" + status;
            }
        }

        /** Merges STATE and STATUS into a single, user-friendly status */
        function prettyStatusEventDetails(state, status) {
            if (status == 'WAITLISTED') {
                return 'Guest waiting to be accommodated.';
            } else if (status == 'NOTIFIED' || status == 'RESERVED' || status == 'CONFIRMED') {
                return 'Guest arrival expected.';
            } else if (status == 'SHOW' && state == 'OPEN') {
                return 'Guest accommodated.';
            } else if (status == 'SHOW' && state == 'CLOSED') {
                return 'Guest accommodated and event closed.';
            } else if (status == 'NO_SHOW') {
                return 'Guest did not arrive (No-Show).';
            } else if (status == 'CANCELLED') {
                return 'Event cancelled';
            } else if (status == 'PRERESERVED') {
                return 'Guest requested reservation, table not assigned';

            } else {
                // this should never happen
                return state + "-" + status;
            }
        }


        /** Returns the CSS background to be used when displaying a STATE/STATUS combination. */
        function prettyStatusBkg(state, status) {
            if (status == 'WAITLISTED') {
                return 'label-info';
            } else if (status == 'NOTIFIED' || status == 'RESERVED' || status == 'CONFIRMED') {
                return 'label-success';
            } else if (status == 'SHOW' && state == 'OPEN') {
                return 'label-primary';
            } else if (status == 'SHOW' && state == 'CLOSED') {
                return 'label-default';
            } else if (status == 'NO_SHOW') {
                return 'label-danger';
            } else if (status == 'CANCELLED') {
                return 'label-warning';
            } else {
                // this should never happen
                return 'label-default';
            }
        }

        function buttonForAction(action) {
            if(action== 'Show') {
                return "btn-success";
            } else if(action == 'No show' || action == 'Cancel' || action=='Close') {
                return "btn-danger";
            } else if(action == 'Move back to arrivals') {
                return "btn-info";
            } else if(action == 'Re-open') {
                return "btn-info";
            } else if(action == 'Notify again') {
                return "btn-success";
            } else {
                return "btn-success"
            }
        }

        function formatEventType(type) {
            return type ? eventTypes[type][0] : '';
        }

        getEventTypeTextStyle
        function getEventTypeTextStyle(type) {
            return type ? eventTypes[type][1] : '';
        }

        function getEventTypeBkg(type) {
            console.log("type:"+type);
            return type ? eventTypes[type][2] : '';
        }

        /** Formats the given amount to dollars. */
        function formatDollar(num) {
            if (num) {
                var p = num.toFixed(2).split(".");
                return "$" + p[0].split("").reverse().reduce(
                        function (acc, num, i, orig) {
                            return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
                        }, "") + "." + p[1];
            }
            return '';
        }


        function formatMinutes(minutes) {
            var formatted = "";
            if (minutes >= 60) {
                formatted = Math.floor(minutes / 60) + " hr";
            }
            if (minutes % 60 !== 0) {
                if (minutes >= 60) {
                    formatted += " ";
                }
                formatted += (minutes % 60) + " min";
            }

            if(formatted == "") {
                return "0 min";
            }
            return formatted;
        }

        /** Takes a full customer object with all attributes and returns a new object
            containing only basic customer data (name, email, phone and note) */
        function getBasicCustomerData(cust) {

            return {
                id: cust.id,
                firstName: cust.firstName,
                lastName: cust.lastName,
                primaryPhone: cust.primaryPhone,
                primaryEmail: cust.primaryEmail,
                note: cust.note
            };
        }

        //TODO stipo, branimir - check how to format address
        function formatAddress(addr) {
            var result = '';
            result += (addr.address1 ? addr.address1 : '');
            result += (addr.address2 ? ' ,' + addr.address2 : '');
            result += (addr.address3 ? ' ,' + addr.address3 : '');
            result += (addr.city ? ' ,' + addr.city : '');
            result += (addr.countryState ? ' ,' + addr.countryState : '');
            result += (addr.zipcode ? ' ,' + addr.zipcode : '');
            result += (addr.country ? ' ,' + addr.country : '');
            return result;
        }

        function formatAmount(number) {
            var locale = ApplicationContext.getLocale();
            var formatter = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: 'USD'
            });

            return formatter.format(number);
        }

        return {
            formatMinutes: formatMinutes,
            formatEventType: formatEventType,
            getEventTypeBkg: getEventTypeBkg,
            formatDollar: formatDollar,
            prettyStatus: prettyStatus,
            prettyStatusBkg: prettyStatusBkg,
            formatAddress: formatAddress,
            getBasicCustomerData : getBasicCustomerData,
            buttonForAction: buttonForAction,
            prettyStatusEventDetails: prettyStatusEventDetails,
            getEventTypeTextStyle: getEventTypeTextStyle,
            formatAmount: formatAmount
        };
    }
})();




