/**
 * Created by branimir on 20.5.2017..
 */
(function () {
    'use strict';
    angular.module('seatonApp')
        .filter('highlight', function () {
            return function (text, phrase, style) {
                //console.log('highlight ' + text + phrase + style);
                return phrase
                    ? text.replace(new RegExp('(' + phrase + ')', 'gi'), '<span class=' + style + '>$1</span>')
                    : text;
            };
        });
})();


