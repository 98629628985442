'use strict';

ReservationOverviewController.$inject = ['$state', '$stateParams', '$location', '$scope', '$q', '$filter', '$document',
    'Tables', 'Statistics', 'googleChartApiPromise', 'Reporting', 'ApplicationContext', '$uibModal', 'ChannelService',
    'TextUtil', 'EventResource'];

function ReservationOverviewController($state, $stateParams, $location, $scope, $q, $filter, $document,
                                       Tables, Statistics, googleChartApiPromise, Reporting, ApplicationContext, $uibModal, ChannelService, TextUtil, EventResource) {

    var vm = this;
    vm.coverTrans = TextUtil.translate('person', 'c');

    $scope.openingHourNotDefined = function () {
        return ApplicationContext.isBusinessHoursNotDefined();
    };

    var ranges_day = {
        'today': {
            from: moment().startOf('day').toISOString(),
            to: moment().endOf('day').toISOString(),
            label: 'Today'
        },
        'tomorrow': {
            from: moment().add(1, 'days').startOf('day').toISOString(),
            to: moment().add(1, 'days').endOf('day').toISOString(),
            label: 'Tomorrow'
        },
        'todayplus2': {
            from: moment().add(2, 'days').startOf('day').toISOString(),
            to: moment().add(2, 'days').endOf('day').toISOString(),
            label: moment().add(2, 'days').format('dddd')
        },
        'todayplus3': {
            from: moment().add(3, 'days').startOf('day').toISOString(),
            to: moment().add(3, 'days').endOf('day').toISOString(),
            label: moment().add(3, 'days').format('dddd')
        },
        'todayplus4': {
            from: moment().add(4, 'days').startOf('day').toISOString(),
            to: moment().add(4, 'days').endOf('day').toISOString(),
            label: moment().add(4, 'days').format('dddd')
        },
        'todayplus5': {
            from: moment().add(5, 'days').startOf('day').toISOString(),
            to: moment().add(5, 'days').endOf('day').toISOString(),
            label: moment().add(5, 'days').format('dddd')
        },
        'todayplus6': {
            from: moment().add(6, 'days').startOf('day').toISOString(),
            to: moment().add(6, 'days').endOf('day').toISOString(),
            label: moment().add(6, 'days').format('dddd')
        }
    };

    var ranges_week = {
        'this_week': {
            from: moment().startOf('week').startOf('day').toISOString(),
            to: moment().startOf('week').add(6, 'days').endOf('day').toISOString(),
            label: 'This week'
        },
        'next_week': {
            from: moment().startOf('week').add(7, 'days').startOf('day').toISOString(),
            to: moment().startOf('week').add(13, 'days').endOf('day').toISOString(),
            label: 'Next week'
        },
        'weekplus2': {
            from: moment().startOf('week').add(14, 'days').startOf('day').toISOString(),
            to: moment().startOf('week').add(20, 'days').endOf('day').toISOString(),
            label: '' + moment().startOf('week').add(14, 'days').startOf('day').format('Do MMM') + ' - ' + moment().startOf('week').add(20, 'days').endOf('day').format('Do MMM')
        },
        'weekplus3': {
            from: moment().startOf('week').add(21, 'days').startOf('day').toISOString(),
            to: moment().startOf('week').add(27, 'days').endOf('day').toISOString(),
            label: '' + moment().startOf('week').add(21, 'days').startOf('day').format('Do MMM') + ' - ' + moment().startOf('week').add(27, 'days').endOf('day').format('Do MMM')
        },
        'weekplus4': {
            from: moment().startOf('week').add(28, 'days').startOf('day').toISOString(),
            to: moment().startOf('week').add(34, 'days').endOf('day').toISOString(),
            label: '' + moment().startOf('week').add(28, 'days').startOf('day').format('Do MMM') + ' - ' + moment().startOf('week').add(34, 'days').endOf('day').format('Do MMM')
        },
        'weekplus5': {
            from: moment().startOf('week').add(35, 'days').startOf('day').toISOString(),
            to: moment().startOf('week').add(41, 'days').endOf('day').toISOString(),
            label: '' + moment().startOf('week').add(35, 'days').startOf('day').format('Do MMM') + ' - ' + moment().startOf('week').add(41, 'days').endOf('day').format('Do MMM')
        },
        'weekplus6': {
            from: moment().startOf('week').add(42, 'days').startOf('day').toISOString(),
            to: moment().startOf('week').add(48, 'days').endOf('day').toISOString(),
            label: '' + moment().startOf('week').add(42, 'days').startOf('day').format('Do MMM') + ' - ' + moment().startOf('week').add(48, 'days').endOf('day').format('Do MMM')
        }
    };

    //var ranges = ranges_day;
    //vm.ranges = ranges;
    //vm.selectedRange = 'this_week';
    //vm.viewType = null;

    vm.getOverview = function (period) {
        var range = getRange(period);
        Tables.getReservationOverview(
            range,
            function (data) {
                vm.overview = data;
            }
        );
        //getReportingOccupancyByHour(range.from);
    };

    // fetch overview for today reservations
    function fetchOverviewAll() {
        Tables.getReservationOverview(
            {
                //range interval for fetching of all reservations
                from: moment().startOf('day').toISOString(),
                to: '9999-12-31T00:00:00.000Z'
            },
            function (data) {
                vm.overviewAll = data;
            }
        );
    }

    vm.getOpenReservations = function (period) {
        var range = getRange(period);
        vm.range = range;

        Tables.getOpenReservations(
            {
                from: range.from,
                to: range.to,
                page: vm.currentPage - 1,
                size: vm.itemsPerPage
            },
            function (data) {
                vm.reservations = data.content;
                vm.totalItems = data.totalElements;
            }
        );
        getReportingExpectedOccupancyByHour(range.from).then(displayHourChart);
    };

    function getRange(period) {
        if (period === 'custom') { // range selected on the date picker
            return {
                from: vm.filterFrom,
                to: vm.filterTo
            };
        } else {
            // pre-defined period
            return vm.ranges[period];
        }
    }

    vm.reload = function (isChanged) {

        if (vm.viewType == 'weekly') {
            vm.ranges = ranges_week;
            if (isChanged) vm.selectedRange = 'this_week';
            //getWeekOccupancy(vm.selectedRange).then(getLastWeekOccupancy(vm.selectedRange)).then(displayWeekChart);
            getWeekOccupancy(vm.selectedRange);
        } else {
            vm.ranges = ranges_day;
            if (isChanged) vm.selectedRange = 'today';
        }

        vm.getOverview(vm.selectedRange);
        vm.getOpenReservations(vm.selectedRange);
        var params = {
            range: vm.selectedRange,
            from: null,
            to: null
        };
        if (vm.selectedRange === 'custom') {
            params.from = vm.filterFrom;
            params.to = vm.filterTo;
        }
        $state.go('tables.overview', params, {notify: false}); // change the URL
        updateDatePickerText();
    };

    // reservations list, page changed
    vm.pageChanged = function () {
        vm.getOpenReservations(vm.selectedRange);
    };

    // paging data
    vm.currentPage = 1;
    vm.itemsPerPage = 100;

    // fetch data for the right column with overview of all reservations
    fetchOverviewAll();

    if (isPredefinedRange($stateParams.range)) {
        vm.selectedRange = $stateParams.range;
        if (vm.selectedRange.startsWith('today') || vm.selectedRange.startsWith('tomorrow')) vm.viewType = 'daily';
        else vm.viewType = 'weekly';
    } else if ($stateParams.range === 'custom' && $stateParams.from && moment($stateParams.from).isValid() && $stateParams.to && moment($stateParams.to).isValid()) {
        vm.selectedRange = 'custom';
        vm.filterFrom = moment($stateParams.from).toISOString();
        vm.filterTo = moment($stateParams.to).toISOString();
    } else {
        vm.selectedRange = 'today';
        //vm.selectedRange = 'this_week';
        vm.viewType = 'daily';
    }

    $q.all([
        googleChartApiPromise
    ]).then(vm.reload);

    // initialize date picker
    $document.ready(
        function () {
            vm.pickerDates = $document.find('input[name="pickerDates"]');
            vm.pickerDates.daterangepicker(
                {
                    showDropdowns: true,
                    autoApply: true,
                    autoUpdateInput: false,
                    alwaysShowCalendars: true,
                    locale: {format: 'MMM D, YYYY'},
                    singleDatePicker: true,
                },
                function (start, end, label) {
                    vm.selectedRange = 'custom';
                    vm.filterFrom = moment(start).toISOString();
                    vm.filterTo = moment(end).toISOString();
                    vm.reload();
                }
            );
            updateDatePickerText();
        }
    );

    vm.openPicker = function () {
        vm.pickerDates.focus();
    };

    function isPredefinedRange(range) {
        //console.log("range" + range);
        if (range) {
            //console.log("vm.ranges_day" + vm.ranges_day);
            if (ranges_day[range] !== undefined) {
                vm.ranges = ranges_day;
                return true;
            }
            //console.log("vm.ranges_day" + vm.ranges_week);
            if (ranges_week[range] !== undefined) {
                vm.ranges = ranges_week;
                return true;
            }
        }
        vm.ranges = ranges_day;
        return false;
    }

    function updateDatePickerText() {
        if (vm.pickerDates) {
            if (vm.selectedRange == 'custom') {
                //vm.pickerDates.val(moment(vm.filterFrom).format('MMM D, YYYY') + ' - ' + moment(vm.filterTo).format('MMM D, YYYY'));
                vm.pickerDates.val(moment(vm.filterFrom).format('MMM D, YYYY'));
            } else {
                vm.pickerDates.val('');
            }
        }
    }

    function getReportingExpectedOccupancyByHour(selectedDateRangeFrom) {
        return Statistics.getReportingExpectedOccupancyByHour({
            selectedDate: selectedDateRangeFrom == null ? null : moment(selectedDateRangeFrom).format('YYYY-MM-DD'),
            reportType: 'includeExpected'
        }, function (data) {
            vm.hourData = data;
        }).$promise;
    }

    function myToolTip(day, occupancy, revenue, tips) {
        return '&nbsp;<b>' + day + '</b><br>' +
            '&nbsp;&nbsp;&nbsp;Occupancy: <span class="text-danger">' + occupancy + '</span><br/>' +
            '&nbsp;&nbsp;&nbsp;Revenue: <span class="text-danger">' + revenue + '</span><br/>' +
            '&nbsp;&nbsp;&nbsp;Tips: <span class="text-danger">' + tips + '</span><br/>';
    }

    function displayHourChart() {
        //console.log(' displayHourChart range.from');
        //console.log(vm.range.from);

        var tableLegendText = TextUtil.translate('resource', 'sc')
        var seatLegendText = TextUtil.translate('capacity', 'sc')

        var nowHour = moment().startOf('hour');
        //console.log(' nowHour');
        //console.log(nowHour.toISOString());

        var deferred = $q.defer();
        //setSimulationToActuals('day');
        // DAY
        var dataOcuppancyDay = new google.visualization.DataTable();
        dataOcuppancyDay.addColumn('string', 'Hour');
        dataOcuppancyDay.addColumn('number', seatLegendText + ' occupancy rate', 'color: #1ABC9C');
        dataOcuppancyDay.addColumn({type: 'boolean', role: 'certainty'}); // certainty col.
        //dataOcuppancyDay.addColumn({type: 'string', role: 'style'});
        dataOcuppancyDay.addColumn({type: 'string', role: 'annotation'});
        dataOcuppancyDay.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});
        dataOcuppancyDay.addColumn('number', tableLegendText + ' occupancy rate', 'color: #336699');
        dataOcuppancyDay.addColumn({type: 'boolean', role: 'certainty'}); // certainty col.
        //dataOcuppancyDay.addColumn({type: 'string', role: 'style'});

        _.each(vm.hourData.occupancyByHour, function (item) {
            var chartItemHour = moment(vm.range.from).set('hour', item.hourOfDay);
            //console.log(' chartItemHour - item.hourOfDayFmt: ' + item.hourOfDayFmt + '  item.hourOfDay: ' + item.hourOfDay);
            //console.log(chartItemHour.toISOString());
            var style = null;
            var certainty = true;
            if (!chartItemHour.isBefore(nowHour)) {
                certainty = false;
                style = 'color: #D3D3D3; fill-color: #D3D3D3; stroke-color: #D3D3D3; opacity: 0.5';
            }

            dataOcuppancyDay.addRow(
                [
                    item.hourOfDayFmt,
                    item.occupancyPct,
                    certainty,
                    //style,
                    $filter('percentage')(item.occupancyPct, 2),
                    myToolTip(item.hourOfDayFmt, $filter('percentage')(item.occupancyPct, 2), $filter('currency')(item.revenue), $filter('currency')(item.tips)),
                    {
                        v: item.occupancyTablePct,
                        f: $filter('percentage')(item.occupancyTablePct, 2)
                    },
                    certainty
                    //,style
                ]);
        });
        /*
         dataOcuppancyDay.addRows([
         ['7am', 0.3450, '34.50%', myToolTip('7am', '65.43%', '$13,456.34', '$2.435,98'), 27.50],
         ['8am', 0.4253, '42.53%', myToolTip('8am', '65.43%', '$13,456.34', '$2.435,98'), 33.34],
         ]);
         */

        var optionsOccupancyDay = {
            //width: 900,
            height: 300,

            tooltip: {
                isHtml: true
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 10,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },

            hAxis: {
                title: 'Hour of day',
                titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
            },

            vAxes: {
                0: {
                    viewWindowMode: 'explicit',
                    //gridlines: {color: 'transparent'},
                    gridlines: {count: 9},
                    format: 'percent',
                    viewWindow: {
                        min: 0
                    }
                },
                1: {
                    //gridlines: {color: 'transparent'},
                    format: 'percent',
                    color: '#CC9933',
                    viewWindow: {
                        min: 0
                    },
                    textStyle: {color: '#CC3333'},
                    titleTextStyle: {color: '#CC3333'}
                }
            },
            chartArea: {width: '80%', height: '65%'},
            series: {
                0: {
                    type: 'bars',
                    targetAxisIndex: 0
                },
                1: {
                    type: 'line',
                    targetAxisIndex: 0
                }
            },
            formatters: {},
            colors: ['#1ABC9C', '#CC3333'],
            legend: {position: 'top', alignment: 'end'}
        };

        $scope.chartComboObjectOccupancyDay = {
            type: "ComboChart",
            options: optionsOccupancyDay,
            data: dataOcuppancyDay
        };

        return deferred.promise;
    }

    $scope.showReservationListForPrinting = function () {
        var itemsPerPageSave = vm.itemsPerPage;
        vm.itemsPerPage = 100000;
        vm.reload();
        vm.itemsPerPage = itemsPerPageSave;
        vm.waitlistNotifyDialog = $uibModal.open({
            templateUrl: 'app/tables/overview/list.html',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.printElement = function () {
        var domClone = document.getElementById("printThis").cloneNode(true);
        var divElements = domClone.innerHTML;
        document.body.innerHTML =
            "<html><head><title></title></head><body>" +
            divElements +
            "</body>";
        window.print();
        window.location.reload();
    };

    $scope.channelIcon = function (channel) {
        return ChannelService.getChannelIcon(channel);
    }

    $scope.channelToolTipText = function (channel) {
        return ChannelService.getChannelToolTipText(channel);
    }


    function getWeekOccupancy(period) {
        var range = getRange(period);
        vm.weekStart = moment(range.from);
        //console.log('getWeekOccupancy' + vm.weekStart.format('YYYY-MM-DD'));
        return EventResource.getHomeWeekStats({
            selectedDate: moment(range.from).format('YYYY-MM-DD')
        }, function (data) {
            vm.data = data;
            vm.data.walkinseatedEventsCount = data.totalWeekOccupancy.totalVisits - data.totalWeekOccupancy.reservationEventsCount - data.totalWeekOccupancy.waitlistEventsCount;
            vm.data.walkinseatedCoversCount = data.totalWeekOccupancy.totalCovers - data.totalWeekOccupancy.reservationCoversCount - data.totalWeekOccupancy.waitlistCoversCount;
            getLastWeekOccupancy(period);
        }).$promise;
    }

    function getLastWeekOccupancy(period) {
        var range = getRange(period);
        vm.lastWeekStart = moment(range.from).subtract(1, 'weeks');
        //console.log('getLastWeekOccupancy' + vm.lastWeekStart.format('YYYY-MM-DD'));
        return EventResource.getHomeWeekStats({
            selectedDate: vm.lastWeekStart.format('YYYY-MM-DD'),
            reportType: 'lastWeek'
        }, function (data) {
            vm.lastWeekData = data;
            displayWeekChart();
        }).$promise;
    }

    function myToolTip2(day, occupancy, visit, cover) {
        return '&nbsp;<b>' + day + '</b><br>' +
            '&nbsp;&nbsp;&nbsp;Occupancy: <span class="text-danger">' + occupancy + '</span><br/>' +
            '&nbsp;&nbsp;&nbsp;Visit(s): <span class="text-danger">' + visit + '</span><br/>' +
            '&nbsp;&nbsp;&nbsp;' + vm.coverTrans + ': <span class="text-danger">' + cover + '</span><br/>';
    }

    function displayWeekChart() {
        // occupancy bar chart
        var nowHour = moment().startOf('hour');

        var deferred = $q.defer();

        var dataOcuppancyDay = new google.visualization.DataTable();
        dataOcuppancyDay.addColumn('string', 'Day');
        dataOcuppancyDay.addColumn('number', 'Current week', 'color: #1ABC9C');
        dataOcuppancyDay.addColumn({type: 'boolean', role: 'certainty'}); // certainty col.
        dataOcuppancyDay.addColumn({type: 'string', role: 'annotation'});
        dataOcuppancyDay.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});
        dataOcuppancyDay.addColumn('number', 'Previous week', 'color: #336699');
        dataOcuppancyDay.addColumn({type: 'boolean', role: 'certainty'}); // certainty col.

        var i;
        for (i = 0; i < vm.data.occupancyByWeekday.length; i++) {
            var item = vm.data.occupancyByWeekday[i];
            var certainity = (i < moment().weekday());
            dataOcuppancyDay.addRow(
                [
                    vm.weekStart.clone().add(item.weekday, 'days').format('Do MMM, dddd'),
                    item.occupancyPct,
                    certainity,
                    $filter('percentage')(item.occupancyPct, 2), //item.weekdayName,
                    myToolTip2(item.weekdayName, $filter('percentage')(item.occupancyPct, 2), item.totalVisits, item.totalCovers),
                    {
                        v: vm.lastWeekData.occupancyByWeekday[i].occupancyPct,
                        f: $filter('percentage')(vm.lastWeekData.occupancyByWeekday[i].occupancyPct, 2)
                    },
                    true
                ]
            );
        }

        var optionsOccupancyDay = {
            //width: 900,
            height: 300,

            tooltip: {
                isHtml: true
            },
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 10,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            hAxis: {
                title: 'Day of the week',
                titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
            },
            vAxes: {
                0: {
                    viewWindowMode: 'explicit',
                    //gridlines: {color: 'transparent'},
                    gridlines: {count: 9},
                    title: 'Occupancy rate',
                    titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
                    format: 'percent',
                    viewWindow: {
                        min: 0
                    },
                    minValue: 0
                },
                1: {
                    //gridlines: {color: 'transparent'},
                    title: 'Occupancy rate',
                    //titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
                    format: 'percent',
                    color: '#CC9933',
                    viewWindow: {
                        min: 0
                    },
                    textStyle: {color: '#CC3333'},
                    titleTextStyle: {color: '#CC3333'},
                    minValue: 0
                }
            },
            chartArea: {width: '80%', height: '65%'},
            series: {
                0: {
                    type: 'bars',
                    targetAxisIndex: 0
                },
                1: {
                    type: 'line',
                    targetAxisIndex: 0
                }
            },
            formatters: {},
            colors: ['#1ABC9C', '#CC3333'],
            legend: {position: 'top', alignment: 'end'}
        };

        $scope.chartComboObjectOccupancyWeek = {
            type: "ComboChart",
            options: optionsOccupancyDay,
            data: dataOcuppancyDay
        };

        return deferred.promise;
    }
}

angular.module('seatonApp').controller('ReservationOverviewController', ReservationOverviewController);
