'use strict';

angular.module('seatonApp').controller('SettingsEmployeeDetailController', SettingsEmployeeDetailController);

SettingsEmployeeDetailController.$inject = ['$q', '$state', '$document', '$stateParams', '$filter',
    'googleChartApiPromise', 'EmployeeResource', 'EventResource', 'EmployeeService', 'locations', 'NgTableParams',
    'Statistics', 'Reporting', 'Utils', 'ApplicationContext', 'ApplicationProfile'];

function SettingsEmployeeDetailController($q, $state, $document, $stateParams, $filter, googleChartApiPromise,
                                          EmployeeResource, EventResource, EmployeeService, locations, NgTableParams,
                                          Statistics, Reporting, Utils, ApplicationContext, ApplicationProfile) {
    var vm = this;
    vm.tabChange = tabChange;
    vm.employee = {};
    vm.employeeStats = {};
    vm.changedSelectedInterval = changedSelectedInterval;
    vm.openPicker = openPicker;
    vm.viewEvent = viewEvent;
    vm.utils = Utils;

    vm.weeklyPerformance = ApplicationProfile.isEmployeeWeeklyPerformanceEnabled();
    vm.weeklyPerformanceText = ApplicationProfile.upgradeTierText("EmployeeWeeklyPerformance");
    vm.monthlyPerformance = ApplicationProfile.isEmployeeMonthlyPerformanceEnabled();
    vm.monthlyPerformanceText = ApplicationProfile.upgradeTierText("EmployeeMonthlyPerformance");
    vm.openWindow = ApplicationProfile.openAppCloverPage;

    function viewEvent(id) {
        $state.go('reservations.details', {eventId: id});
    }

    vm.dateRanges = {
        'lastWeek': [moment().subtract(1, 'weeks').startOf('week').startOf('day'), moment().subtract(1, 'weeks').endOf('week').endOf('day')],
        'currentWeek': [moment().startOf('week').startOf('day'), moment().endOf('week').endOf('day')]
    };

    vm.selectedInterval = 'currentWeek';
    vm.pickerDate = null;

    vm.filterStartDate = vm.dateRanges[vm.selectedInterval][0].startOf('day');
    vm.filterEndDate = vm.dateRanges[vm.selectedInterval][1].endOf('day');

    vm.order = {field: 'amountSum', reverse: true};

    vm.dynamicOrder = function (item) {
        return item[vm.order.field];
    }

    function changedSelectedInterval() {
        vm.filterStartDate = vm.dateRanges[vm.selectedInterval][0].startOf('day');
        vm.filterEndDate = vm.dateRanges[vm.selectedInterval][1].endOf('day');
        vm.pickerDate = null;
        clearPicker('pickerDate');
        reloadWeeklyPerformance();
    }

    function reloadWeeklyPerformance() {
        var selectedFilter = vm.filterStartDate.toISOString() + vm.filterEndDate.toISOString();
        if (vm.currentFilter != selectedFilter) {
            loadAggregatedByWeekdayData().then(loadWeeklyPerformanceChart).then(windowResize);
        }
    }

    function loadEmployee() {
        return EmployeeResource.getEmployeeWithStats({id: $stateParams.id}, function (employeeData) {
            vm.employee = EmployeeService.enrichEmployee(employeeData.employee, locations);
            vm.employeeStats = employeeData.employeeStats;
            getEmployeeLastActivity();
        }).$promise;
    }

    vm.load = function () {
        $q.all([
            loadEmployee(),
            loadLocationStats(),
            loadEmployees()  //TODO 4 performance optimization - put to global variable?
        ]).then(loadVisitsCharts);
    };

    vm.updateAttribute = function (attribute) {
        EmployeeResource.updateAttribute(
            {
                id: vm.employee.id,
                attribute: attribute,
                value: vm.employee[attribute]
            });
    };

    vm.load();

    //datepicker code
    function getPicker(inputName) {
        return $document.find('input[name="' + inputName + '"]');
    }

    function openPicker(inputName) {
        getPicker(inputName).focus();
    }

    function clearPicker(inputName) {
        getPicker(inputName).val('');
    }

    function dPickerInit(inputName, initDate) {
        var pickerObj
        pickerObj = getPicker(inputName);
        pickerObj.daterangepicker(
            {
                //configuration parameters
                autoUpdateInput: false,
                singleDatePicker: true,
                showDropdowns: true,
                locale: {format: 'MMM D, YYYY'}
            },
            function (start, end, label) {
                //callback function - called on picker value change - dates sent in callback
                if (start != vm.pickerDate) {
                    vm.pickerDate = start;
                    changedPickerDate();
                } else {
                    console.log("Selected date same as existing - no need for reload")
                }
            }
        );

        pickerObj.on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('MMM D, YYYY'));
        });

        pickerObj.on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('');
        });

        //initial datepicker values
        //console.log('dPickerInit   2:' + inputName + '   :' + initDate);
        if (initDate != null) {
            pickerObj.data('daterangepicker').setStartDate(moment(initDate));
            pickerObj.data('daterangepicker').setEndDate(moment(initDate));
            pickerObj.val(moment(initDate).format('MMM D, YYYY'));
        }
    }

    function changedPickerDate() {
        if ((typeof vm.pickerDate) != 'string' && vm.pickerDate != null) {
            vm.selectedInterval = '';
            vm.filterStartDate = vm.pickerDate.clone().startOf('week');
            vm.filterEndDate = vm.pickerDate.clone().endOf('week');
            reloadWeeklyPerformance();
        }
    }

    function tabChange(tab) {
        switch (tab) {
            case 'visitStatisticTab':
                $(window).resize();
                break;
            case 'weeklyPerformanceTab':
                if (vm.weeklyPerformance) {
                    vm.order = {field: 'amountSum', reverse: true};
                    loadAggregatedByWeekdayData().then(loadWeeklyPerformanceChart).then(windowResize);
                    $document.ready(
                        function () {
                            dPickerInit('pickerDate', vm.pickerDate);
                        }
                    );
                }
                break;
            case 'monthlyPerformanceTab':
                if (vm.monthlyPerformance) {
                    vm.order = {field: 'amountSum', reverse: true};
                    loadMonthlyPerformanceData().then(loadMonthlyPerformanceChart).then(windowResize);
                }
                break;
        }
    }

    function windowResize() {
        var deferred = $q.defer();
        $(window).resize();
        deferred.resolve(true);
        return deferred.promise;
    }

    function loadLocationStats() {
        //return Statistics.getLocationRegularsStats({}, function (data) {  //use this if regulars only stats are needed
        return Statistics.getCustomerLocationAllVisitsStats({}, function (data) {
            vm.locationStats = data;
            vm.avgSpendPerCover = vm.locationStats.avgSpendByCover;
        }).$promise;
    }

    function loadEmployees() {
        return EmployeeResource.getAllEmployees({}, function (data) {
            vm.employees = data;
            vm.numberOfEmployees = 0;
            _.each(vm.employees, function (item) {
                if (item.active) vm.numberOfEmployees++;
            });
        }).$promise;
    }

    function getEmployeeLastActivity() {
        return EventResource.getEmployeeLastActivity({employeeLogin: vm.employee.cloverId}, function (data) {
            vm.employeeChangeHistory = data;
            console.log('vm.employeeChangeHistory');
            console.log(vm.employeeChangeHistory);

        }).$promise;
    }

    function loadAggregatedByWeekdayData() {
        return EmployeeResource.weeklyPerformance({
            id: vm.employee.id,
            employeeLogin: vm.employee.cloverId,
            startDate: vm.filterStartDate.toISOString(),
            endDate: vm.filterEndDate.toISOString()
        }, function (data) {
            vm.currentFilter = vm.filterStartDate.toISOString() + vm.filterEndDate.toISOString();
            vm.aggrByWeekdayData = data;
            vm.aggrByWeekdayDetailData = vm.aggrByWeekdayData.detailData;
            vm.weeklyStatsTotal = vm.aggrByWeekdayData.statsTotal;

            //console.log("(vm.filterEndDate.diff(vm.filterStartDate, 'days')" + (vm.filterEndDate.diff(vm.filterStartDate, 'days')));
            //console.log(vm.filterStartDate.toISOString());
            //console.log(vm.filterEndDate.toISOString());
            vm.averageRevenuePerDay = vm.aggrByWeekdayData.statsTotal.amountSum / (vm.filterEndDate.diff(vm.filterStartDate, 'days') + 1);

            vm.averageTipsPerDay = vm.aggrByWeekdayData.statsTotal.tipSum / (vm.filterEndDate.diff(vm.filterStartDate, 'days') + 1);
            vm.avgTipsVsRevenuePct = Reporting.divide(vm.averageTipsPerDay, vm.averageRevenuePerDay);

            //calc stat pcts. vs location stats
            for (var i = 0; i < vm.aggrByWeekdayData.detailData.length; i++)
                Reporting.calcAggrByWeekdayDataItem(vm.aggrByWeekdayData.detailData[i], vm.locationStats);
        }).$promise;
    }

    function loadMonthlyPerformanceData() {
        return EmployeeResource.monthlyPerformance({
            id: vm.employee.id,
            employeeLogin: vm.employee.cloverId,
            startDate: moment().subtract(1, 'year').startOf('month').toISOString(),
            endDate: moment().endOf('month').toISOString()
        }, function (data) {
            vm.currentFilter = vm.filterStartDate.toISOString() + vm.filterEndDate.toISOString();
            vm.aggrByMonthData = data.statsByGroup;
            vm.monthlyStatsTotal = data.statsTotal

            vm.averageRevenuePerMonth = vm.monthlyStatsTotal.amountSum / 13;
            vm.averageTipsPerMonth = vm.monthlyStatsTotal.tipSum / 13;
            vm.avgTipsVsRevenuePct_monthly = Reporting.divide(vm.averageTipsPerMonth, vm.averageRevenuePerMonth);

            //calc stat pcts. vs location stats
            for (var i = 0; i < vm.aggrByMonthData.length; i++)
                Reporting.calcAggrByWeekdayDataItem(vm.aggrByMonthData[i], vm.locationStats);
        }).$promise;
    }

    function loadMonthlyPerformanceChart() {
        var deferred = $q.defer();

        // Spend trend

        var dataTrend = new google.visualization.DataTable();
        dataTrend.addColumn('string', 'Month');
        dataTrend.addColumn('number', 'Revenue');
        dataTrend.addColumn('number', 'Tips');
        dataTrend.addColumn({type: 'string', role: 'annotation'});

        for (var i = 0; i < vm.aggrByMonthData.length; i++) {
            var item = vm.aggrByMonthData[i];
            dataTrend.addRow(
                [
                    item.groupedItemShortDisplayName,
                    {
                        v: item.amountSum,
                        f: $filter('currency')(item.amountSum)
                    },
                    {
                        v: item.tipSum,
                        f: $filter('currency')(item.tipSum)
                    },
                    $filter('currency')(item.amountSum + item.tipSum)
                ]);
        }

        /*
         dataTrend.addRows([
         ['Jun 14', 50000, 6500, '$56.500'],
         ['Jul 14', 47500, 5500, '$53.000'],
         ['Aug 14', 42500, 4500, '$47.000'],
         ['Sep 14', 72500, 9500, '$82.000'],
         ['Oct 14', 82500, 10000, '$92.500'],
         ['Nov 14', 87500, 11000, '$98.500'],
         ['Dec 14', 83500, 10500, '$84.000'],
         ['Jan 15', 107500, 12500, '$120.000'],
         ['Feb 15', 62500, 9500, '$72.000'],
         ['Mar 15', 80000, 11000, '$91.000'],
         ['Apr 15', 90000, 13500, '$103.500'],
         ['May 15', 60000, 8000, '$68.000']
         ]);
         */

        var optionsTrend = {
            isStacked: 'true',
            //width: 1050,
            height: 425,
            vAxis: {
                title: 'Revenue in ' + ApplicationContext.getCurrencySym(),
                textPosition: 'none',
                gridlines: {count: 13}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: 'Month',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            chartArea: {width: '85%', height: '65%'},
            formatters: {},
            colors: ['#1ABC9C', '#3399CC'],
            legend: {position: 'top', alignment: 'end'}
        };

        vm.chartObjectTrend = {
            type: "ColumnChart",
            options: optionsTrend,
            data: dataTrend
        };

        deferred.resolve(true);
        return deferred.promise;
    }

    function loadWeeklyPerformanceChart() {
        var deferred = $q.defer();

        // Week performance statistics
        var dataDays = new google.visualization.DataTable();
        dataDays.addColumn('string', 'Day');
        dataDays.addColumn('number', 'Revenue');
        dataDays.addColumn('number', 'Tips');
        dataDays.addColumn({type: 'string', role: 'annotation'});


        for (var i = 0; i < vm.aggrByWeekdayData.chartData.length; i++) {
            var item = vm.aggrByWeekdayData.chartData[i];
            var detailDataItem = vm.aggrByWeekdayData.detailData[i];
            dataDays.addRow(
                [
                    item.intervalUnit,
                    {
                        v: item.sumTotal,
                        f: $filter('currency')(item.sumTotal)
                    },
                    {
                        v: detailDataItem.tipSum,
                        f: $filter('currency')(detailDataItem.tipSum)
                    },
                    $filter('currency')(item.sumTotal + detailDataItem.tipSum)
                ]);
        }

        var optionsDays = {
            isStacked: 'true',
            //width: 1000,
            height: 400,
            vAxis: {
                title: 'Revenue in ' + ApplicationContext.getCurrencySym(),
                textPosition: 'none',
                gridlines: {count: 13}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: 'Days of the Week',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            chartArea: {width: '80%', height: '65%'},
            colors: ['#1ABC9C', '#3399CC'],
            formatters: {},
            legend: {position: 'top', alignment: 'end'}
        };

        vm.chartObjectWeekPerf = {
            type: 'ColumnChart',
            options: optionsDays,
            data: dataDays
        };
        deferred.resolve(true);
        return deferred.promise;
    }


    function loadVisitsCharts() {
        var deferred = $q.defer();

        //vistis - general pie chart
        vm.chartObjectVisitGeneral = {};
        vm.chartObjectVisitGeneral.type = "PieChart";

        vm.chartObjectVisitGeneral.options = {
            title: 'Visit statistics',
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#3399CC'},
                2: {color: '#996699'},
            },
            chartArea: {width: '85%', height: '65%'}
        };

        vm.chartObjectVisitGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Reservation"},
                        {v: vm.employee.totalReservationShow},
                    ]
                },
                {
                    c: [
                        {v: "Walk-In Seated"},
                        {v: vm.employee.totalWalkinSeated}
                    ]
                },
                {
                    c: [
                        {v: "Walk-In Waitlist"},
                        {v: vm.employee.totalWaitlistShow},
                    ]
                }
            ]
        };

        //reservation - general pie chart
        vm.chartObjectReservationGeneral = {};
        vm.chartObjectReservationGeneral.type = "PieChart";

        vm.chartObjectReservationGeneral.options = {
            title: 'Reservation statistics',
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#FF3333'},
                2: {color: '#FFCC33'},
            },
            chartArea: {width: '85%', height: '65%'}
        };

        vm.chartObjectReservationGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Show"},
                        {v: vm.employee.totalReservationShow},
                    ]
                },
                {
                    c: [
                        {v: "No Show"},
                        {v: vm.employee.totalReservationNoShow}
                    ]
                },
                {
                    c: [
                        {v: "Canceled"},
                        {v: vm.employee.totalReservationCanceled},
                    ]
                }
            ]
        };


        //waitlist - general pie chart
        vm.chartObjectWaitlistGeneral = {};
        vm.chartObjectWaitlistGeneral.type = "PieChart";

        vm.chartObjectWaitlistGeneral.options = {
            title: 'Waitlist statistics',
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#FF3333'},
                2: {color: '#FFCC33'},
            },
            chartArea: {width: '85%', height: '65%'}

        };

        vm.chartObjectWaitlistGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Show"},
                        {v: vm.employee.totalWaitlistShow},
                    ]
                },
                {
                    c: [
                        {v: "No Show"},
                        {v: vm.employee.totalWaitlistNoShow}
                    ]
                },
                {
                    c: [
                        {v: "Canceled"},
                        {v: vm.employee.totalWaitlistCanceled},
                    ]
                }
            ]
        };
        deferred.resolve(true);
        return deferred.promise;
    }
}
