'use strict';

CustomerProfileController.$inject = ['$state', '$q', '$stateParams', 'Principal', 'CustomerResource', 'LocationResource',
    'googleChartApiPromise', 'EventResource', 'Reporting', 'ApplicationContext', 'Time', 'Utils', 'Tables', 'SweetAlert',
    'ApplicationProfile'];

function CustomerProfileController($state, $q, $stateParams, Principal, CustomerResource, LocationResource,
                                   googleChartApiPromise, EventResource, Reporting, ApplicationContext, Time,
                                   Utils, Tables, SweetAlert, ApplicationProfile) {
    var vm = this;
    vm.calcPct = Reporting.calcPct;
    vm.moment = moment;
    vm.isMeal = (ApplicationContext.getProductType() == 'MEAL');

    vm.edit = edit;
    vm.updateAttribute = updateAttribute;
    vm.adjustPoints = adjustPoints;
    vm.isNearDate = Time.isNearDate;
    vm.tabChange = tabChange;
    vm.viewEvent = viewEvent;
    vm.viewAllEvents = viewAllEvents;
    vm.utils = Utils;
    vm.formatAddress = Utils.formatAddress;

    vm.oneCustomerStats = null;
    vm.aggrSpendType = 'byhour';
    vm.aggrSpendInterval = 12;
    vm.spendTrendChartLoaded = false;
    vm.aggregatedSpendChartLoaded = false;
    vm.aggregatedSpendChartCurrent = '-';

    vm.avgSpendPerCover = -1;

    vm.profileSpendTrend = ApplicationProfile.isCustomerProfileSpendTrendEnabled();
    vm.profileSpendTrendText = ApplicationProfile.upgradeTierText("ProfileSpendTrend");
    vm.profileAggregatedSpendTrend = ApplicationProfile.isCustomerProfileAggregatedSpendTrendEnabled();
    vm.profileAggregatedSpendTrendText = ApplicationProfile.upgradeTierText("ProfileAggregatedSpendTrend");
    vm.openWindow = ApplicationProfile.openAppCloverPage;


    load($stateParams.customerId);

    function load(customerId) {
        $q.all([
            loadCustomer(customerId),
            loadSettings(),
            googleChartApiPromise
        ]).then(calculateStats).then(loadVisitCharts).then(loadEvents(customerId));
    }

    function loadSettings() {
        return LocationResource.getLocationSettings({}, function (settings) {
            vm.settings = settings;
            vm.numberOfAreas = vm.settings.areas.length;
        }).$promise;
    }


    function tabChange(tab) {
        switch (tab) {
            case 'visit':
                $(window).resize();
                break;
            case 'spendTrend':
                if (vm.profileSpendTrend) {
                    if (vm.spendTrendChartLoaded) {
                        $(window).resize(); //if data already loaded just refresh
                    } else {
                        loadSpendTrendData().then(loadSpendTrendChart).then($(window).resize);
                        vm.spendTrendChartLoaded = true;
                    }
                }
                break;
            case 'aggregatedSpend':
                if (vm.profileAggregatedSpendTrend) {
                    var currentChart = vm.aggrSpendType + vm.aggrSpendInterval;

                    if (vm.aggregatedSpendChartLoaded && vm.aggregatedSpendChartCurrent == currentChart) {
                        $(window).resize(); //if data already loaded just refresh
                    } else {
                        if (vm.aggrSpendType == 'byhour') loadAggregatedByHourData().then(loadAggregatedByHourChart).then($(window).resize);
                        if (vm.aggrSpendType == 'byday') loadAggregatedByWeekdayData().then(loadAggregatedByDayChart).then($(window).resize);
                        vm.aggregatedSpendChartCurrent = currentChart;
                        vm.aggregatedSpendChartLoaded = true;
                    }
                }
                break;
        }
    }

    function edit(id) {
        $state.go('customers.create', {customerId: id});
    }

    function viewEvent(id) {
        $state.go('reservations.details', {eventId: id});
    }

    function viewAllEvents(id) {
        //$state.go('tables.search', {keyword: '' + vm.profile.firstName + ' ' + vm.profile.lastName});
        $state.go('tables.search', {keyword: 'customerId:' + id});
    }

    /*
     function viewAllEvents() {
     ViewState.goToState('customer.profile.events', {
     keyword: vm.profile.name,
     customerId: vm.profile.id
     });
     }
     */

    function updateAttribute(attribute) {
        console.log('updateAttribute:' + vm.profile.id);
        if (attribute == 'waitlistAllowed') {
            var msg = !vm.profile[attribute] ? 'Disallow waitlist?' : 'Allow waitlist?';
            var msgConfirmation = '';
        }
        if (attribute == 'reservationAllowed') {
            var msg = !vm.profile[attribute] ? 'Disallow reservations for customer?' : 'Allow reservations for customer?';
            var msgConfirmation = '';
        }

        SweetAlert.swal({
                title: msg,
                //text: "Your will not be able to recover this imaginary file!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55", confirmButtonText: "Ok",
                cancelButtonText: "Cancel",
                closeOnConfirm: false,
                closeOnCancel: false
            },
            function (isConfirm) {


                if (isConfirm) {
                    if (vm.profile.id) {
                        CustomerResource.updateAttribute({
                            id: vm.profile.id,
                            attribute: attribute,
                            attributeValue: false
                        }, function (data) {
                            console.log(data);
                            SweetAlert.swal("Updated", "", "success");
                        });

                    }
                } else {
                    vm.profile[attribute] = !vm.profile[attribute];
                    SweetAlert.swal("No changes", "", "error");
                }
            });


    }

    function adjustPoints(cb) {
        vm.profile.totalPoints = vm.currentPoints;
        calculateStats();
        vm.profile.customerLevel = vm.stats.memberStatus.stars;
        update(['totalPoints']);
        cb();
    }

    function loadEvents(customerId) {
        return Tables.searchEvents(
            {
                keyword: 'customerId:' + customerId,
                page: 0,
                size: 10
            },
            function (data) {
                vm.events = data.content;
                vm.eventTotalItems = data.totalElements;
            }).$promise;
    }

    function loadSpendTrendData() {
        return CustomerResource.getSpendTrend({id: vm.profile.id}, function (data) {
            vm.customerSpendTrendData = data;
        }).$promise;
    }

    function loadAggregatedByHourData() {
        return CustomerResource.getAggregateByHourData({
            id: vm.profile.id,
            months: vm.aggrSpendInterval
        }, function (data) {
            vm.aggrByHourData = data;
        }).$promise;
    }

    function loadAggregatedByWeekdayData() {
        return CustomerResource.getAggregateByWeekdayData({
            id: vm.profile.id,
            months: vm.aggrSpendInterval
        }, function (data) {
            vm.aggrByWeekdayData = data;
            vm.aggrByWeekdayDetailData = vm.aggrByWeekdayData.detailData;
            //calc stat pcts. vs location stats
            //_.each(vm.aggrByWeekdayData.detailData, Reporting.calcAggrByWeekdayDataItem,vm);
            for (var i = 0; i < vm.aggrByWeekdayData.detailData.length; i++)
                Reporting.calcAggrByWeekdayDataItem(vm.aggrByWeekdayData.detailData[i], vm.locationStats);
        }).$promise;
    }

    function loadCustomer(customerId) {
        return CustomerResource.getProfile({id: customerId}, function (customerWithStats) {
            vm.cstats = customerWithStats;
            vm.profile = customerWithStats.customer;
            vm.oneCustomerStats = customerWithStats.customerStats;
            vm.avgSpendPerCover = vm.oneCustomerStats.avgSpendPerCover;
            //vm.customerProfileVisitStats = customerWithStats.locationStats;
            vm.locationStats = customerWithStats.locationStats;

            vm.currentPoints = customerWithStats.customer.totalPoints;

            vm.templateParam1 = vm.locationStats.avgSpendByCover;
            vm.templateParam2 = vm.locationStats.avgTipPerVisit;
            vm.templateParam3 = vm.locationStats.avgCoversPerVisit;
            //vm.templateParam4 = vm.locationStats.totalReservationNoShow + vm.locationStats.totalWaitlistNoShow;
            vm.templateParam4 = vm.locationStats.totalNoShowRatio;

        }).$promise;
    }

    function calculateStats() {
        var deferred = $q.defer();
        var stats = {};

        //TODO check - calculate on server?
        stats.memberStatus = _.chain(vm.settings.memberStatuses)
            .sortBy(function (status) {
                return status.threshold;
            })
            .reverse()
            .find(function (status) {
                return status.threshold <= vm.profile.totalPoints;
            }).value();
        vm.stats = stats;
        deferred.resolve(stats);
        return deferred.promise;
    }

    function loadVisitCharts() {
        var deferred = $q.defer();
        //vistis - general pie chart
        vm.chartObjectVisitGeneral = {};
        vm.chartObjectVisitGeneral.type = "PieChart";

        vm.chartObjectVisitGeneral.options = {
            title: 'Visit statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#3399CC'},
                2: {color: '#996699'},
            },
            chartArea: {width: '85%', height: '65%'}
        };

        vm.chartObjectVisitGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Reservation"},
                        {v: vm.profile.totalReservationShow}
                    ]
                },
                {
                    c: [
                        {v: "Walk-In Seated"},
                        {v: vm.profile.totalWalkinSeated}
                    ]
                },
                {
                    c: [
                        {v: "Walk-In Waitlisted"},
                        {v: vm.profile.totalWaitlistShow}
                    ]
                }
            ]
        };
        vm.showChartObjectVisitGeneral = !Reporting.allZeroesInPieChartData(vm.chartObjectVisitGeneral.data.rows);

        //reservation - general pie chart
        vm.chartObjectReservationGeneral = {};
        vm.chartObjectReservationGeneral.type = "PieChart";

        vm.chartObjectReservationGeneral.options = {
            title: 'Reservation statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#FF3333'},
                2: {color: '#FFCC33'},
            },
            chartArea: {width: '85%', height: '65%'}
        };

        vm.chartObjectReservationGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Show"},
                        {v: vm.profile.totalReservationShow}
                    ]
                },
                {
                    c: [
                        {v: "No Show"},
                        {v: vm.profile.totalReservationNoShow}
                    ]
                },
                {
                    c: [
                        {v: "Canceled"},
                        {v: vm.profile.totalReservationCanceled}
                    ]
                }
            ]
        };

        vm.showChartObjectReservationGeneral = !Reporting.allZeroesInPieChartData(vm.chartObjectReservationGeneral.data.rows);

        //waitlist - general pie chart
        vm.chartObjectWaitlistGeneral = {};
        vm.chartObjectWaitlistGeneral.type = "PieChart";

        vm.chartObjectWaitlistGeneral.options = {
            title: 'Waitlist statistics',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'right'},
            //width: 450,
            height: 350,

            slices: {
                0: {color: '#1ABC9C'},
                1: {color: '#FF3333'},
                2: {color: '#FFCC33'},
            },
            chartArea: {width: '85%', height: '65%'},
            sliceVisibilityThreshold: 0
        };

        vm.chartObjectWaitlistGeneral.data = {
            "cols": [
                {id: "t", label: "Type", type: "string"},
                {id: "s", label: "Number", type: "number"}
            ], "rows": [
                {
                    c: [
                        {v: "Show"},
                        {v: vm.profile.totalWaitlistShow}
                    ]
                },
                {
                    c: [
                        {v: "No Show"},
                        {v: vm.profile.totalWaitlistNoShow}
                    ]
                },
                {
                    c: [
                        {v: "Canceled"},
                        {v: vm.profile.totalWaitlistCanceled}
                    ]
                }
            ]
        };
        vm.showChartObjectWaitlistGeneral = !Reporting.allZeroesInPieChartData(vm.chartObjectWaitlistGeneral.data.rows);

        return deferred.promise;
    }

    function loadSpendTrendChart() {
        var deferred = $q.defer();
        // Spend trend
        var dataTrend = new google.visualization.DataTable();
        dataTrend.addColumn('string', 'Month');
        dataTrend.addColumn('number', 'Reservation', 'color: #007E3B');
        dataTrend.addColumn('number', 'Walk-In Seated');
        dataTrend.addColumn('number', 'Walk-In Waitlisted');
        dataTrend.addColumn({type: 'string', role: 'annotation'});

        var optionsTrend = {
            title: 'Last 12 months spend statistics (' + moment(vm.customerSpendTrendData.startDate).format('Do MMM YY') + ' - ' + moment(vm.customerSpendTrendData.endDate).format('Do MMM YY') + ')',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            isStacked: 'true',
            //legend: { position: 'top', alignment: 'end'},
            //width: 1000,
            height: 400,
            vAxis: {
                title: 'Customer Spend in ' + ApplicationContext.getCurrencySym(), gridlines: {count: 6}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: "Month",
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true},
                slantedText: true,
                slantedTextAngle: 45
            },
            //hAxis: {
            //title: 'Month in year',
            // viewWindow: {
            //   min: [7, 30, 0],
            //   max: [23, 30, 0]
            // }
            //},
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            colors: ['#1ABC9C', '#3399CC', '#996699'],
            formatters: {}
        };

        vm.chartObjectTrend = {
            type: 'ColumnChart',
            options: optionsTrend,
            data: dataTrend
        };

        Reporting.setSpendTrendChartData(dataTrend, vm.customerSpendTrendData.chartData);

        return deferred.promise;
    }

    function loadAggregatedByHourChart() {
        var deferred = $q.defer();
        // Aggregated spend
        // Spend by hours
        var dataHours = new google.visualization.DataTable();
        dataHours.addColumn('string', 'Time of Day');
        dataHours.addColumn('number', 'Reservation', 'color: #007E3B');
        dataHours.addColumn('number', 'Walk-In Seated');
        dataHours.addColumn('number', 'Walk-In Waitlisted');
        dataHours.addColumn({type: 'string', role: 'annotation'});

        var optionsHours = {
            title: 'Aggregated customer spend behavior on hourly basis (for inteval: ' + moment(vm.aggrByHourData.startDate).format('Do MMM YY') + ' - ' + moment(vm.aggrByHourData.endDate).format('Do MMM YY') + ')',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            isStacked: 'true',
            //width: 1000,
            height: 400,
            vAxis: {
                title: 'Customer Spend in ' + ApplicationContext.getCurrencySym(), gridlines: {count: 6}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: 'Time of Day',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            colors: ['#1ABC9C', '#3399CC', '#996699'],
            formatters: {}

        };
        vm.chartObjectHours = {
            type: 'ColumnChart',
            options: optionsHours,
            data: dataHours
        };

        Reporting.setSpendTrendChartData(dataHours, vm.aggrByHourData.chartData);

        return deferred.promise;
    }


    function loadAggregatedByDayChart() {
        var deferred = $q.defer();
        // Days statistics
        var dataDays = new google.visualization.DataTable();
        dataDays.addColumn('string', 'Day');
        dataDays.addColumn('number', 'Reservation', 'color: #007E3B');
        dataDays.addColumn('number', 'Walk-In Seated');
        dataDays.addColumn('number', 'Walk-In Waitlisted');
        dataDays.addColumn({type: 'string', role: 'annotation'});

        var optionsDays = {
            title: 'Aggregated customer spend behavior on daily basis (for inteval: ' + moment(vm.aggrByWeekdayData.startDate).format('Do MMM YY') + ' - ' + moment(vm.aggrByWeekdayData.endDate).format('Do MMM YY') + ')',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            isStacked: 'true',
            //width: 1000,
            height: 400,
            vAxis: {
                title: 'Customer Spend in ' + ApplicationContext.getCurrencySym(), gridlines: {count: 6}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: 'Days of the Week',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            colors: ['#1ABC9C', '#3399CC', '#996699'],
            formatters: {}
        };
        vm.chartObjectDays = {
            type: 'ColumnChart',
            options: optionsDays,
            data: dataDays
        };

        Reporting.setSpendTrendChartData(dataDays, vm.aggrByWeekdayData.chartData);

        return deferred.promise;
    }
}

angular.module('seatonApp').controller('CustomerProfileController', CustomerProfileController);
