(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('MeteredController', MeteredController);

    MeteredController.$inject = ['$rootScope', '$state', '$document','MeteredService', 'AppService', 'Notification', 'ParseLinks'];

    function MeteredController($rootScope, $state, $document, MeteredService, AppService, Notification, ParseLinks) {
        var vm = this;

        vm.showMetered = showMetered;
        vm.meteredType = 1;
        vm.selectedMonth = moment().format("YYYY-MM-DD");

        $document.ready(
            function () {
                vm.selectedDate = $document.find('input[name="selectedDate"]');
                vm.selectedDate.daterangepicker(
                    {
                        //configuration parameters
                        singleDatePicker: true,
                        showDropdowns: true,
                        locale: {format: 'MMM YYYY'}
                    },
                    function (start, end, label) {
                        //callback function - called on picker value change - dates sent in callback parameters
                        vm.selectedMonth = start.format("YYYY-MM-DD");
                    }
                );
            }
        );

        vm.openPicker = openPicker;
        function openPicker() {
            vm.selectedDate.focus();
        }

        getAllLocations();

        function getAllLocations() {
            AppService.query({
                page: 0,
                size: 2000
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.locations = data;
            }

            function onError(error) {
                Notification.error(error.data.message);
            }
        }

        function showMetered(location) {
            vm.cloverList = null;
            vm.seatonList - null;
            vm.selectedLocation = location;
            //if(vm.selectedLocation.cloverId != "") {
                MeteredService.metered({
                    locationId: location.id,
                    meteredType: vm.meteredType,
                    selectedMonth: vm.selectedMonth
                }, onSuccessMetered, onErrorMetered);
            //} else {
                //vm.cloverList = null;
                //vm.seatonList - null;
            //}

            function onSuccessMetered(data, headers) {
                vm.cloverList = data.clover;
                vm.seatonList = data.seaton;
            }

            function onErrorMetered(error) {
                Notification.error(error.data.message);
            }
        }
    }
})();
