(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('PublicCancelController', PublicCancelController);

    PublicCancelController.$inject = ['$scope', '$state', '$stateParams', 'Log', 'PublicResource'];

    function PublicCancelController($scope, $state, $stateParams, Log, PublicResource) {

        var vm = this;
        var hashedEventId = $stateParams.eventId;
        vm.canceled = false;
        vm.loadStatus = loadStatus;
        vm.cancelFailed = false;

        Log.debug("cancel.controller", "Hashed event id is '" + hashedEventId + "'");
        if (hashedEventId == undefined || hashedEventId == '') {
            $state.go("public.error");
        }

        function loadStatus() {
            PublicResource.cancelEventDetails({
                hashedEventId: hashedEventId
            }, onSuccess, onError);
        }

        vm.loadStatus();

        function onSuccess(data, headers) {
            vm.data = data;
            if (vm.data.status == "CANCELLED") vm.canceled = true;
        }

        function onError(error) {
            Log.debug("cancel.controller", "HTTP request error due event details request." + error);
        }

        $scope.cancelEvent = function () {
            PublicResource.cancelEvent({
                hashedEventId: hashedEventId
            }, onCancelSuccess, onCancelError);

            function onCancelSuccess(data, headers) {
                vm.cancelFailed = false;
                PublicResource.cancelEventDetails({
                    hashedEventId: hashedEventId
                }, function (data) {
                    vm.cancelFailed = data.status != "CANCELLED";
                    vm.canceled = data.status == "CANCELLED";
                });
            }

            function onCancelError(error) {
                Log.debug("cancel.controller", "HTTP request error due cancel request." + error);
            }
        }

    }
})();



