(function () {
    'use strict';
    angular.module('seatonApp').controller('OnlineReserveController', OnlineReserveController);

    OnlineReserveController.$inject = ['$rootScope', '$scope', '$state', '$document', '$stateParams', 'Log', 'PublicResource',
                                        'OnlineReservationContext'];

    function OnlineReserveController($rootScope, $scope, $state, $document, $stateParams, Log, PublicResource,
                                     OnlineReservationContext) {

    // INITIALIZE
        var vm = this;

        vm.DATE_FORMAT = 'YYYY-MM-DD';
        vm.DATE_PICKER_FORMAT = 'MMM D, YYYY';
        vm.TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
        vm.TIME_DISPLAY_FORMAT = 'hh:mmA';
        vm.MINMAX_PICKER_DATE_FORMAT = 'MM/DD/YYYY';
        vm.moment = moment;

        vm.productNotSelectedError = false;
        vm.todayPicker = vm.moment().format(vm.MINMAX_PICKER_DATE_FORMAT);
        vm.maxReservationDatePicker = "12/31/9999";
        vm.settings = null;
        vm.minPartySize = 1;
        vm.maxPartySize = 6;
        vm.seatOptions = [];
        vm.additionalSeatOptions = null;

        vm.selectedDay = vm.moment().format(vm.DATE_FORMAT);
        vm.selectedProductId = null;
        vm.availability = null;

        // function definition
        vm.init = init;
        vm.getReserveSettings = getReserveSettings;
        vm.initPicker = initPicker;
        vm.getAvailability = getAvailability;
        vm.filterByParams = filterByParams;
        vm.calculateButtonColor = calculateButtonColor;
        vm.alternativeProduct = alternativeProduct;
        vm.onParametersChange = onParametersChange;
        vm.findProductById = findProductById;
        vm.proceedToContactPage = proceedToContactPage;

        // initialize online reserve page
        vm.init();

        function init() {

            // check if location id parameter is present
            vm.hashedLocationId = OnlineReservationContext.getLocationId();
            Log.debug("OnlineReserveController", "Hashed locationId id is '" + vm.hashedLocationId + "'");
            if (!vm.hashedLocationId) {
                // if location id not defined abort and show fatal error message
                onError();
            } else {
                vm.request = OnlineReservationContext.getRequest(); // reservation request -> date, time & party_size, productId
                vm.channel = OnlineReservationContext.getChannel(); // channel

                //Log.debug("OnlineReserveController", "Params: locationId:" + vm.hashedLocationId +
                //    ", resRequest {date:" + vm.request.date + ", time:" + vm.request.time + ", party_size:" + vm.request.party_size +
                //", productId:" + vm.request.productId +" }, reference:"+vm.reference+", channel:" + vm.channel + ".");

                vm.numberOfPersons = vm.request.party_size;

                // if location id defined proceed with initialization
                getReserveSettings();
            }
        }


        function getReserveSettings() {

            PublicResource.getReserveSettings({
                hashedLocationId: vm.hashedLocationId
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.settings = data;
                vm.settings.coordinate = encodeURIComponent(vm.settings.coordinate);
                OnlineReservationContext.storeSettings(vm.settings);

                if(vm.settings.maxReservationDate) {
                    vm.maxReservationDatePicker = moment(vm.settings.maxReservationDate, vm.DATE_FORMAT).format(vm.MINMAX_PICKER_DATE_FORMAT);
                }

                // generate party size list
                vm.minPartySize = vm.settings.minSeats;
                if((vm.settings.maxSeats-vm.settings.minSeats+1)>8) {
                    vm.maxPartySize = vm.minPartySize+7; /// 7 button fit on list
                    vm.additionalSeatOptions = [];
                    for(i=vm.maxPartySize+1; i<=vm.settings.maxSeats; i++) {
                        vm.additionalSeatOptions.push({id:i, label:i+" Persons"});
                    }
                } else {
                    vm.maxPartySize = vm.settings.maxSeats;
                }

                for(var i=vm.minPartySize; i<=vm.maxPartySize; i++) {
                    vm.seatOptions.push(i);
                }

                // DEFAULT VALUES
                    // date
                if(vm.request.date) {
                    vm.selectedDay =vm.request.date;
                } else {
                    vm.selectedDay = moment().format(vm.DATE_FORMAT); // today, always by default today will be choose as reservation date
                }
                vm.selectedDayFormatted = moment(vm.selectedDay, vm.DATE_FORMAT).format(vm.MINMAX_PICKER_DATE_FORMAT); // format to match date picker

                    // time
                if(vm.request.time) {
                    vm.selectedTime = vm.request.time;
                } else {
                    vm.selectedTime = null;
                }

                    // party size
                if(!vm.request.party_size) { // if party size not received outside
                    if (vm.settings.minSeats > vm.numberOfPersons) vm.numberOfPersons = vm.settings.minSeats;
                    else vm.numberOfPersons = 2;
                } else {
                    vm.numberOfPersons = parseInt(vm.request.party_size);
                }

                // default selected product id
                if(vm.settings.productChosenByGuest && vm.settings.productList) { // productList sent from server .. product activated
                    if(vm.request.productId) {
                        vm.selectedProductId = vm.request.productId; // productId received through inout parameters
                    } else if(vm.settings.productType=='PRODUCT' && vm.settings.productList.length>0) {
                        vm.selectedProductId = vm.settings.productList[0].id;
                    } else if(vm.settings.productType=='DURATION') {
                        // select third from the list if available, if not second, if not first, if not leave empty
                        if(vm.settings.productList.length>2) {
                            vm.selectedProductId = vm.settings.productList[2].id;
                        } else if(vm.settings.productList.length>1) {
                            vm.selectedProductId = vm.settings.productList[1].id;
                        } else if(vm.settings.productList.length>0){
                            vm.selectedProductId = vm.settings.productList[0].id;
                        }
                    }
                } else { // product not activated
                    vm.selectedProductId = null; // if product null server will decide about reservation product
                }
                vm.productNotSelectedError=false;

                //Log.debug("OnlineReserveController", " After initialization -> selectedDay:" + vm.selectedDay + ", selectedTime:" + vm.selectedTime
                //    + ", numberOfPersons:" + vm.numberOfPersons + ", selectedProductId:" + vm.selectedProductId +".");

                getAvailability();

                $document.ready(
                    function () {
                        vm.initPicker();
                    }
                );
            }
        }

        function onParametersChange() {
            getAvailability();
        }

        function getAvailability() {

            vm.product = null; // if duration null server choose duration
            vm.productNotSelectedError = false;
            if (vm.settings.productChosenByGuest) {
                // product chosen by guest
                vm.product = findProductById(vm.selectedProductId);
                //Log.debug("OnlineReserveController", "Product found:" + JSON.stringify(vm.product));
                if (!vm.product) { // product not found report error
                    vm.productNotSelectedError = true;
                    return;
                }
            }

            /*if (vm.product != null) {
                Log.debug("OnlineReserveController", "Selected date:" + vm.selectedDay + ", selected number of persons:" + vm.numberOfPersons +
                    ", selected product {id:" + vm.product.id + ", name:"+vm.product.name+"}.");
            }else {
                Log.debug("OnlineReserveController","Selected date:" + vm.selectedDay + ", selected number of persons:" + vm.numberOfPersons + ", product: null.");
            }*/

            PublicResource.getReserveAvailability(
                {
                    hashedLocationId: vm.hashedLocationId
                },{
                    requestedDate: vm.selectedDay,
                    requestedPerson: vm.numberOfPersons,
                    product: vm.product
                }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.availability = data;
            }

        }

        function proceedToContactPage(containerId, selectedTime, containerName) {

            //Log.debug("OnlineCreateLegacyController",
            //    "Selected container id is " + containerId + ". selected timeslot is " + selectedTime);

            vm.selectedTime = selectedTime;
            vm.actionSubmitted = true;
            vm.inputErrorOccured = false;

            if (!vm.selectedTime || !vm.selectedDay) { // if time not selected report error
                vm.inputErrorOccured = true;
                vm.actionSubmitted = false;
                return;
            }

            vm.product = null; // if duration null server choose duration
            if (vm.settings.productChosenByGuest) {
                // product chosen by guest
                vm.product = findProductById(vm.selectedProductId);
                //Log.debug("OnlineCreateLegacyController", "Product found:"+JSON.stringify(vm.product));
                if(!vm.product) { // product not found report error
                    vm.actionSubmitted = false;
                    vm.inputErrorOccured = true;
                    return;
                }
            }

            var request = {
                date: vm.selectedDay,
                time: vm.selectedTime,
                containerId: containerId,
                containerName: containerName,
                party_size: vm.numberOfPersons,
                product: vm.product
            }
            OnlineReservationContext.storeRequest(request);
            OnlineReservationContext.storeReference("RESERVE");

            $state.go("public.online.contact");
        }



        function onError(error) {
            Log.debug("OnlineResereCreateController", "Unexpected error:" + error + ".");
            vm.actionSubmitted = false;
            OnlineReservationContext.storeReference("RESERVE");

            $state.go("public.online.error");
        };

        // initialize date & time picker
        function initPicker () {
            // init daterangepicker
            vm.datePicker = $('#daterangepicker1').daterangepicker({
                singleDatePicker: true,
                parentEl: "#daterangepicker1-container",
                autoApply: true,
                minDate: vm.todayPicker,
                maxDate: vm.maxReservationDatePicker,
                startDate: vm.selectedDayFormatted,
                locale: {
                    format: vm.MINMAX_PICKER_DATE_FORMAT
                }
            });

            // range update listener
            vm.datePicker.on('apply.daterangepicker', function(ev, picker) {
                vm.selectedDay = picker.startDate.format(vm.DATE_FORMAT);
                onParametersChange();
            });

            // prevent hide after range selection
            vm.datePicker.data('daterangepicker').hide = function () {};
            // show picker on load
            vm.datePicker.data('daterangepicker').show();

            // define value
            //TODO naci nacin kako pridodjeliti datum an daterangepicker
            //vm.datePicker.data('daterangepicker').setStartDate(vm.selectedDayFormatted);

        };

        function filterByParams(timeSlot) {

            // do not display if event exceed closing business hours
            if(vm.settings.productType!='MEAL') {
                var selectedProduct = vm.product;
                if(!selectedProduct) selectedProduct = findProductById(vm.selectedProductId);
                //Log.debug("OnlineResereCreateController", "Selected product:" + JSON.stringify(selectedProduct) + ".");
                if (selectedProduct && timeSlot.endWorkingTime) {

                    var endWorkingTime = moment(timeSlot.endWorkingTime, vm.TIME_SERVER_FORMAT);
                    var chosenTimeSlot = moment(timeSlot.time, vm.TIME_SERVER_FORMAT);
                    var endEventTime = moment(chosenTimeSlot).add(selectedProduct.duration, 'minutes');
                    //Log.debug("OnlineResereCreateController", "End time:" + endWorkingTime + ", chosen time slot:" + chosenTimeSlot + ", end event time:" + endEventTime + ".");

                    if (endEventTime.isAfter(endWorkingTime)) {
                        return false;
                    }
                }
            }

            if(timeSlot.availableCapacity>=vm.numberOfPersons && //  if available cover capacity greater than requested number of persons and
                (timeSlot.maxReservationAllowed == -1  ||  // max allowed reservation per time slot not activated or
                timeSlot.maxReservationAllowed > timeSlot.numberOfReservation)) { //  number of event equal or higher then maximum allowed

                return true;
            }
            return false;
        }

        function calculateButtonColor(treshold, timeSlot) {
            if((timeSlot.availableCapacity/timeSlot.capacity*100) < treshold) {
                return "btn-warning"
            }
            return "btn-success";
        }

        function alternativeProduct(productType) {
            if(productType == 'DURATION') {
                return " or duration"
            } else if(productType == 'PRODUCT'){
                return " or product"
            }
        }

        function findProductById(productId) {
            if(vm.settings.productList) {
                for (var i = 0; i < vm.settings.productList.length; i++) {
                    if(vm.settings.productList[i].id == productId)
                        return vm.settings.productList[i];
                }
            }
            // product not found for product id
            return null;
        }

        vm.encodeURL = function() {
            return encode
        }

    }

})();



