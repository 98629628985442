(function () {
    'use strict';

    angular
        .module('seatonApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            //'angular-loading-bar',
            'oc.lazyLoad',
            'googlechart',
            'angularMoment',
            'ui.bootstrap.datepicker',
            'angular.filter',
            //'daterangepicker',
            //'ngFlatDatepicker',
            'toastr',
            'ui.mask',
            'ngTable',
            'ui.checkbox',
            'oitozero.ngSweetAlert',
            'ngSanitize',
            'ui.toggle',
            'angular-intro',
            'ngIntlTelInput',
            'colorpicker',
            'dndLists'
        ])
        .config(['$locationProvider', '$urlRouterProvider', 'ngIntlTelInputProvider', function ($locationProvider, $urlRouterProvider, ngIntlTelInputProvider) {

            ngIntlTelInputProvider.set(
                {
                    initialCountry: 'us',
                    nationalMode: false,
                    formatOnDisplay: true
                });

            /*$urlRouterProvider.when("/clover/access",
             ['$stateParams', function ($stateParams) {
             $state.transitionTo("account.clover", $stateParams);
             }]
             )*/
            //$locationProvider.html5Mode(true);
        }])
        .run(run);

    run.$inject = ['$rootScope', '$localStorage', '$timeout', '$location', 'stateHandler', 'translationHandler', '$state',
        'Principal', 'Notification', 'Log', 'ApplicationContext', '$locale', 'tmhDynamicLocale'];

    function run($rootScope, $localStorage, $timeout, $location, stateHandler, translationHandler, $state,
                 Principal, Notification, Log, ApplicationContext, $locale, tmhDynamicLocale) {


        //TODO branimir cleanup
        if ($localStorage.TimeZone) {
            ApplicationContext.setLanguageAndLocale();
            Log.debug("AppModule", "Application start: $localStorage.TimeZone.timezone: " + $localStorage.TimeZone);

            //if timezone is defined in localStorage then set it
            //moment.tz.setDefault($localStorage.TimeZone);
            //moment.locale('de');
            //$locale.id = 'en-gb';
            //tmhDynamicLocale.changeLanguage($localStorage.Locale);
            //var ret = tmhDynamicLocale.set('en-gb');
            //console.log(ret);
        }

        stateHandler.initialize();
        translationHandler.initialize();

        // consuming please-wait events, please-wait interceptor on http request and reponse thros those events
        $rootScope.$on('_start-please_wait_', function () {
            // show div block defined in begining of app_layout.html
            $rootScope.show_loading_started = true;
            $timeout(delayShowingPleaseWait, 250);
        });

        $rootScope.$on('_end-please_wait_', function () {
            // hide div block defined in begining of app_layout.html
            $rootScope.show_loading = false;
            $rootScope.show_loading_started = false;
        });

        //if _end-please_wait_ is not received for delayed interval, then show please wait message
        function delayShowingPleaseWait() {
            if ($rootScope.show_loading_started == true) {
                $rootScope.show_loading = true;
            }
        }

        $rootScope.$on('authenticationSuccess', function () {
            Principal.identity(true);
        });

    }

})();
