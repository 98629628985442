(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('LocationsController', LocationsController);

    LocationsController.$inject = ['$rootScope', '$state', 'Locations', 'ParseLinks', 'Notification', 'ApplicationContext', 'Auth', '$window', '$http', '$localStorage'];

    function LocationsController($rootScope, $state, Locations, ParseLinks, Notification, ApplicationContext, Auth, $window, $http, $localStorage) {
        var vm = this;

        vm.showEmployees = showEmployees;
        vm.logAs = logAs;
        vm.logout = logout;

        getAllLocations();

        function getAllLocations() {
            Locations.query({
                page: 0,
                size: 2000
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.locations = data;
            }

            function onError(error) {
                Notification.error(error.data.message);
            }
        }

        function showEmployees(location) {
            vm.selectedLocation = location;

            Locations.employees({
                id: location.id
            }, onSuccessEmployee, onErrorEmployee);

            function onSuccessEmployee(data, headers) {
                vm.employees = data;
            }

            function onErrorEmployee(error) {
                Notification.error(error.data.message);
            }
        }

        function logAs(employee, asAdmin) {

            ApplicationContext.storeLocation(vm.selectedLocation);
            ApplicationContext.storeAppContext("ADMIN",vm.selectedLocation.cloverId, employee.cloverId,"ADMIN ACCESS");
            //moment.tz.setDefault(vm.selectedLocation.timezone);
            ApplicationContext.setLanguageAndLocale();

            console.log("Log as employee :"+ employee.name +": with employee cloverID :" + employee.cloverId +": for location with id :"
                + vm.selectedLocation.id+":");

            Notification.info("Log as " + ApplicationContext.getEmployeeID() + " for location with id :"
                + ApplicationContext.getLocationID() + ":. Timezone is:" + ApplicationContext.getTimeZone()+":");

            if(asAdmin) {
                console.log("Login with ADMIN role.");
                $http.get('api/auth/admin/log-as/' + vm.selectedLocation.id + '/employee/' + employee.cloverId + '?asAdmin=true', {}).success(authenticateSuccess);
            } else {
                console.log("Login using user role.")
                $http.get('api/auth/admin/log-as/' + vm.selectedLocation.id + '/employee/' + employee.cloverId + '?asAdmin=false', {}).success(authenticateSuccess);
            }

            // on success update token in local storage and go to home state
            // new token contain location details of location with which admin user will work
            function authenticateSuccess(data, status, headers) {

                var bearerToken = headers('Authorization');
                if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
                    var jwt = bearerToken.slice(7, bearerToken.length);

                    $rootScope.$broadcast('authenticationSuccess'); // listener for this event is defined in app.module.js

                    $localStorage.authenticationToken = jwt;
                    ApplicationContext.storeWarnings(false, data.openingHourWarn);
                    $state.go("home");

                    return jwt;
                }
            }
        }

        function logout() {
            Auth.logout();
            $state.go('account.login');
        }


    }
})();
