'use strict';

OnlineListReservationController.$inject = ['$scope', '$state', 'ApplicationContext', '$uibModal', 'Tables', 'Utils', 'SweetAlert'];

function OnlineListReservationController($scope, $state, ApplicationContext, $uibModal, Tables, Utils, SweetAlert) {

    var vm = this;
    vm.utils = Utils;

    vm.diffInMinutes = function (time) {
        var diff = -Math.round(moment(time).diff(moment()) / 1000 / 60);
        return diff;
    }
    //var online.reservations = 3;

    vm.getPreresevedReservations = function () {

        Tables.getPreresevedReservations(
            {
                from: moment().startOf('day').toISOString(),
                to: moment().add(1, 'year').toISOString(),
                page: vm.currentPage - 1,
                size: vm.itemsPerPage
            },
            function (data) {
                vm.reservations = data.content;
                vm.totalItems = data.totalElements;
            }
        );
    }

    vm.pageChanged = function () {
        vm.getPreresevedReservations();
    };

    // paging data
    vm.currentPage = 1;
    vm.itemsPerPage = 25;

    vm.getPreresevedReservations();

    vm.showAssignDialog = function () {
        console.log('Test');
        vm.assignDialog = $uibModal.open({
            templateUrl: 'app/tables/online-res/assign.dialog.html',
            scope: $scope
        });
    }

    vm.triggerStateChange = function (id, trigger, confirmMsg, params) {

        if (confirmMsg) {
            SweetAlert.swal({
                    title: "Please confirm action",
                    text: confirmMsg + id,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        triggerStateChangeOnServer(id, trigger, params);
                    }
                });
        } else {
            triggerStateChangeOnServer(id, trigger, params);
        }
    }

    function triggerStateChangeOnServer(id, trigger, params) {
        var postParams = params ? params : {};
        postParams.id = id;
        postParams.trigger = trigger;

        Tables.triggerStateChange(
            postParams,
            function (data) {
                vm.getPreresevedReservations();
            });
    }

    vm.findTables = function (event) {
        $state.go('tables.newreservation', {prereservedEventId: event.id});
    }
}

angular.module('seatonApp').controller('OnlineListReservationController', OnlineListReservationController);
