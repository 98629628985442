(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('Reporting', Reporting);

    Reporting.$inject = ['$filter'];

    function Reporting($filter) {
        function setSpendTrendChartData(chartObj, chartData) {
            var max = _.max(chartData, function (o) {
                return o.sumTotal;
            });
            var z = max.sumTotal == 0 ? 0 : 0.01;

            _.each(chartData, function (item) {
                var sumReservation = item.sumReservation == 0 ? z : item.sumReservation;
                var sumSeated = item.sumSeated == 0 ? z : item.sumSeated;
                var sumWaitlist = item.sumWaitlist == 0 ? z : item.sumWaitlist;

                chartObj.addRow([item.intervalUnit,
                    {
                        v: sumReservation,
                        f: $filter('currency')(sumReservation)
                    },
                    {
                        v: sumSeated,
                        f: $filter('currency')(sumSeated)
                    },
                    {
                        v: sumWaitlist,
                        f: $filter('currency')(sumWaitlist)
                    },
                    $filter('currency')(item.sumTotal)]);
            });
        }

        function calcPct(a, b) {
            if (b == 0)
                return a == 0 ? 0 : 1;
            else
                return (a - b) / b;
        }

        function divide(a, b) {
            if (b == 0)
                return a == 0 ? 0 : 1;
            else
                return a / b;
        }

        function calcAggrByWeekdayDataItem(day_data, locationStats) {
            if (typeof day_data === 'undefined') return day_data;
            //calculate percentage vs general location stats
            day_data.calcAmountAvgPerCoverPct = calcPct(day_data.amountAvgPerCover, locationStats.avgSpendByCover);
            day_data.calcTipAvgPct = calcPct(day_data.tipAvg, locationStats.avgTipPerVisit);
            day_data.calcCoversPerVisitPct = calcPct(day_data.coversPerVisit, locationStats.avgCoversPerVisit);
            return day_data;
        }

        function allZeroesInPieChartData(pieChartRows) {

            for (var i = 0; i < pieChartRows.length; i++) {
                //console.log('i:'+i+ ' pieChartRows[i].c[1].v: '+pieChartRows[i].c[1].v);
                if (pieChartRows[i].c[1].v != 0) return false;
            }
            return true;
        }

        return {
            setSpendTrendChartData: setSpendTrendChartData,
            calcPct: calcPct,
            divide: divide,
            calcAggrByWeekdayDataItem: calcAggrByWeekdayDataItem,
            allZeroesInPieChartData: allZeroesInPieChartData
        };
    }
})();




