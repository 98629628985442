(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('customers', {
            abstract: true,
            parent: 'app',
            url: '/customers',
            data: {
                roles: ['ROLE_USER']
            }
        });
    }
})();
