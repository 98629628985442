(function() {

    'use strict';

    CustomerSearchCtrl.$inject = ['$scope', '$state', 'CustomerSearch'];

    function CustomerSearchCtrl($scope, $state, CustomerSearch) {

        var vm = this;

        vm.currentPage = 1;
        vm.itemsPerPage = 5;

        // this will be two-way bound with some property from the parent field (look below)
        // if you want to perform some action on it just use $scope.$watch
        $scope.customerSearchKeyword;

        vm.refreshList = function() {

            if (!$scope.customerSearchKeyword) return; // can not search if keyword not defined

            CustomerSearch.searchCustomers(
                {
                    keyword: $scope.customerSearchKeyword,
                    page: vm.currentPage - 1,
                    size: vm.itemsPerPage
                },
                function (data) {
                    vm.customers = data.content;
                    vm.totalItems = data.totalElements;
                 });
        }

        vm.customerSelected = function(customer) {
            // send the selected customer to parent controller
            $scope.customerSelectedCallback(customer);
            vm.close();
        };

        /** Called when user changes a page */
        vm.pageChanged = function() {
            vm.refreshList();
        };

        vm.close = function() {
            // the same instance that was created in element.on('click',...)
            $scope.modalInstance.close();
        }
    }

    angular.module('seatonApp').controller('CustomerSearchCtrl', CustomerSearchCtrl);

})();
