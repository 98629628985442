(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('settings', {
            abstract: true,
            parent: 'app',
            url: '/settings',
            data: {
                roles: ['ROLE_ADMIN', 'ROLE_OWNER', 'ROLE_MANAGER']
            },
            resolve: {
                googleChartApi: function (googleChartApiPromise) {
                    return googleChartApiPromise;
                }
            }
        });
    }
})();

