(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('account.error', {
                parent: 'account',
                url: '/error',
                data: {
                    authorities: [],
                    specialClass: " gray-bg"
                },
                views: {
                    'layout@': {
                        templateUrl: 'app/layouts/clover_layout.html'
                    },
                    'content@account.error': {
                        templateUrl: 'app/account/error/error.html',
                        controller: 'ErrorController',
                        controllerAs: 'vm'
                    },
                    'navbar@app': {},
                    'topnavbar@app': {}
                    //'footer@app': {
                    //    templateUrl: 'app/layouts/footer/footer.html'
                    //}

                },
                params: {
                    "errorCode": '',
                    "errorMessage": '',
                    "contExec": ''
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('account.clover.error');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
