(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('GoogleIntegrationController', GoogleIntegrationController);

    GoogleIntegrationController.$inject = ['$scope', 'SettingsResource', 'Log', 'ApplicationContext'];

    function GoogleIntegrationController($scope, SettingsResource, Log, ApplicationContext) {

        var vm = this;
        vm.scrollToTop = scrollToTop;

        vm.onlineResGoogleUrl = getBaseURL() + '?channel=google#/public/online/reservation/' + data.hashedLocationId;

        function getBaseURL() {
            var baseUrl = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port) {
                baseUrl += ':' + window.location.port;
            }
            return baseUrl;
        }

        // When the user clicks on the button, scroll to the top of the document
        function scrollToTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }

    }
})();



