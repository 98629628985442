(function () {
    'use strict';
    angular.module('seatonApp').controller('OnlineProxyReservationController', OnlineProxyReservationController);

    OnlineProxyReservationController.$inject = ['$state', '$stateParams', 'Log', 'PublicResource', 'OnlineReservationContext'];

    function OnlineProxyReservationController($state, $stateParams, Log, PublicResource, OnlineReservationContext) {

    // INITIALIZE
        var vm = this;

        OnlineReservationContext.clear();
        vm.locationId = $stateParams.locationId;
        Log.debug("OnlineProxyReservationController", "Hashed locationId id is '" + vm.locationId + "'");
        if (!vm.locationId) {
            // if location not defined abort and show fatal error message
            onError();
        } else {
            // if location id defined proceed with proxy initialization
            vm.queryParams = getQueryParams();
            PublicResource.getReservationPage({
                hashedLocationId: vm.locationId
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.reservationPage = data.reservationPage;
            vm.channel = getChannel();
            OnlineReservationContext.storeLocationChannelPage(vm.locationId, vm.channel, vm.reservationPage);

            var request = {
                date: vm.queryParams.date,
                time: vm.queryParams.time,
                party_size: vm.queryParams.party_size,
                productId: vm.queryParams.productId
            }
            OnlineReservationContext.storeRequest(request);
            OnlineReservationContext.storeReference("PROXY");

            Log.debug("OnlineProxyReservationController", "Page: " + vm.reservationPage + ", Params: locationId:" + vm.locationId +
                ", resRequest {date:" + request.date + ", time:" + request.time + ", party_size:" + request.party_size +
                ", productId:" + request.productId +" }, reference: PROXY, channel:" + vm.channel + ".");

            Log.debug("Local storage:"+OnlineReservationContext.getChannel());

            if("LEGACY" == vm.reservationPage) {
                $state.go("public.online.legacy");
            } else if ("RESERVE" == vm.reservationPage) {
                $state.go("public.online.reserve");
            } else {
                onError("Not recognized reservation page :"+vm.reservationPage+":");
            }
        }

        function onError(error) {
            Log.debug("OnlineProxyReservationController", "Unexpected error:" + error + ".");
            vm.actionSubmitted = false;

            $state.go("public.online.error");
        }

        //parse query parameters from URL (when coming from reservation widget)
        function getQueryParams() {
            var queryParams = {};
            var pairs = window.location.search.substring(1).split(/[&?]/);
            var i, pair;

            for (i = 0; i < pairs.length; i++) {
                pair = pairs[i].split('=');
                if (pair[1])
                    queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
            }

            Log.debug("OnlineReservationCreate","Query params, date:"+queryParams.date+", time:"+queryParams.time
                +", party_size:"+queryParams.party_size+".");
            return queryParams;
        }

        // fetch reservation channel
        function getChannel() {
            var channel = vm.queryParams.channel;
            if (channel) {
                var channelUpper = channel.toUpperCase();
                if (channelUpper == "FACEBOOK" || channelUpper == "GOOGLE" || channelUpper == "MERCHANT_WEB") {
                    return channelUpper;
                }
            } else {
                var referrer = document.referrer;
                if (referrer) {
                    if (referrer.toUpperCase().indexOf("FACEBOOK") != -1) {
                        return "FACEBOOK";
                    } else if (referrer.toUpperCase().indexOf("GOOGLE") != -1) {
                        return "GOOGLE";
                    }
                }
            }
            return "MERCHANT_WEB"; // default
        }


    }

})();



