'use strict';

angular.module('seatonApp').controller('SettingsEmployeesController', SettingsEmployeesController);

SettingsEmployeesController.$inject = ['$q', '$state', '$stateParams', 'EmployeeResource', 'EmployeeService', 'locations', 'LocationResource', 'NgTableParams'];

function SettingsEmployeesController($q, $state, $stateParams, EmployeeResource, EmployeeService, locations, LocationResource, NgTableParams) {
    var vm = this;
    vm.view_mode = 'list'; // 'list', 'box'

    vm.filter = vm.currentFilter = {};
    vm.employee = {};
    vm.locations = locations;
    vm.employees = [];
    vm.selectedAuthority = {
        authority: null
    };
    vm.password_reset = {
        password: '',
        confirm_password: '',
        reset_password: false,
        show_password: false
    };
    vm.loaded = false;

    vm.queries = [
        {name: 'name', direction: 'asc', sort: 'name,desc', filter: {}, desc: 'Sort by name'},
        {
            name: 'avgRevenuePerCover',
            direction: 'desc',
            sort: 'avgRevenuePerCover,desc',
            filter: {},
            desc: 'Sort by average revenue per cover'
        },
        {name: 'noShowRate', direction: 'desc', sort: 'noShowRate,desc', filter: {}, desc: 'Sort by no show rate'},
        {name: 'rating', direction: 'desc', sort: 'rating,desc', filter: {}, desc: 'Sort by ratings'}
    ];
    vm.selectedQuery = getSelectedQuery();

    vm.tableParams = new NgTableParams({
        sorting: {
            name: 'asc',
            avg_revenue_per_cover: 'desc'
        }
    }, {
        counts: [], // disable pagination
        defaultSort: 'asc',
        getData: function (params) {
            var data = vm.employees;
            if (vm.selectedQuery) {
                data = _.sortBy(vm.employees, vm.selectedQuery.name);
                if (vm.selectedQuery.direction === 'desc') {
                    data = data.reverse();
                }
            }
            return data;
        }
    });

    function loadEmployees(locations) {
        return EmployeeResource.getAllEmployees({}, function (data) {
            var enriched = _(data).map(function (emp) {
                return EmployeeService.enrichEmployee(emp, locations);
            });
            vm.employees = enriched;
            vm.loaded = true;
            return enriched;
        }).$promise;
    }

    vm.load = function () {
        loadEmployees(locations);
    };

    vm.load();

    function _getById(id) {
        return _.find(vm.employees, function (employee) {
            return employee.id === id;
        });
    }

    function getSelectedQuery() {
        return _.find(vm.queries, function (q) {
            //return q.name === ($stateParams.query || ViewState.get());
            //TODO CHECK!!
            return q.name === ($stateParams.query );
        }) || vm.queries[0];
    }

    vm.reload = function () {
        vm.tableParams.sorting(vm.selectedQuery.name, vm.selectedQuery.direction);
    };

    vm.back = function () {
        $state.go('^');
    };

    vm.edit = function (id) {
        vm.password_reset.password = "";
        vm.password_reset.confirm_password = "";
        vm.password_reset.resetpassword = false;
        if (id) {
            vm.employee = angular.copy(_getById(id));
            delete vm.employee.role;
            delete vm.employee.location;
            vm.selectedAuthority.authority = vm.employee.authorities[0];
        } else {
            vm.employee = {};
        }
        $state.go('settings.employees.edit', {id: id});
    };

    vm.view = function (id) {
        $state.go('settings.employees.view', {id: id});
    };

    //TODO branimir check
    vm.save = function () {
        vm.employee.authorities = [vm.selectedAuthority.authority];
        if (vm.password_reset.resetpassword && vm.password_reset.password && vm.password_reset.password === vm.password_reset.confirm_password) {
            vm.employee.password = vm.password_reset.password;
        }
        EmployeeResource.save(vm.employee, function () {
            vm.load();
            $state.go('^');
        });
    };


}
