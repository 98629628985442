'use strict';

CloverController.$inject = ['$rootScope', '$scope','$q', '$state', '$stateParams', 'CloverResource', 'AuthServerProvider',
    'ApplicationContext', 'Principal', 'Log'];

function CloverController($rootScope, $scope, $q, $state, $stateParams, CloverResource, AuthServerProvider,
                          ApplicationContext, Principal, Log) {
    var vm = this;

    vm.merchant_id = $stateParams.merchant_id;
    vm.employee_id = $stateParams.employee_id;
    vm.client_id = $stateParams.client_id;
    vm.code = $stateParams.code;

    ApplicationContext.storeAppContext("CLOVER", vm.merchant_id, vm.employee_id, vm.client_id);

    vm.syncNeeded = false;
    vm.configNeeded = false;
    vm.appStarting = true;
    vm.syncStarted = false;
    vm.syncCompleted = false;
    vm.openingHourWarn = false;
    vm.reactivated = false;
    vm.hasWarning = false;

    Log.info("CloverAccess","CLOVER access with MERCHANT_ID:" + vm.merchant_id + ": and EMPLOYEE_ID:" + vm.employee_id +
    ": and CLIENT_ID:" + vm.client_id + ": and CODE:" + vm.code);

    if (vm.merchant_id == undefined || vm.employee_id == undefined || vm.client_id == undefined || vm.code == undefined) {

        Log.error("CloverAccess", "CLOVER access bad url!");
        var errorMsg = "CLOVER access bad url! " + "CLOVER access with MERCHANT_ID:" + vm.merchant_id +
            ": and EMPLOYEE_ID:" + vm.employee_id + ": and CLIENT_ID:" + vm.client_id;

        var params = { 'errorCode': 'S001', 'errorMessage': errorMsg, 'contExec': false};
        $state.go("account.error", params)

    } else {
        authorizeClover();
    }


    // check if this is first access to Clover, if yes synchronize
    // authorize employee
    // if authorization ok go to state "home"
    function authorizeClover() {

        $q.all([
            executeAuthQuery()
        ]).then(authThen);

        function executeAuthQuery() {
            return CloverResource.cloverAuthorization({
                merchant_id: vm.merchant_id,
                employee_id: vm.employee_id,
                client_id: vm.client_id,
                code: vm.code
            }, function (data) {
                vm.cloverAuthData = data;
                vm.syncNeeded = vm.cloverAuthData.syncNeeded;
                vm.configNeeded = !vm.cloverAuthData.setupCompleted;
                vm.openingHourWarn = vm.cloverAuthData.openingHourWarn;
                vm.reactivated = vm.cloverAuthData.reactivated;
                if(vm.reactivated) {
                    $rootScope.initialStart = true;
                }
                vm.hasWarning = (vm.configNeeded || vm.openingHourWarn);
            }).$promise;
        }

        function authThen (data) {
            var deferred = $q.defer();
            Log.debug("CloverAccess", "Clover Auth Data jwt=" + vm.cloverAuthData.jwt +": authorized:"+ vm.cloverAuthData.authorized
                +": syncNeded:"+ vm.cloverAuthData.syncNeeded+": setupCompleted:"+ vm.cloverAuthData.setupCompleted);

            if (vm.cloverAuthData.authorized) {
                // authorization succeed
                Log.info("CloverAccess", "Successful authorization!");
                accessGranted();
                if(!vm.hasWarning && !vm.reactivated) {
                    $state.go("home");
                } else {
                    if(vm.configNeeded) $rootScope.configNotCompleted = true;
                    vm.appStarting = false;
                }

            } else if (vm.cloverAuthData.syncNeeded) {
                // authorization failed, sync needed, call sync function
                Log.info("CloverAccess", "Sync needed!");
                executeSync();
            } else {
                Log.error("CloverAccess", "Authorization failed.");
            }

            return deferred.promise;
        }
    }

    function executeSync() {
        vm.appStarting = false;
        vm.syncStarted = true;

        $q.all([
            executeSyncQuery()
        ]).then(syncThen);

        function executeSyncQuery() {
            return CloverResource.cloverSync({
                merchant_id: vm.merchant_id,
                employee_id: vm.employee_id,
                client_id: vm.client_id,
                code: vm.code
            }, function (data) {
                vm.cloverAuthData = data;
            }).$promise;
        }

        function syncThen (data) {
            var deferred = $q.defer();
            Log.debug("CloverAccess", "Successful authorization!");
            Log.debug("CloverAccess", "Clover Auth Data jwt=" + vm.cloverAuthData.jwt +": authorization:"+ vm.cloverAuthData.authorization
                +": needSync:"+ vm.cloverAuthData.needSync+": needSetupCompleted:"+ vm.cloverAuthData.needSetupCompleted);

            vm.syncStarted = false;
            vm.syncCompleted = true;
            vm.openingHourWarn = vm.cloverAuthData.openingHourWarn;
            Log.error("CloverAccess", "Opening hours not defined = "+vm.openingHourWarn);

            $rootScope.initialStart = true;
            vm.configNeeded = true;

            accessGranted();

            return deferred.promise;
        }

    }

    function accessGranted() {
        // set context, token and location in local storage
        AuthServerProvider.storeAuthenticationToken(vm.cloverAuthData.jwt, true);
        ApplicationContext.storeLocation(vm.cloverAuthData.location);

        Log.info("CloverAccess", "Timezone set to "+vm.cloverAuthData.location.timezone);
        //moment.tz.setDefault(vm.cloverAuthData.location.timezone);
        ApplicationContext.setLanguageAndLocale();
        ApplicationContext.storeWarnings(vm.configNeeded, vm.openingHourWarn);
        $rootScope.configNotCompleted = vm.configNeeded;

        moment.tz.setDefault(vm.cloverAuthData.location.timezone);

        // define principal
        Principal.identity();

    }

    // redirects
    $scope.configure = function() {
        $state.go("settings.floor");
    };

    $scope.home = function() {
        $state.go("home");
    };



    $scope.setResourceTypeAndOpenApp = function(resourceTypeValue) {

        CloverResource.setResourceType({resourceType: resourceTypeValue}, openApp);

        function openApp() {
            ApplicationContext.setResourceType(resourceTypeValue);
            $state.go("home");
        }
    };

    // content controllers
    $scope.starting = function() {
        return vm.appStarting;
    };

    $scope.syncStarted = function() {
        return (vm.syncNeeded && vm.syncStarted);
    };

    $scope.syncCompleted = function() {
        return ((vm.syncNeeded && vm.syncCompleted) || vm.reactivated);
    };

    $scope.hasWarning = function() {
        return (!vm.syncNeeded && vm.hasWarning && !vm.reactivated);
    };

    $scope.configNotCompleted = function() {
        return (vm.configNeeded);
    };

    $scope.openingHoursWarning = function() {
        return (vm.openingHourWarn);
    }

}

angular.module('seatonApp').controller('CloverController', CloverController);
