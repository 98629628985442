'use strict';

AggregatedController.$inject = ['$scope', 'Principal', '$q', 'CustomerResource', 'googleChartApiPromise', '$filter',
    'Statistics', 'Reporting', 'ApplicationContext', 'ApplicationProfile'];

function AggregatedController($scope, Principal, $q, CustomerResource, googleChartApiPromise, $filter, Statistics,
                              Reporting, ApplicationContext, ApplicationProfile) {
    var vm = this;

    vm.reload = reload;
    vm.isMeal = (ApplicationContext.getProductType() == 'MEAL');

    vm.aggrSpendType = 'byhour';
    vm.aggrSpendInterval = 12;
    vm.aggrCustomerType = 'all';
    vm.aggregatedSpendChartLoaded = false;
    vm.aggregatedSpendChartCurrent = '-';

    vm.avgSpendPerCover = -1;

    vm.reportAggregatedRevenue = ApplicationProfile.isReportAggregatedRevenueEnabled();
    vm.reportAggregatedRevenueText = ApplicationProfile.upgradeTierText("ReportAggregatedRevenue");
    vm.openWindow = ApplicationProfile.openAppCloverPage;

    if (vm.reportAggregatedRevenue) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
            load();
        });
    }

    function reload() {
        loadData();
    }

    function loadData() {
        if (vm.aggrSpendType == 'byhour') loadAggregatedByHourData().then(displayAggregatedByHourChart).then($(window).resize);
        if (vm.aggrSpendType == 'byday') loadAggregatedByWeekdayData().then(displayAggregatedByDayChart).then($(window).resize);
    }

    function load() {
        $q.all([
            googleChartApiPromise,
            loadAggregatedByHourData(),
            loadLocationStats()
        ]).then(displayAggregatedByHourChart).then($(window).resize);
    }

    function loadLocationStats() {
        return Statistics.getLocationRegularsStats({}, function (data) {
            //TODO check - reload on each selection change - all customers, regulars, non regulars - ?
            vm.customerLocationStats = data;
            vm.avgSpendPerCover = vm.customerLocationStats.avgSpendByCover;
        }).$promise;
    }

    function loadAggregatedByHourData() {
        return CustomerResource.getAggregateByHourData({
            id: 0,
            months: vm.aggrSpendInterval,
            customerType: vm.aggrCustomerType
        }, function (data) {
            vm.aggrByHourData = data;
        }).$promise;
    }

    function loadAggregatedByWeekdayData() {
        return CustomerResource.getAggregateByWeekdayData({
            id: 0,
            months: vm.aggrSpendInterval,
            customerType: vm.aggrCustomerType
        }, function (data) {
            vm.aggrByWeekdayData = data;
            vm.aggrByWeekdayDetailData = vm.aggrByWeekdayData.detailData;
            //calc stat pcts. vs location stats
            //_.each(vm.aggrByWeekdayData.detailData, calcAggrByWeekdayDataItem);
            for (var i = 0; i < vm.aggrByWeekdayData.detailData.length; i++)
                Reporting.calcAggrByWeekdayDataItem(vm.aggrByWeekdayData.detailData[i], vm.customerLocationStats);
        }).$promise;
    }

    function displayAggregatedByHourChart() {
        var deferred = $q.defer();
        // Aggregated spend
        // Spend by hours
        var dataHours = new google.visualization.DataTable();
        dataHours.addColumn('string', 'Time of Day');
        dataHours.addColumn('number', 'Reservation', 'color: #007E3B');
        dataHours.addColumn('number', 'Walk-In Seated');
        dataHours.addColumn('number', 'Walk-In Waitlisted');
        dataHours.addColumn({type: 'string', role: 'annotation'});

        var optionsHours = {
            title: 'Aggregated revenue on hourly basis',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            legend: {position: 'top', alignment: 'end'},
            isStacked: 'true',
            chartArea: {width: '90%', height: '70%'},
            //width: 1000,
            height: 450,
            vAxis: {
                title: 'Revenue in ' + ApplicationContext.getCurrencySym(),
                textPosition: 'none',
                gridlines: {count: 13}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: 'Time of Day',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            colors: ['#1ABC9C', '#3399CC', '#996699'],
            formatters: {}

        };

        vm.chartObjectHours = {
            type: 'ColumnChart',
            options: optionsHours,
            data: dataHours
        };

        Reporting.setSpendTrendChartData(dataHours, vm.aggrByHourData.chartData);

        return deferred.promise;
    }

    function displayAggregatedByDayChart() {
        var deferred = $q.defer();
        // Days statistics
        var dataDays = new google.visualization.DataTable();
        dataDays.addColumn('string', 'Day');
        dataDays.addColumn('number', 'Reservation', 'color: #007E3B');
        dataDays.addColumn('number', 'Walk-In Seated');
        dataDays.addColumn('number', 'Walk-In Waitlisted');
        dataDays.addColumn({type: 'string', role: 'annotation'});

        var optionsDays = {
            title: 'Aggregated revenue on daily basis',
            titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
            isStacked: 'true',
            //width: 1000,
            height: 400,
            vAxis: {
                title: 'Revenue in ' + ApplicationContext.getCurrencySym(),
                textPosition: 'none',
                gridlines: {count: 13}, viewWindow: {min: 0},
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },
            hAxis: {
                title: 'Days of the Week',
                titleTextStyle: {color: '#1ab394', fontSize: 14, bold: true}
            },

            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontSize: 11,
                    bold: 'true',
                    color: '#000',
                    auraColor: 'none'
                }
            },
            colors: ['#1ABC9C', '#3399CC', '#996699'],
            formatters: {}
        };

        vm.chartObjectDays = {
            type: 'ColumnChart',
            options: optionsDays,
            data: dataDays
        };

        Reporting.setSpendTrendChartData(dataDays, vm.aggrByWeekdayData.chartData);

        return deferred.promise;
    }

}

angular.module('seatonApp').controller('AggregatedController', AggregatedController);
