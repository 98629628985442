(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('FeedbackController', FeedbackController);

    FeedbackController.$inject = ['$scope', '$state', '$stateParams', 'Log', 'PublicResource'];

    function FeedbackController($scope, $state, $stateParams, Log, PublicResource) {

        var vm = this;
        vm.submitted = false;
        vm.rating = 0;
        vm.feedback = "";

        var hashedEventId = $stateParams.eventId;
        Log.debug("queue.controller","Hashed event id is '"+hashedEventId+"'");
        if(hashedEventId==undefined || hashedEventId=='') {
            $state.go("public.error");
        }


        PublicResource.getFeedbackDetails({
            hashedEventId: hashedEventId
        }, onSuccess, onError);

        function onSuccess(data, headers) {
            vm.data = data;
        }

        function onError(error) {
            Log.debug("feedback.controller", "HTTP request error." + error);
        }

        $scope.submitFeedback = function() {
            Log.debug("feedback.controller", "Submit feedback rating="+vm.rating+" text="+vm.feedback);

            vm.data.rating = vm.rating;
            vm.data.feedback = vm.feedback;

            PublicResource.saveFeedback({
                hashedEventId: hashedEventId
            }, vm.data, onSuccessSave, onErrorSave);

            function onSuccessSave(data, headers) {
                vm.submitted=true;
            }

            function onErrorSave(error) {
                Log.debug("feedback.controller", "HTTP request error." + error);
            }
        }
    }
})();



