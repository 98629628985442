angular.module('seatonApp')
    .directive('allergyList', allergyListCtrl);

function allergyListCtrl() {
    'use strict';
    return {
        scope: {
            'selectedAllergies': '=model',
            'debug': '@'
        },
        restrict: 'E',
        transclude: false,
        templateUrl: 'app/customer/create/partials/allergy_list.html',
        controllerAs: 'allergyCtrl',
        bindToController: true,
        controller: allergyCtrl
    };
}

allergyCtrl.$inject = ['$scope'];
function allergyCtrl($scope) {



    var ctrl = this;
    ctrl.codelists = $scope.$root.codelists;
    ctrl.predefinedAllergies = {};
    ctrl.otherAllergies = [];

    ctrl._allergies = [
        {name: 'Peanuts', value: 'PEANUTS'},
        {name: 'Milk', value: 'MILK'},
        {name: 'Soy', value: 'SOY'},
        {name: 'Eggs', value: 'EGGS'},
        {name: 'Wheat', value: 'WHEAT'},
        {name: 'Tree nuts', value: 'TREE_NUTS'},
        {name: 'Fish', value: 'FISH'},
        {name: 'Shellfish', value: 'SHELLFISH'},
        {name: 'Seeds', value: 'SEEDS'},
        {name: 'Other', value: 'OTHER'}
    ];

    function load() {
        if (_.isUndefined(ctrl.selectedAllergies)) {
            ctrl.selectedAllergies = {};
        }
        _.each(ctrl._allergies, function (allergy) {
            if ('OTHER' !== allergy.value) {
                var isChecked = false;
                if (ctrl.selectedAllergies[allergy.name]) {
                    isChecked = true;
                }
                ctrl.predefinedAllergies[allergy.name] = {
                    checked: isChecked,
                    allergy: {otherName: allergy.name, allergyType: allergy.value}
                };
            }
        });
        ctrl.otherAllergies = [];
        _.each(ctrl.selectedAllergies, function (allergy) {
            if ('OTHER' === allergy.allergyType) {
                ctrl.otherAllergies.push(allergy);
            }
        });
    }

    load();

    $scope.$watch(angular.bind(ctrl, function () {
            return this.selectedAllergies;
        }),
        function (newVal, oldVal) {
            if (newVal) {
                load();
            }
        });

    ctrl.newAllergy = function (el) {
        ctrl.selectedAllergies[el.otherName] = _.extend(el, {'allergyType': 'OTHER'});
    };

    ctrl.removeAllergy = function (el) {
        delete ctrl.selectedAllergies[el.otherName];
    };

    ctrl.updateAllergies = function (av) {
        if (av.checked === true && _.isUndefined(ctrl.selectedAllergies[av.allergy.otherName])) {
            ctrl.selectedAllergies[av.allergy.otherName] = av.allergy;
        } else if (av.checked === false && av.allergy.otherName in ctrl.selectedAllergies) {
            delete ctrl.selectedAllergies[av.allergy.otherName];
        }
    };

}
