(function() {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('account.locations', {
            parent: 'account',
            url: '/locations',
            data: {
                authorities: [],
            },
            views: {
                'layout@': {
                    templateUrl: 'app/layouts/login_layout.html'
                },
                'content@account.locations': {
                    templateUrl: 'app/account/locations/locations.html',
                    controller: 'LocationsController',
                    controllerAs: 'vm'
                },
                'navbar@app': {},
                'footer@app': {}
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('account.locations');
                    return $translate.refresh();
                }]
            },
        });
    }
})();
