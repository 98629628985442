'use strict';

function ngEnterDirective() {
    var link = function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.ngEnter);
                });

                event.preventDefault();
            }
        });
    };
    var directive = {
        link: link
    };
    return directive;
}

//ImportantDatesListController.$inject = ['$scope', 'datepickerPopupConfig', '$filter'];
//function ImportantDatesListController($scope, datepickerPopupConfig, $filter) {
ImportantDatesListController.$inject = ['$scope', '$filter', '$document'];
function ImportantDatesListController($scope, $filter, $document) {
    var vm = this;
    // data model:
    vm.codelists = $scope.$root.codelists;
    vm.dateformat = 'yyyy-MM-dd';
    vm.datepicker_options = {
        class: 'datepicker',
        showWeeks: false,
        format: vm.dateformat  // ISO 8601
    };

    function getPicker(inputName) {
        return $document.find('input[name="' + inputName + '"]');
    }

    function openPicker(inputName) {
        getPicker(inputName).focus();
    }

    function clearPicker(inputName) {
        getPicker(inputName).val('');
    }

    function dPickerInit(inputName, initDate) {
        console.log('dPickerInit:' + inputName + '   :' + initDate);
        var pickerObj
        pickerObj = $document.find('input[name="' + inputName + '"]');
        console.log(pickerObj);
        pickerObj.daterangepicker(
            {
                //configuration parameters
                autoUpdateInput: false,
                singleDatePicker: true,
                showDropdowns: true,
                locale: {format: 'MMM D, YYYY'}
                //startDate: (!initDate ? '' : initDate)
            },
            function (start, end, label) {
                //callback function - called on picker value change - dates sent in callback
                //console.log('inputName:' + inputName)
                //console.log(vm.otherDates);
                if (inputName == 'picker_birthday') {
                    vm.birthday.value = start;
                    updateImportantDates(vm.birthday, null, vm.birthday.value);
                } else if (inputName == 'picker_anniversary') {
                    vm.anniversary.value = start;
                    updateImportantDates(vm.anniversary, 'BIRTHDAY', vm.anniversary.value);
                } else if (inputName.startsWith("otherDate_")) {
                    var idx = parseInt(inputName.replace(/[^0-9]/g, ''));
                    vm.otherDates[idx].value = start;
                }
            }
        );

        pickerObj.on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('MMM D, YYYY'));
        });

        pickerObj.on('cancel.daterangepicker', function (ev, picker) {
            $(this).val('');
        });

        //initial datepicker values
        //console.log('dPickerInit   2:' + inputName + '   :' + initDate);
        if (initDate != null) {
            pickerObj.data('daterangepicker').setStartDate(moment(initDate));
            pickerObj.data('daterangepicker').setEndDate(moment(initDate));
            pickerObj.val(moment(initDate).format('MMM D, YYYY'));
        }
    }

    $document.ready(
        function () {
            dPickerInit('picker_anniversary', vm.anniversary.value);
            dPickerInit('picker_birthday', vm.birthday.value);
            for (var i = 0; i < vm.otherDates.length; i++)
                dPickerInit('otherDate_' + i + '_date', vm.otherDates[i].value);
        }
    );

    vm.birthday = {
        dateType: 'BIRTHDAY',
        value: null,
        opened: false
    };
    vm.anniversary = {
        dateType: 'ANNIVERSARY',
        value: null,
        opened: false
    };
    vm.otherDates = []; // dateType: 'OTHER', otherName: '',

    // controller methods:
    vm.clearBirthday = clearBirthday;
    vm.clearAnniversary = clearAnniversary;
    vm.removeOtherDate = removeOtherDate;
    vm.newOtherDate = newOtherDate;

    vm.addImportantDate = addImportantDate;

    vm.openBirthday = openBirthday;
    vm.openAnniversary = openAnniversary;
    vm.openImportantDate = openImportantDate;

    function openBirthday() {
        vm.birthday.opened = !vm.birthday.opened;
        openPicker('picker_birthday');
    }

    function openAnniversary() {
        vm.anniversary.opened = !vm.anniversary.opened;
        openPicker('picker_anniversary');
    }

    function openImportantDate(index) {
        vm.otherDates[index].opened = !vm.otherDates[index].opened;
        openPicker('otherDate_' + index + '_date');
    }

    function removeOtherDate(index) {
        var removedItem = vm.otherDates[index];
        vm.otherDates.splice(index, 1);
        var importantDateIndex = _.findIndex(vm.importantDates, function (elem) {
            return elem === removedItem;
        });
        if (importantDateIndex > -1) {
            vm.importantDates.splice(importantDateIndex, 1);
        }
    }

    function newOtherDate() {
        var newDateVal = {
            dateType: 'OTHER',
            value: null
        };
        vm.otherDates.push(newDateVal);
        vm.addImportantDate(newDateVal);

        var idx = vm.otherDates.length - 1;
        //TODO 1 branimir - tbd test - find better way to init datePickers
        $document.ready(
            function () {
                dPickerInit('otherDate_' + idx + '_date', vm.otherDates[idx].value);
            });
        return newDateVal;
    }

    function clearBirthday() {
        vm.birthday.value = null;
        clearPicker('picker_birthday');
    }

    function clearAnniversary() {
        vm.anniversary.value = null;
        clearPicker('picker_anniversary');
    }

    function addImportantDate(date) {
        vm.importantDates.push(date);
    }

    function watch(date, relativeTo) {
        //console.log('watch ' + date + ' relativeTo:' + relativeTo);
        $scope.$watch(angular.bind(vm, function () {
                return date.value;
            }),
            function (newVal, oldVal) {
                //console.log('watch' + ' ' + date + '  newVal:' + newVal);
                updateImportantDates(date, relativeTo, newVal);
            }
        );
    }

    function updateImportantDates(date, relativeTo, newVal) {
        var index = indexOf(vm.importantDates, date.dateType);
        if (newVal !== null) {
            if (index > -1) {
                vm.importantDates.splice(index, 1, date);
            } else {
                var relativeIndex = -1;
                if (relativeTo) {
                    relativeIndex = indexOf(vm.importantDates, relativeTo);
                }
                vm.importantDates.splice(relativeIndex + 1, 0, date);
            }
        } else {
            if (index > -1) {
                vm.importantDates.splice(index, 1);
            }
        }
    }

    function indexOf(col, dateType) {
        return _.findIndex(col, function (elem) {
            return elem.dateType === dateType;
        });
    }

    $scope.$watch(angular.bind(vm, function () {
            return this.importantDates;
        }),
        changedImportantDates
    );

    function changedImportantDates(newVal, oldVal) {
        //console.log('$scope.$watch newVal:' + newVal);
        _.each(newVal, function (item) {
            switch (item.dateType) {
                case 'BIRTHDAY':
                    vm.birthday.value = item.value;
                    break;
                case 'ANNIVERSARY':
                    vm.anniversary.value = item.value;
                    break;
                case 'OTHER':
                    var index = _.findIndex(vm.otherDates, function (elem) {
                        return elem.otherName === item.otherName;
                    });
                    if (index > -1) {
                        vm.otherDates.splice(index, 1, item);
                    } else {
                        vm.otherDates.push(item);
                    }
                    break;
            }
        });
    }

    watch(vm.birthday);
    watch(vm.anniversary, 'BIRTHDAY');
}

function importantDatesListDirective() {
    var directive = {
        scope: {
            importantDates: '=model',
            debug: '@'
        },
        restrict: 'E',
        transclude: false,
        templateUrl: 'app/customer/create/partials/important_dates.html',
        controller: ImportantDatesListController,
        controllerAs: 'vm',
        bindToController: true
    };
    return directive;
}

angular.module('seatonApp').directive('ngEnter', ngEnterDirective);
angular.module('seatonApp').directive('importantDatesList', importantDatesListDirective);
