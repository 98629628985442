(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('settings.employees', {
                parent: 'settings',
                url: '/employees',
                data: {
                    authorities: ['ROLE_USER']
                    //,pageTitle: 'seatonApp.allergy.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/settings/employees/employees.html',
                        controller: 'SettingsEmployeesController',
                        controllerAs: 'employeesCtrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('allergy');
                        $translatePartialLoader.addPart('global');
                        //$translatePartialLoader.addPart('reporting.aggregated');
                        return $translate.refresh();
                    }],
                    locations: function ($q, LocationResource) {
                        return LocationResource.getLocation({}, function (data) {
                            return data;
                        });
                    }
                }
            })
            .state('settings.employees.view', {
                parent: 'settings.employees',
                url: '/view/{id}',
                views: {
                    'content@app': {
                        controller: 'SettingsEmployeeDetailController',
                        controllerAs: 'employeeCtrl',
                        templateUrl: 'app/settings/employees/employee-details.html'
                    }
                }
            });
    }
})();
