'use strict';

EventSearchController.$inject = ['$state', '$stateParams', '$scope', '$q', '$filter', '$document', 'Tables',
    'Utils','ChannelService'];

function EventSearchController($state, $stateParams, $scope, $q, $filter, $document, Tables,
                               Utils, ChannelService) {
    var vm = this;
    vm.utils = Utils;

    // paging data
    vm.currentPage = 1;
    vm.itemsPerPage = 25;
    vm.totalItems = 0;
    vm.searchEvents = searchEvents;

    vm.keyword = $stateParams.keyword ? $stateParams.keyword : null;
    vm.filterFrom = null;
    vm.filterTo = null;
    if ($stateParams.from && moment($stateParams.from).isValid() && $stateParams.to && moment($stateParams.to).isValid()) {
        vm.filterFrom = moment($stateParams.from).toISOString();
        vm.filterTo = moment($stateParams.to).toISOString();
    }

    vm.searchAllowed = function () {
        if (vm.keyword && vm.keyword.length >= 3) {
            return true;
        }
        if (vm.filterFrom && moment(vm.filterFrom).isValid() && vm.filterTo && moment(vm.filterTo).isValid()) {
            return true;
        }
        return false;
    }

    searchEvents();

    function searchEvents() {

        if (!vm.searchAllowed()) {
            return; // can not search if either keyword or range are defined
        }

        Tables.searchEvents(
            {
                keyword: vm.keyword,
                from: vm.filterFrom,
                to: vm.filterTo,
                page: vm.currentPage - 1,
                size: vm.itemsPerPage
            },
            function (data) {
                vm.events = data.content;
                vm.totalItems = data.totalElements;
            }
        );
        var params = {
            keyword: null,
            from: null,
            to: null
        }

        if (vm.keyword && vm.keyword.length >= 3) {
            params.keyword = vm.keyword;
        }

        if (vm.filterFrom && moment(vm.filterFrom).isValid() && vm.filterTo && moment(vm.filterTo).isValid()) {
            params.from = vm.filterFrom;
            params.to = vm.filterTo;
        }

        $state.go('tables.search', params, {notify: false}); // change the URL
    }

    vm.resetSearch = function () {
        vm.keyword = null;
        vm.filterFrom = null;
        vm.filterTo = null;
        vm.pickerDates.val('');
        vm.events = null;
        vm.totalItems = 0;

        $state.go('tables.search', {keyword: null, from: null, to: null}, {notify: false});
    }

    vm.searchToday = function () {
        //vm.keyword = null;
        vm.pickerDates.val('');
        vm.filterFrom = moment().startOf('day').toISOString();
        vm.filterTo = moment().add(1, 'day').startOf('day').toISOString();
        vm.events = null;
        vm.totalItems = 0;
        updateDatePickerText();
        searchEvents();
    }

    vm.searchLastWeek = function () {
        //vm.keyword = null;
        vm.pickerDates.val('');
        vm.filterFrom = moment().startOf('day').subtract(7, 'day').toISOString();
        vm.filterTo = moment().add(1, 'day').startOf('day').toISOString();
        vm.events = null;
        vm.totalItems = 0;
        updateDatePickerText();
        searchEvents();
    }

    // reservations list, page changed
    vm.pageChanged = function () {
        vm.searchEvents();
    };

    // initialize date picker
    $document.ready(
        function () {
            vm.pickerDates = $document.find('input[name="pickerDates"]');
            vm.pickerDates.daterangepicker(
                {
                    showDropdowns: true,
                    autoApply: true,
                    autoUpdateInput: false,
                    alwaysShowCalendars: true,
                    locale: {format: 'MMM D, YYYY'}
                },
                function (start, end, label) {
                    vm.filterFrom = moment(start).toISOString();
                    vm.filterTo = moment(end).toISOString();
                    updateDatePickerText();
                    $scope.$apply(); // to propagate model changes to angular
                }
            );
            updateDatePickerText();
        }
    );

    vm.openPicker = function () {
        vm.pickerDates.focus();
    }

    function updateDatePickerText() {
        if (vm.filterFrom && vm.filterTo) {
            vm.pickerDates.val(moment(vm.filterFrom).format('MMM D, YYYY') + ' - ' + moment(vm.filterTo).format('MMM D, YYYY'));
        } else {
            vm.pickerDates.val('');
        }
    }

    $scope.channelIcon = function(channel) {
        return ChannelService.getChannelIcon(channel);
    }

    $scope.channelToolTipText = function(channel) {
        return ChannelService.getChannelToolTipText(channel);
    }

}

angular.module('seatonApp').controller('EventSearchController', EventSearchController);
