(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('AlertsController', AlertsController);

    AlertsController.$inject = ['$scope', '$state', 'SettingsResource', 'Log', '$location', '$uibModal', '$document',
        'ApplicationContext','ApplicationProfile'];

    function AlertsController($scope, $state, SettingsResource, Log, $location, $uibModal, $document,
                              ApplicationContext, ApplicationProfile) {

        var vm = this;
        vm.subscription = ApplicationContext.getLocation().subscription;
        vm.isFree = vm.subscription=='FREE';
        vm.openWindow = ApplicationProfile.openAppCloverPage;

        SettingsResource.getAlertsSettings({}, onSuccessGetData);

        function onSuccessGetData(data) {
            vm.data = data;
            Log.debug("NotificationController", "HTTP request succeed.");
        }

        function onErrorGetData(error) {
            Log.debug("NotificationController", "HTTP request error." + error);
        }

        $scope.saveData = function () {
            console.log('save data', $scope.data);
            SettingsResource.saveAlertsSettings(vm.data, onSuccessGetData, onErrorGetData);
        };


    }
})();



