(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('HomeNewController', HomeNewController);

    HomeNewController.$inject = ['$rootScope', '$scope', 'Principal', '$state', '$q', 'googleChartApiPromise',
        '$filter', 'EventResource', 'LocationResource', 'ApplicationContext',
        'EmployeeResource', '$document', 'Log', 'ChannelService', 'Tables', 'TextUtil'];

    function HomeNewController($rootScope, $scope, Principal, $state, $q, googleChartApiPromise, $filter, EventResource, LocationResource, ApplicationContext, EmployeeResource,
                               $document, Log, ChannelService, Tables, TextUtil) {

        if (ApplicationContext.getLocation().webHome == 1) {
            $state.go("homeold");
            return;
        }

        var vm = this;
        vm.changeHome = changeHome;
        vm.weekStart = moment().startOf('week');
        vm.weekEnd = moment().endOf('week');
        vm.lastWeekStart = vm.weekStart.clone().subtract(1, 'weeks');
        vm.lastWeekEnd = vm.weekEnd.clone().subtract(1, 'weeks');
        vm.coverTrans = TextUtil.translate('person', 'c');

        // subscription information
        vm.subscriptionInfo = {
            'tier': ApplicationContext.getLocation().subscription,
            'reservationCounter': ApplicationContext.getLocation().reservationCoverCount,
            'waitlistCounter': ApplicationContext.getLocation().waitlistCoverCount
        };

        if(!vm.subscriptionInfo.reservationCounter) vm.subscriptionInfo.reservationCounter=0;
        if(!vm.subscriptionInfo.waitlistCounter) vm.subscriptionInfo.waitlistCounter=0;
        vm.showEUAppLink = $rootScope.isEU;

        console.log("Subscription:"+JSON.stringify(vm.subscriptionInfo));


        $document.ready(load());

        if ($rootScope.initialStart == true) {
            $scope.showIntro = true;
            $rootScope.initialStart = false;
            $rootScope.configNotCompleted == true;
            Log.debug("HomeNew", "Show application intro.");
        }


        function load() {
            vm.timeNow = moment();
            vm.lastWeekDay = moment().subtract(1, 'weeks');
            vm.timeDebugNotification = '';

            Log.debug("HomeDashboard", "timeNow=" + vm.timeNow + ", timeNow.format=" + vm.timeNow.format());

            $q.all([
                loadCounters(),
                getWeekOccupancy(),
                getLastWeekOccupancy(),
                loadWaitlists(),
                getPreresevedReservations(),
                googleChartApiPromise
            ]).then(displayHourChart);
        }

        $scope.openingHourNotDefined = function () {
            return ApplicationContext.isBusinessHoursNotDefined();
        }

        $scope.configNotCompleted = function () {
            return ApplicationContext.isConfigNotCompleted();
        }

        function loadCounters() {
            //TODO 5 check ApplicationContext.getEmployeeID() has value "ADMIN%20ACCESS"
            Log.debug("HomeDashboard", "Value of ApplicationContext.getEmployeeID():" + ApplicationContext.getEmployeeID())
            return EmployeeResource.sinceLastLogin({id: -1}, function (data) {
                vm.counters = data;
            }).$promise;
        }

        function changeHome() {
            LocationResource.changeHome(
                {},
                function onSuccess(data) {
                    var location = ApplicationContext.getLocation();
                    location.webHome = data.webHome;
                    ApplicationContext.storeLocation(location);
                    $state.go("homeold");
                    return;
                }
            );
        }

        function loadWaitlists() {
            Tables.getWaitlistQueues({},
                function (data) {
                    vm.waitlistQueues = data;
                }
            );
        }

        function getWeekOccupancy() {
            return EventResource.getHomeWeekStats({
                selectedDate: vm.weekStart.clone().format('YYYY-MM-DD')
            }, function (data) {
                vm.data = data;
                vm.data.walkinseatedEventsCount = data.totalWeekOccupancy.totalVisits - data.totalWeekOccupancy.reservationEventsCount - data.totalWeekOccupancy.waitlistEventsCount;
                vm.data.walkinseatedCoversCount = data.totalWeekOccupancy.totalCovers - data.totalWeekOccupancy.reservationCoversCount - data.totalWeekOccupancy.waitlistCoversCount;
            }).$promise;
        }

        function getLastWeekOccupancy() {
            return EventResource.getHomeWeekStats({
                selectedDate: vm.lastWeekStart.clone().format('YYYY-MM-DD'),
                reportType: 'lastWeek'
            }, function (data) {
                vm.lastWeekData = data;
            }).$promise;
        }

        function myToolTip(day, occupancy, visit, cover) {
            return '&nbsp;<b>' + day + '</b><br>' +
                '&nbsp;&nbsp;&nbsp;Occupancy: <span class="text-danger">' + occupancy + '</span><br/>' +
                '&nbsp;&nbsp;&nbsp;Visit(s): <span class="text-danger">' + visit + '</span><br/>' +
                '&nbsp;&nbsp;&nbsp;' + vm.coverTrans + ': <span class="text-danger">' + cover + '</span><br/>';
        }

        function displayHourChart() {

            // occupancy bar chart
            var nowHour = moment().startOf('hour');

            var deferred = $q.defer();

            var dataOcuppancyDay = new google.visualization.DataTable();
            dataOcuppancyDay.addColumn('string', 'Day');
            dataOcuppancyDay.addColumn('number', 'Current week', 'color: #1ABC9C');
            dataOcuppancyDay.addColumn({type: 'boolean', role: 'certainty'}); // certainty col.
            dataOcuppancyDay.addColumn({type: 'string', role: 'annotation'});
            dataOcuppancyDay.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});
            dataOcuppancyDay.addColumn('number', 'Previous week', 'color: #336699');
            dataOcuppancyDay.addColumn({type: 'boolean', role: 'certainty'}); // certainty col.

            var i;
            for (i = 0; i < vm.data.occupancyByWeekday.length; i++) {
                var item = vm.data.occupancyByWeekday[i];
                var certainity = (i < moment().weekday());
                dataOcuppancyDay.addRow(
                    [
                        vm.weekStart.clone().add(item.weekday, 'days').format('Do MMM, dddd'),
                        item.occupancyPct,
                        certainity,
                        $filter('percentage')(item.occupancyPct, 2), //item.weekdayName,
                        myToolTip(item.weekdayName, $filter('percentage')(item.occupancyPct, 2), item.totalVisits, item.totalCovers),
                        {
                            v: vm.lastWeekData.occupancyByWeekday[i].occupancyPct,
                            f: $filter('percentage')(vm.lastWeekData.occupancyByWeekday[i].occupancyPct, 2)
                        },
                        true
                    ]
                );
            }

            var optionsOccupancyDay = {
                //width: 900,
                height: 300,

                tooltip: {
                    isHtml: true
                },
                annotations: {
                    alwaysOutside: true,
                    textStyle: {
                        fontSize: 10,
                        bold: 'true',
                        color: '#000',
                        auraColor: 'none'
                    }
                },
                hAxis: {
                    title: 'Day of the week',
                    titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true}
                },
                vAxes: {
                    0: {
                        viewWindowMode: 'explicit',
                        //gridlines: {color: 'transparent'},
                        gridlines: {count: 9},
                        title: 'Occupancy rate',
                        titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
                        format: 'percent',
                        viewWindow: {
                            min: 0
                        },
                        minValue: 0
                        //TODO limit chart min value!!
                    },
                    1: {
                        //gridlines: {color: 'transparent'},
                        title: 'Occupancy rate',
                        //titleTextStyle: {color: '#23c6c8', fontSize: 14, bold: true},
                        format: 'percent',
                        color: '#CC9933',
                        viewWindow: {
                            min: 0
                        },
                        textStyle: {color: '#CC3333'},
                        titleTextStyle: {color: '#CC3333'},
                        minValue: 0
                    }
                },
                chartArea: {width: '80%', height: '65%'},
                series: {
                    0: {
                        type: 'bars',
                        targetAxisIndex: 0
                    },
                    1: {
                        type: 'line',
                        targetAxisIndex: 0
                    }
                },
                formatters: {},
                colors: ['#1ABC9C', '#CC3333'],
                legend: {position: 'top', alignment: 'end'}
            };

            $scope.chartComboObjectOccupancyDay = {
                type: "ComboChart",
                options: optionsOccupancyDay,
                data: dataOcuppancyDay
            };

            // visit pie donut chart
            var dataPie = new google.visualization.DataTable();
            dataPie.addColumn('string', 'Visit type');
            dataPie.addColumn('number', 'Number of visits');
            dataPie.addRows([
                ['Reservation', vm.data.totalWeekOccupancy.reservationEventsCount],
                ['Wait list', vm.data.totalWeekOccupancy.waitlistEventsCount],
                ['Walk-in', vm.data.walkinseatedEventsCount]
            ]);

            $scope.showChart = vm.data.totalWeekOccupancy.reservationEventsCount + vm.data.totalWeekOccupancy.waitlistEventsCount + vm.data.walkinseatedEventsCount;

            var optionsPie = {
                /*title: 'Current week visit statistic',
                titleTextStyle: {
                    fontSize: 14,
                    bold: true,
                    color: 757789
                },*/
                pieHole: 0.3,
                legend: 'none',
                chartArea: {
                    top: 15, left: 0, right: 0, bottom: 0
                },
                slices: {
                    0: {color: '#1ab394'},
                    1: {color: '#f8ac59'},
                    2: {color: '#23c6c8'}
                }
            };

            $scope.chartPieDonutVisitStat = {
                type: "PieChart",
                data: dataPie,
                options: optionsPie
            };
            return deferred.promise;
        }

        $scope.channelIcon = function (channel) {
            return ChannelService.getChannelIcon(channel);
        }

        $scope.channelToolTipText = function (channel) {
            return ChannelService.getChannelToolTipText(channel);
        }

        function getPreresevedReservations() {
            Tables.getPreresevedReservations(
                {
                    from: moment("2000-01-01", "YYYY-MM-DD").startOf('day').toISOString(),
                    to: moment().add(1, 'year').toISOString(),
                    page: 0,
                    size: 15
                },
                function (data) {
                    vm.preReservations = data.content;
                    vm.totalPreReservations = data.totalElements;
                }
            );
        }

        $scope.IntroOptions = {
            steps: [
                {
                    intro: "<h3 align=\"center\">Welcome to SeatOn Reservations</h3>" +
                    "<p>" +
                    "<strong>Dear Customer</strong>,<br/><br/>" +
                    "Thank you for choosing our solution to assist you in running your business. <br/><br/>" +
                    "Any time when you need support or have any questions related to SeatOn, please do not hesitate to contact us via email " +
                    "<a href=\"mailto:support@seaton.site\"/>support@seaton.site</a> or visit SeatOn customer support portal at " +
                    "<a href=\"http://support.seaton.site\" target=\"_blank\"/>http://support.seaton.site</a>" +
                    "</p>" +
                    "<p>" +
                    "As a SeatOn user you will use one of the following applications:<br/>" +
                    "<ul>" +
                    "<li><strong>SeatOn Clover</strong> - intended for use by employees on Clover device</li>" +
                    "<li><strong>SeatOn Web</strong> - intended for use by management or employees using any modern internet browser</li>" +
                    "</ul>" +
                    "</p>" +
                    "<p>" +
                    "SeatOn Web consists of three main modules:" +
                    "<ul>" +
                    "<li> Resource management" +
                    "<li> Customer relationship management" +
                    "<li> Reports and analytics" +
                    "</ul>" +
                    "</p>" +
                    "<p><small><font color=\"red\">Press button \"Next\" or right arrow key on your keyboard to continue with the introduction guide to SeatOn.</font></small></p>" +
                    "<p>" +
                    "<code>SeatOn Reservations, All rights reserved &copy; since 2018</code>" +
                    "</p>"
                },
                {
                    element: '#menu',
                    intro: "<h3 align=\"center\">SeatOn Web application menu</h3>" +
                    "<p>" +
                    "Menu is organized to match main SeatOn Web components including resource management, customer relationship management, reports and settings.<br/><br/>" +
                    "Top level menu includes following items:" +
                    "<ul>" +
                    "<li><i>Home</i> - application entry point with current performance dashboard</li>" +
                    "<li><i>Today reservation</i> - upcoming reservation scheduled for today</li>" +
                    "<li><i>Open pre-reservation</i> - pending reservation which requires your approval / resource assignment.</li>" +
                    "<li><i>Wait list</i> - waiting list management.</li>" +
                    "<li><i>Walk-in</i> - walk-in management.</li>" +
                    "<li><i>Active visits</i> - started visits list.</li>" +
                    "<li><i>Reservation center</i> - management of open reservations.</li>" +
                    "<li><i>Search</i> - search all event on system.</li>" +
                    "<li><i>Customers</i> - all details about your customers.</li>" +
                    "<li><i>Reports</i> - various reports to give you insight in your business.</li>" +
                    "<li><i>Settings</i> - customize SeatOn to fit your needs.</li>" +
                    "</ul>" +
                    "</p>" +
                    "<p>" +
                    "<code>SeatOn Reservations, All rights reserved &copy; since 2018</code>" +
                    "</p>",
                    position: 'auto'
                },
                {
                    element: '#performance',
                    intro: "<h3 align=\"center\">Current week overview</h3>" +
                    "<p>" +
                    "In this section of Home dashboard page, you can see <strong>current week overview</strong>, including:. " +
                     "<ul>" +
                        "<li>Occupancy rate for current week and each day in the week</li>" +
                        "<li>Completed and upcoming visit statistics, and open pre-reservation count</li>" +
                    "</ul>" +
                    "</p>" +
                    "<p>" +
                    "Current week occupancy rate is compared with occupancy rate from the previous week for each day in the week.<br/>" +
                    "</p>" +
                    "<p>" +
                    "<code>SeatOn Reservations,, All rights reserved &copy; since 2018</code>" +
                    "</p>",
                    position: 'auto'
                },
                {
                    element: '#lastlogin',
                    intro: "<h3 align=\"center\">Important notes and changes since last login</h3>" +
                    "<p>In this section of Home dashboard page, you can see <strong>important notes</strong> related to actions which require you attention and" +
                    " <strong>changes in the system</strong> since the last time you used SeatOn Web application." +
                    "</p>" +
                    "<p>" +
                    "<code>SeatOn Next Generation Table Management System, All rights reserved &copy; 2016-2021</code>" +
                    "</p>",
                    position: 'left'
                },
                {
                    element: '#events',
                    intro: "<h3 align=\"center\">Events list</h3>" +
                    "<p>In this section of Home dashboard page, you can see the list of event splited in three categories:" +
                    "<ul>" +
                    "<li>Upcoming reservation list for current week</li>" +
                    "<li>Open pre-reservation list</li>" +
                    "<li>Wait list</li>" +
                    "</ul>" +
                    "Press on button at the end of the list and access to page where you can manage different event types." +
                    "</p>" +
                    "<p>" +
                    "Do not forget to:" +
                    "<ul>" +
                    "<li>Visit SeatOn support pages <a href='http://support.seaton.site' target='_blank'>support.seaton.site</a> to find out more details</li>" +
                    "<li>Send us your feedback on SeatOn application or  email <a href='mailto:support@seaton.site'>support@seaton.site</a></li>" +
                    "</ul>" +
                    "<p class='text-danger font-bold'>" +
                    "Start with the customization to set up SeatOn to better match your business needs.<br/>" +
                    "Follow this link to continue - <a href='/#/settings/floor'>start customization.</a></strong></font>" +
                    "</p>" +
                    "<code>SeatOn Reservations,, All rights reserved &copy; since 2018</code>" +
                    "</p>",
                    position: 'top'
                }
                /*
                {
                    element: '#table',
                    intro: "<h3 align=\"center\">Table management</h3>" +
                    "<p>Table management module of the application manages whole procedure from <strong>customer arrival</strong> to <strong>customer walkout</strong>. " +
                    "It handles all type of your guests' arrivals, including walk-in, reservation, waitlist and walkout management. " +
                    "</p>" +
                    "<p>" +
                    "In the table management module, you can <strong>create reservations</strong>, <strong>handle walk-in guests</strong>," +
                    " as well as <strong>add customers to the waiting list</strong>." +
                    " and finally, <strong>manage customer walkouts</strong>. " +
                    "</p>" +
                    "<p> In Table management section on Home dashboard page you have a quick insight into the status of restaurant tables: " +
                    "<ul>" +
                    "<li>Number of currently available tables</li>" +
                    "<li>Number of currently occupied tables and related covers</li>" +
                    "<li>Number of expected arrivals and related covers</li>" +
                    "<li>Number of waitlist events and related covers</li>" +
                    "</ul>" +
                    "</p>" +
                    "<p>" +
                    "<code>SeatOn Reservations,, All rights reserved &copy; 2016-2021</code>" +
                    "</p>",
                    position: 'right'
                },
                {
                    element: '#customer',
                    intro: "<h3 align=\"center\">Customer relationship management</h3>" +
                    "<p>Customer relationship management module <strong>records customer information</strong>, <strong>feedback</strong>, <strong>visiting habits</strong>, " +
                    "and <strong>spending behavior</strong> to the customer database. By analyzing customer data, it is possible to trace " +
                    "top customers and loyal guests, as well as check for frequent no-show customers, and eventually restrict " +
                    "reservations for them. " +
                    "</p>" +
                    "<p> In Customer management section on Home dashboard page you have a quick insight into some key indicators " +
                    "related to customers:" +
                    "<ul>" +
                    "<li>Number of all registered customers</li>" +
                    "<li>Percentage of visits made by registered customers</li>" +
                    "<li>Percentage of revenue generated by registered customers</li>" +
                    "<li>Average spend per person by registered customers</li>" +
                    "</ul>" +
                    "</p>" +
                    "<p>" +
                    "<code>SeatOn Next Generation Table Management System, All rights reserved &copy; 2016-2021</code>" +
                    "</p>",

                    position: 'right'
                },
                {
                    element: '#report',
                    intro: "<h3 align=\"center\">Reports and analytics</h3>" +
                    "<p>Reporting module contains a number of different reports with extensive analytics, such as <strong>visit statistics</strong>," +
                    " <strong>aggregated spending trend</strong>, <strong>occupancy rate</strong> and <strong>employee performance</strong>. It is intended for users who want to " +
                    "improve and grow their business, recognize new opportunities and maximize profit." +
                    "</p>" +
                    "<p>" +
                    "<code>SeatOn Reservations,, All rights reserved &copy; 2016-2021</code>" +
                    "</p>",
                    position: 'left'
                },
                {
                    element: '#settings',
                    intro: "<h3 align=\"center\">SeatOn settings</h3>" +
                    "<p>Through Settings pages, you can <strong>customize SeatOn application to match your restaurant's needs</strong>. " +
                    "Here you can set up your floor, including areas and tables definitions, notification messages, customer membership," +
                    " check your employees' data, set up employees' restrictions and track their performance." +
                    "</p>" +
                    "<p>" +
                    "Do not forget to:" +
                    "<ul>" +
                    "<li>Visit SeatOn support pages <a href='http://support.seaton.site' target='_blank'>support.seaton.site</a> to find out more details</li>" +
                    "<li>Send us your feedback on SeatOn application or  email <a href='mailto:support@seaton.site'>support@seaton.site</a></li>" +
                    "</ul>" +
                    "<p>" +
                    "<br/><font color=\"red\"><strong>Now you are ready to use SeatOn application. <br/>" +
                    "Let's start with the set up of your restaurant floor first by following this link -> <a href='/#/settings/floor'>Floor setup.</a></strong></font>" +
                    "</p>" +
                    "<p>" +
                    "<small>Note: You will not be able to use SeatOn on Clover devices before you set up your restaurant floor.</small>" +
                    "</p>" +
                    "<p>" +
                    "<code>SeatOn Reservations,, All rights reserved &copy; 2016-2021</code>" +
                    "</p>",
                    position: 'left'
                }
                */
            ],

            showStepNumbers: true,
            showBullets: true,
            exitOnOverlayClick: false,
            exitOnEsc: false,
            nextLabel: '<strong>Next</strong>',
            prevLabel: '<strong>Previous</strong>',
            skipLabel: 'Exit',
            doneLabel: 'Done'
        };

        $scope.showSubscriptionInfo = function() {
            if(vm.subscriptionInfo && vm.subscriptionInfo.tier=='FREE') {
                return true;
            }
            return false;
        }

    }
})();



