(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('MembershipController', MembershipController);

    MembershipController.$inject = ['$scope', '$state', 'SettingsResource', 'Log', 'SweetAlert'];

    function MembershipController($scope, $state, SettingsResource, Log, SweetAlert) {
        
        $scope.bronzeMemberThresholdProblem = false;
        
        SettingsResource.getMemberStatusSettings({
        }, onSuccessGetData, onErrorGetData);

//        SettingsResource.getAreasAndTables({
//        }, onSuccess, onError);

        /*SettingsResource.createArea({
            locationId: 1,
            name: 'Test',
            vip: false

        }, onSuccess, onError);*/

        /*SettingsResource.updateArea({
            id: 1013,
            locationId: 1,
            name: 'Test123',
            vip: true

         }, onSuccess, onError);*/

        /*SettingsResource.deleteArea({
            id: 2
        }, onSuccess, onError);*/


        function onSuccessGetData(data, headers) {
            $scope.data = data;
            console.log('$scope.data', $scope.data);
            
            $scope.beginnerMemberBenefitVipTable = false;
            $scope.beginnerMemberBenefitFavoriteTable = false;
            $scope.beginnerMemberBenefitWaitPriority = false;
            
            $scope.bronzeMemberBenefitVipTable = false;
            $scope.bronzeMemberBenefitFavoriteTable = false;
            $scope.bronzeMemberBenefitWaitPriority = false;
            
            $scope.silverMemberBenefitVipTable = false;
            $scope.silverMemberBenefitFavoriteTable = false;
            $scope.silverMemberBenefitWaitPriority = false;
            
            $scope.goldMemberBenefitVipTable = false;
            $scope.goldMemberBenefitFavoriteTable = false;
            $scope.goldMemberBenefitWaitPriority = false;
            
            $scope.platinumMemberBenefitVipTable = false;
            $scope.platinumMemberBenefitFavoriteTable = false;
            $scope.platinumMemberBenefitWaitPriority = false;
   
            $scope.beginnerMember = $scope.data.beginnerMember.benefits.split(","); // comma splitting
            angular.forEach($scope.beginnerMember, function (item) {
                    if (item == "VIP_TABLE") {
                        $scope.beginnerMemberBenefitVipTable = true;
                    }
                    if (item == "FAVORITE_TABLE") {
                        $scope.beginnerMemberBenefitFavoriteTable = true;
                    }
                    if (item == "WAIT_PRIORITY") {
                        $scope.beginnerMemberBenefitWaitPriority = true;
                    };
            });
            
            $scope.bronzeMember = $scope.data.bronzeMember.benefits.split(","); // comma splitting
            angular.forEach($scope.bronzeMember, function (item) {
                    if (item == "VIP_TABLE") {
                        $scope.bronzeMemberBenefitVipTable = true;
                    }
                    if (item == "FAVORITE_TABLE") {
                        $scope.bronzeMemberBenefitFavoriteTable = true;
                    }
                    if (item == "WAIT_PRIORITY") {
                        $scope.bronzeMemberBenefitWaitPriority = true;
                    };
            });
            
            $scope.silverMember = $scope.data.silverMember.benefits.split(","); // comma splitting
            angular.forEach($scope.silverMember, function (item) {
                    if (item == "VIP_TABLE") {
                        $scope.silverMemberBenefitVipTable = true;
                    }
                    if (item == "FAVORITE_TABLE") {
                        $scope.silverMemberBenefitFavoriteTable = true;
                    }
                    if (item == "WAIT_PRIORITY") {
                        $scope.silverMemberBenefitWaitPriority = true;
                    };
            });
            
            $scope.goldMember = $scope.data.goldMember.benefits.split(","); // comma splitting
            angular.forEach($scope.goldMember, function (item) {
                    if (item == "VIP_TABLE") {
                        $scope.goldMemberBenefitVipTable = true;
                    }
                    if (item == "FAVORITE_TABLE") {
                        $scope.goldMemberBenefitFavoriteTable = true;
                    }
                    if (item == "WAIT_PRIORITY") {
                        $scope.goldMemberBenefitWaitPriority = true;
                    };
            });                        
            
            $scope.platinumMember = $scope.data.platinumMember.benefits.split(","); // comma splitting
            angular.forEach($scope.platinumMember, function (item) {
                    if (item == "VIP_TABLE") {
                        $scope.platinumMemberBenefitVipTable = true;
                    }
                    if (item == "FAVORITE_TABLE") {
                        $scope.platinumMemberBenefitFavoriteTable = true;
                    }
                    if (item == "WAIT_PRIORITY") {
                        $scope.platinumMemberBenefitWaitPriority = true;
                    };
            });            
            
            // threshold check (or else $dirty will repeat warning if user change anything after save of threshold)
            $scope.bronzeMemberThresholdStart = data.bronzeMember.threshold;
            $scope.silverMemberThresholdStart = data.silverMember.threshold;
            $scope.goldMemberThresholdStart = data.goldMember.threshold;
            $scope.platinumMemberThresholdStart = data.platinumMember.threshold;
            

            Log.debug("MembershipController", "HTTP request succeed. Data=" + data.id);
        }

        function onErrorGetData(error) {
            Log.debug("MembershipController", "HTTP request error." + error);
        }
        
        // save data
        $scope.saveData = function (bronzeThresholdDirty, silverMemberThresholdDirty, goldMemberThresholdDirty, platinumMemberThresholdDirty) {
            
            if ($scope.data.bronzeMember.threshold != $scope.bronzeMemberThresholdStart || $scope.data.silverMember.threshold != $scope.silverMemberThresholdStart || $scope.data.goldMember.threshold != $scope.goldMemberThresholdStart || $scope.data.platinumMember.threshold != $scope.platinumMemberThresholdStart) {
                
                $scope.thresholdDirtyChangedList = "You have changed threshold for: ";
                var memberNameAdded = 0;                
                    
                if(bronzeThresholdDirty == true && $scope.data.bronzeMember.threshold != $scope.bronzeMemberThresholdStart){
                    memberNameAdded++;
                    $scope.thresholdDirtyChangedList += $scope.data.bronzeMember.name;
                }
                
                if(memberNameAdded > 0 && silverMemberThresholdDirty == true && $scope.data.silverMember.threshold != $scope.silverMemberThresholdStart){
                    memberNameAdded++;
                    $scope.thresholdDirtyChangedList += ", " + $scope.data.silverMember.name;
                }
                else if(silverMemberThresholdDirty == true && $scope.data.silverMember.threshold != $scope.silverMemberThresholdStart){
                    memberNameAdded++;
                    $scope.thresholdDirtyChangedList += $scope.data.silverMember.name;                    
                }
                
                if(memberNameAdded > 0 && goldMemberThresholdDirty == true && $scope.data.goldMember.threshold != $scope.goldMemberThresholdStart){
                    memberNameAdded++;
                    $scope.thresholdDirtyChangedList += ", " + $scope.data.goldMember.name;
                }
                else if(goldMemberThresholdDirty == true && $scope.data.goldMember.threshold != $scope.goldMemberThresholdStart){
                    memberNameAdded++;
                    $scope.thresholdDirtyChangedList += $scope.data.goldMember.name;                    
                }
                
                if(memberNameAdded > 0 && platinumMemberThresholdDirty == true && $scope.data.platinumMember.threshold != $scope.platinumMemberThresholdStart){
                    memberNameAdded++;
                    $scope.thresholdDirtyChangedList += ", " + $scope.data.platinumMember.name;
                }
                else if(platinumMemberThresholdDirty == true && $scope.data.platinumMember.threshold != $scope.platinumMemberThresholdStart){
                    memberNameAdded++;
                    $scope.thresholdDirtyChangedList += $scope.data.platinumMember.name;                    
                }

                SweetAlert.swal({
                    title: "Are you sure?",
                    text: $scope.thresholdDirtyChangedList,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#AEDEF4", confirmButtonText: "Save everything",
                    cancelButtonText: "Recover old threshold data and save",
                    closeOnConfirm: true,
                    closeOnCancel: false
                },
                        function (isConfirm) {
                            if (isConfirm) {
                                SettingsResource.saveMemberStatusSettings($scope.data, onSuccessGetData, onErrorGetData);
                            }
                            else {
                                $scope.data.bronzeMember.threshold = $scope.bronzeMemberThresholdStart
                                $scope.data.silverMember.threshold = $scope.silverMemberThresholdStart;
                                $scope.data.goldMember.threshold = $scope.goldMemberThresholdStart;
                                $scope.data.platinumMember.threshold = $scope.platinumMemberThresholdStart;
                                
                                SettingsResource.saveMemberStatusSettings($scope.data, onSuccessGetData, onErrorGetData);
                                swal("Threshold data recovered", "Data is saved, but with old threshold data", "success");
                            }
                        }
                );

            } else {
                SettingsResource.saveMemberStatusSettings($scope.data, onSuccessGetData, onErrorGetData);
            }

        }
        
        // beginner benefitsChanged
        $scope.beginnerMemberBenefitsChanged = function(type){
            var count = 0;
            var found = 0;
            $scope.beginnerMemberBenefits = "";
            
            if ($scope.beginnerMember.length == 0) {
                $scope.beginnerMember.push(type);
            } else {
                angular.forEach($scope.beginnerMember, function (item) {
                    count++;
                    if (item == type) {
                        found++;
                        $scope.beginnerMember.splice(count - 1, 1);
                    } else if (found == 0 && $scope.beginnerMember.length == count) {
                        $scope.beginnerMember.push(type);
                    }
                });
            }
            
            angular.forEach($scope.beginnerMember, function(item){
                $scope.beginnerMemberBenefits += "," + item;
            });            
            $scope.data.beginnerMember.benefits = $scope.beginnerMemberBenefits.substring(1, $scope.beginnerMemberBenefits.length);
            
            onSuccessGetData($scope.data);
        }
        
        // bronze benefitsChanged
        $scope.bronzeMemberBenefitsChanged = function(type){
            var count = 0;
            var found = 0;
            $scope.bronzeMemberBenefits = "";
            
            if ($scope.bronzeMember.length == 0) {
                $scope.bronzeMember.push(type);
            } else {
                angular.forEach($scope.bronzeMember, function (item) {
                    count++;
                    if (item == type) {
                        found++;
                        $scope.bronzeMember.splice(count - 1, 1);
                    } else if (found == 0 && $scope.bronzeMember.length == count) {
                        $scope.bronzeMember.push(type);
                    }
                });
            }
            
            angular.forEach($scope.bronzeMember, function(item){
                $scope.bronzeMemberBenefits += "," + item;
            });            
            $scope.data.bronzeMember.benefits = $scope.bronzeMemberBenefits.substring(1, $scope.bronzeMemberBenefits.length);
            
            onSuccessGetData($scope.data);
        }
        
        // silver benefitsChanged
        $scope.silverMemberBenefitsChanged = function(type){
            var count = 0;
            var found = 0;
            $scope.silverMemberBenefits = "";
            
            if ($scope.silverMember.length == 0) {
                $scope.silverMember.push(type);
            } else {
                angular.forEach($scope.silverMember, function (item) {
                    count++;
                    if (item == type) {
                        found++;
                        $scope.silverMember.splice(count - 1, 1);
                    } else if (found == 0 && $scope.silverMember.length == count) {
                        $scope.silverMember.push(type);
                    }
                });
            }
            
            angular.forEach($scope.silverMember, function(item){
                $scope.silverMemberBenefits += "," + item;
            });            
            $scope.data.silverMember.benefits = $scope.silverMemberBenefits.substring(1, $scope.silverMemberBenefits.length);
            
            onSuccessGetData($scope.data);
        }
        
        // gold benefitsChanged
        $scope.goldMemberBenefitsChanged = function(type){
            var count = 0;
            var found = 0;
            $scope.goldMemberBenefits = "";
            
            if ($scope.goldMember.length == 0) {
                $scope.goldMember.push(type);
            } else {
                angular.forEach($scope.goldMember, function (item) {
                    count++;
                    if (item == type) {
                        found++;
                        $scope.goldMember.splice(count - 1, 1);
                    } else if (found == 0 && $scope.goldMember.length == count) {
                        $scope.goldMember.push(type);
                    }
                });
            }
            
            angular.forEach($scope.goldMember, function(item){
                $scope.goldMemberBenefits += "," + item;
            });            
            $scope.data.goldMember.benefits = $scope.goldMemberBenefits.substring(1, $scope.goldMemberBenefits.length);
            
            onSuccessGetData($scope.data);
        }
        
        // platinum benefitsChanged
        $scope.platinumMemberBenefitsChanged = function(type){
            var count = 0;
            var found = 0;
            $scope.platinumMemberBenefits = "";
            
            if ($scope.platinumMember.length == 0) {
                $scope.platinumMember.push(type);
            } else {
                angular.forEach($scope.platinumMember, function (item) {
                    count++;
                    if (item == type) {
                        found++;
                        $scope.platinumMember.splice(count - 1, 1);
                    } else if (found == 0 && $scope.platinumMember.length == count) {
                        $scope.platinumMember.push(type);
                    }
                });
            }
            
            angular.forEach($scope.platinumMember, function(item){
                $scope.platinumMemberBenefits += "," + item;
            });            
            $scope.data.platinumMember.benefits = $scope.platinumMemberBenefits.substring(1, $scope.platinumMemberBenefits.length);
            
            onSuccessGetData($scope.data);
        }
        
        $scope.beginnerActive = true;
        $scope.activeTab = function (type) {
            $scope.beginnerActive = false;
            $scope.bronzeActive = false;
            $scope.silverActive = false;
            $scope.goldActive = false;
            $scope.platinumActive = false;

            if (type == "beginner") {
                $scope.beginnerActive = true;
            }
            if (type == "bronze") {
                $scope.bronzeActive = true;
            }
            if (type == "silver") {
                $scope.silverActive = true;
            }
            if (type == "gold") {
                $scope.goldActive = true;
            }
            if (type == "platinum") {
                $scope.platinumActive = true;
            }
        }
        
        $scope.thresholdChanged = function (type) {

            $scope.saveButtonDisabled = false;       
            
            if (angular.isDefined($scope.data.bronzeMember.threshold)) {
                $scope.data.bronzeMember.threshold = Math.round($scope.data.bronzeMember.threshold);
            }
            if (angular.isDefined($scope.data.silverMember.threshold)) {
                $scope.data.silverMember.threshold = Math.round($scope.data.silverMember.threshold);
            }
            if (angular.isDefined($scope.data.goldMember.threshold)) {
                $scope.data.goldMember.threshold = Math.round($scope.data.goldMember.threshold);
            }
            if (angular.isDefined($scope.data.platinumMember.threshold)) {
                $scope.data.platinumMember.threshold = Math.round($scope.data.platinumMember.threshold);
            }
                
            if (angular.isUndefined($scope.data.bronzeMember.threshold) || $scope.data.bronzeMember.threshold <= 0 || $scope.data.bronzeMember.threshold >= $scope.data.silverMember.threshold) {
                $scope.bronzeMemberThresholdProblem = true;
            } else {
                $scope.bronzeMemberThresholdProblem = false;
            }

            if (angular.isUndefined($scope.data.silverMember.threshold) || $scope.data.silverMember.threshold <= $scope.data.bronzeMember.threshold || $scope.data.silverMember.threshold >= $scope.data.goldMember.threshold) {
                $scope.silverMemberThresholdProblem = true;
            } else {
                $scope.silverMemberThresholdProblem = false;
            }
            if (angular.isUndefined($scope.data.goldMember.threshold) || $scope.data.goldMember.threshold <= $scope.data.silverMember.threshold || $scope.data.goldMember.threshold >= $scope.data.platinumMember.threshold) {
                $scope.goldMemberThresholdProblem = true;
            } else {
                $scope.goldMemberThresholdProblem = false;
            }
            if (angular.isUndefined($scope.data.platinumMember.threshold) || $scope.data.platinumMember.threshold <= $scope.data.goldMember.threshold) {
                $scope.platinumMemberThresholdProblem = true;
            } else {
                $scope.platinumMemberThresholdProblem = false;
            }
            
            if (angular.isUndefined($scope.data.bronzeMember.threshold) || $scope.data.bronzeMember.threshold <= 0 || $scope.data.bronzeMember.threshold >= $scope.data.silverMember.threshold
                    || angular.isUndefined($scope.data.silverMember.threshold) || $scope.data.silverMember.threshold <= $scope.data.bronzeMember.threshold || $scope.data.silverMember.threshold >= $scope.data.goldMember.threshold
                    || angular.isUndefined($scope.data.goldMember.threshold) || $scope.data.goldMember.threshold <= $scope.data.silverMember.threshold || $scope.data.goldMember.threshold >= $scope.data.platinumMember.threshold
                    || angular.isUndefined($scope.data.platinumMember.threshold) || $scope.data.platinumMember.threshold <= $scope.data.goldMember.threshold) {
                $scope.saveButtonDisabled = true;
            }
            
        }

    }
})();



