angular.module('seatonApp')
    .directive('favoriteTable', favoriteTableDirective);

function favoriteTableDirective() {
    'use strict';
    return {
        scope: {
            favorite_table: '=model',
            areas: '=',
            debug: '@',
            readonly: '='
        },
        restrict: 'EA',
        transclude: false,
        templateUrl: 'app/customer/create/partials/favorite_table.html',
        controllerAs: 'ctrl',
        bindToController: true,
        controller: favCtrl
    };
}

favCtrl.$inject = ['$scope'];
function favCtrl($scope) {
    var ctrl = this;
    ctrl.selected_area = {};
    ctrl.resources_with_areas = [];

    $scope.$watch('ctrl.areas', ctrlAreasWatch);

    $scope.$watch('ctrl.favorite_table', ctrlFavWatch);

    function searchResourceAreas(tableId) {
        var ra = _.find(ctrl.resources_with_areas, function (ra) {
            return tableId === ra.resource.id;
        });
        return ra;
    }

    function updateSelectedTable() {
        //console.log('ctrl.favorite_table:' + ctrl.favorite_table);
        //console.log(ctrl.favorite_table);
        if (ctrl.favorite_table) {
            var r = searchResourceAreas(ctrl.favorite_table.id);
            if (r) {
                ctrl.selected_area = r.area;
                ctrl.favorite_table = r.resource;
            }
        }
    }



    //ctrlAreasWatch.$inject = ['newVal'];
    function ctrlAreasWatch(newVal) {
        if (newVal) {
            var flatMap = _.compose(_.flatten, _.map);
            ctrl.resources_with_areas = flatMap(ctrl.areas, function (ar) {
                return _.map(ar.resources, function (resource) {
                    return {resource: resource, area: ar};
                });
            });
            updateSelectedTable();
        }
    }

    //ctrlFavWatch.$inject = ['newVal', 'oldVal'];
    function ctrlFavWatch(newVal, oldVal) {
        if (newVal) {
            updateSelectedTable();
        }
    }
}


