(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('OnlineErrorController', OnlineErrorController);

    OnlineErrorController.$inject = ['$scope', '$state', '$stateParams', 'Log','OnlineReservationContext'];

    function OnlineErrorController($scope, $state, $stateParams, Log, OnlineReservationContext) {

        var vm = this;
        vm.type = OnlineReservationContext.getReference();
        vm.locationId = OnlineReservationContext.getLocationId();
        vm.settings = OnlineReservationContext.getSettings();

        if(!vm.locationId) vm.errorCode=1;
        else vm.errorCode=99;

        Log.debug("OnlineErrorController", "Reference value is " + vm.reference);

    }
})();



