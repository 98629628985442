(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customers.create', {
                parent: 'customers',
                url: '/create/:customerId',
                data: {
                    authorities: ['ROLE_USER']
                    //,pageTitle: 'seatonApp.allergy.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/customer/create/create.html',
                        controller: 'CustomerCreateController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('customers.create');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    PreviousState: [ '$state', function($state) {
                        return {
                            name: $state.current.name,
                            params: $state.params
                        };
                    }]
                }
            });
    }

})();
