'use strict';

RearrangeController.$inject = ['$state', '$scope', '$q', '$document', '$filter', '$stateParams',
    'EventResource', 'ApplicationContext', 'ApplicationProfile', 'Tables', 'SweetAlert','Principal', 'Log','$timeout'];

var DATE_SERVER_FORMAT = 'YYYY-MM-DD';
var TIME_SERVER_FORMAT = 'HH:mm:ss.SSS';
var TIME_DISPLAY_FORMAT = 'hh:mmA';
var DATE_DISPLAY_FORMAT = 'MMM D, YYYY';

function RearrangeController($state, $scope, $q, $document, $filter, $stateParams,
                             EventResource, ApplicationContext, ApplicationProfile, Tables, SweetAlert, Principal, Log,  $timeout) {
    var vm = this;
    vm.eventId = $stateParams.eventId;

    vm.requiredCapacity = 0;
    vm.textColor = "";
    vm.currentCapacity = 0;
    vm.occupancy = 0;
    vm.originalModel = null;

    // holder for save
    vm.date = null;
    vm.startTime = null;
    vm.endTime = null;
    vm.control = null;

    var alertAction = function (event) {

        event.stopImmediatePropagation();
        event.preventDefault();
        //alert("I'm your default action override");
        return true;
    };

    var polyfillApplied = MobileDragDrop.polyfill({
//            dragImageCenterOnTouch: true,
//            dragImageOffset: {
//                x: 0,
//                y: 0
//            },
//            dragStartConditionOverride: allowHorizontalDragOnly,
//            defaultActionOverride: alertAction,
        dragImageTranslateOverride: MobileDragDrop.scrollBehaviourDragImageTranslateOverride,
        // holdToDrag: 300
    });
    try {
        window.addEventListener('touchmove', function () {
        }, {passive: false})
    } catch(e) {}

    //vm.onLoad();

    //vm.onLoad = function() {

        Tables.rearrangeFetch(
            {
                eventId: vm.eventId
            },
            onSuccess,
            onError
        );
    //}

    vm.save = function() {

        if(!vm.tableAssignedToEachEvent($scope.model)) {
            console.log("Tabel not assigned to event.");
            SweetAlert.swal({
                    title: "Please assign table(s) to all events?",
                    text: '',
                    type: "error",
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "OK",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    return;
                }
            );
        } else {

            console.log("All table assigned to event.");

            SweetAlert.swal({
                    title: "Do you want to update reservations?",
                    text: '',
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No"
                    ,closeOnConfirm: true
                },
                function (isConfirm) {

                    if (isConfirm) {
                        Tables.rearrangeSave(
                            {
                                date: vm.date,
                                startTime: vm.startTime,
                                endTime: vm.endTime,
                                control: vm.control,
                                model: $scope.model,
                                selectedEventId: vm.eventId
                            },
                            onSuccessSave,
                            onError
                        );
                    }
                });
        }
    }

    function onSuccessSave(data, headers) {

        onSuccess(data, headers);

        //Log.debug("Saved. Changed="+data.eventChanged);
        if (data.eventChanged) {
            SweetAlert.swal({
                title: "Changes in system since rearrange started. Save canceled.",
                text: "New changes loaded, please continue to rearrange tables.",
                type: "warning",
                showCancelButton: false,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "OK",
                //cancelButtonText: "No",
                closeOnConfirm: true
            });
        } else {
            //dirty workaround for SweetAlert issues
            $timeout(showRedirectAlert, 70);
        }
    }

    function onSuccess(data, headers) {

        $scope.model = data.model;
        $scope.dateDisplay = moment(data.date, DATE_SERVER_FORMAT).format(DATE_DISPLAY_FORMAT);
        $scope.startTimeDisplay = moment(data.startTime, TIME_SERVER_FORMAT).format(TIME_DISPLAY_FORMAT);
        $scope.endTimeDisplay = moment(data.endTime, TIME_SERVER_FORMAT).format(TIME_DISPLAY_FORMAT);
        vm.requiredCapacity = vm.sumRequiredCapacity(data.model);
        vm.originalModel = JSON.parse(JSON.stringify(data.model));

        vm.date = data.date;
        vm.startTime = data.startTime;
        vm.endTime = data.endTime;
        vm.control = data.control;

        Log.debug("RearrangeController", "HTTP request succeed.");
    }

    function onError(error) {
        Log.debug("RearrangeController", "HTTP request error." + error);
    }

    function showRedirectAlert() {
        SweetAlert.swal({
                title: "Reservations changes successfully saved.",
                text: "",
                type: "success",
                showCancelButton: true,
                cancelButtonColor: "#1a94b3",
                confirmButtonColor: "#1ab394",
                cancelButtonText: "Ok, go to reservation center",
                confirmButtonText: "Ok, go to open pre-reservations list"
                ,closeOnConfirm: true
            },
            function (isConfirmed) {
                if (isConfirmed) {
                    $state.go('tables.management',{tab: 'online-res'});
                } else {
                    $state.go('tables.overview');
                }
            });
    }


    vm.restore = function() {
        SweetAlert.swal({
                title: "Restore to initial table assignment.",
                text: 'Please note that the changes from last save will be lost.',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    if(vm.originalModel)
                        $scope.model = JSON.parse(JSON.stringify(vm.originalModel))
                }
            });

    }

    $scope.allowDrop = function(ev) {
        ev.preventDefault();
    }

    $scope.drag = function(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }

    $scope.drop = function(ev) {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        ev.target.appendChild($document.getElementById(data));
    }

    // drag and drop
    $scope.dragoverCallback = function(index, external, type, callback) {
        $scope.logListEvent('dragged over', index, external, type);
        // Invoke callback to origin for container types.
        return true;
    };

    $scope.dropCallback = function(index, item, external, type) {
        $scope.logListEvent('dropped at', index, external, type);
        // Return false here to cancel drop. Return true if you insert the item yourself.
        return item;
    };

    $scope.logEvent = function(message) {
        //console.log(message);
    };

    $scope.logListEvent = function(action, index, external, type) {
        //var message = external ? 'External ' : '';
        //message += type + ' element was ' + action + ' position ' + index;
    };

    $scope.$watch('model', function(model) {
        $scope.modelAsJson = angular.toJson(model, true);
    }, true);

    $scope.sumCapacity = function(container) {
        var total = 0;
        if (container.items) {
            angular.forEach(container.items, function(index) {
                total += index.capacity;
            });
        }
        return total;
    }

    vm.tableAssignedToEachEvent = function(model) {
        var allAssigned = true;
        if (model) {
            angular.forEach(model, function(containers) {
                angular.forEach(containers, function(container) {
                    if(container.type!='available' && $scope.sumCapacity(container)===0) {
                        console.log("Not assigned to container.name="+container.name);
                        allAssigned = false;
                    }
                });
            });
        }
        console.log("All assigned " + allAssigned);
        // all event have at least one table
        return allAssigned;
    }

    vm.totalCapacity = function(model) {
        var total = 0;
        if (model) {
            angular.forEach(model, function(containers) {
                angular.forEach(containers, function(container) {
                    angular.forEach(container.items, function(index) {
                        if(container.type!='available')
                            total += index.capacity;
                    });
                });
            });
        }
        return total;
    }

    vm.sumRequiredCapacity = function(model) {
        var total = 0;
        if (model) {
            angular.forEach(model, function(containers) {
                angular.forEach(containers, function(container) {
                    if(container.type!='available')
                        total += container.covers;
                });
            });
        }
        return total;
    }


    vm.calculateOccupancy = function(currentCapacity) {
        if(currentCapacity>0) {
            vm.occupancy = (vm.requiredCapacity/currentCapacity*100).toFixed(2);
        } else {
            vm.occupancy = 0;
        }

        if(vm.occupancy<50) {
            vm.textColor = "text-danger";
        } else if(vm.occupancy<90 && vm.occupancy >=50) {
            vm.textColor = "text-warning";
        } else {
            vm.textColor = "text-navy";
        }
        return vm.occupancy;
    }


    // Initialize model
    /*$scope.model = [[], []];
    var id = 10;
    angular.forEach(['all', 'move', 'copy', 'link', 'copyLink', 'copyMove'], function(effect, i) {
        var container = {items: [], effectAllowed: effect};
        for (var k = 0; k < 7; ++k) {
            container.items.push({label: effect + ' ' + id++, effectAllowed: effect});
        }
        $scope.model[i % $scope.model.length].push(container);
        console.log('Container;'+container)
    });*/

    /*$scope.model = [
        [
            {
                "items": [
                    {
                        "id": 12345,
                        "name": "A012",
                        "capacity": 4,
                        "areaColor": "#DD6633",
                        "effectAllowed": "move"

                    },
                    {
                        "id": 12346,
                        "name": "A011",
                        "capacity": 4,
                        "areaColor": "#DD6633",
                        "effectAllowed": "move"

                    }
                ],
                "type": "event",
                "panel": "panel-success",
                "effectAllowed": "move",
                "date": "June 7th 2020",
                "start": "6:00 PM",
                "end": "6:00 PM",
                "customer": "John Legend",
                "customerEmail": "legend@gmail.com",
                "customerPhone": "(345) 123-1234",
                "note": "",
                "covers": 5

            }
        ],
        [
            {
                "items": [
                    {
                        "id": 12345,
                        "name": "C001",
                        "capacity": 4,
                        "areaColor": "#3366DD",
                        "effectAllowed": "move"

                    },
                    {
                        "id": 12346,
                        "name": "C002",
                        "capacity": 4,
                        "areaColor": "#3366DD",
                        "effectAllowed": "move"

                    }
                ],
                "type": "event",
                "panel": "panel-default",
                "effectAllowed": "move",
                "date": "June 7th 2020",
                "start": "6:00 PM",
                "end": "6:00 PM",
                "customer": "John Legend",
                "customerEmail": "legend@gmail.com",
                "customerPhone": "(345) 123-1234",
                "note": "Table by window please",
                "covers": 7
            },
            {
                "items": [
                    {
                        "id": 12345,
                        "name": "A007",
                        "capacity": 4,
                        "areaColor": "#DD6633",
                        "effectAllowed": "move"

                    }
                ],
                "type": "event",
                "panel": "panel-default",
                "effectAllowed": "move",
                "date": "June 7th 2020",
                "start": "6:00 PM",
                "end": "6:00 PM",
                "customer": "John Legend",
                "customerEmail": "legend@gmail.com",
                "customerPhone": "(345) 123-1234",
                "note": "",
                "covers": 3
            },
            {
                "items": [
                    {
                        "id": 12345,
                        "name": "B005",
                        "capacity": 2,
                        "areaColor": "#66DD33",
                        "effectAllowed": "move"

                    },
                    {
                        "id": 12346,
                        "name": "B011",
                        "capacity": 2,
                        "areaColor": "#66DD33",
                        "effectAllowed": "move"

                    }
                ],
                "type": "event",
                "panel": "panel-default",
                "effectAllowed": "move",
                "date": "June 7th 2020",
                "start": "6:00 PM",
                "end": "8:00 PM",
                "customer": "John Legend",
                "customerEmail": "legend@gmail.com",
                "customerPhone": "(345) 123-1234",
                "note": "",
                "covers": 4
            },

        ],
        [
            {
                "items": [
                    {
                        "id": 12345,
                        "name": "A002",
                        "capacity": 4,
                        "areaColor": "#FF6633",
                        "effectAllowed": "move"

                    },
                    {
                        "id": 12346,
                        "name": "B011",
                        "capacity": 4,
                        "areaColor": "#66DD33",
                        "effectAllowed": "move"

                    },
                    {
                        "id": 12346,
                        "name": "B011",
                        "capacity": 4,
                        "areaColor": "#66DD33",
                        "effectAllowed": "move"

                    }
                ],
                "type": "available",
                "panel": "panel-primary",
                "effectAllowed": "move",
                "date": "",
                "start": "6:00 PM",
                "end": "8:00 PM",
                "customer": "",
                "customerEmail": "",
                "customerPhone": "",
                "note": "",
                "covers": 12

            }
        ]
    ]
*/

   /* $scope.model = {
        event:
            {
                "resources": [
                    {
                        "id": 12345,
                        "name": "A012",
                        "capacity": 4,
                        "areaColor": "#FF0000",
                        "effectAllowed": "move"
                    },
                    {
                        "id": 12346,
                        "name": "A011",
                        "capacity": 2,
                        "areaColor": "#FF0000",
                        "effectAllowed": "move"
                    }
                ],
                "effectAllowed": "none",
                "date": "June 7th 2020",
                "start": "6:00 PM",
                "end": "6:00 PM",
                "customer": "John Legend",
                "customerEmail": "legend@gmail.com",
                "customerPhone": "(345) 123-1234",
                "note": "",
                "covers": 5
            },
        matchingEvents:
            [
                {
                    "resources": [
                        {
                            "id": 12346,
                            "name": "A014",
                            "capacity": 4,
                            "areaColor": "#FF0000",
                            "effectAllowed": "move"
                        }
                    ],
                    "effectAllowed": "none",
                    "date": "June 7th 2020",
                    "start": "6:00 PM",
                    "end": "6:00 PM",
                    "customer": "John Legend",
                    "customerEmail": "legend@gmail.com",
                    "customerPhone": "(345) 123-1234",
                    "note": "Outside table please.",
                    "covers": 4
                },
                {
                    "resources": [
                        {
                            "id": 12345,
                            "name": "A012",
                            "capacity": 4,
                            "areaColor": "#FF0000",
                            "effectAllowed": "move"
                        },
                        {
                            "id": 12346,
                            "name": "B001",
                            "capacity": 4,
                            "areaColor": "#00FF00",
                            "effectAllowed": "move"
                        }
                    ],
                    "effectAllowed": "none",
                    "date": "June 7th 2020",
                    "start": "6:00 PM",
                    "end": "6:00 PM",
                    "customer": "John Legend",
                    "customerEmail": "legend@gmail.com",
                    "customerPhone": "(345) 123-1234",
                    "note": "",
                    "covers": 6
                },
                {
                    "resources": [
                        {
                            "id": 12346,
                            "name": "B011",
                            "capacity": 2,
                            "areaColor": "#00FF00",
                            "effectAllowed": "move"
                        }
                    ],
                    "effectAllowed": "none",
                    "date": "June 7th 2020",
                    "start": "6:00 PM",
                    "end": "6:00 PM",
                    "customer": "John Legend",
                    "customerEmail": "legend@gmail.com",
                    "customerPhone": "(345) 123-1234",
                    "note": "Table at window.",
                    "covers": 5
                }
            ],
        availableResources:
            [
                {
                    "id": 21234,
                    "name": "B012",
                    "capacity": 4,
                    "areaColor": "#00FF00",
                    "effectAllowed": "move"
                },
                {
                    "id": 22346,
                    "name": "A009",
                    "capacity": 2,
                    "areaColor": "#FF0000",
                    "effectAllowed": "move"
                },
                {
                    "id": 12116,
                    "name": "A009",
                    "capacity": 6,
                    "areaColor": "#FF0000",
                    "effectAllowed": "move"
                },
                {
                    "id": 12226,
                    "name": "C009",
                    "capacity": 4,
                    "areaColor": "#0000FF",
                    "effectAllowed": "move"
                }
            ]

    }*/

}

angular.module('seatonApp').controller('RearrangeController', RearrangeController);
