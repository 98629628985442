angular.module('seatonApp')
    .directive('emailList', emailListCtrl);

function emailListCtrl() {
    'use strict';
    return {
        scope: {
            items: '=ngModel'
        },
        restrict: 'E',
        transclude: true,
        require: ['ngModel', '^form'],
        templateUrl: 'app/customer/create/partials/email_list.html',
        link: function (scope, elem, attrs, ctrls) {
            scope.ngModelCtrl = ctrls[0];
            scope.ngFormCtrl = ctrls[1];
        },
        controller: emailCtrl
    }
}

emailCtrl.$inject = ['$scope'];
function emailCtrl($scope) {

    $scope.newItem = {};

    $scope.addItem = function () {
        var propertyName = 'email';

        //if new email not defined or email input field is empty  - return
        if (!$scope.newItem[propertyName] || $scope.newItem[propertyName].trim() == '') {
            $scope.newItem = {};
            return;
        }

        //if format of email is not valid - also return
        if (!$scope.ngFormCtrl.email.$valid) {
            return;
        }

        var index = $scope.newItem.index;
        if (!_.isUndefined(index)) {
            delete $scope.newItem.index;
            $scope.items[index] = $scope.newItem;
        } else {
            if ($scope.newItem[propertyName]) {
                $scope.items.push($scope.newItem);
            }
        }
        $scope.newItem = {};
        validate();
    };

    $scope.editItem = function (index) {
        $scope.newItem = _.clone($scope.items[index]);
        $scope.newItem.index = index;
    };

    $scope.deleteItem = function (index) {
        $scope.items.splice(index, 1);
        validate();
    };

    $scope.makePrimary = function (index) {
        _.each($scope.items, function (email) {
            email.primaryEmail = false;
        });
        $scope.items[index].primaryEmail = true;
        validate();
    };

    function validate() {
        $scope.ngModelCtrl.$setDirty();
        $scope.ngModelCtrl.$validate();
    }
}
