(function () {
    'use strict';

    angular
        .module('seatonApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customers.list', {
                parent: 'customers',
                url: '/list/:listType?keyword',
                data: {
                    authorities: ['ROLE_USER']
                    //,pageTitle: 'seatonApp.allergy.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/customer/list/list.html',
                        controller: 'CustomerListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'totalSpend,desc',
                        squash: true
                    },
                    search: null,
                    listType: 'totalSpend'
                    , keyword: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        //$translatePartialLoader.addPart('allergy');
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('global');
                        //$translatePartialLoader.addPart('reporting.aggregated');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
