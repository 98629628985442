'use strict';

TestController.$inject = ['$rootScope', '$scope','$q', '$state', '$stateParams', 'CloverResource', 'Notification'];

function TestController($rootScope, $scope, $q, $state, $stateParams, CloverResource, Notification) {
    var vm = this;

    CloverResource.cloverTest();
    //CloverResource.healthCheck();

}

angular.module('seatonApp').controller('TestController', TestController);
