(function() {
    'use strict';

    angular
        .module('seatonApp')
        .factory('loadingInterceptor', loadingInterceptor);

    loadingInterceptor.$inject = ['$q', '$rootScope', '$injector'];

    function loadingInterceptor ($q, $rootScope, $injector) {

        // reset please-wait div block, default message + hide div block
        complete();

        var $http;

        var service = {

            request: function ( config ) {
                $rootScope.$broadcast('_start-please_wait_');
                return config;
            },

            response: function ( response ) {
                // get $http via $injector because of circular dependency problem
                $http = $http || $injector.get( '$http' );
                if ( $http.pendingRequests.length < 1 ) {
                    complete();
                }
                return response;
            },

            responseError: function ( response ) {
                // get $http via $injector because of circular dependency problem
                $http = $http || $injector.get( '$http' );
                if ( $http.pendingRequests.length < 1 ) {
                    complete();
                }
                return $q.reject( response );
            }
        };

        return service;

        function complete() {
            // dispose please wait messgae and modl dialog, set loading message to default value
            $rootScope.$broadcast('_end-please_wait_');
            $rootScope.LoadingMessage = "Processing ...";
        }
    }

})();

