(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('IVRController', IVRController);

    IVRController.$inject = ['$rootScope', '$scope', '$state', 'Log', 'ApplicationProfile', 'LocationResource', 'SweetAlert', 'Principal'];

    function IVRController($rootScope, $scope, $state, Log, ApplicationProfile, LocationResource, SweetAlert, Principal) {

        var vm = this;

        vm.IVRReservationEnabled = ApplicationProfile.isIVRReservationEnabled();
        vm.IVRReservationText = ApplicationProfile.upgradeTierText("IVRReservation");
        vm.openWindow = ApplicationProfile.openAppCloverPage;

        vm.requestIVR = requestIVR;
        vm.activatePhone = activatePhone;
        vm.newPhone = null;

        loadLocation();

        function loadLocation() {
            return LocationResource.getLocation({}, function (location) {
                vm.location = location;
                vm.newPhone = vm.location.smsPhoneNumber;
            }).$promise;
        }

        function requestIVR() {

            LocationResource.requestIVR(
                {}
                ,
                function onSuccess(data) {
                    vm.location = data;
                    vm.newPhone = vm.location.smsPhoneNumber;
                    console.log("success:" + data);
                    SweetAlert.swal("Request sent successfully", "IVR activation in progress, within 24 hours dedicated phone number for IVR reservations will be assigned.", "success");
                }
                ,
                function onError(data) {
                    SweetAlert.swal("Unable to send request", "There was issue with sending request. Please try again or contact support. (email: support@seaton.site)", "error");
                    console.log("error:" + data);
                }
            );
        }

        function activatePhone() {

            LocationResource.activateIvrPhone(
                {smsPhone: vm.newPhone}
                ,
                function onSuccess(data) {
                    vm.location = data;
                    vm.newPhone = vm.location.smsPhoneNumber;
                    console.log("success:" + data);
                    SweetAlert.swal("SmsPhoneNumber changed", "", "success");
                }
                ,
                function onError(data) {
                    SweetAlert.swal("Error occured", "", "error");
                    console.log("error:" + data);
                }
            );
        }


        vm.account = null;

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

    }
})();



