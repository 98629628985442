(function () {
    'use strict';

    angular
        .module('seatonApp')
        .controller('ApplicationController', ApplicationController);

    ApplicationController.$inject = ['$scope', '$state'];

    function ApplicationController($scope, $state) {

        var vm = this;

        // slider
        $("#slider_meals").ionRangeSlider({
            type: 'double',
            min: 7,
            max: 23,
            from: 11.50,
            to: 18.00,
            postfix: "pm",
            prettify: true,
            grid: true,
            decorate_both: true,
            grid_snap: true
        });

        $("#slider_duration_breakfast").ionRangeSlider({
            min: 0,
            max: 240,
            step: 5,
            from: 45,
            grid: false,
            decorate_both: true,
            grid_snap: true,

            prettify: function (num) {
                return ((num/60) | 0) + ':' + num%60 +'h';
            }
        });

        $("#slider_duration_lunch").ionRangeSlider({
            min: 0,
            max: 240,
            step: 5,
            from: 75,
            grid: false,
            decorate_both: true,
            grid_snap: true,

            prettify: function (num) {
                return ((num/60) | 0) + ':' + num%60 +'h';
            }
        });

        $("#slider_duration_dinner").ionRangeSlider({
            min: 0,
            max: 240,
            step: 5,
            from: 120,
            grid: false,
            decorate_both: true,
            grid_snap: true,

            prettify: function (num) {
                return ((num/60) | 0) + ':' + num%60 +'h';
            }
        });

    }
})();



