(function () {
    'use strict';

    angular
        .module('seatonApp')
        .factory('ApplicationProfile', ApplicationProfile);

    ApplicationProfile.$inject = ['$window', 'ApplicationContext'];

    function ApplicationProfile($window, ApplicationContext) {

        var service = {
            openAppCloverPage : openAppCloverPage,
            countStrategy: countStrategy,
            exceedBehavior: exceedBehavior,
            limitType: limitType,
            getReservationLimit: getReservationLimit,
            getWaitlistLimit: getWaitlistLimit,
            getOnlineReservationLimit: getOnlineReservationLimit,
            isCustomerGrowthTrendEnabled: isCustomerGrowthTrendEnabled,
            isCustomerMemberTypeEnabled: isCustomerMemberTypeEnabled,
            isCustomerProfileSpendTrendEnabled: isCustomerProfileSpendTrendEnabled,
            isCustomerProfileAggregatedSpendTrendEnabled: isCustomerProfileAggregatedSpendTrendEnabled,
            isTablesTodayVsEnabled: isTablesTodayVsEnabled,
            isReportEmployeePerformanceEnabled: isReportEmployeePerformanceEnabled,
            isReportAggregatedRevenueEnabled: isReportAggregatedRevenueEnabled,
            isReportOccupancyRateEnabled: isReportOccupancyRateEnabled,
            isEmployeeWeeklyPerformanceEnabled: isEmployeeWeeklyPerformanceEnabled,
            isEmployeeMonthlyPerformanceEnabled: isEmployeeMonthlyPerformanceEnabled,
            isInternationalSmsEnabled: isInternationalSmsEnabled,
            isEventCreateCustomerStatsEnabled: isEventCreateCustomerStatsEnabled,
            isEventChangeHistoryEnabled: isEventChangeHistoryEnabled,
            isOnlineReservationEnabled: isOnlineReservationEnabled,
            isIVRReservationEnabled: isIVRReservationEnabled,
            getIVRReservationLimit: getIVRReservationLimit,
            upgradeTierText: upgradeTierText
        };

        return service;

        function openAppCloverPage() {
            var url = "https://www.clover.com/appmarket/apps/VW368JQ4FJ6SY?clientCountry=US";
            if(ApplicationContext.getLocale() != "en-US") {
                // TODO fix once EU market ready, GB for now to distinguish while testing
                url = "https://www.clover.com/appmarket/apps/VW368JQ4FJ6SY?clientCountry=GB";
            }
            var win = $window.open(url, '_blank');
            win.focus();
        }

        function isInternationalSmsEnabled() {
            var enabled = ApplicationContext.getLocation().profile.internationalSms;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isEventCreateCustomerStatsEnabled() {
            var enabled = ApplicationContext.getLocation().profile.eventCreateCustomerStatistic;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isEventChangeHistoryEnabled() {
            var enabled = ApplicationContext.getLocation().profile.eventDetailsChangeHistory;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isOnlineReservationEnabled() {
            var enabled = ApplicationContext.getLocation().profile.onlineReservation;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isIVRReservationEnabled() {
            var enabled = ApplicationContext.getLocation().profile.ivrReservation;
            if(enabled == undefined) return true;
            return enabled;
        }

        function countStrategy() {
            var countStrategy = ApplicationContext.getLocation().profile.limitation.countStrategy;
            if(countStrategy == undefined) return 'none';
            return countStrategy;
        }

        function exceedBehavior() {
            var exceedBehavior = ApplicationContext.getLocation().profile.limitation.exceedBehavior;
            if(exceedBehavior == undefined) return 'none';
            return exceedBehavior;
        }

        function limitType() {
            var limitType = ApplicationContext.getLocation().profile.limitation.limitType;
            if(limitType == undefined) return 'none';
            return limitType;
        }

        function getReservationLimit() {
            var limit = ApplicationContext.getLocation().profile.limitation.reservation;
            if(limit == undefined) return -1;
            return limit;
        }

        function getWaitlistLimit() {
            var limit = ApplicationContext.getLocation().profile.limitation.waitlist;
            if(limit == undefined) return -1;
            return limit;
        }

        function getOnlineReservationLimit() {
            var limit = ApplicationContext.getLocation().profile.limitation.onlineReservation;
            if(limit == undefined) return -1;
            return limit;
        }

        function getIVRReservationLimit() {
            var limit = ApplicationContext.getLocation().profile.limitation.ivrReservation;
            if(limit == undefined) return -1;
            return limit;
        }


        function isCustomerGrowthTrendEnabled() {
            var enabled = ApplicationContext.getLocation().profile.customer.dashboardGrowthTrend;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isCustomerMemberTypeEnabled() {
            var enabled = ApplicationContext.getLocation().profile.customer.dashboardMemberTypeStatistics;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isCustomerProfileSpendTrendEnabled() {
            var enabled = ApplicationContext.getLocation().profile.customer.profileSpendTrend;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isCustomerProfileAggregatedSpendTrendEnabled() {
            var enabled = ApplicationContext.getLocation().profile.customer.profileAggregatedSpendTrend;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isTablesTodayVsEnabled() {
            var enabled = ApplicationContext.getLocation().profile.tables.dashboardTodayVs;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isReportEmployeePerformanceEnabled() {
            var enabled = ApplicationContext.getLocation().profile.reports.employeePerformance;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isReportAggregatedRevenueEnabled() {
            var enabled = ApplicationContext.getLocation().profile.reports.aggregatedRevenue;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isReportOccupancyRateEnabled() {
            var enabled = ApplicationContext.getLocation().profile.reports.occupancy;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isEmployeeWeeklyPerformanceEnabled() {
            var enabled = ApplicationContext.getLocation().profile.settings.employeeWeeklyPerformance;
            if(enabled == undefined) return true;
            return enabled;
        }

        function isEmployeeMonthlyPerformanceEnabled() {
            var enabled = ApplicationContext.getLocation().profile.settings.employeeMonthlyPerformance;
            if(enabled == undefined) return true;
            return enabled;
        }


        function upgradeTierText(name) {

            var text = "Upgrade to ";

            switch(name) {
                case "CustomerMembershipTypeStatistic": text = text + "PROFESSIONAL"; break;
                case "CustomerGrowthTrend": text = text + "PAY AS YOU GO, STANDARD or PROFESSIONAL"; break;
                case "TablesTodayVs": text = text + "PAY AS YOU GO, STANDARD or PROFESSIONAL"; break;
                case "ReportEmployeePerformance": text = text + "PAY AS YOU GO, STANDARD or PROFESSIONAL"; break;
                case "ReportAggregatedRevenue": text = text + "PROFESSIONAL"; break;
                case "ReportOccupancyRate": text = text + "PROFESSIONAL"; break;
                case "EmployeeWeeklyPerformance": text = text + "PAY AS YOU GO, STANDARD or PROFESSIONAL"; break;
                case "EmployeeMonthlyPerformance": text = text + "PROFESSIONAL"; break;
                case "CreateReservation": return "Upgrade subscription to activate this functionality."; break;
                case "EventChangeHistory": return "Upgrade subscription to activate this functionality."; break;
                case "ProfileSpendTrend": text = text + "PAY AS YOU GO, STANDARD or PROFESSIONAL"; break;
                case "ProfileAggregatedSpendTrend": text = text + "PROFESSIONAL"; break;
                case "IVRReservation": text = text + "STANDARD or PROFESSIONAL"; break;
            }

            text = text + " tier(s) to activate this functionality.";
            return text;

        }

    }
})();
