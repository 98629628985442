(function() {
    'use strict';

    angular
        .module('seatonApp')
        .factory('Log', Log);

    Log.$inject = ['$rootScope', '$http','ApplicationContext'];

    function Log ($rootScope, $http, ApplicationContext) {

        var service = {
            debug: debug,
            info: info,
            warn: warn,
            error: error
        };

        return service;

        function debug(scriptName, message) {
            log(4, "DEBUG", scriptName, message);
        }

        function info(scriptName, message) {
            log(3, "INFO", scriptName, message);
        }

        function warn(scriptName, message) {
            log(2, "WARNING", scriptName, message);
        }

        function error(scriptName, message) {
            log(1, "ERROR", scriptName, message);
        }

        function log(severity, prepend, scriptName, message) {
            if($rootScope.severity==undefined) {
                $rootScope.severity = 1;
            }

            console.log(prepend+" -> "+scriptName+" : "+message);
            if(severity<=$rootScope.severity) {

                var data = {
                    severity: $rootScope.severity,
                    merchantId: ApplicationContext.getMerchantID(),
                    employeeId: ApplicationContext.getEmployeeID(),
                    message: scriptName + ":" + message
                };

                //console.log("Sending to server:"+data.severity+" message:"+data.message);
                //return $http.post('api/admin/log', data).success(logSuccess);

            }

            function logSuccess(data, status, headers) {
                $rootScope.severity = data.severity;
                console.log("Set LOG severity to "+$rootScope.severity);
            }

        }

    }
})();
