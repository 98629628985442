'use strict';

angular.module('seatonApp')
    .directive('ngIncludeTemplate', function () {
        return {
            templateUrl: function (elem, attrs) {
                return attrs.ngIncludeTemplate;
            },
            restrict: 'A',
            scope: {
                'ngIncludeVariables': '&'
            },
            link: function (scope, elem, attrs) {
                var key, results, value, vars;
                vars = scope.ngIncludeVariables();
                results = [];
                for (key in vars) {
                    value = vars[key];
                    results.push(scope[key] = value);
                }
                return results;
            }
        };
    });
