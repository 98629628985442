(function() {
    'use strict';

    angular
        .module('seatonApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'ApplicationContext'];

    function LoginController ($rootScope, $state, $timeout, Auth, ApplicationContext) {
        var vm = this;

        vm.login = login;
        vm.cancel = cancel;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;

        ApplicationContext.storeAppContext("ADMIN","ADMIN ACCESS","ADMIN ACCESS","ADMIN ACCESS");

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function login (event) {
            event.preventDefault();

            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $state.go('account.locations');
            }).catch(function () {
                vm.authenticationError = true;
            });
        }
    }
})();
