'use strict';

angular.module('seatonApp')
    .directive('level', function () {
        return {
            restrict: 'EA',
            scope: {
                'value': '=',
                'display': '@',
                'invert': '@'
            },
            transclude: true,
            template: '<span style="white-space: nowrap"><span ng-if="value>0"><span class="{{colorLevelUp}}"><i ng-if="display == \'left\'" class="fa fa-level-up"></i><ng-transclude></ng-transclude><i ng-if="display == \'right\'" class="fa fa-level-up"></i></span></span>' +
            '<span ng-if="value==0"><span class="{{colorEqual}}"><ng-transclude></ng-transclude></span></span>' +
            '<span ng-if="value<0"><span class="{{colorLevelDown}}"><i ng-if="display == \'left\'" class="fa fa-level-down"></i><ng-transclude></ng-transclude><i ng-if="display == \'right\'" class="fa fa-level-down"></i></span></span></span>',
            controller: levelCtrl
        };
    });

levelCtrl.$inject = ['$scope'];
function levelCtrl($scope) {
    $scope.colorEqual = 'text-success';
    $scope.colorLevelUp = 'text-navy';
    $scope.colorLevelDown = 'text-danger';
    if ($scope.invert == 'true') {
        $scope.colorLevelUp = 'text-danger';
        $scope.colorLevelDown = 'text-navy';
    }
};

angular.module('seatonApp').directive('levelPct', ['Reporting', function (Reporting) {
    return {
        restrict: 'EA',
        scope: {
            'val1': '=',
            'val2': '=',
            'display': '@',
            'desc': '@',
            'desc1': '@',
            'desc2': '@',
            'invert': '@'
        },
        transclude: true,
        template: '<span style="white-space: nowrap" uib-tooltip="{{ desc }} ({{ desc1 }}: {{ val1 | number: 2 }} ; {{ desc2 }}: {{ val2 | number: 2 }}).">' +
        '<span ng-if="val1>val2"><span class="{{colorLevelUp}}"><i ng-if="display == \'left\'" class="fa fa-level-up"></i>{{calcPct(val1, val2) | percentage:2}}<ng-transclude></ng-transclude><i ng-if="display != \'left\'" class="fa fa-level-up"></i></span></span>' +
        '<span ng-if="val1==val2"><span class="{{colorEqual}}">{{ 0 | percentage:2}}<ng-transclude></ng-transclude></span></span>' +
        '<span ng-if="val1<val2"><span class="{{colorLevelDown}}"><i ng-if="display == \'left\'" class="fa fa-level-down"></i>{{calcPct(val1, val2) | percentage:2}}<ng-transclude></ng-transclude><i ng-if="display != \'left\'" class="fa fa-level-down"></i></span></span></span>',
        controller: levelPctCtrl
    };

}]);

levelPctCtrl.$inject = ['$scope', 'Reporting'];
function levelPctCtrl($scope, Reporting) {
    //console.log('val1:' + $scope.val1 + '  val2:' + $scope.val2);
    //console.log($scope);
    $scope.calcPct = Reporting.calcPct;
    $scope.colorEqual = 'text-success';
    $scope.colorLevelUp = 'text-navy';
    $scope.colorLevelDown = 'text-danger';
    if ($scope.invert == 'true') {
        $scope.colorLevelUp = 'text-danger';
        $scope.colorLevelDown = 'text-navy';
    }
};
