'use strict';

angular.module('seatonApp')
    .factory('SettingsResource',settingsResource );

settingsResource.$inject = ['$resource', 'ApplicationContext'];
function settingsResource($resource, ApplicationContext) {

        return $resource('api/v1/merchants/_MERCHANTID_/areas', {}, {

            'getAreasAndTables': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/areas', isArray: true},

            'createArea': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/settings/areas'},
            'updateArea': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/areas'},
            'deleteArea': {method: 'DELETE', url: 'api/v1/merchants/_MERCHANTID_/settings/areas/:id'},

            'createTable': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/settings/resources'},
            'updateTable': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/resources'},
            'deleteTable': {method: 'DELETE', url: 'api/v1/merchants/_MERCHANTID_/settings/resources/:id'},

            'saveGeneralSettings': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/general'},
            'getGeneralSettings': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/general'},
            'getLocationData': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/general/location'},

            'getWorkTimeSettings': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime'},
            'addBusinessHours': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/business_hours'},
            'deleteBusinessHours': {method: 'DELETE', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/business_hours/:id'},
            'syncWithClover': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/sync/:syncClover'},
            'addNonWorkingDay': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/non_working_day'},
            'deleteNonWorkingDay': {method: 'DELETE', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/non_working_day/:id'},
            'addPredefinedBookingTime': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/predefined_booking_times'},
            'deletePredefinedBookingTime': {method: 'DELETE', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/predefined_booking_times/:id'},
            'enableTimeSlots': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/enable_predefined_booking_times/:enableTimeSlots'},
            'sameForAllDeysInWeek': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/use_same_for_all/:useSame'},
            'addSpecialDayWorkingHours': {method: 'POST', url: 'api/v1/merchants/_MERCHANTID_/settings/worktime/special_day'},

            'saveNotificationSettings': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/notification'},
            'getNotificationSettings': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/notification'},

            'saveMemberStatusSettings': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/memberstatus'},
            'getMemberStatusSettings': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/memberstatus'},

            'saveAlertsSettings': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/alerts'},
            'getAlertsSettings': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/alerts'},

            'saveOnlineWaitlistCustomization': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/onlineWaitlist'},
            'getOnlineWaitlistCustomization': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/onlineWaitlist'},
            'saveOnlineReservationCustomization': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/onlineReservation'},
            'getOnlineReservationCustomization': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/onlineReservation'},
            'getAvailabilityIntegrationScript': {
                method: 'GET',
                url: 'api/v1/merchants/_MERCHANTID_/settings/onlineWaitlist/integrationScript',
                transformResponse: function (data) {
                    // workaround to prevent angular from converting the plain text response to an array!
                    return {content: data};
                  }
                },

            'getSeatingOptionSettings': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/seatingOption/'},
            'saveSeatingOption': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/seatingOption'},
            'deleteSeatingOption': {method: 'DELETE', url: 'api/v1/merchants/_MERCHANTID_/settings/seatingOption/:id'},
            'reOrderSeatingOption': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/seatingOption/reorder'},
            'canActivateReservePage': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/seatingOption/canActivate'},

            'getProductSettings': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/product/'},
            'changeProductType': {method: 'GET', url: 'api/v1/merchants/_MERCHANTID_/settings/product/change_type/:productType'},
            'saveMealSettings': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/product/meal'},
            'saveDurationSettings': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/product/duration'},
            'saveProduct': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/product/', isArray: true},
            'deleteProduct': {method: 'DELETE', url: 'api/v1/merchants/_MERCHANTID_/settings/product/:id', isArray: true},
            'reOrderProduct': {method: 'PUT', url: 'api/v1/merchants/_MERCHANTID_/settings/product/reorder', isArray: true},
            'getSegmentedProductList' : {method: 'GET', url: "api/v1/merchants/_MERCHANTID_/settings/product/segmentedProductList", isArray: false}

        });

}

